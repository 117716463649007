import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import update from 'immutability-helper';
import {
  DeviceConfigGridQuery,
  DeviceConfigQuery,
  DeviceConfigDropdownQuery,
} from '../../../graphql/queries/DeviceConfigQueries';
import DeviceConfigForm from './DeviceConfigForm';
import FormBase from '../../form/FormBase';
import handleDeviceConfigMutation from '../../../graphql/functions/handleDeviceConfigMutation';
import {
  CreateDeviceConfigMutation,
  UpdateDeviceConfigMutation,
} from '../../../graphql/mutations/DeviceConfigMutations';

class DeviceConfigUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    if (typeof values === 'object') {
      let mutation;
      if (this.props.duplicate) {
        delete values.id;
        mutation = this.props.CreateDeviceConfigMutation;
      } else {
        // store deviceConfig id
        values.id = this.props.deviceConfig.id;
        mutation = this.props.UpdateDeviceConfigMutation;
      }

      handleDeviceConfigMutation({
        values,
        mutation,
        onSuccess: this.props.onSuccess,
        refetchQueries: [
          { query: DeviceConfigGridQuery },
          { query: DeviceConfigDropdownQuery },
        ],
      });
    }
  }

  render() {
    // render form with deviceConfig values pre-loaded
    const {
      data: { loading, error, deviceConfig },
    } = this.props;
    return (
      <FormBase loading={loading} error={error} header={this.props.header}>
        <DeviceConfigForm
          initialValues={deviceConfig}
          onSubmit={this.handleSubmit}
        />
      </FormBase>
    );
  }
}

export default compose(
  graphql(UpdateDeviceConfigMutation, {
    name: 'UpdateDeviceConfigMutation',
    options: (props) => ({ variables: { id: props.deviceConfig.id } }),
  }),
  graphql(CreateDeviceConfigMutation, {
    name: 'CreateDeviceConfigMutation',
  }),
  graphql(DeviceConfigQuery, {
    name: 'DeviceConfigQuery',
    options: (props) => ({ variables: { id: props.deviceConfig.id }, fetchPolicy: 'network-only' }),
    props: ({
      ownProps,
      DeviceConfigQuery: { loading, error, deviceConfig },
    }) => {
      if (deviceConfig) {
        if (
          deviceConfig.indicatorWidgets
          && deviceConfig.indicatorWidgets.length > 0
        ) {
          console.log('indicatorWidgets');
          const deviceIndicatorWidgets = deviceConfig.indicatorWidgets.map(
            (widget) => {
              widget = update(widget, {
                widgetId: {
                  $set: [widget.widgetId],
                },
              });
              return widget;
            },
          );
          deviceConfig = update(deviceConfig, {
            indicatorWidgets: {
              $set: deviceIndicatorWidgets,
            },
          });
          console.log('indicatorWidgets3', deviceConfig.indicatorWidgets[0]);
        }
      }
      return {
        data: {
          deviceConfig,
          loading,
          error,
        },
      };
    },
  }),
)(DeviceConfigUpdate);
