import React, { Component } from 'react';
import Button from '@material-ui/core/Button';

class FormSubmitButton extends Component {
  constructor(props) {
    super(props);
    this.timeout = null;
    this.state = {
      preventSubmit: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  // prevent double click on FormSubmitButton
  // store in state "preventSubmit" which defaults to false
  // after click, set preventSubmit to true
  // after short delay, reset preventSubmit to false
  handleClick(event) {
    if (this.state.preventSubmit) {
      // prevent submission if preventSubmit === true
      event.preventDefault();
    } else {
      // set preventSubmit to true
      this.setState({ preventSubmit: true });
      // after short delay, reset preventSubmit to false
      this.timeout = setTimeout(() => {
        this.setState({ preventSubmit: false });
      }, 500);
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  render() {
    const { pristine, submitting } = this.props;
    return (
      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={pristine || submitting}
        onClick={this.handleClick}
      >
        Submit
      </Button>
    );
  }
}
export default FormSubmitButton;
