import { gql } from '@apollo/client';
import { modelDashboardFragment } from '../fragments';

const ModelDashboardGridQuery = gql`
  query ModelDashboardGridQuery {
    modelDashboards {
      ...modelDashboardAll
    }
  }
  ${modelDashboardFragment}
`;

const ModelDashboardQuery = gql`
  query modelDashboard($id: ID!) {
    modelDashboard(id: $id) {
      ...modelDashboardAll
    }
  }
  ${modelDashboardFragment}
`;

const ModelDashboardDropdownQuery = gql`
  query ModelDashboardDropdownQuery {
    modelDashboards {
      name
      id
    }
  }
`;

export {
  ModelDashboardDropdownQuery,
  ModelDashboardQuery,
  ModelDashboardGridQuery,
};
