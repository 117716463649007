import React, { Component } from 'react';
import AssetTypeDropdown from './AssetTypeDropdown';
import AssetTypeConfig from '../AssetTypeConfig';

class AssetTypeDropdownWithConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assetTypeId: null,
    };
    this.handleAssetTypeChange = this.handleAssetTypeChange.bind(this);
  }

  handleAssetTypeChange(assetType) {
    console.log('handleAssetTypeChange', assetType.id);
    this.setState({ assetTypeId: assetType.id });
    //  this.props.setConfigVals(assetType.fields);
  }

  render() {
    return (
      <div>
        <AssetTypeDropdown
          fieldName="assetType"
          label="Asset Type"
          onDropdownChange={this.handleAssetTypeChange}
        />
        <AssetTypeConfig
          assetTypeId={this.state.assetTypeId}
          formName={this.props.formName}
        />
      </div>
    );
  }
}

AssetTypeDropdownWithConfig.defaultProps = {
  formName: 'Asset',
};

export default AssetTypeDropdownWithConfig;
