import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';

import {
  DeviceTypeGridQuery,
  DeviceTypeQuery,
} from '../../../graphql/queries/DeviceTypeQueries';
import DeviceTypeForm from './DeviceTypeForm';
import FormBase from '../../form/FormBase';
import handleDeviceTypeMutation from '../../../graphql/functions/handleDeviceTypeMutation';
import { UpdateDeviceTypeMutation } from '../../../graphql/mutations/DeviceTypeMutations';

class DeviceTypeUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    if (typeof values === 'object') {
      // store deviceType id
      values.id = this.props.deviceType.id;
      if (values.fields) {
        for (let i = 0; i < values.fields.length; i++) {
          if (values.fields[i].rule && values.fields[i].rule.id) {
            values.fields[i].rule = values.fields[i].rule.id;
          }
        }
      }
      handleDeviceTypeMutation({
        values,
        mutation: this.props.updateDeviceTypeMutation,
        onSuccess: this.props.onSuccess,
        refetchQueries: [{ query: DeviceTypeGridQuery }],
      });
    }
  }

  render() {
    // render form with deviceType values pre-loaded
    const {
      data: { loading, error, deviceType },
    } = this.props;
    return (
      <FormBase loading={loading} error={error} header="Edit Device Type">
        <DeviceTypeForm
          initialValues={deviceType}
          onSubmit={this.handleSubmit}
        />
      </FormBase>
    );
  }
}

export default compose(
  graphql(UpdateDeviceTypeMutation, {
    name: 'updateDeviceTypeMutation',
    options: (props) => ({ variables: { id: props.deviceType.id } }),
  }),
  graphql(DeviceTypeQuery, {
    options: (props) => ({ variables: { id: props.deviceType.id }, fetchPolicy: 'network-only' }),
  }),
)(DeviceTypeUpdate);
