import React from 'react';
import { Field } from 'redux-form';
import renderCheckbox from '../form/renderCheckbox';
import Ap2200FirmwareDropdown from './ap2200Firmware/Ap2200FirmwareDropdown';

export default [
  {
    id: 'forceFirmwareUpdate',
    name: 'Force Firmware Update',
    field: (
      <Field
        name="forceFirmwareUpdate"
        label="Force Firmware Update"
        component={renderCheckbox}
      />
    ),
  },
  {
    id: 'ap2200Firmware',
    name: 'Desired AP2200 Firmware',
    field: <Ap2200FirmwareDropdown fieldName="desiredFirmware" label="Desired AP2200 Firmware" />,
  },
];
