import React, { Component } from 'react';
import FixedActionButton from '../../common/FixedActionButton';
import DataTypeGrid from './DataTypeGrid';
import Modal from '../../common/Modal';
import PSCard from '../../common/PSCard';
import DataTypeNew from './DataTypeNew';

class DataTypeMain extends Component {
  constructor() {
    super();
    this.state = { modalIsOpen: false };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    return (
      <div>
        <PSCard cardTitle="Data Types">
          <DataTypeGrid />
        </PSCard>

        <div>
          <FixedActionButton onClick={this.openModal} />
        </div>
        <Modal
          onClose={this.closeModal}
          modalIsOpen={this.state.modalIsOpen}
          contentLabel="Add Data Type"
        >
          <DataTypeNew />
        </Modal>
      </div>
    );
  }
}

export default DataTypeMain;
