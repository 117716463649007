import React, { Component } from 'react';
import { reduxForm, Field, FieldArray } from 'redux-form';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormField from '../../form/FormField';
import FormSubmitButton from '../../form/FormSubmitButton';
import ClearButton from '../../form/ClearButton';
import ScalarTypeDropdown from '../../common/ScalarTypeDropdown';
import renderCheckbox from '../../form/renderCheckbox';
import renderTagInputField from '../../form/renderTagInputField';
import DeviceConfigDropdown from '../deviceConfig/DeviceConfigDropdown';

const renderFieldArray = ({ fields, meta: { error, submitFailed } }) => (
  <div>
    <div>
      <Button
        variant="contained"
        type="button"
        onClick={() => fields.push({})}
      >
        Add Device Field
      </Button>
      {submitFailed && error && <span>{error}</span>}
    </div>
    {fields.map((deviceField, index) => (
      <div key={index}>
        <Button
          variant="contained"
          className="right"
          type="button"
          title="Remove Field"
          onClick={() => fields.remove(index)}
        >
          <i className="material-icons">delete</i>
        </Button>
        <Typography variant="h5" className="ps-form-header">
          Field #
          {index + 1}
        </Typography>
        <Field
          name={`${deviceField}.key`}
          type="text"
          component={FormField}
          label="Field Key"
        />
        <Field
          name={`${deviceField}.name`}
          type="text"
          component={FormField}
          label="Field Name"
        />
        <Field
          name={`${deviceField}.description`}
          type="text"
          component={FormField}
          label="Field Description"
        />
        <ScalarTypeDropdown
          fieldName={`${deviceField}.rule`}
          label="Field Type"
        />
        <br />
        <Field
          name={`${deviceField}.required`}
          label="Required"
          id={`${deviceField}.required`}
          component={renderCheckbox}
        />
      </div>
    ))}
  </div>
);

class DeviceTypeForm extends Component {
  render() {
    const {
      handleSubmit, pristine, reset, submitting, initialValues,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div data-tip="Name of Device Type">
          <Field
            name="name"
            type="text"
            component={FormField}
            label="Device Type Name"
          />
        </div>
        <div data-tip="Key for Device Type. Once added this cannot be changed.">
          <Field
            props={{ disabled: !!initialValues }}
            name="key"
            type="text"
            component={FormField}
            label="Device Type Key"
          />
        </div>
        <div data-tip="Type keys are included in AP2200 data packets to indicate the device type of the data.">
          <Field
            name="importKeys"
            component={renderTagInputField}
            label="Import type keys"
          />
        </div>
        <div>
          <Typography variant="h5" className="ps-form-header">Default Configuration</Typography>
          <DeviceConfigDropdown
            fieldName="deviceConfig"
            label="Device Config"
          />
        </div>
        <FieldArray name="fields" component={renderFieldArray} />
        <div>
          <FormSubmitButton pristine={pristine} submitting={submitting} />
          <ClearButton
            pristine={pristine}
            submitting={submitting}
            reset={reset}
          />
        </div>
      </form>
    );
  }
}

DeviceTypeForm.defaultProps = {
  initialValues: null,
};
const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Cannot be blank';
  }
  if (!values.key) {
    errors.key = 'Cannot be blank';
  }
  return errors;
};

export default reduxForm({ validate, form: 'DeviceType' })(
  DeviceTypeForm,
);
