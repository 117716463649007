import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Close from '@material-ui/icons/Close';

ReactModal.setAppElement('#root');

const styles = () => ({
  root: {
    minWidth: '15px',
    minHeight: '15px',
    float: 'right',
    padding: '8px',
  },
});

class Modal extends Component {
  constructor() {
    super();
    this.state = { modalIsOpen: false };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.modalIsOpen !== prevProps.modalIsOpen) {
      this.setState({ modalIsOpen: this.props.modalIsOpen });
    }
  }

  render() {
    return (
      <div>
        {this.state.modalIsOpen ? (
          <ReactModal
            isOpen={this.props.modalIsOpen}
            onRequestClose={this.props.onClose}
            contentLabel={this.props.contentLabel}
            style={this.props.customStyles}
          >
            {this.props.showClose && (
              <Button
                style={{ zIndex: '50' }}
                classes={{ root: this.props.classes.root }}
                size="small"
                onClick={this.props.onClose}
              >
                <Close style={{ fontSize: 12 }} />
              </Button>
            )}
            {React.cloneElement(this.props.children, {
              onSuccess: this.props.onClose,
            })}
            {this.props.showSubmit && (
              <Button
                variant="contained"
                type="submit"
                color="primary"
                onClick={this.props.onSubmit}
              >
                Submit
              </Button>
            )}
          </ReactModal>
        ) : null}
      </div>
    );
  }
}
Modal.defaultProps = {
  contentLabel: '',
  showClose: true,
  customStyles: {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 100,
    },
    content: {
      position: 'absolute',
      top: '80px',
      left: '40px',
      right: '40px',
      bottom: '40px',
      border: '1px solid #ccc',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '4px',
      outline: 'none',
      padding: '20px',
    },
  },
};
export default withStyles(styles, { withTheme: true })(Modal);
