import React, { Component } from 'react';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import { graphql } from '@apollo/client/react/hoc';
import {
  DeviceGridQuery,
} from '../../../graphql/queries/DeviceQueries';
import DeviceReplacement from './DeviceReplacement';

class DeviceReplacementPage extends Component {
  constructor(props) {
    super(props);
    this.onSuccess = this.onSuccess.bind(this);
  }

  onSuccess() {
    this.props.history.push('/device');
  }

  render() {
    const {
      data: { loading, error, devices },
    } = this.props;
    const replacementDeviceId = this.props.match.params.id;
    if (loading) {
      return <p>Loading full device list...</p>;
    } if (error) {
      return <p>Error loading devices!</p>;
    } if (!replacementDeviceId) {
      return <p>Error: replacement device id required!</p>;
    }
    // get the replacement device
    const replacementDevice = devices.find((d) => d.id === replacementDeviceId);
    if (!replacementDevice || typeof replacementDevice === 'undefined') {
      return <p>Error: replacement device not found!</p>;
    }
    return (
      <DeviceReplacement
        devices={devices}
        replacementDevice={replacementDevice}
        header="Replace Device"
        onSuccess={this.onSuccess}
      />
    );
  }
}

DeviceReplacementPage = graphql(DeviceGridQuery, {
  name: 'DeviceGridQuery',
  props: ({
    ownProps,
    DeviceGridQuery: {
      loading, error, editableDevices, refetch,
    },
  }) => {
    if (editableDevices) {
      editableDevices = editableDevices.map((device) => {
        if (device.asset && device.asset.parent) {
          device = update(device, {
            asset: { location: { $set: device.asset.parent.location } },
          });
        }
        return device;
      });
    }
    return {
      data: {
        devices: editableDevices,
        loading,
        error,
        refetch,
      },
    };
  },
})(DeviceReplacementPage);
export default connect(null)(DeviceReplacementPage);
