import { FETCH_MFA } from '../actions/types';

export default function mfaReducer(state = null, action) {
  switch (action.type) {
    case FETCH_MFA:
      if (action.payload && typeof action.payload !== 'undefined') {
        return action.payload;
      }
      return false;

    default:
      return state;
  }
}
