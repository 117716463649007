import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import {
  FETCH_MFA, IS_FETCHING_MFA, FETCH_USER, IS_FETCHING_USER, IS_SENDING_MFA_CODE,
} from './types';
import { REACT_APP_BACKEND } from '../config';

axios.defaults.withCredentials = true;

export const fetchUser = () => async (dispatch) => {
  dispatch({
    type: IS_FETCHING_USER,
    payload: true,
  });
  const res = await axios.get(`${REACT_APP_BACKEND}/currentUser`);
  dispatch({ type: FETCH_USER, payload: res.data ? res.data.user : res.data });
  dispatch({
    type: IS_FETCHING_USER,
    payload: false,
  });
};

export const loginUser = (values, history) => async (dispatch) => {
  let res;
  try {
    res = await axios.post(`${REACT_APP_BACKEND}/loginUser`, { ...values, loginAdmin: true });
    if (res && res.data && res.data.user) {
      dispatch({
        type: FETCH_USER,
        payload: res.data ? res.data.user : res.data,
      });
      history.push('/mfa');
    } else if (res && res.data && res.data.message) {
      const toastrType = 'error';
      const toastrOptions = {
        icon: toastrType,
        status: toastrType,
        timeOut: 6000,
      };
      toastr.light('Error', res.data.message, toastrOptions);
    } else {
      const toastrType = 'error';
      const toastrOptions = {
        icon: toastrType,
        status: toastrType,
        timeOut: 6000,
      };
      toastr.light('Error', 'Unable to authenticate user', toastrOptions);
    }
  } catch (e) {
    console.log(e, JSON.stringify(e));
    const toastrType = 'error';
    const toastrOptions = {
      icon: toastrType,
      status: toastrType,
      timeOut: 6000,
    };
    toastr.light(
      'Error',
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : `Unable to authenticate user: ${JSON.stringify(e)}`,
      toastrOptions,
    );
  }
};

export const sendMfaCode = () => async (dispatch) => {
  console.log('running sendMfaCode');
  dispatch({
    type: IS_SENDING_MFA_CODE,
    payload: true,
  });
  await axios.post(`${REACT_APP_BACKEND}/sendMfaCode`);
  dispatch({
    type: IS_SENDING_MFA_CODE,
    payload: false,
  });
};

export const fetchMfa = () => async (dispatch) => {
  dispatch({
    type: IS_FETCHING_MFA,
    payload: true,
  });
  const res = await axios.get(`${REACT_APP_BACKEND}/sessionMfa`);
  dispatch({ type: FETCH_MFA, payload: res.data ? res.data.mfaVerified : res.data });
  dispatch({
    type: IS_FETCHING_MFA,
    payload: false,
  });
};

export const verifyOtp = (code, history) => async (dispatch) => {
  let res;
  try {
    res = await axios.post(`${REACT_APP_BACKEND}/verifyOtp`, { code });
    if (res && res.data && res.data.mfaVerified) {
      dispatch({
        type: FETCH_MFA,
        payload: res.data ? res.data.mfaVerified : res.data,
      });
      history.push('/homepage');
    } else if (res && res.data && res.data.message) {
      const toastrType = 'error';
      const toastrOptions = {
        icon: toastrType,
        status: toastrType,
        timeOut: 6000,
      };
      toastr.light('Error', res.data.message, toastrOptions);
    } else {
      const toastrType = 'error';
      const toastrOptions = {
        icon: toastrType,
        status: toastrType,
        timeOut: 6000,
      };
      toastr.light('Error', 'Unable to authenticate user', toastrOptions);
    }
  } catch (e) {
    console.log(e, JSON.stringify(e));
    const toastrType = 'error';
    const toastrOptions = {
      icon: toastrType,
      status: toastrType,
      timeOut: 6000,
    };
    toastr.light(
      'Error',
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : `Unable to MFA user: ${JSON.stringify(e)}`,
      toastrOptions,
    );
  }
};

export const logoutUser = (history) => async (dispatch) => {
  const res = await axios.get(`${REACT_APP_BACKEND}/logout`);
  dispatch({
    type: FETCH_USER,
    payload: null,
  });
  dispatch({
    type: FETCH_MFA,
    payload: false,
  });
  history.push('/login');
};
