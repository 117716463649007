import React, { Component } from 'react';
import EventGrid from './EventGrid';
import PSCard from '../common/PSCard';

class EventMain extends Component {
  render() {
    return (
      <div>
        <PSCard cardTitle="Events">
          <EventGrid />
        </PSCard>
      </div>
    );
  }
}

export default EventMain;
