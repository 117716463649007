import React, { Component } from 'react';
import { reduxForm, Field, change } from 'redux-form';
import { connect } from 'react-redux';
import FormField from '../form/FormField';
import FormSubmitButton from '../form/FormSubmitButton';
import ClearButton from '../form/ClearButton';
import AttConfigFormSection from '../data/attribute/attConfig/AttConfigFormSection';

class OilSampleTypeForm extends Component {
  render() {
    const {
      handleSubmit, pristine, reset, submitting,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div data-tip="Name of Oil Sample Type">
          <Field
            name="name"
            type="text"
            component={FormField}
            label="Oil Sample Type Name"
          />
        </div>
        <label>Metadata Fields</label>
        <AttConfigFormSection
          fieldName="metadataFields"
          formName="OilSampleType"
          setConfigAtts={this.props.setMetadataFields}
        />
        <label>Fields</label>
        <AttConfigFormSection
          fieldName="fields"
          formName="OilSampleType"
          setConfigAtts={this.props.setFields}
        />

        <div>
          <FormSubmitButton pristine={pristine} submitting={submitting} />
          <ClearButton
            pristine={pristine}
            submitting={submitting}
            reset={reset}
          />
        </div>
      </form>
    );
  }
}

OilSampleTypeForm.defaultProps = {
  initialValues: null,
};
const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Cannot be blank';
  }
  return errors;
};

OilSampleTypeForm = reduxForm({ validate, form: 'OilSampleType' })(
  OilSampleTypeForm,
);

OilSampleTypeForm = connect(
  null,
  {
    setFields: (event, newValue, previousValue) => change('OilSampleType', 'fields', newValue),
    setMetadataFields: (event, newValue, previousValue) => change('OilSampleType', 'metadataFields', newValue),
  },

)(OilSampleTypeForm);

export default OilSampleTypeForm;
