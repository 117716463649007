import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import Button from '@material-ui/core/Button';
import WizardBase from '../../../form/WizardBase';

class EditFields extends Component {
  getFields() {
    return this.props.fields.map((field, ind) => {
      console.log(field);
      return <div key={ind}>{field.field}</div>;
    });
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <WizardBase header="Edit Selected Fields">
        <form onSubmit={handleSubmit}>
          {this.getFields()}
          <br />
          <div>
            <Button type="submit" className="next">
              Submit
            </Button>
          </div>
        </form>
      </WizardBase>
    );
  }
}

export default (EditFields = reduxForm({
  form: 'multiEditWizard', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(EditFields));
