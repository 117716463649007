import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';

import {
  AssetTypeGridQuery,
  AssetTypeQuery,
} from '../../../graphql/queries/AssetTypeQueries';
import AssetTypeForm from './AssetTypeForm';
import FormBase from '../../form/FormBase';
import handleMutation from '../../../graphql/functions/handleMutation';
import { UpdateAssetTypeMutation } from '../../../graphql/mutations/AssetTypeMutations';

class AssetTypeUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    if (typeof values === 'object') {
      // store assetType id
      values.id = this.props.assetType.id;
      delete values.uploadCSV;
      if (values.fields) {
        for (let i = 0; i < values.fields.length; i++) {
          if (values.fields[i].rule && values.fields[i].rule.id) {
            values.fields[i].rule = values.fields[i].rule.id;
          }
        }
      }

      handleMutation({
        mutate: this.props.updateAssetTypeMutation,
        variables: { assetType: values },
        refetchQueries: [{ query: AssetTypeGridQuery }],
        onSuccess: this.props.onSuccess,
      });
    }
  }

  render() {
    // render form with assetType values pre-loaded
    const {
      data: { loading, error, assetType },
    } = this.props;
    return (
      <FormBase loading={loading} error={error} header="Edit Asset Type">
        <AssetTypeForm initialValues={assetType} onSubmit={this.handleSubmit} />
      </FormBase>
    );
  }
}

export default compose(
  graphql(UpdateAssetTypeMutation, {
    name: 'updateAssetTypeMutation',
    options: (props) => ({ variables: { id: props.assetType.id } }),
  }),
  graphql(AssetTypeQuery, {
    options: (props) => ({ variables: { id: props.assetType.id }, fetchPolicy: 'network-only' }),
  }),
)(AssetTypeUpdate);
