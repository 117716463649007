import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';

import { CONST } from '../common/constants';
import { formatRole } from '../common/functions';
import renderDropdownList from './renderDropdownList';

class UserRoleDropdown extends Component {
  constructor(props) {
    super(props);
    this.getRoles = this.getRoles.bind(this);
    this.handleUserRoleChange = this.handleUserRoleChange.bind(this);
  }

  handleUserRoleChange(e, nv, pv) {
    this.props.onDropdownChange(e, nv, pv);
  }

  getRoles() {
    // admin
    if (this.props.auth && this.props.auth.isAdmin) {
      // super admin & we want to offer superAdmin as role setting
      // we don't use superadmin role for Companies, locations etc.
      if (this.props.auth.isSuperAdmin && this.props.showSuperAdmin) {
        return CONST.ROLES;
      } if (this.props.auth.isSuperAdmin && !this.props.showSuperAdmin) {
        return CONST.SUPER_ADMIN_ASSIGNING_ROLES;
      }
      // company admin
      return CONST.COMPANY_ADMIN_ROLES;
    }
    return null;
  }

  render() {
    return (
      <div>
        <label>User Role</label>
        <Field
          key="role"
          format={formatRole}
          component={renderDropdownList}
          onChange={this.handleUserRoleChange}
          data={this.getRoles()}
          canBeNull={false}
          valueField="id"
          textField="name"
          label="Role"
          name="role"
          error="Must select a role"
        />
      </div>
    );
  }
}
UserRoleDropdown.defaultProps = {
  onDropdownChange: () => {},
  showSuperAdmin: false,
};
function mapStateToProps({ auth }) {
  return { auth };
}
export default connect(mapStateToProps)(UserRoleDropdown);
