import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  Button,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import WizardBase from '../form/WizardBase';

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 300,
  },
});

class ReviewUpload extends Component {
  constructor(props) {
    super(props);
    console.log('constructor props', props);
  }

  getHeader() {
    console.log('getHeader', this);
    let modelName = null;
    switch (this.props.model) {
      case 'device':
        modelName = 'Devices';
        break;
      case 'asset':
        modelName = 'Assets';
        break;

      default:
        break;
    }

    let createOrUpdate = null;

    switch (this.props.createOrUpdate) {
      case 'create':
        createOrUpdate = 'created';
        break;
      case 'update':
        createOrUpdate = 'updated';
        break;

      default:
        break;
    }

    if (createOrUpdate && modelName) {
      return (
        <p>
          {modelName}
          {' '}
          to be
          {' '}
          {createOrUpdate}
          :
        </p>
      );
    }
    return <div />;
  }

  getDataTable() {
    const { classes } = this.props;
    const header = this.props.uploadedData[0].map((c, ind) => <TableCell key={ind}>{c}</TableCell>);
    const rows = this.props.uploadedData.slice(1);
    const body = rows.map((row, ind) => {
      const cells = row.map((rowVal, rvInd) => <TableCell key={rvInd}>{rowVal}</TableCell>);
      return <TableRow key={ind}>{cells}</TableRow>;
    });
    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>{header}</TableRow>
        </TableHead>
        <TableBody>{body}</TableBody>
      </Table>
    );
  }

  render() {
    const { handleSubmit, previousPage, classes } = this.props;
    return (
      <WizardBase header="Review Data">
        <form onSubmit={handleSubmit}>
          <div>{this.getHeader()}</div>
          <Paper className={classes.root}>{this.getDataTable()}</Paper>
          <div>
            <Button
              variant="contained"
              type="button"
              onClick={previousPage}
            >
              Previous
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
            >
              Submit
              <i className="material-icons right">send</i>
            </Button>
          </div>
        </form>
      </WizardBase>
    );
  }
}
ReviewUpload.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  uploadedData:
    state.form
    && state.form.CSVImport
    && state.form.CSVImport.values
    && state.form.CSVImport.values.uploadedData
    && state.form.CSVImport.values.uploadedData.length > 0
      ? state.form.CSVImport.values.uploadedData
      : null,
  requiredFields:
    state.form
    && state.form.CSVImport
    && state.form.CSVImport.values
    && state.form.CSVImport.values.requiredFields
    && state.form.CSVImport.values.requiredFields.length > 0
      ? state.form.CSVImport.values.requiredFields
      : null,
  selectFields:
    state.form
    && state.form.CSVImport
    && state.form.CSVImport.values
    && state.form.CSVImport.values.selectFields
    && state.form.CSVImport.values.selectFields.length > 0
      ? state.form.CSVImport.values.selectFields
      : null,
  model:
    state.form
    && state.form.CSVImport
    && state.form.CSVImport.values
    && state.form.CSVImport.values.model
    && state.form.CSVImport.values.model.id
      ? state.form.CSVImport.values.model.id
      : null,
  createOrUpdate:
    state.form
    && state.form.CSVImport
    && state.form.CSVImport.values
    && state.form.CSVImport.values.createOrUpdate
    && state.form.CSVImport.values.createOrUpdate.id
      ? state.form.CSVImport.values.createOrUpdate.id
      : null,
  identifyBy:
    state.form
    && state.form.CSVImport
    && state.form.CSVImport.values
    && state.form.CSVImport.values.identifyBy
    && state.form.CSVImport.values.identifyBy.id
      ? state.form.CSVImport.values.identifyBy.id
      : null,
  subAssets:
    !!(state.form
    && state.form.CSVImport
    && state.form.CSVImport.values
    && state.form.CSVImport.values.subAssets),
});
ReviewUpload = reduxForm({
  form: 'CSVImport', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(ReviewUpload);
ReviewUpload = withStyles(styles)(ReviewUpload);
export default connect(mapStateToProps)(ReviewUpload);
