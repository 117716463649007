import React, { Component } from 'react';
import { reduxForm, Field, FieldArray } from 'redux-form';
import FormField from '../../../form/FormField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ScalarTypeDropdown from '../../../common/ScalarTypeDropdown';
import FormSubmitButton from '../../../form/FormSubmitButton';
import ClearButton from '../../../form/ClearButton';
import renderCheckbox from '../../../form/renderCheckbox';

const renderFieldArray = ({ fields, meta: { error, submitFailed } }) => (
  <div>
    <div>
      <Button
        variant="contained"
        type="button"
        className="clear-button waves-effect "
        onClick={() => fields.push({})}
      >
        Add Attribute Field
      </Button>
      {submitFailed && error && <span>{error}</span>}
    </div>
    {fields.map((attributeField, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={index}>
        <Button
          variant="contained"
          className="right"
          type="button"
          title="Remove Field"
          onClick={() => fields.remove(index)}
        >
          <i className="material-icons">delete</i>
        </Button>
        <Typography variant="h5" className="ps-form-header">
          Field #
          {index + 1}
        </Typography>
        <Field
          name={`${attributeField}.key`}
          type="text"
          component={FormField}
          label="Field Key"
        />
        <Field
          name={`${attributeField}.name`}
          type="text"
          component={FormField}
          label="Field Name"
        />
        <Field
          name={`${attributeField}.description`}
          type="text"
          component={FormField}
          label="Field Description"
        />
        <ScalarTypeDropdown
          fieldName={`${attributeField}.rule`}
          label="Field Type"
        />
        <br />
        <Field
          name={`${attributeField}.required`}
          label="Required"
          id={`${attributeField}.required`}
          component={renderCheckbox}
        />
      </div>
    ))}
  </div>
);

class AttributeTypeForm extends Component {
  render() {
    const {
      handleSubmit, pristine, reset, submitting,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div data-tip="Name of Attribute Type">
          <Field
            name="name"
            type="text"
            component={FormField}
            label="Attribute Type Name"
          />
        </div>
        <FieldArray name="fields" component={renderFieldArray} />
        <div>
          <FormSubmitButton pristine={pristine} submitting={submitting} />
          <ClearButton
            pristine={pristine}
            submitting={submitting}
            reset={reset}
          />
        </div>
      </form>
    );
  }
}

AttributeTypeForm.defaultProps = {
  initialValues: null,
};
const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Cannot be blank';
  }
  return errors;
};

export default reduxForm({ validate, form: 'AttributeType' })(
  AttributeTypeForm,
);
