import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';

import handleMutation from '../../graphql/functions/handleMutation';
import CompanyForm from './CompanyForm';
import FormBase from '../form/FormBase';
import {
  CompanyGridQuery,
  CompanyDropdownQuery,
} from '../../graphql/queries/CompanyQueries';
import { CreateCompanyMutation } from '../../graphql/mutations/CompanyMutations';

class CompanyNew extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // submit mutation and refetch company grid query
  handleSubmit(values) {
    handleMutation({
      mutate: this.props.mutate,
      variables: { company: values },
      refetchQueries: [
        { query: CompanyGridQuery },
        { query: CompanyDropdownQuery },
      ],
      onSuccess: this.props.onSuccess,
    });
  }

  render() {
    return (
      <FormBase header="Create Company">
        <CompanyForm onSubmit={this.handleSubmit} />
      </FormBase>
    );
  }
}

const CompanyNewWithMutation = graphql(CreateCompanyMutation)(CompanyNew);

export default CompanyNewWithMutation;
