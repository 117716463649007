import { FETCH_USER } from '../actions/types';
import { CONST } from '../components/common/constants';

const setUserRole = (payload) => {
  const adminRole = payload.role;
  if (adminRole && typeof adminRole !== 'undefined') {
    if (adminRole === CONST.SUPER_ADMIN_ROLE || adminRole === CONST.COMPANY_ADMIN_ROLE) {
      payload.isAdmin = true; // at least company admin
      if (adminRole === CONST.SUPER_ADMIN_ROLE) {
        payload.isSuperAdmin = true; // is super
      } else {
        payload.isCompanyAdmin = true; // is company
      }
    }
  }
  return payload;
};

export default function authReducer(state = null, action) {
  switch (action.type) {
    case FETCH_USER:
      if (action.payload && typeof action.payload !== 'undefined') {
        action.payload = setUserRole(action.payload);
        return action.payload;
      }
      console.log('no fetchUser payload');
      return false;

    default:
      return state;
  }
}
