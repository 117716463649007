import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import GridBase from '../../common/GridBase';
import { DeviceGroupQuery } from '../../../graphql/queries/DeviceGroupQueries';

class DeviceGroupDeviceList extends Component {
  columns = [
    {
      Header: 'ID',
      accessor: 'id',
      maxWidth: 50,
      filterable: true,
      show: true,
    },
    {
      Header: 'Company',
      id: 'companyName',
      accessor: (d) => (d.asset && d.asset.location && d.asset.location.company
        ? d.asset.location.company.name
        : null),
      maxWidth: 120,

      filterable: true,
    },
    {
      Header: 'Location',
      id: 'locationName',
      accessor: (d) => (d.asset && d.asset.location ? d.asset.location.name : null),
      maxWidth: 120,
      filterable: true,
    },
    {
      Header: 'Asset',
      id: 'assetName',
      accessor: (d) => (d.asset ? d.asset.fullName + (d.installLocation ? ` ${d.installLocation}` : '') : null),
      minWidth: 110,
      filterable: true,
    },
    {
      Header: 'Name',
      accessor: 'name',
      minWidth: 100,
      filterable: true,
    },
    {
      Header: 'Serial',
      accessor: 'serial',
      maxWidth: 50,
      filterable: true,
    },
  ];

  render() {
    const header = `${this.props.deviceGroup.name}: Devices`;
    const {
      DeviceGroupQuery: { loading, error, deviceGroup },
    } = this.props;
    if (loading) {
      return (
        <div>
          <Typography variant="h4">{header}</Typography>
          <p>Loading devices...</p>
        </div>
      );
    } if (error) {
      return (
        <div>
          <Typography variant="h4">{header}</Typography>
          <p>Error loading data!</p>
        </div>
      );
    }
    const sorting = [
      { id: 'companyName', asc: true },
      { id: 'locationName', asc: true },
      { id: 'assetName', asc: true },
      { id: 'name', asc: true },
    ];
    return (
      <div>
        <Typography variant="h4">{header}</Typography>
        <Divider />
        <br />
        <GridBase
          dataVar={deviceGroup.devices}
          columns={this.columns}
          sorting={sorting}
        />
      </div>
    );
  }
}

export default graphql(DeviceGroupQuery, {
  name: 'DeviceGroupQuery',
  options: (props) => ({ variables: { id: props.deviceGroup.id }, fetchPolicy: 'network-only' }),
})(DeviceGroupDeviceList);
