import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';

import { connect } from 'react-redux';
import GridBase from '../common/GridBase';
import ContextMenuWithTrigger from '../common/ContextMenuWithTrigger';
import Modal from '../common/Modal';
import LocationUpdate from './LocationUpdate';
import { LocationGridQuery } from '../../graphql/queries/LocationQueries';
import { CompanyGridQuery } from '../../graphql/queries/CompanyQueries';
import { DeleteLocationMutation } from '../../graphql/mutations/LocationMutations';
import DeleteModel from '../common/DeleteModel';
import locationFormFields from './locationFormFields';

class LocationGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      location: null,
      delete: { id: null, name: '' },
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleDeleteClose = this.handleDeleteClose.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  columns = [
    {
      Header: 'ID',
      accessor: 'id',
      maxWidth: 90,
      filterable: true,
    },
    {
      Header: 'Company',
      id: 'companyName',
      accessor: (d) => d.company.name,
      minWidth: 100,
      filterable: true,
    },
    {
      Header: 'Location',
      accessor: 'name',
      minWidth: 100,
      filterable: true,
    },
    {
      Header: 'Installer App Pin',
      accessor: 'apiKey',
      minWidth: 100,
      filterable: true,
    },
    {
      Header: 'Model Dashboard',
      id: 'modelDashboard',
      accessor: (d) => (d.modelDashboard ? d.modelDashboard.name : ''),
      minWidth: 70,
      filterable: true,
    },
    // {
    //   Header: 'Installer Configuration',
    //   id: 'installerConfig',
    //   accessor: d => (d.installerConfig ? d.installerConfig.name : ''),
    //   minWidth: 70,
    //   filterable: true,
    // },
    {
      Header: '',
      maxWidth: 23,
      resizable: false,
      sortable: false,
      Cell: ({ row }) => ContextMenuWithTrigger({
        handleClick: this.handleClick,
        row,
      }),
    },
  ];

  handleClick(e, data, target) {
    if (data.action === 'Edit') {
      this.setState({
        location: data.row,
        modalIsOpen: true,
      });
    } else if (data.action === 'Delete') {
      console.log('delete data', data);
      this.setState({ delete: { id: data.row.id, name: data.row.name } });
    }
  }

  // reset delete location id and name to null upon deletion
  handleDeleteClose() {
    this.setState({ delete: { id: null, name: '' } });
  }

  render() {
    const {
      data: { loading, error, editableLocations },
    } = this.props;
    const sorting = [
      { id: 'companyName', asc: true },
      { id: 'locationName', asc: true },
    ];
    if (loading) {
      return <p>Loading...</p>;
    } if (error) {
      return <p>Error loading locations!</p>;
    }
    const multiSelect = {
      fields: locationFormFields,
      modelName: 'location',
      onSubmit: this.props.data.refetch,
    };
    return (
      <div>
        <GridBase
          dataVar={editableLocations}
          columns={this.columns}
          sorting={sorting}
          multiSelect={multiSelect}
        />

        <DeleteModel
          deleteMutation={DeleteLocationMutation}
          refetchQueries={[
            { query: CompanyGridQuery },
            { query: LocationGridQuery },
          ]}
          id={this.state.delete.id}
          name={this.state.delete.name}
          onClose={this.handleDeleteClose}
        />

        <Modal
          onClose={this.closeModal}
          modalIsOpen={this.state.modalIsOpen}
          contentLabel="Edit Location"
        >
          <LocationUpdate location={this.state.location} />
        </Modal>
      </div>
    );
  }
}

function mapStateToProps({ auth }) {
  return { auth };
}

LocationGrid = connect(mapStateToProps)(LocationGrid);
export default graphql(LocationGridQuery)(LocationGrid);
