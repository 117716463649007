import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import GridBase from '../common/GridBase';
import ContextMenuWithTrigger from '../common/ContextMenuWithTrigger';
import Modal from '../common/Modal';
import DeleteModel from '../common/DeleteModel';
import OilSampleTypeUpdate from './OilSampleTypeUpdate';
import { OilSampleTypeGridQuery } from '../../graphql/queries/OilSampleTypeQueries';
import { DeleteOilSampleTypeMutation } from '../../graphql/mutations/OilSampleTypeMutations';
import handleContextMenuClick from '../common/handleContextMenuClick';

class OilSampleTypeGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      modalTitle: '',
      modalType: '',
      oilSampleType: null,
      delete: { id: null, name: '' },
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleDeleteClose = this.handleDeleteClose.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  columns = [
    {
      Header: 'ID',
      accessor: 'id',
      maxWidth: 90,
      filterable: true,
    },
    {
      Header: 'Name',
      accessor: 'name',
      minWidth: 100,
      filterable: true,
    },
    {
      Header: '',
      maxWidth: 23,
      resizable: false,
      sortable: false,
      Cell: ({ row }) => ContextMenuWithTrigger({
        handleClick: this.handleClick,
        row,
        duplicateOption: true,
      }),
    },
  ];

  // handle context menu clicks
  handleClick(e, data, target) {
    const modelName = 'oilSampleType';
    const stateUpdate = handleContextMenuClick({
      e, data, target, modelName,
    });
    console.log('stateUpdate', stateUpdate);
    this.setState({ ...stateUpdate });
  }

  handleDeleteClose() {
    this.setState({ delete: { id: null, name: '' } });
  }

  render() {
    const {
      data: { loading, error, oilSampleTypes },
    } = this.props;
    const sorting = [{ id: 'name', asc: true }];
    if (loading) {
      return <p>Loading...</p>;
    } if (error) {
      return <p>Error loading oil sample types!</p>;
    }
    return (
      <div>
        <GridBase
          dataVar={oilSampleTypes}
          columns={this.columns}
          sorting={sorting}
        />

        <DeleteModel
          deleteMutation={DeleteOilSampleTypeMutation}
          refetchQueries={[{ query: OilSampleTypeGridQuery }]}
          id={this.state.delete.id}
          name={this.state.delete.name}
          onClose={this.handleDeleteClose}
        />

        <Modal onClose={this.closeModal} modalIsOpen={this.state.modalIsOpen}>
          <OilSampleTypeUpdate
            oilSampleType={this.state.oilSampleType}
            header={this.state.modalTitle}
            duplicate={
                !!(this.state.modalType && this.state.modalType === 'Duplicate')
              }
          />
        </Modal>
      </div>
    );
  }
}

export default graphql(OilSampleTypeGridQuery)(OilSampleTypeGrid);
