import { gql } from '@apollo/client';

const DeleteEventMutation = gql`
  mutation deleteEvent($id: ID!) {
    deleteEvent(id: $id) {
      id
      name
    }
  }
`;
export { DeleteEventMutation };
