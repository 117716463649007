import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../form/FormField';
import FormSubmitButton from '../form/FormSubmitButton';
import ClearButton from '../form/ClearButton';
import ChartSelect from '../chart/ChartSelect';
import TableSelect from '../table/TableSelect';
import renderSelectTree from '../form/renderSelectTree';

const displayKeys = [
  { id: 'deviceOilHealthSummary', name: 'Device Oil Health Summary' },
  { id: 'hiStatusGrid', name: 'Health Indicator Status Grid' },
  { id: 'linkedVibrationCharts', name: 'Linked Vibration Charts' },
  { id: 'isoCode', name: 'ISO Code' },
  { id: 'qxEngineeringView', name: 'QX 4100 Engineering View Dashboard' },
];
class ModelDashboardForm extends Component {
  render() {
    const {
      handleSubmit, pristine, reset, submitting,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <Field
          name="name"
          type="text"
          component={FormField}
          label="Model Dashboard Name"
        />

        <ChartSelect label="Charts" fieldName="charts" />

        <TableSelect label="Table" fieldName="tables" />

        <div>
          <label>Additional Displays</label>

          <Field
            name="displays"
            component={renderSelectTree}
            data={displayKeys}
            valueField="id"
            textField="name"
          />
        </div>

        <div>
          <FormSubmitButton pristine={pristine} submitting={submitting} />
          <ClearButton
            pristine={pristine}
            submitting={submitting}
            reset={reset}
          />
        </div>
      </form>
    );
  }
}

ModelDashboardForm.defaultProps = {
  initialValues: null,
};
const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Cannot be blank';
  }
  return errors;
};

export default reduxForm({ validate, form: 'ModelDashboardForm' })(
  ModelDashboardForm,
);
