import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { Field } from 'redux-form';
import renderDropdownList from '../../form/renderDropdownList';
import { DeviceTypeDropdownQuery } from '../../../graphql/queries/DeviceTypeQueries';

class DeviceTypeDropdown extends Component {
  constructor(props) {
    super(props);
    this.handleDeviceTypeChange = this.handleDeviceTypeChange.bind(this);
  }

  handleDeviceTypeChange(e, nv, pv) {
    this.props.onDropdownChange(e, nv, pv);
  }

  render() {
    console.log('devTypeDrop', this.props);
    if (this.props.data.loading) {
      return <p>Loading device types...</p>;
    } if (this.props.data.error) {
      return <p>Error Loading Device Types!</p>;
    }
    return (
      <div>
        <label>{this.props.label}</label>
        <Field
          name={this.props.fieldName}
          onChange={this.handleDeviceTypeChange}
          component={renderDropdownList}
          canBeNull={this.props.canBeNull}
          data={this.props.data.deviceTypes}
          valueField="id"
          textField="name"
        />
      </div>
    );
  }
}

DeviceTypeDropdown.defaultProps = {
  onDropdownChange: () => {},
  canBeNull: false,
};

export default graphql(DeviceTypeDropdownQuery)(DeviceTypeDropdown);
