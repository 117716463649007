import React from 'react';
import update from 'immutability-helper';
import DropdownList from 'react-widgets/DropdownList';

export default function renderDropdownList({
  input, data, valueField, textField, canBeNull, disabled, meta: { touched, error },
}) {
  let dataWithNull;
  if (canBeNull && canBeNull === true) {
    const nullEntry = {};
    nullEntry[valueField] = null;
    nullEntry[textField] = '-';

    dataWithNull = update(data, {
      $unshift: [nullEntry],
    });
  } else {
    dataWithNull = data;
  }
  if (disabled !== true) {
    disabled = false;
  }

  return (
    <div>
      <DropdownList
        {...input}
        data={dataWithNull}
        dataKey={valueField}
        textField={textField}
        onChange={input.onChange}
        disabled={disabled}
        filter={false}
      />
      <div className="ps-text-red" style={{ marginBottom: '20px' }}>
        {touched && error && <span>{error}</span>}
      </div>
    </div>
  );
}
