import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import ChartForm from './ChartForm';
import FormBase from '../form/FormBase';
import handleMutation from '../../graphql/functions/handleMutation';
import {
  ChartGridQuery,
  ChartQuery,
  ChartSelectQuery,
} from '../../graphql/queries/ChartQueries';
import { UpdateChartMutation } from '../../graphql/mutations/ChartMutations';

class ChartUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    // store chart id
    if (typeof values === 'object') {
      values.id = this.props.chart.id;
      if (values.chartAttributes && values.chartAttributes.length > 0) {
        values.chartAttributes = values.chartAttributes.map((att) => {
          const a = {};
          if (typeof att.attribute === 'object' && att.attribute.id) {
            a.attribute = att.attribute.id;
          } else if (Array.isArray(att.attribute)) {
            a.attribute = att.attribute[0];
          } else {
            a.attribute = att.attribute;
          }
          if (
            typeof att.axis === 'object'
            && att.axis !== null
            && att.axis.id
          ) {
            a.axis = att.axis.id;
          } else {
            a.axis = att.axis;
          }
          // only set attribute value type for column or bar charts
          if (values.plotType === 'column' || values.plotType === 'bar') {
            if (
              typeof att.valueType === 'object'
              && att.valueType !== null
              && att.valueType.id
            ) {
              a.valueType = att.valueType.id;
            } else {
              a.valueType = att.valueType;
            }
          }
          a.visible = att.visible;
          return a;
        });
      }
    }
    handleMutation({
      mutate: this.props.mutate,
      variables: { chart: values },
      refetchQueries: [{ query: ChartGridQuery }, { query: ChartSelectQuery }],
      onSuccess: this.props.onSuccess,
    });
  }

  render() {
    const {
      data: { loading, error, chart },
    } = this.props;
    return (
      <FormBase loading={loading} error={error} header="Edit Chart">
        <ChartForm initialValues={chart} onSubmit={this.handleSubmit} />
      </FormBase>
    );
  }
}

export default compose(
  graphql(UpdateChartMutation, {
    options: (props) => ({ variables: { id: props.chart.id } }),
  }),
  graphql(ChartQuery, {
    name: 'ChartQuery',
    options: (props) => ({ variables: { id: props.chart.id }, fetchPolicy: 'network-only' }),
    props: ({ ownProps, ChartQuery: { loading, error, chart } }) => ({
      data: {
        chart,
        loading,
        error,
      },
    })
    ,
  }),
)(ChartUpdate);
