import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import Button from '@material-ui/core/Button';
import DeviceConfigDropdown from '../device/deviceConfig/DeviceConfigDropdown';
import DeviceConfigNew from '../device/deviceConfig/DeviceConfigNew';
import WizardBase from '../form/WizardBase';

class DeviceConfigurationPage extends Component {
  constructor(props) {
    super(props);
    this.state = { modalIsOpen: false };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  render() {
    const { handleSubmit, previousPage } = this.props;
    const modalContent = <DeviceConfigNew />;
    const modalType = 'New Configuration';
    return (
      <WizardBase
        header="How is your device configured?"
        closeModal={this.closeModal}
        modalIsOpen={this.state.modalIsOpen}
        modalContent={modalContent}
        modalType={modalType}
      >
        <form onSubmit={handleSubmit}>
          <DeviceConfigDropdown
            fieldName="deviceConfig"
            label="Device Config"
          />
          <Button onClick={this.openModal}>
            Add&nbsp;
            {modalType}
          </Button>
          <br />
          <div>
            <Button className="previous" onClick={previousPage}>
              Previous
            </Button>
            <Button type="submit" className="next">
              Next
            </Button>
          </div>
        </form>
      </WizardBase>
    );
  }
}

export default reduxForm({
  form: 'deviceWizard', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(DeviceConfigurationPage);
