import { gql } from '@apollo/client';
import { accountManagerAllFragment } from '../fragments';

const AccountManagerQuery = gql`
  query accountManager($id: ID!) {
    accountManager(id: $id) {
      ...accountManagerAll
    }
  }
  ${accountManagerAllFragment}
`;

const AccountManagerGridQuery = gql`
  query AccountManagerGridQuery {
    accountManagers {
      ...accountManagerAll
    }
  }
  ${accountManagerAllFragment}
`;

export { AccountManagerGridQuery, AccountManagerQuery };
