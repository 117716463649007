import React, { Component } from 'react';
import { Field, FieldArray, formValueSelector } from 'redux-form';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import renderSelectTree from '../../form/renderSelectTree';
import { CONST } from '../../common/constants';
import AttConfigFormSection from '../../data/attribute/attConfig/AttConfigFormSection';
import ModelDashboardDropdown from '../../modelDashboard/ModelDashboardDropdown';
import ExpansionPanelBase from '../../common/ExpansionPanelBase';
import IndicatorWidgetSelect from './IndicatorWidgetSelect';

class DeviceConfigFormSection extends Component {
  constructor(props) {
    super(props);
    this.renderIndicatorWidgetArray = this.renderIndicatorWidgetArray.bind(
      this,
    );
  }

  getIndicatorWidgetPanels(fields) {
    return fields.map((deviceWidget, index) => {
      const widgetNum = index + 1;
      return {
        key: `panel${index}`,
        header: `Widget #${widgetNum}`,
        data: (
          <div className="expandable-form-div">
            <Button
              variant="contained"
              className="right"
              type="button"
              title="Remove Widget"
              onClick={() => fields.remove(index)}
            >
              <i className="material-icons">delete</i>
            </Button>

            <IndicatorWidgetSelect
              fieldName={`${deviceWidget}`}
              indicatorWidget={this.props.indicatorWidgets[index]}
              label="Select Widget"
            />
            <br />
            <br />
          </div>
        ),
      };
    });
  }

  renderIndicatorWidgetArray = ({ fields, meta: { error, submitFailed } }) => (
    <div style={{ marginTop: '5px' }}>
      <div>
        <Button
          variant="contained"
          style={{ marginTop: '5px' }}
          type="button"
          onClick={() => fields.push({})}
        >
          Add Widgets
        </Button>
        {submitFailed && error && <span>{error}</span>}
      </div>
      <div>
        <ExpansionPanelBase panelData={this.getIndicatorWidgetPanels(fields)} />
      </div>
    </div>
  );

  render() {
    return (
      <div>
        <label>Processing Functions</label>
        <div data-tip="Run these functions on the incoming data">
          <Field
            name="processingFunctions"
            component={renderSelectTree}
            data={CONST.PROCESSING_FUNCTIONS}
            valueField="id"
            textField="name"
          />
        </div>
        <br />
        <ModelDashboardDropdown
          label="Default Dashboard"
          fieldName="modelDashboard"
        />
        <br />
        <br />
        <label>Indicator Widgets</label>
        <FieldArray
          name="indicatorWidgets"
          component={this.renderIndicatorWidgetArray}
        />
        <br />
        <br />
        <label>Attributes Reported by this Device</label>
        <AttConfigFormSection
          fieldName="attConfigs"
          formName="DeviceConfig"
          setConfigAtts={this.props.setConfigAtts}
        />
      </div>
    );
  }
}

DeviceConfigFormSection.defaultProps = {
  onChange: () => {},
};
const selector = formValueSelector('DeviceConfig'); // <-- same as form name
const mapStateToProps = (state) => {
  // can select values individually
  const indicatorWidgets = selector(state, 'indicatorWidgets');
  return {
    indicatorWidgets,
  };
};
export default connect(mapStateToProps)(DeviceConfigFormSection);
