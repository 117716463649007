import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import Typography from '@material-ui/core/Typography';
import * as compose from 'lodash.flowright';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import AddBox from '@material-ui/icons/AddBox';
import GridBase from '../common/GridBase';
import DeleteModel from '../common/DeleteModel';
import Modal from '../common/Modal';
import ContextMenuWithTrigger from '../common/ContextMenuWithTrigger';
import handleContextMenuClick from '../common/handleContextMenuClick';

import AccountManagerNew from './AccountManagerNew';
import AccountManagerUpdate from './AccountManagerUpdate';
import { CompanyAccountManagerQuery, CompanyGridQuery } from '../../graphql/queries/CompanyQueries';
import {
  UpdateAccountManagerMutation,
  CreateAccountManagerMutation,
  DeleteAccountManagerMutation,
} from '../../graphql/mutations/AccountManagerMutations';

class CompanyAccountManagerGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      modalTitle: '',
      modalType: '',
      accountManager: null,
      delete: {
        id: null,
        name: '',
      },
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.addAccountManager = this.addAccountManager.bind(this);
    this.handleDeleteClose = this.handleDeleteClose.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  addAccountManager() {
    this.setState({ modalIsOpen: true, modalTitle: 'Add Account Manager', modalType: 'New' });
    console.log('this addAcccountManager', this.state);
  }

  // handle context menu clicks
  handleClick(e, data, target) {
    const modelName = 'accountManager';
    const stateUpdate = handleContextMenuClick({
      e, data, target, modelName,
    });
    console.log('stateUpdate', stateUpdate);
    this.setState({
      ...stateUpdate,
    });
  }

  // reset delete accountManager id and name to null upon deletion
  handleDeleteClose() {
    this.setState({
      delete: {
        id: null,
        name: '',
      },
    });
  }

  render() {
    const columns = [
      {
        Header: 'ID',
        accessor: 'id',
        maxWidth: 90,
        filterable: true,
      }, {
        Header: 'User',
        id: 'user',
        accessor: (d) => (
          d.user ? d.user.fullName : ''),
        minWidth: 70,
        filterable: true,
      }, {
        Header: 'Rank',
        id: 'rank',
        accessor: (d) => (
          d.rank
            ? d.rank === 1
              ? 'Primary' : 'Secondary' : ''),
        minWidth: 70,
        filterable: true,
      }, {
        Header: 'Name',
        id: 'name',
        accessor: (d) => (
          d.user ? `${d.user.fullName}- Acount Manager` : 'Acount Manager'),
        show: false,
      },
      {
        Header: '',
        maxWidth: 23,
        resizable: false,
        sortable: false,
        Cell: ({ row }) => ContextMenuWithTrigger({
          handleClick: this.handleClick,
          row,
        }),
      },
    ];
    console.log(this.props);
    const sorting = [
      {
        id: 'rank',
        asc: true,
      },
    ];
    // render raw data
    const { loading, error, accountManagers } = this.props;
    if (loading) {
      return <p>Loading...</p>;
    } if (error) {
      console.log(error);
      return <p>Error loading data!</p>;
    }
    return (
      <div>
        <Typography variant="h4">{this.props.header}</Typography>
        <Divider />
        <IconButton color="primary" onClick={this.addAccountManager}>
          <AddBox />
        </IconButton>
        <GridBase dataVar={accountManagers} columns={columns} sorting={sorting} />

        <DeleteModel
          deleteMutation={DeleteAccountManagerMutation}
          refetchQueries={[{
            query: CompanyGridQuery,
          },
          ]}
          id={this.state.delete.id}
          name={this.state.delete.name}
          onClose={this.handleDeleteClose}
        />

        <Modal onClose={this.closeModal} modalIsOpen={this.state.modalIsOpen} contentLabel={this.state.modalTitle}>
          {
                  this.state.modalType && this.state.modalType === 'New'
                    ? (<AccountManagerNew companyId={this.props.company.id} />)
                    : (<AccountManagerUpdate accountManager={this.state.accountManager} header={this.state.modalTitle} />)
                }
        </Modal>
      </div>
    );
  }
}

export default compose(
  graphql(UpdateAccountManagerMutation, {
    name: 'UpdateAccountManager',
    //  options: props => ({ variables: { id: props.chart.id } }),
  }),
  graphql(CreateAccountManagerMutation, {
    name: 'CreateAccountManager',
  }),
  graphql(DeleteAccountManagerMutation),
  graphql(CompanyAccountManagerQuery, {
    name: 'AccountManagers',
    options: (props) => ({ variables: { id: props.company.id } }),
    props: ({
      ownProps, AccountManagers: {
        loading, error, company, refetch,
      },
    }) => {
      let accountManagers = [];
      if (company && company.accountManagers) {
        accountManagers = company.accountManagers;
      }
      return {
        accountManagers,
        loading,
        error,
        refetch,
      };
    },
  }),
)(CompanyAccountManagerGrid);
