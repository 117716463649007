import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import update from 'immutability-helper';
import { Field } from 'redux-form';
import renderDropdownList from '../form/renderDropdownList';
import {
  AssetDropdownWithLocationQuery,
  AssetDropdownQuery,
  ChildAssetsQuery,
} from '../../graphql/queries/AssetQueries';

class AssetDropdown extends Component {
  constructor(props) {
    super(props);
    this.handleAssetChange = this.handleAssetChange.bind(this);
  }

  handleAssetChange(e) {
    this.props.onDropdownChange(e);
  }

  getAssets(assets) {
    return assets.map((asset) => {
      if (asset.parent) {
        asset = update(asset, {
          // eslint-disable-next-line
          ['location']: { $set: asset.parent.location },
        });
      }
      return asset;
    });
  }

  render() {
    if (this.props.AssetQuery) {
      const {
        AssetQuery: { loading, error, assets },
      } = this.props;

      if (loading) {
        return <p>Loading...</p>;
      } if (error) {
        return <p>Error Loading Assets!</p>;
      }
      // TODO
      // add child asset dropdown
      return (
        <div>
          {this.props.label ? (
            <label>{this.props.label}</label>
          ) : (
            <label>Asset</label>
          )}
          <Field
            onChange={this.handleAssetChange}
            name={this.props.fieldName}
            component={renderDropdownList}
            data={this.getAssets(assets)}
            valueField="id"
            textField="fullName"
            canBeNull={this.props.canBeNull}
          />
        </div>
      );
    }
    return (
      <div>
        {this.props.label ? (
          <label>{this.props.label}</label>
        ) : (
          <label>Asset</label>
        )}
        <Field
          name={this.props.fieldName}
          component={renderDropdownList}
          data={[]}
          valueField="id"
          textField="fullName"
          canBeNull={this.props.canBeNull}
        />
      </div>
    );
  }
}

AssetDropdown.defaultProps = {
  fieldName: 'asset',
  waitForParent: 'false',
  onDropdownChange: () => {},
  canBeNull: true,
};
// if we have a location id, filter by that,
// otherwise fetch all assets
export default compose(
  graphql(AssetDropdownQuery, {
    name: 'AssetQuery',
    skip: (ownProps) => ownProps.locationId || ownProps.waitForParent,
    props: ({ ownProps, AssetQuery: { loading, error, assets } }) => ({
      AssetQuery: {
        assets,
        loading,
        error,
      },
    }),
  }),
  graphql(AssetDropdownWithLocationQuery, {
    name: 'AssetQuery',
    skip: (ownProps) => !ownProps.locationId,
    props: ({
      ownProps,
      AssetQuery: { loading, error, assetsByLocation },
    }) => ({
      AssetQuery: {
        assets: assetsByLocation,
        loading,
        error,
      },
    }),
    options: (ownProps) => ({
      variables: { locationId: ownProps.locationId },
    }),
  }),
  graphql(ChildAssetsQuery, {
    name: 'AssetQuery',
    skip: (ownProps) => !ownProps.parentId,
    props: ({ ownProps, AssetQuery: { loading, error, childAssets } }) => ({
      AssetQuery: {
        assets: childAssets,
        loading,
        error,
      },
    }),
    options: (ownProps) => ({
      variables: { parentId: ownProps.parentId },
    }),
  }),
)(AssetDropdown);
