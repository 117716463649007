import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

class renderDatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      selectedDate: null,
    };
  }

  componentDidMount() {
    if (this.props.input.value) {
      this.setState({
        selectedDate: new Date(this.props.input.value * 1000),
      });
    }
  }

  handleChange = (date) => {
    this.setState({
      selectedDate: date,
    });

    this.props.input.onChange(date);
  };

  render() {
    const {
      input,
      placeholder,
      disabled,
      meta: { touched, error },
    } = this.props;
    return (
      <div>
        <DatePicker
          //  {...input}
          disabled={disabled}
          isClearable
          placeholderText={placeholder}
          selected={this.state.selectedDate}
          onChange={this.handleChange}
          showTimeSelect
          dateFormat={this.props.inputValueFormat}
        />
        {touched && error && <span>{error}</span>}
      </div>
    );
  }
}

renderDatePicker.defaultProps = {
  inputValueFormat: 'MM/dd/yyyy h:mm aa',
  placeholder: '',
};
export default renderDatePicker;
