import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import {
  OilSampleTypeGridQuery,
  OilSampleTypeQuery,
} from '../../graphql/queries/OilSampleTypeQueries';
import OilSampleTypeForm from './OilSampleTypeForm';
import FormBase from '../form/FormBase';
import handleOilSampleTypeMutation from '../../graphql/functions/handleOilSampleTypeMutation';
import {
  CreateOilSampleTypeMutation,
  UpdateOilSampleTypeMutation,
} from '../../graphql/mutations/OilSampleTypeMutations';

class OilSampleTypeUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    if (typeof values === 'object') {
      let mutation;
      if (this.props.duplicate) {
        delete values.id;
        mutation = this.props.CreateOilSampleTypeMutation;
      } else {
        // store oilSampleType id
        values.id = this.props.oilSampleType.id;
        mutation = this.props.UpdateOilSampleTypeMutation;
      }

      handleOilSampleTypeMutation({
        values,
        mutation,
        onSuccess: this.props.onSuccess,
        refetchQueries: [
          { query: OilSampleTypeGridQuery },
        ],
      });
    }
  }

  render() {
    const {
      data: { loading, error, oilSampleType },
    } = this.props;
    return (
      <FormBase loading={loading} error={error} header={this.props.header}>
        <OilSampleTypeForm
          initialValues={oilSampleType}
          onSubmit={this.handleSubmit}
        />
      </FormBase>
    );
  }
}

export default compose(
  graphql(UpdateOilSampleTypeMutation, {
    name: 'UpdateOilSampleTypeMutation',
    options: (props) => ({ variables: { id: props.oilSampleType.id } }),
  }),
  graphql(CreateOilSampleTypeMutation, {
    name: 'CreateOilSampleTypeMutation',
  }),
  graphql(OilSampleTypeQuery, {
    options: (props) => ({ variables: { id: props.oilSampleType.id }, fetchPolicy: 'network-only' }),
  }),
)(OilSampleTypeUpdate);
