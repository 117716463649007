import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { reduxForm, Field } from 'redux-form';
import WizardBase from '../form/WizardBase';
import ErrorMessage from '../common/ErrorMessage';
import CSVFileInput from '../form/CSVFileInput';

class CSVUpload extends Component {
  constructor(props) {
    super(props);
    this.handleFileError = this.handleFileError.bind(this);
    this.handleFileLoaded = this.handleFileLoaded.bind(this);
    this.state = { fileLoaded: false, error: false };
  }

  handleFileLoaded(data) {
    console.log(data);
    this.setState({ fileLoaded: true });
  }

  handleFileError(err) {
    console.log(err);
    this.setState({ error: true });
    ErrorMessage({
      msg: err,
    });
  }

  render() {
    const { handleSubmit, pristine, submitting } = this.props;
    return (
      <WizardBase header="Upload CSV">
        <form onSubmit={handleSubmit}>
          <Field
            name="uploadedData"
            onChange={this.handleFileLoaded}
            onError={this.handleFileError}
            component={CSVFileInput}
          />
          <br />
          <div>
            <Button
              variant="contained"
              type="button"
              onClick={this.props.previousPage}
            >
              Previous
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={
                pristine
                || submitting
                || this.state.error
                || !this.state.fileLoaded
              }
            >
              Next
              <i className="material-icons right">send</i>
            </Button>
          </div>
        </form>
      </WizardBase>
    );
  }
}

const mapStateToProps = (state) => ({
  formState: state.form,
});

CSVUpload = reduxForm({
  form: 'CSVImport', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(CSVUpload);
export default connect(mapStateToProps)(CSVUpload);
