import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';

import InstallerConfigFieldForm from './InstallerConfigFieldForm';
import FormBase from '../../../form/FormBase';
import { CreateInstallerConfigFieldMutation } from '../../../../graphql/mutations/InstallerConfigMutations';
import { InstallerConfigFieldGridQuery } from '../../../../graphql/queries/InstallerConfigQueries';
import handleInstallerConfigFieldMutation from '../../../../graphql/functions/handleInstallerConfigFieldMutation';

class InstallerConfigFieldNew extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    console.log('values', values);
    handleInstallerConfigFieldMutation({
      values,
      mutation: this.props.createInstallerConfigFieldMutation,
      refetchQueries: [{ query: InstallerConfigFieldGridQuery }],
      onSuccess: this.props.onSuccess,
    });
  }

  render() {
    return (
      <FormBase header="Add Installer Configuration Field">
        <InstallerConfigFieldForm onSubmit={this.handleSubmit} />
      </FormBase>
    );
  }
}

const InstallerConfigFieldNewWithMutation = compose(
  graphql(CreateInstallerConfigFieldMutation, {
    name: 'createInstallerConfigFieldMutation',
  }),
)(InstallerConfigFieldNew);

export default InstallerConfigFieldNewWithMutation;
