import React, { Component } from 'react';
import { reduxForm, Field, change } from 'redux-form';
import { connect } from 'react-redux';
import FormField from '../../form/FormField';
import FormSubmitButton from '../../form/FormSubmitButton';
import ClearButton from '../../form/ClearButton';
import AttConfigFormSection from '../../data/attribute/attConfig/AttConfigFormSection';
import ModelDashboardDropdown from '../../modelDashboard/ModelDashboardDropdown';

class AssetConfigForm extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    console.log('values', values);
    if (typeof values === 'object') {
      if (values.modelDashboard && values.modelDashboard.id) {
        values.modelDashboard = values.modelDashboard.id;
      }
    }
    this.props.handleSubmit(values);
  }

  render() {
    const { pristine, reset, submitting } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <div data-tip="Name of Asset Configuration">
          <Field
            name="name"
            type="text"
            component={FormField}
            label="Asset Configuration Name"
          />
        </div>

        <ModelDashboardDropdown
          label="Default Dashboard"
          fieldName="modelDashboard"
        />

        <label>Attributes Reported by this Asset</label>
        <AttConfigFormSection
          fieldName="attConfigs"
          formName="AssetConfig"
          setConfigAtts={this.props.setConfigAtts}
        />

        <div>
          <FormSubmitButton pristine={pristine} submitting={submitting} />
          <ClearButton
            pristine={pristine}
            submitting={submitting}
            reset={reset}
          />
        </div>
      </form>
    );
  }
}

AssetConfigForm.defaultProps = {
  initialValues: null,
};
const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Cannot be blank';
  }
  return errors;
};

AssetConfigForm = reduxForm({ validate, form: 'AssetConfig' })(
  AssetConfigForm,
);
AssetConfigForm = connect(
  null,
  {
    setConfigAtts: (event, newValue, previousValue) => change('AssetConfig', 'attConfigs', newValue),
  },
)(AssetConfigForm);
export default AssetConfigForm;
