import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

class Restricted extends Component {
  render() {
    return (
      <Container>
        <Typography variant="h3">Restricted</Typography>
        <Typography variant="h5">You do not have access to this page</Typography>
      </Container>
    );
  }
}

export default Restricted;
