import { gql } from '@apollo/client';
import { oilSampleTypeFragment } from '../fragments';

const OilSampleTypeQuery = gql`
  query oilSampleType($id: ID!) {
    oilSampleType(id: $id) {
      ...oilSampleType
    }
  }
  ${oilSampleTypeFragment}
`;

const OilSampleTypeGridQuery = gql`
  query OilSampleTypeGridQuery {
    oilSampleTypes {
      ...oilSampleType
    }
  }
  ${oilSampleTypeFragment}
`;

export { OilSampleTypeGridQuery, OilSampleTypeQuery };
