import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import { connect } from 'react-redux';
import LocationForm from './LocationForm';
import FormBase from '../form/FormBase';
import handleMutation from '../../graphql/functions/handleMutation';
import {
  LocationQuery,
  LocationGridQuery,
  LocationDropdownQuery,
} from '../../graphql/queries/LocationQueries';
import { UpdateLocationMutation } from '../../graphql/mutations/LocationMutations';
import { CompanyGridQuery } from '../../graphql/queries/CompanyQueries';
import { isNonNullObject } from '../common/functions';

class LocationUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
  }

  handleSuccess(res) {
    this.props.onSuccess(res);
  }

  handleSubmit(values) {
    if (typeof values === 'object') {
      // store location id
      values.id = this.props.location.id;
      // set company to company id instead of object
      if (typeof values.company === 'object' && values.company.id) {
        values.company = values.company.id;
      }
      if (isNonNullObject(values.modelDashboard) && values.modelDashboard.id) {
        values.modelDashboard = values.modelDashboard.id;
      }
      if (
        isNonNullObject(values.installerConfig)
        && values.installerConfig.id
      ) {
        values.installerConfig = values.installerConfig.id;
      }
      if (this.props.auth && this.props.auth.obfuscate === true) {
        delete values.name;
      }
      if (values.apiKey) {
        delete values.apiKey;
      }
    }
    // submit mutation and refetch location and company grid queries
    handleMutation({
      mutate: this.props.mutate,
      variables: { location: values },
      refetchQueries: [
        { query: LocationGridQuery },
        { query: CompanyGridQuery },
        { query: LocationDropdownQuery },
      ],
      onSuccess: this.handleSuccess,
    });
  }

  render() {
    const {
      data: { loading, error, location },
    } = this.props;
    return (
      <FormBase loading={loading} error={error} header="Edit Location">
        <LocationForm initialValues={location} onSubmit={this.handleSubmit} />
      </FormBase>
    );
  }
}

LocationUpdate = compose(
  graphql(UpdateLocationMutation, {
    options: (props) => ({ variables: { id: props.location.id } }),
  }),
  graphql(LocationQuery, {
    options: (props) => ({ variables: { id: props.location.id }, fetchPolicy: 'network-only' }),
  }),
)(LocationUpdate);

function mapStateToProps({ auth }) {
  return { auth };
}
export default connect(mapStateToProps)(LocationUpdate);
