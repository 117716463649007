import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';

import DeviceForm from './DeviceForm';
import FormBase from '../form/FormBase';
import { CreateDeviceMutation } from '../../graphql/mutations/DeviceMutations';
import { AddDeviceToGroupsMutation } from '../../graphql/mutations/DeviceGroupMutations';
import { ap2200GridFragment, deviceGridFragment } from '../../graphql/fragments';
import handleDeviceMutation from '../../graphql/functions/handleDeviceMutation';
import handleMutation from '../../graphql/functions/handleMutation';
import cacheAddToArray from '../../graphql/functions/cacheAddToArray';

class DeviceNew extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [], deviceTypeConfigVals: {}, deviceGroupAdd: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setConfigVals = this.setConfigVals.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
  }

  setConfigVals(vals) {
    this.setState({ deviceTypeConfigVals: vals });
  }

  handleSubmit(values) {
    if (!values) {
      return;
    }
    const deviceTypeConfigs = this.state.deviceTypeConfigVals;
    for (let i = 0; i < deviceTypeConfigs.length; i++) {
      if (deviceTypeConfigs[i].rule === 'Boolean') {
        // if this boolean has not been set, automatically set it to false
        if (!values.deviceTypeConfig[i]) {
          values.deviceTypeConfig[i] = {
            k: deviceTypeConfigs[i].key,
            v: false,
          };
        }
      }
    }
    let successFn = this.props.onSuccess;
    if (values.deviceGroupAdd && values.deviceGroupAdd.length > 0) {
      this.setState({ deviceGroupAdd: values.deviceGroupAdd });
      successFn = this.onSuccess;
    }
    const cacheOptions = [{ cachedListName: 'editableDevices', fragment: deviceGridFragment, fragmentName: 'deviceGrid' }];
    if (values.deviceType && values.deviceType.key === 'ap2200') {
      cacheOptions.push({
        cachedListName: 'editableAp2200s',
        fragment: ap2200GridFragment,
        fragmentName: 'ap2200Grid',
      });
    }
    const updateFn = (cache, { data }) => {
      cacheAddToArray({
        cache,
        data,
        mutationString: 'createDevice',
        cacheOptions,
      });
    };
    handleDeviceMutation({
      values,
      deviceMutation: this.props.createDeviceMutation,
      onSuccess: successFn,
      update: updateFn,
    });
  }

  onSuccess(newDevice) {
    // if added to groups, send request to add device to groups
    if (newDevice.data && newDevice.data.createDevice && newDevice.data.createDevice.id
      && this.state.deviceGroupAdd && this.state.deviceGroupAdd.length > 0) {
      handleMutation({
        mutate: this.props.addDeviceToGroupsMutation,
        variables: {
          deviceGroupDeviceInput: {
            deviceId: newDevice.data.createDevice.id, deviceGroups: this.state.deviceGroupAdd,
          },
        },
        onSuccess: this.props.onSuccess,
        dontShowSuccess: true,
      });
    }
  }

  render() {
    return (
      <FormBase header="Add Device">
        <DeviceForm onSubmit={this.handleSubmit} />
      </FormBase>
    );
  }
}

const DeviceNewWithMutation = compose(
  graphql(CreateDeviceMutation, { name: 'createDeviceMutation' }),
  graphql(AddDeviceToGroupsMutation, { name: 'addDeviceToGroupsMutation' }),
)(DeviceNew);

export default DeviceNewWithMutation;
