import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import PSCard from '../common/PSCard';
import DeviceConfigurationPage from './DeviceConfigurationPage';
import handleDeviceMutation from '../../graphql/functions/handleDeviceMutation';
import { AddDeviceToGroupsMutation } from '../../graphql/mutations/DeviceGroupMutations';
import { CreateDeviceMutation } from '../../graphql/mutations/DeviceMutations';
import { DeviceGridQuery } from '../../graphql/queries/DeviceQueries';
import DeviceLocationPage from './DeviceLocationPage';
import DeviceAssetPage from './DeviceAssetPage';
import DeviceCompanyPage from './DeviceCompanyPage';
import DeviceOwnerPage from './DeviceOwnerPage';
import DeviceBasicsPage from './DeviceBasicsPage';
import handleMutation from '../../graphql/functions/handleMutation';

class DeviceWizard extends Component {
  constructor(props) {
    super(props);
    this.state = { deviceGroupAdd: [] };
    this.onSubmit = this.onSubmit.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.state = {
      page: 1,
    };
  }

  componentWillUnmount(dispatch) {
    this.props.dispatch(reset('deviceWizard'));
  }

  onSubmit(values) {
    let refetchQueries = [{ query: DeviceGridQuery }];
    if (values && values.deviceGroupAdd && values.deviceGroupAdd.length > 0) {
      this.setState({ deviceGroupAdd: values.deviceGroupAdd });
      refetchQueries = [];
    }
    handleDeviceMutation({
      values,
      deviceMutation: this.props.createDeviceMutation,
      refetchQueries,
      onSuccess: this.onSuccess,
    });
  }

  onSuccess(newDevice) {
    // if added to groups, send request to add device to groups
    if (this.state.deviceGroupAdd && this.state.deviceGroupAdd.length > 0) {
      handleMutation({
        mutate: this.props.addDeviceToGroupsMutation,
        variables: {
          deviceGroupDeviceInput: {
            deviceId: newDevice.data.createDevice.id, deviceGroups: this.state.deviceGroupAdd,
          },
        },
        refetchQueries: [{ query: DeviceGridQuery }],
        onSuccess: () => {},
        dontShowSuccess: true,
      });
    }
    this.props.history.push('/homepage');
  }

  nextPage() {
    this.setState((prevState) => ({ page: prevState.page + 1 }));
  }

  previousPage() {
    this.setState((prevState) => ({ page: prevState.page - 1 }));
  }

  render() {
    const { onSubmit } = this;
    const { page } = this.state;
    return (
      <PSCard cardTitle="Create Device">
        {page === 1 && <DeviceBasicsPage onSubmit={this.nextPage} />}
        {page === 2 && (
          <DeviceConfigurationPage
            previousPage={this.previousPage}
            onSubmit={this.nextPage}
          />
        )}
        {page === 3 && (
          <DeviceCompanyPage
            previousPage={this.previousPage}
            onSubmit={this.nextPage}
          />
        )}
        {page === 4 && (
          <DeviceOwnerPage
            previousPage={this.previousPage}
            onSubmit={this.nextPage}
          />
        )}
        {page === 5 && (
          <DeviceLocationPage
            previousPage={this.previousPage}
            onSubmit={this.nextPage}
          />
        )}
        {page === 6 && (
          <DeviceAssetPage
            previousPage={this.previousPage}
            onSubmit={onSubmit}
          />
        )}
      </PSCard>
    );
  }
}
DeviceWizard = compose(
  graphql(CreateDeviceMutation, { name: 'createDeviceMutation' }),
  graphql(AddDeviceToGroupsMutation, { name: 'addDeviceToGroupsMutation' }),
)(DeviceWizard);
export default connect(null)(DeviceWizard);
