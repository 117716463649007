export default ({ data, modelName }) => {
  let returnObj = {};
  if (data.action === 'Edit') {
    returnObj[modelName] = data.row;
    returnObj.modalIsOpen = true;
    const name = data.row && data.row.name ? data.row.name : '';
    returnObj.modalTitle = `Edit ${name}`;
    returnObj.modalType = 'Edit';
  } else if (data.action === 'Delete') {
    returnObj = {
      delete: { id: data.row.id, name: data.row.name },
    };
  } else if (data.action === 'Duplicate') {
    const name = data.row && data.row.name ? `${data.row.name} (copy)` : ' Copy';
    returnObj[modelName] = data.row;
    returnObj.modalIsOpen = true;
    returnObj.modalTitle = `Create ${name}`;
    returnObj.modalType = 'Duplicate';
  } else if (data.action === 'LatestData') {
    returnObj[modelName] = data.row;
    returnObj.modalIsOpen = true;
    returnObj.modalTitle = data.row && data.row.name
      ? `${data.row.name} - Latest Data`
      : 'Latest Data';
    returnObj.modalType = 'LatestData';
  } else if (data.action === 'DeviceGroupDevices') {
    returnObj[modelName] = data.row;
    returnObj.modalIsOpen = true;
    returnObj.modalTitle = data.row && data.row.name
      ? `${data.row.name} - Devices`
      : 'Device Group Devices';
    returnObj.modalType = 'DeviceGroupDevices';
  } else if (data.action === 'ReplaceDevice') {
    returnObj[modelName] = data.row;
    returnObj.modalIsOpen = true;
    returnObj.modalTitle = 'Replace Device';
    returnObj.modalType = 'ReplaceDevice';
  } else if (data.action === 'AccountManagers') {
    returnObj[modelName] = data.row;
    returnObj.modalIsOpen = true;
    returnObj.modalTitle = data.row && data.row.name
      ? `${data.row.name} - Account Managers`
      : 'Account Managers';
    returnObj.modalType = 'AccountManagers';
  }
  return returnObj;
};
