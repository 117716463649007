import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuVertIcon from '@material-ui/icons/MoreVert';
import { withStyles } from '@material-ui/core/styles';
import * as actions from '../../actions';
import { HEADER_CONTRAST } from '../../config';

const styles = () => ({
  root: {
    color: HEADER_CONTRAST,
  },
});

class MoreMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      open: false,
    };
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleClick = (event) => {
    console.log('fetchUser debug');
    this.props.fetchUser();
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  handleLogout = () => {
    const { history } = this.props;
    this.props.logoutUser(history);
  };

  render() {
    return (
      <div>
        <IconButton
          onClick={this.handleClick}
          classes={{ root: this.props.classes.root }}
        >
          <MenuVertIcon color="inherit" />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.handleRequestClose}
        >
          {/* <MenuItem onClick={this.handleRequestClose}>Profile</MenuItem> */}
          <MenuItem onClick={this.handleRequestClose}>
            Welcome
            {' '}
            {this.props.name}
          </MenuItem>
          <MenuItem onClick={this.props.themeToggle}>
            {this.props.toggleThemeName}
            {' '}
            Theme
          </MenuItem>
          <Divider />
          <MenuItem onClick={this.handleLogout} target="_self">
            Logout
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

function mapStateToProps({ auth }) {
  return { auth };
}
MoreMenu = connect(
  mapStateToProps,
  actions,
)(withStyles(styles)(MoreMenu));
export default withRouter(MoreMenu);
