import React, { Component } from 'react';
import FixedActionButton from '../common/FixedActionButton';
import OilSampleTypeGrid from './OilSampleTypeGrid';
import Modal from '../common/Modal';
import PSCard from '../common/PSCard';
import OilSampleTypeNew from './OilSampleTypeNew';

class OilSampleTypeMain extends Component {
  constructor() {
    super();
    this.state = { modalIsOpen: false };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    return (
      <div>
        <PSCard cardTitle="Oil Sample Types">
          <OilSampleTypeGrid />
        </PSCard>

        <div>
          <FixedActionButton onClick={this.openModal} />
        </div>
        <Modal
          onClose={this.closeModal}
          modalIsOpen={this.state.modalIsOpen}
          contentLabel="Add Oil Sample Type"
        >
          <OilSampleTypeNew />
        </Modal>
      </div>
    );
  }
}

export default OilSampleTypeMain;
