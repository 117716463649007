import { gql } from '@apollo/client';
import { deviceTypeFragment } from '../fragments';

const CreateDeviceTypeMutation = gql`
  mutation createDeviceType($deviceType: DeviceTypeInput) {
    createDeviceType(deviceType: $deviceType) {
      ...deviceType
    }
  }
  ${deviceTypeFragment}
`;

const UpdateDeviceTypeMutation = gql`
  mutation updateDeviceType($deviceType: DeviceTypeInput) {
    updateDeviceType(deviceType: $deviceType) {
      ...deviceType
    }
  }
  ${deviceTypeFragment}
`;

const DeleteDeviceTypeMutation = gql`
  mutation deleteDeviceType($id: ID!) {
    deleteDeviceType(id: $id) {
      name
    }
  }
`;

export {
  CreateDeviceTypeMutation,
  UpdateDeviceTypeMutation,
  DeleteDeviceTypeMutation,
};
