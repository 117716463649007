import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';

import AssetTypeForm from './AssetTypeForm';
import FormBase from '../../form/FormBase';
import { CreateAssetTypeMutation } from '../../../graphql/mutations/AssetTypeMutations';
import { AssetTypeGridQuery } from '../../../graphql/queries/AssetTypeQueries';
import handleMutation from '../../../graphql/functions/handleMutation';

class AssetTypeNew extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    if (typeof values === 'object' && values.fields) {
      delete values.uploadCSV;
      for (let i = 0; i < values.fields.length; i++) {
        if (values.fields[i].rule && values.fields[i].rule.id) {
          values.fields[i].rule = values.fields[i].rule.id;
        }
      }
    }
    handleMutation({
      mutate: this.props.createAssetTypeMutation,
      variables: { assetType: values },
      refetchQueries: [{ query: AssetTypeGridQuery }],
      onSuccess: this.props.onSuccess,
    });
  }

  render() {
    return (
      <FormBase header="Add Asset Type">
        <AssetTypeForm onSubmit={this.handleSubmit} />
      </FormBase>
    );
  }
}

export default compose(
  graphql(CreateAssetTypeMutation, { name: 'createAssetTypeMutation' }),
)(AssetTypeNew);
