import { gql } from '@apollo/client';
import { deviceAllFragment } from '../fragments';

const CreateDeviceMutation = gql`
  mutation createDevice($device: DeviceInput!) {
    createDevice(device: $device) {
      ...deviceAll
      latestConnection
    }
  }
  ${deviceAllFragment}
`;

const UpdateDeviceMutation = gql`
  mutation updateDevice($device: DeviceInput!) {
    updateDevice(device: $device) {
      ...deviceAll
      latestConnection
    }
  }
  ${deviceAllFragment}
`;

const DeleteDeviceMutation = gql`
  mutation deleteDevice($id: ID!) {
    deleteDevice(id: $id) {
      name
    }
  }
`;

export { CreateDeviceMutation, UpdateDeviceMutation, DeleteDeviceMutation };
