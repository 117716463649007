import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import GridBase from '../common/GridBase';

import {
  companyUsersByUserQuery,
  assetUsersByUserQuery,
  locationUsersByUserQuery,
} from '../../graphql/queries/AccessControlQueries';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

class UserAccessGrid extends Component {
  // Get queries formatted for table
  mapQueries(queries) {
    const access = [];
    queries.forEach((q) => {
      if (q && q.accessData) {
        // Each query result is initially formatted with
        // {control type}: {name of location/asset/company}
        q.accessData.forEach((res) => {
          const data = Object.entries(res).map(([type, name]) => ({ type, name }));
          access.push(...data);
        });
      }
    });
    return access;
  }

  getColumns() {
    const columns = [
      {
        Header: 'Access Type ',
        id: 'type',
        accessor: (d) => d.type,
        minWidth: 70,
        sortable: true,
      },
      {
        Header: 'Name',
        id: 'name',
        accessor: (d) => d.name,
        minWidth: 70,
        sortable: true,
      },
    ];
    return columns;
  }

  render() {
    const queries = [
      this.props.assetUsersByUserQuery,
      this.props.companyUsersByUserQuery,
      this.props.locationUsersByUserQuery,
    ];
    let loading = false;
    // require that all queries be loading otherwise sometimes the loading is not set to false when it should be
    if (this.props.assetUsersByUserQuery.loading
      && this.props.companyUsersByUserQuery.loading
      && this.props.locationUsersByUserQuery.loading) {
      loading = true;
    }
    console.log('this.props', this.props, loading);
    let error = false;
    queries.forEach((q) => {
      if (q && q.error) {
        error = true;
      }
    });

    if (loading) {
      return <p>Loading...</p>;
    } if (error) {
      return <p>Error loading user access!</p>;
    }
    return (
      <div>
        <Container>
          <Typography variant="h5">Current Access</Typography>
          <GridBase
            dataVar={this.mapQueries(queries)}
            columns={this.getColumns()}
            sorting={[{ id: 'type', asc: true }]}
          />
        </Container>
      </div>
    );
  }
}

export default compose(
  graphql(companyUsersByUserQuery, {
    name: 'companyUsersByUserQuery',
    props: ({ companyUsersByUserQuery: { error, loading, companyUsersByUser } }) => {
      let accessData;
      if (companyUsersByUser) {
        accessData = companyUsersByUser.map((user) => ({ Company: user.company.name }));
      }
      return {
        companyUsersByUserQuery: {
          accessData,
          loading,
          error,
        },
      };
    },
  }),
  graphql(assetUsersByUserQuery, {
    name: 'assetUsersByUserQuery',
    props: ({ assetUsersByUserQuery: { error, loading, assetUsersByUser } }) => {
      let accessData;
      if (assetUsersByUser) {
        accessData = assetUsersByUser.map((user) => ({ Asset: user.asset.name }));
      }
      return {
        assetUsersByUserQuery: {
          accessData,
          loading,
          error,
        },
      };
    },
  }),
  graphql(locationUsersByUserQuery, {
    name: 'locationUsersByUserQuery',
    props: ({ locationUsersByUserQuery: { error, loading, locationUsersByUser } }) => {
      let accessData;
      if (locationUsersByUser) {
        accessData = locationUsersByUser.map((user) => ({ Location: user.location.name }));
      }
      return {
        locationUsersByUserQuery: {
          accessData,
          loading,
          error,
        },
      };
    },
  }),
)(UserAccessGrid);
