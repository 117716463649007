import { gql } from '@apollo/client';
import { assetTypeAllFragment } from '../fragments';

const AssetTypeQuery = gql`
  query assetType($id: ID!) {
    assetType(id: $id) {
      ...assetTypeAll
    }
  }
  ${assetTypeAllFragment}
`;

const AssetTypeGridQuery = gql`
  query AssetTypeGridQuery {
    assetTypes {
      ...assetTypeAll
    }
  }
  ${assetTypeAllFragment}
`;

const AssetTypeDropdownQuery = gql`
  query assetTypes {
    assetTypes {
      ...assetTypeAll
    }
  }
  ${assetTypeAllFragment}
`;

export { AssetTypeGridQuery, AssetTypeDropdownQuery, AssetTypeQuery };
