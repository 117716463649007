export default ({
  cache, data, mutationString, cacheOptions,
}) => {
  cacheOptions.forEach((element) => {
    cache.modify({
      fields: {
        [element.cachedListName](existingArr = [], { readField }) {
          const newRef = cache.writeFragment({
            data: data[mutationString],
            fragment: element.fragment,
            fragmentName: element.fragmentName,
          });
          // Quick safety check - if the new data is already
          // present in the cache, we don't need to add it again.
          if (existingArr.some(
            (ref) => readField('id', ref) === data[mutationString].id,
          )) {
            return existingArr;
          }
          return [...existingArr, newRef];
        },
      },
    });
  });
};
