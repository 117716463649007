import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { Field } from 'redux-form';
import { InstallerConfigFieldSelectQuery } from '../../../../graphql/queries/InstallerConfigQueries';
import renderSelectTree from '../../../form/renderSelectTree';
import { selectMultipleArrayToString } from '../../../common/functions';

class InstallerConfigFieldSelect extends Component {
  constructor(props) {
    super(props);
    this.handleInstallerConfigFieldChange = this.handleInstallerConfigFieldChange.bind(
      this,
    );
    this.normalizeSelectArray = this.normalizeSelectArray.bind(this);
  }

  handleInstallerConfigFieldChange(e) {
    this.props.onChange(e);
  }

  // Set array to string if only 1 value allowed
  normalizeSelectArray(value) {
    return selectMultipleArrayToString(this.props.multiple, value);
  }

  render() {
    console.log('installerConfigFields props', this.props);
    const {
      data: { loading, error, installerConfigFields },
    } = this.props;

    if (loading) {
      return <p>Loading...</p>;
    } if (error) {
      return <p>Error Loading Fields!</p>;
    }
    return (
      <div>
        <label>{this.props.label}</label>

        <Field
          onChange={this.handleInstallerConfigFieldChange}
          name={this.props.fieldName}
          component={renderSelectTree}
          data={installerConfigFields}
          valueField="id"
          textField="name"
          multiple={this.props.multiple}
          normalize={this.normalizeSelectArray}
        />
      </div>
    );
  }
}

InstallerConfigFieldSelect.defaultProps = {
  label: 'InstallerConfigFields',
  fieldName: 'installerConfigFields',
  multiple: false,
  onChange: () => {},
};
export default graphql(InstallerConfigFieldSelectQuery)(
  InstallerConfigFieldSelect,
);
