import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';

import handleAccessControlMutation from '../../graphql/functions/handleAccessControlMutation';
import AccessControlForm from './AccessControlForm';
import FormBase from '../form/FormBase';
import {
  CreateCompanyUserMutation,
  CreateLocationUserMutation,
  CreateAssetUserMutation,
} from '../../graphql/mutations/AccessControlMutations';

class AccessControlNew extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    let mutation;
    switch (this.props.model) {
      case 'company':
        mutation = this.props.mutateCompany;
        break;
      case 'location':
        mutation = this.props.mutateLocation;
        break;
      case 'asset':
        mutation = this.props.mutateAsset;
        break;
      default:
        break;
    }
    handleAccessControlMutation({
      model: this.props.model,
      mutation,
      values,
      refetchQueries: this.props.refetchQueries,
      onSuccess: this.props.onSuccess,
    });
  }

  render() {
    console.log('Access control new', this.props);
    return (
      <FormBase header={this.props.header}>
        <AccessControlForm
          onSubmit={this.handleSubmit}
          modelSelect={this.props.modelSelect}
        />
      </FormBase>
    );
  }
}

export default compose(
  graphql(CreateCompanyUserMutation, {
    name: 'mutateCompany',
  }),
  graphql(CreateLocationUserMutation, {
    name: 'mutateLocation',
  }),
  graphql(CreateAssetUserMutation, {
    name: 'mutateAsset',
  }),
)(AccessControlNew);
