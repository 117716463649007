import React, { Component } from 'react';
import { reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import WizardBase from '../form/WizardBase';
import BasicDeviceForm from '../device/BasicDeviceForm';

class DeviceBasicsPage extends Component {
  render() {
    const { handleSubmit } = this.props;
    return (
      <WizardBase header="Enter Your Device Information">
        <form onSubmit={handleSubmit}>
          <BasicDeviceForm
            setDeviceConfig={this.props.setDeviceConfig}
            setDeviceName={this.props.setDeviceName}
            setDeviceGroupAdd={this.props.setDeviceGroupAdd}
          />
          <br />
          <div>
            <Button type="submit" className="next">
              Next
            </Button>
          </div>
        </form>
      </WizardBase>
    );
  }
}

DeviceBasicsPage = reduxForm({
  form: 'deviceWizard', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(DeviceBasicsPage);
export default connect(
  null,
  {
    setDeviceConfig: (event, newValue, previousValue) => change('deviceWizard', 'deviceConfig', event.deviceConfig),
    setDeviceName: (event, newValue, previousValue) => change('deviceWizard', 'name', event.name),
    setDeviceGroupAdd: (newValue) => change('deviceWizard', 'deviceGroupAdd', newValue),
  },
)(DeviceBasicsPage);
