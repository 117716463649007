import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

const StyledMenu = withStyles({
  // paper: {
  //   border: 'none',
  //   background: 'transparent',
  // },
})((props) => (
  <Menu
    elevation={1}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    {...props}
  />
));

const getOptions = (options) => options.map(({
  title, size, name, icon, color, onClick,
}) => (
  <MenuItem
    key={name}
    onClick={onClick}
  >
    {/* <ListItemIcon>
      <i className="material-icons">{icon}</i>
    </ListItemIcon> */}
    <Typography variant="inherit" noWrap>{title}</Typography>
  </MenuItem>
));
function FixedActionButton(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  if (!props.options) {
    return (
      <Fab onClick={props.onClick}>
        <i className="material-icons">add</i>
      </Fab>
    );
  }
  return (
    <div>
      <Fab onClick={handleClick}>
        <i className="material-icons">add</i>
      </Fab>
      <StyledMenu
        id="simple-StyledMenu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {getOptions(props.options)}
      </StyledMenu>
    </div>
  );
}
export default FixedActionButton;
