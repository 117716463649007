import { gql } from '@apollo/client';
import { dataTypeAllFragment } from '../fragments';

const DataTypeGridQuery = gql`
  query DataTypeGridQuery {
    dataTypes {
      ...dataTypeAll
    }
  }
  ${dataTypeAllFragment}
`;

const DataTypeDropdownQuery = gql`
  query DataTypeDropdownQuery {
    dataTypes {
      id
      name
    }
  }
`;

const DataTypeSelectQuery = gql`
  query DataTypeSelectQuery {
    dataTypes {
      id
      name
    }
  }
`;

const DataTypeQuery = gql`
  query dataType($id: ID!) {
    dataType(id: $id) {
      ...dataTypeAll
    }
  }
  ${dataTypeAllFragment}
`;

export {
  DataTypeDropdownQuery,
  DataTypeQuery,
  DataTypeSelectQuery,
  DataTypeGridQuery,
};
