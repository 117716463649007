import React, { Component } from 'react';

class ConfigJsonForm extends Component {
  // user changes json string
  // parse json string and use values to update attribute configuration form

  render() {
    return (
      <div>
        <label>Optional: Load Attributes From Json</label>

        <div
          data-tip="Quickly populate attributes via example JSON. <br/>Remember: use proper JSON syntax <br/>e.g. use double quotes around both field names)"
        >
          <textarea
            placeholder="{&quot;exampleImportField1&quot;: 4567, &quot;exampleImportField2&quot;: 1234}"
            onChange={this.props.handleChange}
          />
        </div>
      </div>
    );
  }
}

export default ConfigJsonForm;
