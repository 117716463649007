import { toastr } from 'react-redux-toastr';

export default (props) => (
  // show success toast
  toastr.light('Success!', props.msg, {
    icon: 'success',
    status: 'success',
    timeOut: props.timeOut || 10000,
  })
);
