import handleMutation from './handleMutation';
import formatAttConfig from './formatAttConfig';

const handleSubmitAssetConfig = (props) => {
  handleMutation({
    mutate: props.mutation,
    variables: { assetConfig: props.values },
    refetchQueries: props.refetchQueries,
    onSuccess: props.onSuccess,
  });
};

export default (props) => {
  const { values } = props;
  if (typeof values === 'object' && values !== null) {
    // reformat asset configuration attributes
    const formattedAttConfig = formatAttConfig(values);
    values.attConfigs = formattedAttConfig.attConfigs;
    handleSubmitAssetConfig(props);
  }
};
