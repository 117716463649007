import React, { Component } from 'react';
import ReactTable from 'react-table';
import displayTimestamp from '../common/displayTimestamp';
import timestampSort from '../common/timestampSort';
import containsFilter from '../common/containsFilter';

class DeviceGrid extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedRowIndex: null };
  }

  columns = [
    {
      Header: 'ID',
      accessor: 'id',
      maxWidth: 50,
      filterable: true,
      show: true,
    },
    {
      Header: 'Company',
      id: 'companyName',
      accessor: (d) => (d.asset && d.asset.location && d.asset.location.company
        ? d.asset.location.company.name
        : null),
      maxWidth: 120,
      filterable: true,
    },
    {
      Header: 'Location',
      id: 'locationName',
      accessor: (d) => (d.asset && d.asset.location ? d.asset.location.name : null),
      maxWidth: 120,
      filterable: true,
    },
    {
      Header: 'Asset',
      id: 'assetName',
      accessor: (d) => (d.asset ? d.asset.fullName + (d.installLocation ? ` ${d.installLocation}` : '') : null),
      minWidth: 110,
      filterable: true,
    },
    {
      Header: 'Name',
      accessor: 'name',
      minWidth: 100,
      filterable: true,
    },
    {
      Header: 'Type',
      id: 'deviceType',
      accessor: (d) => (d.deviceType ? d.deviceType.name : null),
      maxWidth: 80,
      filterable: true,
    },
    {
      Header: 'Device Type Id',
      id: 'deviceTypeId',
      accessor: (d) => (d.deviceType ? d.deviceType.id : null),
      show: false,
    },
    {
      Header: 'Config',
      id: 'deviceConfig',
      accessor: (d) => (d.deviceConfig ? d.deviceConfig.name : null),
      maxWidth: 80,
      filterable: true,
    },
    {
      Header: 'Serial',
      accessor: 'serial',
      maxWidth: 50,
      filterable: true,
    },
    {
      Header: 'Legacy serial',
      accessor: 'legacy_serial',
      maxWidth: 50,
      filterable: true,
    },
    {
      Header: 'Owner',
      id: 'owner',
      accessor: (d) => (d.owner ? d.owner.name : null),
      maxWidth: 70,
      filterable: true,
    },
    {
      Header: 'Latest Connection',
      id: 'latestConnection',
      accessor: (d) => displayTimestamp(d, 'latestConnection'),
      sortMethod: timestampSort,
      maxWidth: 50,
      filterable: true,
    },
  ];

  render() {
    const sorting = [
      { id: 'companyName', asc: true },
      { id: 'locationName', asc: true },
      { id: 'assetName', asc: true },
      { id: 'name', asc: true },
    ];
    return (
      <ReactTable
        getTdProps={(state, rowInfo) => {
          const index = rowInfo ? rowInfo.index : -1;
          return {
            onClick: (e) => {
              this.setState({ selectedRowIndex: index });
              this.props.setSelectedDevice(rowInfo ? rowInfo.row : null);
            },
            style: {
              background: this.state.selectedRowIndex === index ? 'gold' : null,
            },
          };
        }}
        data={this.props.devices}
        columns={this.columns}
        minRows={1}
        defaultPageSize={5}
        className="-striped -highlight"
        defaultSorted={sorting}
        defaultFilterMethod={this.props.filterMethod}
      />
    );
  }
}
DeviceGrid.defaultProps = {
  filterMethod: (filter, row) => containsFilter(filter, row),
};
export default DeviceGrid;
