import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import * as actions from '../../actions';
import FormField from '../form/FormField';
import FormSubmitButton from '../form/FormSubmitButton';

class Login extends Component {
  constructor(props) {
    super(props);

    this.submitLogin = this.submitLogin.bind(this);
  }

  submitLogin(values, history) {
    this.props.loginUser(values, history);
  }

  render() {
    const { submitting } = this.props;
    return (
      <div>
        <form
          onSubmit={this.props.handleSubmit((values) => {
            const { history } = this.props;
            this.submitLogin(values, history);
          })}
        >
          <Field name="email" type="text" label="Email" component={FormField} />
          <Field
            name="password"
            type="password"
            label="Password"
            component={FormField}
          />
          <FormSubmitButton submitting={submitting} />
        </form>
      </div>
    );
  }
}

function validate(values) {
  const errors = {};
  // validate inputs
  if (!values.email) {
    errors.email = 'Cannot be blank';
  }
  if (!values.password) {
    errors.password = 'Cannot be blank';
  }
  return errors;
}

Login = connect(null, actions)(Login);

export default reduxForm({
  validate,
  form: 'Login',
})(withRouter(Login));
