import { CONST } from './constants';

const isTimestamp = (val) => {
  let isTs = false;
  // val is string, matches date format
  if (val && typeof val === 'string' && CONST.DATE_REGEX.test(val)) {
    isTs = true;
  }
  return isTs;
};

export default isTimestamp;
