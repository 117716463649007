import React, { Component } from 'react';
import { reduxForm, change } from 'redux-form';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import WizardBase from '../form/WizardBase';
import CompanyDropdown from '../company/CompanyDropdown';
import CompanyNewWithMutation from '../company/CompanyNew';

class DeviceOwnerPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e, n, p) {
    console.log('setCompany', e, n, p);
    this.props.setCompany(e, { id: e.id, name: e.name }, p);
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  render() {
    const { handleSubmit, previousPage } = this.props;
    const modalContent = <CompanyNewWithMutation />;
    const modalType = 'New Company';
    return (
      <WizardBase
        header="Who owns this device?"
        closeModal={this.closeModal}
        modalIsOpen={this.state.modalIsOpen}
        modalContent={modalContent}
        modalType={modalType}
      >
        <form onSubmit={handleSubmit}>
          <CompanyDropdown
            fieldName="owner"
            label="Owner"
            onDropdownChange={this.handleChange}
          />
          <Button onClick={this.openModal}>
            Add
            {modalType}
          </Button>

          <br />
          <div>
            <Button className="previous" onClick={previousPage}>
              Previous
            </Button>
            <Button type="submit" className="next">
              Next
            </Button>
          </div>
        </form>
      </WizardBase>
    );
  }
}

DeviceOwnerPage = reduxForm({
  form: 'deviceWizard', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(DeviceOwnerPage);
export default connect(
  null,
  {
    setCompany: (event, newValue, previousValue) => change('deviceWizard', 'company', newValue),
  },
)(DeviceOwnerPage);
