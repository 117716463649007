import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { Field } from 'redux-form';
import renderDropdownList from '../form/renderDropdownList';
import { CompanyDropdownQuery } from '../../graphql/queries/CompanyQueries';

class CompanyDropdown extends Component {
  constructor(props) {
    super(props);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
  }

  handleCompanyChange(e) {
    this.props.onDropdownChange(e);
  }

  render() {
    console.log(this.props);
    if (this.props.data.loading) {
      return <p>Loading...</p>;
    } if (this.props.data.error) {
      return <p>Error Loading Companies!</p>;
    }
    return (
      <div>
        <label>{this.props.label}</label>
        <Field
          name={this.props.fieldName}
          onChange={this.handleCompanyChange}
          component={renderDropdownList}
          data={this.props.data.visibleCompanies}
          valueField="id"
          textField="name"
          canBeNull={this.props.canBeNull}
        />
      </div>
    );
  }
}

CompanyDropdown.defaultProps = {
  onDropdownChange: () => {},
  fieldName: 'company',
  label: 'Company',
  canBeNull: false,
};

export default graphql(CompanyDropdownQuery)(CompanyDropdown);
