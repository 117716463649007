import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import {
  Ap2200FirmwareGridQuery,
  Ap2200FirmwareQuery,
  Ap2200FirmwareDropdownQuery,
} from '../../../graphql/queries/Ap2200FirmwareQueries';
import Ap2200FirmwareForm from './Ap2200FirmwareForm';
import FormBase from '../../form/FormBase';
import handleMutation from '../../../graphql/functions/handleMutation';
import {
  CreateAp2200FirmwareMutation,
  UpdateAp2200FirmwareMutation,
} from '../../../graphql/mutations/Ap2200FirmwareMutations';

class Ap2200FirmwareUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    if (typeof values === 'object') {
      // store ap2200Firmware id
      values.id = this.props.ap2200Firmware.id;
      // remove fileExists
      delete values.fileExists;
      handleMutation({
        mutate: this.props.UpdateAp2200FirmwareMutation,
        variables: { ap2200Firmware: values },
        refetchQueries: [
          { query: Ap2200FirmwareGridQuery }, { query: Ap2200FirmwareDropdownQuery },
        ],
        onSuccess: this.props.onSuccess,
      });
    }
  }

  render() {
    const {
      data: { loading, error, ap2200Firmware },
    } = this.props;
    return (
      <FormBase loading={loading} error={error} header={this.props.header}>
        <Ap2200FirmwareForm
          initialValues={ap2200Firmware}
          onSubmit={this.handleSubmit}
        />
      </FormBase>
    );
  }
}

export default compose(
  graphql(UpdateAp2200FirmwareMutation, {
    name: 'UpdateAp2200FirmwareMutation',
    options: (props) => ({ variables: { id: props.ap2200Firmware.id } }),
  }),
  graphql(CreateAp2200FirmwareMutation, {
    name: 'CreateAp2200FirmwareMutation',
  }),
  graphql(Ap2200FirmwareQuery, {
    options: (props) => ({ variables: { id: props.ap2200Firmware.id }, fetchPolicy: 'network-only' }),
  }),
)(Ap2200FirmwareUpdate);
