import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';

import handleMutation from '../../graphql/functions/handleMutation';
import ChartForm from './ChartForm';
import FormBase from '../form/FormBase';
import {
  ChartGridQuery,
  ChartSelectQuery,
} from '../../graphql/queries/ChartQueries';
import { CreateChartMutation } from '../../graphql/mutations/ChartMutations';

class ChartNew extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // submit mutation and refetch chart grid query
  handleSubmit(values) {
    if (typeof values === 'object') {
      if (values.chartAttributes && values.chartAttributes.length > 0) {
        values.chartAttributes = values.chartAttributes.map((att) => {
          const a = {};
          if (typeof att.attribute === 'object' && att.attribute.id) {
            a.attribute = att.attribute.id;
          } else if (Array.isArray(att.attribute)) {
            a.attribute = att.attribute[0];
          } else {
            a.attribute = att.attribute;
          }
          if (
            typeof att.axis === 'object'
            && att.axis !== null
            && att.axis.id
          ) {
            a.axis = att.axis.id;
          } else {
            a.axis = att.axis;
          }
          // only set attribute value type for column charts
          if (values.plotType && (values.plotType.id === 'column' || values.plotType.id === 'bar')) {
            a.valueType = att.valueType && att.valueType.id ? att.valueType.id : 'MAX';
          }
          a.visible = att.visible;
          console.log('create a', a);
          return a;
        });
      }
    }
    handleMutation({
      mutate: this.props.mutate,
      variables: { chart: values },
      refetchQueries: [{ query: ChartGridQuery }, { query: ChartSelectQuery }],
      onSuccess: this.props.onSuccess,
    });
  }

  render() {
    return (
      <FormBase header="Create Chart">
        <ChartForm onSubmit={this.handleSubmit} />
      </FormBase>
    );
  }
}

const ChartNewWithMutation = graphql(CreateChartMutation)(ChartNew);

export default ChartNewWithMutation;
