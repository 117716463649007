import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import { Field } from 'redux-form';
import update from 'immutability-helper';
import { LocationEditableSelectQuery } from '../../graphql/queries/LocationQueries';
import renderSelectTree from '../form/renderSelectTree';

class LocationSelect extends Component {
  constructor(props) {
    super(props);
    this.handleLocationChange = this.handleLocationChange.bind(this);
  }

  handleLocationChange(e) {
    this.props.onChange(e);
  }

  render() {
    const { LocationEditableSelectQuery: { loading, error, companies } } = this.props;

    if (loading) {
      return <p>Loading locations...</p>;
    } if (error) {
      return <p>Error Loading Locations!</p>;
    }
    return (
      <div>
        <label>{this.props.label}</label>

        <Field
          onChange={this.handleLocationChange}
          name={this.props.fieldName}
          component={renderSelectTree}
          data={companies}
          valueField="id"
          textField="name"
        />
      </div>
    );
  }
}

LocationSelect.defaultProps = {
  label: 'Locations',
  fieldName: 'locations',
  onChange: () => {},
};
function mapStateToProps({ auth }) {
  return { auth };
}
export default compose(
  connect(mapStateToProps),
  graphql(LocationEditableSelectQuery, {
    name: 'LocationEditableSelectQuery',
    props: ({
      ownProps,
      LocationEditableSelectQuery: { loading, error, editableLocations },
    }) => {
      console.log(ownProps);
      const companies = [];
      if (editableLocations && editableLocations.length > 0) {
        // get all companies in array
        const allCompanies = editableLocations.map((l) => l.company);
        const duplicateFlag = {};
        allCompanies.forEach((company) => {
          if (!duplicateFlag[company.id]) {
            duplicateFlag[company.id] = true;
            companies.push({ ...company, locations: [] });
          }
        });
        companies.sort((a, b) => {
          const keyA = a.name.toUpperCase();
          const keyB = b.name.toUpperCase();
          let comparison = 0;
          if (keyA > keyB) {
            comparison = 1;
          } else if (keyA < keyB) {
            comparison = -1;
          }
          return comparison;
        });
        editableLocations.forEach((location) => {
          const parentCompany = companies.find((c) => c.id === location.company.id);
          parentCompany.locations.push({ id: location.id, name: location.name });
        });
      }
      return {
        LocationEditableSelectQuery: {
          companies,
          loading,
          error,
        },
      };
    },
  }),
)(LocationSelect);
