import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import GridBase from '../common/GridBase';

class InstallerLogGrid extends Component {
  columns = [
    {
      Header: 'Log Message',
      accessor: 'logMessage',
      minWidth: 200,
      filterable: true,
    },
    {
      Header: 'Date',
      accessor: 'timestamp',
      maxWidth: 200,
      filterable: true,
    },
  ];

  render() {
    const sorting = [{ id: 'timestamp', asc: false }];
    if (this.props.installerLogs.length === 0) {
      return (
        <div>
          <div>
            <Typography variant="h4">{this.props.locationName}</Typography>
          </div>
          <div>
            <Typography variant="h6">No logs found.</Typography>
          </div>
        </div>
      );
    }
    return (
      <div>
        <div>
          <Typography variant="h4">{this.props.locationName}</Typography>
        </div>
        <div>
          <GridBase
            dataVar={this.props.installerLogs}
            columns={this.columns}
            sorting={sorting}
          />
        </div>
      </div>
    );
  }
}
export default InstallerLogGrid;
