import React, { Component } from 'react';
import { Field, FieldArray } from 'redux-form';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import FormField from '../../../form/FormField';
import renderCheckbox from '../../../form/renderCheckbox';
import renderDropdownList from '../../../form/renderDropdownList';
import { CONST } from '../../../common/constants';
import { validateNumber } from '../../../common/functions';
import AttributeSelect from '../AttributeSelect';
import ConfigJsonForm from './ConfigJsonForm';
import renderTagInputField from '../../../form/renderTagInputField';
import ExpansionPanelBase from '../../../common/ExpansionPanelBase';
import { AttributeGridQuery } from '../../../../graphql/queries/AttributeQueries';

class AttConfigFormSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attributes: null,
    };
    this.handleJsonStringChange = this.handleJsonStringChange.bind(this);
    this.handleIgnoreArrayChange = this.handleIgnoreArrayChange.bind(this);
    this.renderFieldArray = this.renderFieldArray.bind(this);
  }

  handleIgnoreArrayChange(val) {
    this.props.onChange(val);
  }

  handleJsonStringChange(event, newValue, previousValue) {
    const targetValue = event.target.value;
    // update attConfigs data
    let newAtts = {};
    let inputVals = {};
    try {
      inputVals = JSON.parse(targetValue);
    } catch (err) {
      // console.log(err);
    }
    if (inputVals) {
      const dataKeys = Object.keys(inputVals);
      newAtts = dataKeys
        .filter((key) => {
          if (
            key !== 'ts'
            && key !== 'type'
            && key !== 'sn'
            && key !== 'id'
            && key !== 'liveinfo'
          ) {
            return true;
          }
          return false;
        })
        .map((attKey) => {
          const newVal = { importField: attKey };
          return newVal;
        });
      // populate attConfig form
      this.props.setConfigAtts(event, newAtts, previousValue);
    }
  }

  getAttributePanels(fields) {
    return fields.map((attributeConfig, index) => {
      const attNum = index + 1;
      let header = `Attribute #${attNum}`;
      if (this.state.attributes && this.props.formState
        && this.props.formState[this.props.formName]
        && this.props.formState[this.props.formName].values
        && this.props.formState[this.props.formName].values[this.props.fieldName]
        && this.props.formState[this.props.formName].values[this.props.fieldName][index]
      ) {
        let configAtt = this.props.formState[this.props.formName].values[this.props.fieldName][index];
        if (configAtt.attribute) {
          configAtt = configAtt.attribute;
        }
        // get the name
        let attId;
        if (typeof configAtt === 'object' && configAtt.id) {
          attId = configAtt.id;
        } else if (Array.isArray(configAtt)) {
          attId = configAtt[0];
        } else {
          attId = configAtt;
        }
        const foundAtt = this.state.attributes.find((att) => att.id === attId);
        if (foundAtt && foundAtt.name) {
          header += ` - ${foundAtt.name}`;
        }
      }
      return {
        key: `panel${index}`,
        header,
        data: (
          <div className="expandable-form-div">
            <Button
              variant="contained"
              className="right"
              type="button"
              title="Remove Attribute"
              onClick={() => fields.remove(index)}
            >
              <i className="material-icons">delete</i>
            </Button>

            <div data-tip="Attribute to be imported">
              <AttributeSelect
                multiple={false}
                fieldName={`${attributeConfig}.attribute`}
                label="Select Attribute"
              />
            </div>

            <div data-tip="Field key used to import this attribute">
              <Field
                name={`${attributeConfig}.importField`}
                type="text"
                component={FormField}
                label="Import Field"
              />
            </div>
            {this.props.formName === 'OilSampleType'
              && (
              <div data-tip="Category in Oil Sample Report">
                <Field
                  name={`${attributeConfig}.category`}
                  type="text"
                  component={FormField}
                  label="Category"
                />
              </div>
              )}
            {this.props.formName !== 'OilSampleType'
              && (
              <div>
                <div data-tip="Use this function to query the data">
                  <label>Query Function</label>
                  <Field
                    name={`${attributeConfig}.queryFunction`}
                    component={renderDropdownList}
                    data={CONST.QUERY_FUNCTIONS}
                    valueField="id"
                    textField="name"
                    canBeNull
                  />
                </div>
                <div data-tip="Use these parameters for the query function. NOTE: Query att must be of the same data type as the selected attribute">
                  <label>Query Function Parameters</label>
                  <Field
                    name={`${attributeConfig}.queryParams`}
                    component="textarea"
                    label="Query Function Parameters JSON"
                    placeholder='{"queryAttKey": "fe_mass", "windowSeconds": 86400}'
                  />
                </div>
                <div data-tip="This attribute is expected in every data packet">
                  <Field
                    name={`${attributeConfig}.primaryAttribute`}
                    label="Primary Attribute"
                    component={renderCheckbox}
                  />
                </div>
                <div data-tip="Error Codes Not to be Imported as Data">
                  <Field
                    name={`${attributeConfig}.ignore`}
                    component={renderTagInputField}
                    label="Error Code Values"
                    onChange={this.handleIgnoreArrayChange}
                  />
                </div>
                <div data-tip="Multiply values by this factor on display">
                  <Field
                    name={`${attributeConfig}.displayFactor`}
                    type="number"
                    parse={(value) => (!value ? null : Number(value))}
                    component={FormField}
                    label="Display Factor"
                    validate={[validateNumber]}
                  />
                </div>
                <div data-tip="Add this value to data on display">
                  <Field
                    name={`${attributeConfig}.displayOffset`}
                    type="number"
                    parse={(value) => (!value ? null : Number(value))}
                    component={FormField}
                    label="Display Offset"
                    validate={[validateNumber]}
                  />
                </div>
                <div data-tip="Minimum value to display">
                  <Field
                    name={`${attributeConfig}.min`}
                    type="number"
                    parse={(value) => (!value ? null : Number(value))}
                    component={FormField}
                    label="Min"
                    validate={[validateNumber]}
                  />
                </div>
                <div data-tip="Maximum value to display">
                  <Field
                    name={`${attributeConfig}.max`}
                    type="number"
                    parse={(value) => (!value ? null : Number(value))}
                    component={FormField}
                    label="Max"
                    validate={[validateNumber]}
                  />
                </div>
              </div>
              )}
          </div>
        ),
      };
    });
  }

  renderFieldArray = ({ fields, meta: { error, submitFailed } }) => (
    <div style={{ marginTop: '5px' }}>
      <div>
        <Button
          variant="contained"
          style={{ marginTop: '5px' }}
          type="button"
          onClick={() => fields.push({})}
        >
          Add Attribute
        </Button>
        {submitFailed && error && <span>{error}</span>}
      </div>
      {fields && fields.length === 0 && (
        <div>
          <ConfigJsonForm handleChange={this.handleJsonStringChange} />
        </div>
      )}
      <div>
        <ExpansionPanelBase panelData={this.getAttributePanels(fields)} />
      </div>
    </div>
  );

  // Load all attributes for Labels
  componentDidUpdate(prevProps, prevState) {
    if (this.props.AttributeGridQuery
      && this.props.AttributeGridQuery.attributes
      && this.props.AttributeGridQuery.attributes.length
      && prevState.attributes === null) {
      this.setState({ attributes: this.props.AttributeGridQuery.attributes });
    }
  }

  render() {
    if (this.state.attributes === null) {
      return <p>Loading...</p>;
    }
    return (
      <div>
        <FieldArray name={this.props.fieldName} component={this.renderFieldArray} />
      </div>
    );
  }
}

AttConfigFormSection.defaultProps = {
  onChange: () => {},
  setConfigAtts: () => {},
  fieldName: 'attConfigs',
  formName: 'DeviceConfig',
};

const mapStateToProps = (state) => ({
  formState: state.form,
});

AttConfigFormSection = compose(
  graphql(AttributeGridQuery, {
    name: 'AttributeGridQuery',
    options: () => ({
      fetchPolicy: 'network-only',
    }),
  }),
)(AttConfigFormSection);
export default connect(mapStateToProps)(AttConfigFormSection);
