import React, { Component } from 'react';
import { Field } from 'redux-form';
import renderDropdownList from '../../form/renderDropdownList';

const AXES = [{ id: 1, name: 'y1' }, { id: 2, name: 'y2' }];
class AxisDropdown extends Component {
  constructor(props) {
    super(props);
    this.handleAxisChange = this.handleAxisChange.bind(this);
  }

  handleAxisChange(e) {
    this.props.onDropdownChange(e);
  }

  render() {
    return (
      <div>
        <label>{this.props.label}</label>
        <Field
          name={this.props.fieldName}
          onChange={this.handleAxisChange}
          component={renderDropdownList}
          canBeNull={false}
          data={AXES}
          valueField="id"
          textField="name"
        />
      </div>
    );
  }
}

AxisDropdown.defaultProps = {
  onDropdownChange: () => {},
};

export default AxisDropdown;
