export const CONST = Object.freeze({
  ROLES: [
    { id: 3, name: 'Super Admin' },
    { id: 2, name: 'Company Admin' },
    { id: 1, name: 'User' },
    { id: 0, name: 'Demo' },
  ],
  SUPER_ADMIN_ASSIGNING_ROLES: [
    { id: 2, name: 'Company Admin' },
    { id: 1, name: 'User' },
  ],
  COMPANY_ADMIN_ROLES: [
    { id: 2, name: 'Company Admin' },
    { id: 1, name: 'User' },
  ],
  COMPANY_ADMIN_ROLE: 2,
  SUPER_ADMIN_ROLE: 3,
  USER_ROLES: ['', 'User', 'Company Admin', 'Super Admin'],
  PROCESSING_FUNCTIONS: [
    { id: 'viscosityStatusFlag', name: 'Viscosity Status Flag' },
    { id: 'oilQualityS1S0', name: 'Oil Quality S1 S0' },
    { id: 'oilQualityFixed', name: 'Oil Quality Fixed Temperature Compensation' },
    { id: 'oilQualityHealthIndicator', name: 'Oil Quality Health Indicator' },
    { id: 'gemsPressurePercent', name: 'Gems Pressure Percent Conversion' },
    { id: 'gemsLevelPercent', name: 'Gems Level Percent Conversion' },
  ],
  QUERY_FUNCTIONS: [
    { id: 'movAvgNonNegDerivCumSumNonNegDiff', name: 'Moving average of the NonNeg Derivative of the CumSum of the NonNeg Difference' },
    { id: 'movAvgNonNegDerivCumSum', name: 'Moving average of the NonNeg Derivative of the CumSum' },
    { id: 'nonNegDerivCumSumNonNegDiff', name: 'NonNeg Derivative of the CumSum of the NonNeg Difference' },
    { id: 'nonNegDerivCumSum', name: 'NonNeg Derivative of the Cumulative Sum' },
    { id: 'cumSumNonNegDiff', name: 'Cumulative Sum of the NonNeg Difference' },
    { id: 'cumSum', name: 'Cumulative Sum' },
    { id: 'movingAverage', name: 'Smoothed (Moving Average)' },
  ],
  CHART_TYPES: [
    { name: 'Line', id: 'line' },
    { name: 'Column', id: 'column' },
    { name: 'Bar', id: 'bar' },
    { name: 'Boxplot', id: 'boxplot' },
    { name: 'Gauge', id: 'solidgauge' },
  ],
  WIDGET_TYPES: [
    { name: 'Chart', id: 'chart' },
    { name: 'Table', id: 'table' },
  ],
  COMPARISONS: [
    { name: 'Comparison: Greater than (>)', id: 0 },
    { name: 'Comparison: Greater than or equal to (>=)', id: 1 },
    { name: 'Comparison: Equal to (=)', id: 2 },
    { name: 'Comparison: Less than or equal to (<=)', id: 3 },
    { name: 'Comparison: Less than (<)', id: 4 },
  ],
  TABLE_COLUMN_TYPES: [
    { name: 'Maximum', id: 'MAX' },
    { name: 'Minimum', id: 'MIN' },
    { name: 'Last', id: 'LAST' },
    { name: 'Total', id: 'PERIODTOTAL' },
  ],
  CHART_ATT_VALUE_TYPES: [
    { name: 'Maximum', id: 'MAX' },
    { name: 'Minimum', id: 'MIN' },
    { name: 'Last', id: 'LAST' },
    { name: 'Total', id: 'PERIODTOTAL' },
  ],
  SORT_TYPES: [
    { name: 'Descending', id: 'desc' },
    { name: 'Ascending', id: 'asc' },
  ],
  DATE_REGEX: /\d*\/\d*\/\d*\s\d*:\d*\s(AM|PM)/g,
  HANDLE_DATA_OPTIONS: [
    { name: 'Merge', id: 'merge' },
    { name: 'Delete Old', id: 'deleteOld' },
    // { name: 'Archive Old', id: 'archiveOld' },
  ],
  VIEW_TYPES: [
    { name: 'My Accounts', id: true },
    { name: 'All Companies', id: false },
  ],
});
