import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import GridBase from '../common/GridBase';
import { ChartGridQuery } from '../../graphql/queries/ChartQueries';
import ContextMenuWithTrigger from '../common/ContextMenuWithTrigger';
import Modal from '../common/Modal';
import ChartUpdate from './ChartUpdate';
import { DeleteChartMutation } from '../../graphql/mutations/ChartMutations';
import DeleteModel from '../common/DeleteModel';

class ChartGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      chart: null,
      delete: { id: null, name: '' },
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleDeleteClose = this.handleDeleteClose.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  columns = [
    {
      Header: 'ID',
      accessor: 'id',
      maxWidth: 90,
      filterable: true,
    },
    {
      Header: 'Chart Name',
      accessor: 'name',
      filterable: true,
    },
    {
      Header: 'Chart Title',
      accessor: 'title',
      filterable: true,
    },
    {
      Header: 'Plot Type',
      accessor: 'plotType',
      filterable: true,
    },
    {
      Header: 'Raw Data',
      id: 'raw',
      accessor: (d) => (d.raw === true ? 'Yes' : 'No'),
      filterable: true,
    },
    {
      Header: '',
      maxWidth: 23,
      resizable: false,
      sortable: false,
      Cell: ({ row }) => ContextMenuWithTrigger({
        handleClick: this.handleClick,
        row,
      }),
    },
  ];

  // handle context menu clicks
  handleClick(e, data, target) {
    if (data.action === 'Edit') {
      this.setState({
        chart: data.row,
        modalIsOpen: true,
      });
    } else if (data.action === 'Delete') {
      this.setState({
        delete: { id: data.row.id, name: data.row.name },
      });
    }
  }

  // reset delete chart id and name to null upon deletion
  handleDeleteClose() {
    this.setState({ delete: { id: null, name: '' } });
  }

  render() {
    const {
      data: { loading, error, defaultCharts },
    } = this.props;
    const sorting = [{ id: 'name', asc: true }];
    const charts = defaultCharts;
    if (loading) {
      return <p>Loading...</p>;
    } if (error) {
      return <p>Error loading charts!</p>;
    }
    return (
      <div>
        <GridBase dataVar={charts} columns={this.columns} sorting={sorting} />

        <DeleteModel
          deleteMutation={DeleteChartMutation}
          refetchQueries={[{ query: ChartGridQuery }]}
          id={this.state.delete.id}
          name={this.state.delete.name}
          onClose={this.handleDeleteClose}
        />

        <Modal
          onClose={this.closeModal}
          modalIsOpen={this.state.modalIsOpen}
          contentLabel="Edit Chart"
        >
          <ChartUpdate chart={this.state.chart} />
        </Modal>
      </div>
    );
  }
}

export default graphql(ChartGridQuery)(ChartGrid);
