import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';

import TableForm from './TableForm';
import FormBase from '../form/FormBase';
import {
  TableGridQuery,
  TableSelectQuery,
} from '../../graphql/queries/TableQueries';
import { CreateTableMutation } from '../../graphql/mutations/TableMutations';
import handleTableMutation from '../../graphql/functions/handleTableMutation';

class TableNew extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    handleTableMutation({
      values,
      mutation: this.props.mutate,
      onSuccess: this.props.onSuccess,
      refetchQueries: [{ query: TableGridQuery }, { query: TableSelectQuery }],
    });
  }

  render() {
    return (
      <FormBase header="Create Table">
        <TableForm onSubmit={this.handleSubmit} />
      </FormBase>
    );
  }
}

const TableNewWithMutation = graphql(CreateTableMutation)(TableNew);

export default TableNewWithMutation;
