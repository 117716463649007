import { gql } from '@apollo/client';

const CreateAssetTypeMutation = gql`
  mutation createAssetType($assetType: AssetTypeInput!) {
    createAssetType(assetType: $assetType) {
      name
      id
    }
  }
`;

const UpdateAssetTypeMutation = gql`
  mutation updateAssetType($assetType: AssetTypeInput!) {
    updateAssetType(assetType: $assetType) {
      name
      id
    }
  }
`;

const DeleteAssetTypeMutation = gql`
  mutation deleteAssetType($id: ID!) {
    deleteAssetType(id: $id) {
      name
    }
  }
`;

export {
  CreateAssetTypeMutation,
  UpdateAssetTypeMutation,
  DeleteAssetTypeMutation,
};
