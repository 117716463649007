import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';

import {
  AssetConfigGridQuery,
  AssetConfigQuery,
  AssetConfigDropdownQuery,
} from '../../../graphql/queries/AssetConfigQueries';
import AssetConfigForm from './AssetConfigForm';
import FormBase from '../../form/FormBase';
import handleAssetConfigMutation from '../../../graphql/functions/handleAssetConfigMutation';
import { UpdateAssetConfigMutation } from '../../../graphql/mutations/AssetConfigMutations';

class AssetConfigUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    if (typeof values === 'object') {
      // store assetConfig id
      values.id = this.props.assetConfig.id;
      handleAssetConfigMutation({
        values,
        mutation: this.props.updateAssetConfigMutation,
        onSuccess: this.props.onSuccess,
        refetchQueries: [
          { query: AssetConfigGridQuery },
          { query: AssetConfigDropdownQuery },
        ],
      });
    }
  }

  render() {
    // render form with assetConfig values pre-loaded
    const {
      data: { loading, error, assetConfig },
    } = this.props;
    return (
      <FormBase
        loading={loading}
        error={error}
        header="Edit Data Configuration"
      >
        <AssetConfigForm
          initialValues={assetConfig}
          onSubmit={this.handleSubmit}
        />
      </FormBase>
    );
  }
}

export default compose(
  graphql(UpdateAssetConfigMutation, {
    name: 'updateAssetConfigMutation',
    options: (props) => ({ variables: { id: props.assetConfig.id } }),
  }),
  graphql(AssetConfigQuery, {
    options: (props) => ({ variables: { id: props.assetConfig.id }, fetchPolicy: 'network-only' }),
  }),
)(AssetConfigUpdate);
