import { gql } from '@apollo/client';

const AttributeTypeGridQuery = gql`
  query AttributeTypeGridQuery {
    attributeTypes {
      id
      name
      fields {
        key
        name
        description
        rule
        required
      }
    }
  }
`;

const AttributeTypeQuery = gql`
  query attributeType($id: ID!) {
    attributeType(id: $id) {
      name
      id
      fields {
        key
        name
        description
        rule
        required
      }
    }
  }
`;

const AttributeTypeDropdownQuery = gql`
  query attributeTypes {
    attributeTypes {
      name
      id
      fields {
        key
        name
        description
        rule
        required
      }
    }
  }
`;

export {
  AttributeTypeQuery,
  AttributeTypeGridQuery,
  AttributeTypeDropdownQuery,
};
