import { gql } from '@apollo/client';
import { deviceConfigOnlyFragment } from '../fragments';

const DeviceConfigQuery = gql`
  query deviceConfig($id: ID!) {
    deviceConfig(id: $id) {
      ...deviceConfiguration
    }
  }
  ${deviceConfigOnlyFragment}
`;

const DeviceConfigGridQuery = gql`
  query DeviceConfigGridQuery {
    deviceConfigs {
      name
      id
    }
  }
`;

const DeviceConfigDropdownQuery = gql`
  query deviceConfigs {
    deviceConfigs {
      ...deviceConfiguration
    }
  }
  ${deviceConfigOnlyFragment}
`;

export { DeviceConfigGridQuery, DeviceConfigDropdownQuery, DeviceConfigQuery };
