import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import WizardBase from '../form/WizardBase';
import LocationDropdown from '../location/LocationDropdown';
import LocationNewWithMutation from '../location/LocationNew';

class DeviceLocationPage extends Component {
  constructor(props) {
    super(props);
    this.state = { modalIsOpen: false };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  render() {
    const { handleSubmit, previousPage } = this.props;
    const modalContent = (
      <LocationNewWithMutation
        companyId={
          this.props.formState.deviceWizard
          && this.props.formState.deviceWizard.values.company
            ? this.props.formState.deviceWizard.values.company.id
            : null
        }
      />
    );
    const modalType = 'New Location';
    return (
      <div>
        {this.props.formState.deviceWizard
          && this.props.formState.deviceWizard.values.company && (
            <WizardBase
              header="Where is your device located?"
              closeModal={this.closeModal}
              modalIsOpen={this.state.modalIsOpen}
              modalContent={modalContent}
              modalType={modalType}
            >
              <form onSubmit={handleSubmit}>
                <LocationDropdown
                  fieldName="location"
                  label="Location"
                  companyId={
                    this.props.formState.deviceWizard.values.company.id
                  }
                />
                <Button onClick={this.openModal}>
                  Add&nbsp;
                  {modalType}
                </Button>

                <br />
                <div>
                  <Button className="previous" onClick={previousPage}>
                    Previous
                  </Button>
                  <Button type="submit" className="next">
                    Next
                  </Button>
                </div>
              </form>
            </WizardBase>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  formState: state.form,
});
DeviceLocationPage = reduxForm({
  form: 'deviceWizard', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(DeviceLocationPage);

export default connect(mapStateToProps)(DeviceLocationPage);
