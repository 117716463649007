const convertDateToTimestamp = (dateStr) => {
  const date = new Date(dateStr);
  return Math.floor(date.getTime() / 1000);
};

const sortMethod = (a, b) => {
  const aNum = convertDateToTimestamp(a);
  const bNum = convertDateToTimestamp(b);
  if (aNum === bNum) {
    return 0;
  }
  return aNum > bNum ? 1 : -1;
};

export default sortMethod;
