import React, { Component } from 'react';
import CompanyDropdown from './CompanyDropdown';
import LocationDropdown from '../location/LocationDropdown';
import AssetDropdown from '../asset/AssetDropdown';
import DeviceDropdown from '../device/DeviceDropdown';

class CompanyLocationDropdown extends Component {
  constructor(props) {
    super(props);
    // state.locations the filtered set of locations after chosing a company
    this.state = {
      companyId: this.props.companyId ? this.props.companyId : null,
      locationId: this.props.locationId ? this.props.locationId : null,
      assetId: this.props.assetId ? this.props.assetId : null,
      parentId: this.props.parentId ? this.props.parentId : null,
    };
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleAssetChange = this.handleAssetChange.bind(this);
    this.handleChildAssetChange = this.handleChildAssetChange.bind(this);
  }

  handleCompanyChange(company) {
    this.setState({
      companyId: company.id,
    });
    // set device value to null
    this.props.selectAsset();
    // set child asset to null
    this.props.selectParent();
    // set asset value to null
    this.props.selectLocation();
    // set location value to null
    this.props.selectCompany(company);
  }

  handleLocationChange(location) {
    this.setState({
      locationId: location.id,
    });
    // set device value to null
    this.props.selectAsset();
    // set child asset to null
    this.props.selectParent();
    // set asset value to null
    this.props.selectLocation();
  }

  handleAssetChange(asset) {
    console.log('asset', asset);
    this.setState({
      assetId: asset.id,
      parentId: asset.id,
    });

    // set device value to null
    this.props.selectAsset();
    // set child asset to null
    this.props.selectParent();
  }

  handleChildAssetChange(asset) {
    console.log('childAsset', asset);
    this.setState({
      assetId: asset.id,
    });
    // set device value to null
    this.props.selectAsset();
  }

  handleDeviceChange() {}

  render() {
    console.log('CompanyLocationDropdown', this.props);
    return (
      <div>
        <CompanyDropdown
          label="Company"
          fieldName={this.props.companyFieldName}
          onDropdownChange={this.handleCompanyChange}
        />
        <LocationDropdown
          fieldName={this.props.locationFieldName}
          companyId={this.state.companyId}
          onDropdownChange={this.handleLocationChange}
          waitForParent
        />
        {this.props.assetInput && (
          <AssetDropdown
            fieldName={this.props.assetFieldName}
            locationId={this.state.locationId}
            onDropdownChange={this.handleAssetChange}
            waitForParent
          />
        )}
        {this.state.parentId && (
          <AssetDropdown
            label="Optional Asset Component"
            fieldName={this.props.childAssetFieldName}
            parentId={this.state.parentId}
            onDropdownChange={this.handleChildAssetChange}
            waitForParent
          />
        )}
        {this.props.deviceInput && (
          <DeviceDropdown
            fieldName="device"
            assetId={this.state.assetId}
            onDropdownChange={this.handleDeviceChange}
            waitForParent
            deviceTypeFilter={this.props.deviceTypeFilter}
          />
        )}
      </div>
    );
  }
}
CompanyLocationDropdown.defaultProps = {
  assetInput: false,
  deviceInput: false,
  assetFieldName: 'asset',
  childAssetFieldName: 'childAsset',
  selectAsset: () => {},
  selectParent: () => {},
  selectLocation: () => {},
  selectCompany: () => {},
};
export default CompanyLocationDropdown;
