import React, { Component } from 'react';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import FormField from '../form/FormField';
import DeviceTypeConfig from './DeviceTypeConfig';
import DeviceTypeDropdown from './deviceType/DeviceTypeDropdown';
import ExpansionPanelBase from '../common/ExpansionPanelBase';
import DeviceTypeSpecificFields from './DeviceTypeSpecificFields';
import DeviceGroupSelection from './DeviceGroupSelection';

class BasicDeviceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceTypeId:
        props.initialValues && props.initialValues.deviceType
          ? props.initialValues.deviceType.id
            ? props.initialValues.deviceType.id
            : props.initialValues.deviceType
          : null,
      deviceId:
        props.initialValues && props.initialValues.id
          ? props.initialValues.id
          : null,
    };
    this.onDeviceTypeChange = this.onDeviceTypeChange.bind(this);
    this.getSerialPanels = this.getSerialPanels.bind(this);
    this.onChangeDeviceGroups = this.onChangeDeviceGroups.bind(this);
  }

  onDeviceTypeChange(event, newValue, previousValue) {
    this.setState({ deviceTypeId: newValue.id });
    this.props.selectDeviceType();
    this.props.setConfigVals(newValue.fields);
    this.props.setDeviceName(event, newValue, previousValue);
    // populate deviceConfig form
    this.props.setDeviceConfig(event, newValue, previousValue);
  }

  onChangeDeviceGroups(newValue) {
    this.props.setDeviceGroupAdd(newValue.idsToAdd);
    this.props.setDeviceGroupRemove(newValue.idsToRemove);
    this.props.setDeviceGroupCurrentList(newValue.currentIdList);
  }

  getSerialPanels() {
    const panelData = [];
    panelData[0] = {
      key: 'panel0',
      header: 'Serial Number Configuration',
      data: (
        <div data-tip="Serial number of the device">
          <Field
            name="serial"
            type="text"
            component={FormField}
            label="Serial Number"
          />
        </div>
      ),
    };
    panelData[1] = {
      key: 'panel1',
      header: 'Legacy Configuration for AP2200 1x Units',
      data: (
        <div>
          <div data-tip="Legacy serial id of the device. ">
            <Field
              name="legacy_serial"
              type="text"
              component={FormField}
              label="Legacy Serial Id"
            />
          </div>
          <div data-tip="Legacy Config id of the device. ">
            <Field
              name="legacy_config"
              type="text"
              component={FormField}
              label="Legacy Config Field"
            />
          </div>
        </div>
      ),
    };
    panelData[2] = {
      key: 'panel2',
      header: 'Unique id used if serial number is unknown (Optional)',
      data: (
        <div data-tip="Unique id used if serial number is unknown (Optional)">
          <Field
            name="importId"
            type="text"
            component={FormField}
            label="Unique Import Id"
          />
        </div>
      ),
    };
    return panelData;
  }

  render() {
    return (
      <div>
        <DeviceTypeDropdown
          fieldName="deviceType"
          label="Device Type"
          onDropdownChange={this.onDeviceTypeChange}
        />
        <DeviceTypeConfig deviceTypeId={this.state.deviceTypeId} />
        <DeviceTypeSpecificFields deviceTypeId={this.state.deviceTypeId} />
        <div data-tip="Recommend using device type for easy identification">
          <Field
            name="name"
            type="text"
            component={FormField}
            label="Device Name"
          />
        </div>
        <ExpansionPanelBase
          initExpanded="panel0"
          panelData={this.getSerialPanels()}
        />
        <br />
        {this.props.auth && this.props.auth.isSuperAdmin
          && (
          <DeviceGroupSelection
            deviceId={this.state.deviceId}
            deviceTypeId={this.state.deviceTypeId}
            onChange={this.onChangeDeviceGroups}
          />
          )}
        <br />
      </div>
    );
  }
}

BasicDeviceForm.defaultProps = {
  setDeviceConfig: () => {},
  selectDeviceType: () => {},
  setConfigVals: () => {},
  setDeviceGroupAdd: () => {},
  setDeviceGroupRemove: () => {},
  setDeviceGroupCurrentList: () => {},
};
function mapStateToProps({ auth }) {
  return { auth };
}
BasicDeviceForm = connect(
  mapStateToProps,
)(BasicDeviceForm);
export default BasicDeviceForm;
