import React from 'react';
import PSTree from '../common/PSTree';

export default function renderSelectTree({
  input, data, valueField, textField, multiple,
}) {
  return (
    <PSTree
      value={input.value}
      checkable
      multiple={multiple}
      data={data}
      valueField={valueField}
      textField={textField}
      onChange={input.onChange}
    />
  );
}
