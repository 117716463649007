import React, { Component } from 'react';
import { reduxForm, Field, FieldArray } from 'redux-form';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import { CONST } from '../common/constants';
import renderDropdownList from '../form/renderDropdownList';
import renderCheckbox from '../form/renderCheckbox';
import FormField from '../form/FormField';
import FormSubmitButton from '../form/FormSubmitButton';
import ClearButton from '../form/ClearButton';
import AttributeSelect from '../data/attribute/AttributeSelect';
import AxisDropdown from '../data/attribute/AxisDropdown';
import ExpansionPanelBase from '../common/ExpansionPanelBase';
import { AttributeGridQuery } from '../../graphql/queries/AttributeQueries';
import { Button } from '@material-ui/core';

class ChartForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attributes: null,
      plotType: props.initialValues && props.initialValues.plotType
        ? props.initialValues.plotType : null,
    };
    this.onPlotTypeChange = this.onPlotTypeChange.bind(this);
  }

  onPlotTypeChange(event, newValue, previousValue) {
    this.setState({ plotType: newValue.id });
  }

  getAttributePanels(fields) {
    return fields.map((chartAtt, index) => {
      const attNum = index + 1;
      let header = `Attribute #${attNum}`;
      if (this.state.attributes && this.props.formState
        && this.props.formState.ChartForm
        && this.props.formState.ChartForm.values
        && this.props.formState.ChartForm.values.chartAttributes
        && this.props.formState.ChartForm.values.chartAttributes[index]
      ) {
        let cAtt = this.props.formState.ChartForm.values.chartAttributes[index];
        if (cAtt.attribute) {
          cAtt = cAtt.attribute;
        }
        // get the name
        let attId;
        if (typeof cAtt === 'object' && cAtt.id) {
          attId = cAtt.id;
        } else if (Array.isArray(cAtt)) {
          attId = cAtt[0];
        } else {
          attId = cAtt;
        }
        const foundAtt = this.state.attributes.find((att) => att.id === attId);
        if (foundAtt && foundAtt.name) {
          header += ` - ${foundAtt.name}`;
        }
      }
      return {
        key: `panel${index}`,
        header,
        data: (
          <div className="expandable-form-div">
            <Button
              variant="contained"
              className="right"
              type="button"
              title="Remove Attribute"
              onClick={() => fields.remove(index)}
            >
              <i className="material-icons">delete</i>
            </Button>

            <AttributeSelect
              multiple={false}
              fieldName={`${chartAtt}.attribute`}
              label="Select Attribute"
            />
            <br />
            <AxisDropdown fieldName={`${chartAtt}.axis`} label="Y Axis" />
            <Field
              name={`${chartAtt}.visible`}
              label="Visible By Default"
              component={renderCheckbox}
            />
            {(this.state.plotType === 'column' || this.state.plotType === 'bar')
              && (
              <div>
                <div data-tip="Type of data to display">
                  <label>Value Type</label>
                  <Field
                    name={`${chartAtt}.valueType`}
                    component={renderDropdownList}
                    data={CONST.CHART_ATT_VALUE_TYPES}
                    valueField="id"
                    textField="name"
                    canBeNull={false}
                  />
                </div>
                <br />
                <br />
                <br />
              </div>
              )}
            <br />
          </div>
        ),
      };
    });
  }

  renderAttributeArrray = ({ fields, meta: { error, submitFailed } }) => (
    <div style={{ marginTop: '5px' }}>
      <div>
        <Button
          variant="contained"
          style={{ marginTop: '5px' }}
          type="button"
          onClick={() => fields.push({ axis: 1, visible: true })}
        >
          Add Attribute
        </Button>
        {submitFailed && error && <span>{error}</span>}
      </div>
      <div>
        <ExpansionPanelBase panelData={this.getAttributePanels(fields)} />
      </div>
    </div>
  );

  componentDidUpdate(prevProps, prevState) {
    if (this.props.AttributeGridQuery
      && this.props.AttributeGridQuery.attributes
      && this.props.AttributeGridQuery.attributes.length
      && prevState.attributes === null) {
      this.setState({ attributes: this.props.AttributeGridQuery.attributes });
    }
  }

  render() {
    const {
      handleSubmit, pristine, reset, submitting,
    } = this.props;
    if (this.state.attributes === null) {
      return <p>Loading...</p>;
    }
    return (
    /* Select Plot Type */

    /* Select Attributes */

    /* Name Chart */

      <form onSubmit={handleSubmit}>
        <Field
          name="plotType"
          component={renderDropdownList}
          data={CONST.CHART_TYPES}
          valueField="id"
          textField="name"
          label="Plot Type"
          canBeNull={false}
          onChange={this.onPlotTypeChange}
        />
        <label>Attributes</label>
        <FieldArray
          name="chartAttributes"
          component={this.renderAttributeArrray}
        />
        <Field
          name="name"
          type="text"
          component={FormField}
          label="Chart Name"
        />
        <Field
          name="title"
          type="text"
          component={FormField}
          label="Display Title"
        />
        <label>Default Data Series Sort</label>
        <Field
          name="defaultSort"
          component={renderDropdownList}
          data={CONST.SORT_TYPES}
          valueField="id"
          textField="name"
          label="Default data sort direction"
          canBeNull={false}
        />
        <Field
          name="raw"
          label="Raw Data"
          component={renderCheckbox}
        />
        <br />
        <Field
          name="additionalOptions"
          component="textarea"
          label="Additional Options JSON"
          placeholder='{"yAxis": {"min": 0, "max": 100}}'
        />
        <div>
          <FormSubmitButton pristine={pristine} submitting={submitting} />
          <ClearButton
            pristine={pristine}
            submitting={submitting}
            reset={reset}
          />
        </div>
      </form>
    );
  }
}

ChartForm.defaultProps = {
  initialValues: null,
};
const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Cannot be blank';
  }
  if (!values.chartAttributes) {
    errors.chartAttributes = 'Cannot be empty';
  }
  if (!values.plotType) {
    errors.plotType = 'Cannot be empty';
  }
  return errors;
};
const mapStateToProps = (state) => ({
  formState: state.form,
});

ChartForm = compose(
  graphql(AttributeGridQuery, {
    name: 'AttributeGridQuery',
    options: () => ({
      fetchPolicy: 'network-only',
    }),
  }),
)(ChartForm);
ChartForm = reduxForm({ validate, form: 'ChartForm' })(ChartForm);
export default connect(mapStateToProps)(ChartForm);
