import React, { Component } from 'react';
import axios from 'axios';
import SuccessMessage from '../common/SuccessMessage';
import ErrorMessage from '../common/ErrorMessage';
import PSLoader from '../common/PSLoader';
import FormBase from '../form/FormBase';
import { REACT_APP_BACKEND } from '../../config';
import AdminFileUploadForm from './AdminFileUploadForm';

class AdminFileUpload extends Component {
  constructor(props) {
    super(props);
    this.handleFileError = this.handleFileError.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      showLoader: false,
    };
  }

  handleFileError(err) {
    console.log(err);
    ErrorMessage({
      msg: err,
      timeOut: 30000,
    });
  }

  async handleSubmit(values) {
    if (!values.file) {
      ErrorMessage({
        msg: 'Please submit a file',
      });
      return;
    }
    this.setState({ showLoader: true });
    const formData = new FormData();
    formData.append('file', values.file);
    // eslint-disable-next-line no-unused-vars
    let res;
    try {
      res = await axios.post(`${REACT_APP_BACKEND}/file/superAdminList`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      this.setState({ showLoader: false });
      SuccessMessage({
        msg: 'Upload successful!',
        timeOut: 60000,
      });
    } catch (err) {
      let msg = 'Upload failed';
      if (err && err.response && err.response.data) {
        msg += `: ${err.response.data}`;
      }
      this.setState({ showLoader: false });
      ErrorMessage({
        msg,
        timeOut: 60000,
      });
    }
  }

  render() {
    return (
      <div>
        <FormBase header="Upload Admin File">
          <PSLoader showLoader={this.state.showLoader}>
            <AdminFileUploadForm
              onSubmit={this.handleSubmit}
              handleFileError={this.handleFileError}
            />
          </PSLoader>
        </FormBase>
      </div>
    );
  }
}

export default AdminFileUpload;
