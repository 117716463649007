import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';

import handleMutation from '../../../graphql/functions/handleMutation';
import DataTypeForm from './DataTypeForm';
import FormBase from '../../form/FormBase';
import { DataTypeGridQuery } from '../../../graphql/queries/DataTypeQueries';
import { CreateDataTypeMutation } from '../../../graphql/mutations/DataTypeMutations';

class DataTypeNew extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // submit mutation and refetch dataType grid query
  handleSubmit(values) {
    handleMutation({
      mutate: this.props.mutate,
      variables: { dataType: values },
      refetchQueries: [{ query: DataTypeGridQuery }],
      onSuccess: this.props.onSuccess,
    });
  }

  render() {
    return (
      <FormBase header="Create DataType">
        <DataTypeForm onSubmit={this.handleSubmit} />
      </FormBase>
    );
  }
}

const DataTypeNewWithMutation = graphql(CreateDataTypeMutation)(DataTypeNew);

export default DataTypeNewWithMutation;
