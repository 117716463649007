import handleMutation from './handleMutation';

const handleSubmitModelUser = (props) => {
  console.log('submit model user props', props);
  handleMutation({
    mutate: props.mutation,
    variables: { [`${props.model}User`]: props.values },
    refetchQueries: props.refetchQueries,
    onSuccess: props.onSuccess,
  });
};

const processModelUser = ({
  allProps, model, modelVal, values,
}) => {
  if (values.users && Array.isArray(values.users) && values.users.length > 0) {
    for (let i = 0; i < values.users.length; i++) {
      const user = {
        [model]: modelVal,
        user: values.users[i],
      };
      allProps.values = user;
      handleSubmitModelUser(allProps);
    }
  }
};

export default (props) => {
  const { values, model } = props;
  console.log(props);
  console.log('submit', values);
  if (typeof values === 'object' && values !== null) {
    // values will be: model, list of users
    // we want to add a new entry in modelUser for each user
    // and for each model
    let modelVal;
    if (
      values[model]
      && Array.isArray(values[model])
      && values[model].length > 0
    ) {
      // model is an array
      for (let q = 0; q < values[model].length; q++) {
        modelVal = values[model][q];
        if (modelVal && typeof modelVal !== 'undefined' && modelVal !== null) {
          processModelUser({
            allProps: props,
            values,
            model,
            modelVal,
          });
        }
      }
    } else {
      modelVal = values[model];
      if (modelVal && typeof modelVal !== 'undefined' && modelVal !== null) {
        processModelUser({
          allProps: props,
          values,
          model,
          modelVal,
        });
      }
    }
  }
};
