import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../form/FormField';
import { validateNumber } from '../common/functions';
import CompanyDropdown from '../company/CompanyDropdown';
import FormSubmitButton from '../form/FormSubmitButton';
import ClearButton from '../form/ClearButton';
import ModelDashboardDropdown from '../modelDashboard/ModelDashboardDropdown';

class LocationForm extends Component {
  render() {
    const {
      handleSubmit,
      pristine,
      reset,
      submitting,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <CompanyDropdown fieldName="company" label="Company" />
        <Field
          name="name"
          type="text"
          component={FormField}
          label="Location Name"
        />
        <ModelDashboardDropdown
          label="Default Dashboard"
          fieldName="modelDashboard"
        />
        <br />
        <Field name="obfuscateName" type="text" component={FormField} label="Obfuscated Name" />

        <Field
          name="latitude"
          type="number"
          parse={(value) => (!value ? null : Number(value))}
          component={FormField}
          label="Latitude"
          validate={[validateNumber]}
        />
        <Field
          name="longitude"
          type="number"
          parse={(value) => (!value ? null : Number(value))}
          component={FormField}
          label="Longitude"
          validate={[validateNumber]}
        />
        <div>
          <FormSubmitButton pristine={pristine} submitting={submitting} />
          <ClearButton
            pristine={pristine}
            submitting={submitting}
            reset={reset}
          />
        </div>
      </form>
    );
  }
}

const validate = (values) => {
  console.log('values', values);
  const errors = {};
  if (!values.name) {
    errors.name = 'Cannot be blank';
  }
  if (!values.company) {
    errors.company = 'Cannot be blank';
  }
  console.log('errors', errors);
  return errors;
};

export default reduxForm({
  validate,
  form: 'Location',
})(LocationForm);
