import { gql } from '@apollo/client';
import { assetConfigOnlyFragment } from '../fragments';

const AssetConfigQuery = gql`
  query assetConfig($id: ID!) {
    assetConfig(id: $id) {
      ...assetConfiguration
    }
  }
  ${assetConfigOnlyFragment}
`;

const AssetConfigGridQuery = gql`
  query AssetConfigGridQuery {
    assetConfigs {
      name
      id
    }
  }
`;

const AssetConfigDropdownQuery = gql`
  query assetConfigs {
    assetConfigs {
      ...assetConfiguration
    }
  }
  ${assetConfigOnlyFragment}
`;

export { AssetConfigGridQuery, AssetConfigDropdownQuery, AssetConfigQuery };
