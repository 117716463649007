import { gql } from '@apollo/client';
import {
  deviceBasicsFragment,
  deviceAllFragment,
  deviceAssetLocationCompanyFragment,
  deviceGridFragment,
  ap2200GridFragment,
} from '../fragments';

const DeviceGridQuery = gql`
  query DeviceGridQuery {
    editableDevices {
      ...deviceGrid
    }
  }
  ${deviceGridFragment}
`;

const Ap2200GridQuery = gql`
  query Ap2200GridQuery {
    editableAp2200s {
      ...ap2200Grid
    }
  }
  ${ap2200GridFragment}
`;

const DevicesByAssetQuery = gql`
  query devicesByAsset($assetId: ID!) {
    devicesByAsset(assetId: $assetId) {
      serial
      legacy_serial
      installLocation
      deviceType {
        id
        name
      }
      ...deviceAssetLocationCompany
    }
  }
  ${deviceAssetLocationCompanyFragment}
`;

const DeviceQuery = gql`
  query DeviceQuery($id: ID!) {
    device(id: $id) {
      ...deviceAll
    }
  }
  ${deviceAllFragment}
`;

const DeviceDropdownQuery = gql`
  query DeviceDropdownQuery {
    devices {
      ...deviceAssetLocationCompany
    }
  }
  ${deviceAssetLocationCompanyFragment}
`;

const DeviceLatestDataQuery = gql`
  query DeviceQuery($id: ID!) {
    device(id: $id) {
      ...deviceBasics
      latestConnection
      latestDataPacket
      latestConnectionPacket
      latestAp2200ConnectionPacket
    }
  }
  ${deviceBasicsFragment}
`;

const DevicesByDeviceTypeQuery = gql`
  query devicesByDeviceType($deviceTypeId: ID!) {
    devicesByDeviceType(deviceTypeId: $deviceTypeId) {
      serial
      legacy_serial
      installLocation
      deviceType {
        id
        name
      }
      owner {
        id
        name
      }
      ...deviceAssetLocationCompany
    }
  }
  ${deviceAssetLocationCompanyFragment}
`;

export {
  Ap2200GridQuery,
  DeviceQuery,
  DeviceGridQuery,
  DeviceDropdownQuery,
  DevicesByAssetQuery,
  DeviceLatestDataQuery,
  DevicesByDeviceTypeQuery,
};
