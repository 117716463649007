import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { Field, FieldArray, change } from 'redux-form';
import _ from 'lodash';
import FormField from '../form/FormField';
import ExpansionPanelBase from '../common/ExpansionPanelBase';
import { AssetTypeQuery } from '../../graphql/queries/AssetTypeQueries';
import renderCheckbox from '../form/renderCheckbox';

class AssetTypeConfig extends Component {
  constructor(props) {
    super(props);
    this.renderConfigFields = this.renderConfigFields.bind(this);
    this.setConfigKey = this.setConfigKey.bind(this);
    this.getPanel = this.getPanel.bind(this);
  }

  setConfigKey(key, e, n, p) {
    this.props.setConfigKeyVal(this.props.formName, e, key, null);
  }

  renderConfigFields({ assetType, fields, meta: { error, submitFailed } }) {
    const allFields = _.map(assetType.fields, (obj, fieldIndex) => {
      switch (obj.rule) {
        case 'Number':
          return (
            <div key={`${fieldIndex}.tooltip`} data-tip={obj.description}>
              <Field
                key={`assetTypeConfig[${fieldIndex}].v`}
                name={`assetTypeConfig[${fieldIndex}].v`}
                type="text"
                component={FormField}
                label={obj.name}
                onChange={(e, n, p) => this.setConfigKey(obj.key, e, n, p)}
              />
            </div>
          );
        case 'Boolean':
          return (
            <div key={`${fieldIndex}.tooltip`} data-tip={obj.description}>
              <Field
                key={`assetTypeConfig[${fieldIndex}].v`}
                name={`assetTypeConfig[${fieldIndex}].v`}
                label={obj.name}
                id={`assetTypeConfig[${fieldIndex}].v`}
                component={renderCheckbox}
                onChange={(e, n, p) => this.setConfigKey(obj.key, e, n, p)}
              />
            </div>
          );
        case 'String':
        default:
          return (
            <div key={`${fieldIndex}.tooltip`} data-tip={obj.description}>
              <Field
                key={`assetTypeConfig[${fieldIndex}].v`}
                name={`assetTypeConfig[${fieldIndex}].v`}
                type="text"
                component={FormField}
                label={obj.name}
                onChange={(e, n, p) => this.setConfigKey(obj.key, e, n, p)}
              />
            </div>
          );
      }
    });
    return allFields;
  }

  getPanel(assetType) {
    const panelData = [];
    panelData[0] = {
      key: 'panel0',
      header: 'Metadata',
      data: (
        <div>
          <FieldArray
            name="assetTypeConfig"
            component={this.renderConfigFields}
            props={{ assetType }}
          />
        </div>
      ),
    };
    return panelData;
  }

  render() {
    if (!this.props.data) {
      return <div />;
    }
    const {
      data: { loading, error, assetType },
    } = this.props;

    if (loading) {
      return <p>Loading...</p>;
    } if (error) {
      return <p>Error Loading Asset Configuration!</p>;
    }
    return (
      <div>
        <ExpansionPanelBase
          panelData={this.getPanel(assetType)}
        />
      </div>
    );
  }
}
AssetTypeConfig = connect(
  null,
  {
    setConfigKeyVal: (formName, event, newValue, previousValue) => {
      const name = `${event.currentTarget.name.slice(0, -1)}k`;
      return change(formName, name, newValue);
    },
  },
)(AssetTypeConfig);

AssetTypeConfig.defaultProps = {
  formName: 'Asset',
};

export default graphql(AssetTypeQuery, {
  skip: (ownProps) => !ownProps.assetTypeId,
  options: (ownProps) => ({
    variables: { id: ownProps.assetTypeId },
  }),
})(AssetTypeConfig);
