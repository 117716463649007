import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { Field } from 'redux-form';
import { AttributeSelectQuery } from '../../../graphql/queries/AttributeQueries';
import renderSelectTree from '../../form/renderSelectTree';
import { selectMultipleArrayToString } from '../../common/functions';

class AttributeSelect extends Component {
  constructor(props) {
    super(props);
    this.handleAttributeChange = this.handleAttributeChange.bind(this);
    this.normalizeSelectArray = this.normalizeSelectArray.bind(this);
  }

  handleAttributeChange(e) {
    this.props.onChange(e);
  }

  // Set array to string if only 1 value allowed
  normalizeSelectArray(value) {
    return selectMultipleArrayToString(this.props.multiple, value);
  }

  render() {
    const { data: { loading, error, dataTypes } } = this.props;

    if (loading) {
      return <p>Loading...</p>;
    } if (error) {
      return <p>Error Loading Attributes!</p>;
    }
    return (
      <div>
        <label>{this.props.label}</label>

        <Field
          onChange={this.handleAttributeChange}
          name={this.props.fieldName}
          component={renderSelectTree}
          data={dataTypes}
          valueField="id"
          textField="name"
          multiple={this.props.multiple}
          normalize={this.normalizeSelectArray}
        />
      </div>
    );
  }
}

AttributeSelect.defaultProps = {
  label: 'Attributes',
  fieldName: 'attributes',
  multiple: true,
  onChange: () => {},
};
export default graphql(AttributeSelectQuery)(AttributeSelect);
