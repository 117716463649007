import { gql } from '@apollo/client';
import { accountManagerAllFragment } from '../fragments';

const CreateAccountManagerMutation = gql`
  mutation createAccountManager($accountManager: AccountManagerInput!) {
    createAccountManager(accountManager: $accountManager) {
      ...accountManagerAll
    }
  }
  ${accountManagerAllFragment}
`;

const UpdateAccountManagerMutation = gql`
  mutation updateAccountManager($accountManager: AccountManagerInput!) {
    updateAccountManager(accountManager: $accountManager) {
      ...accountManagerAll
    }
  }
  ${accountManagerAllFragment}
`;

const DeleteAccountManagerMutation = gql`
  mutation deleteAccountManager($id: ID!) {
    deleteAccountManager(id: $id) {
      id
    }
  }
`;

export { CreateAccountManagerMutation, UpdateAccountManagerMutation, DeleteAccountManagerMutation };
