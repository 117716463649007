import { gql } from '@apollo/client';
import { tableFragment } from '../fragments';

const TableQuery = gql`
  query table($id: ID!) {
    table(id: $id) {
      ...table
    }
  }
  ${tableFragment}
`;

const TableGridQuery = gql`
  query TableGridQuery {
    defaultTables {
      ...table
    }
  }
  ${tableFragment}
`;

const TableSelectQuery = gql`
  query tables {
    defaultTables {
      id
      name
    }
  }
`;

export { TableGridQuery, TableSelectQuery, TableQuery };
