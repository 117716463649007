import React, { Component } from 'react';
import { reduxForm, Field, FieldArray } from 'redux-form';
import FormField from '../../form/FormField';
import Button from '@material-ui/core/Button';
import ScalarTypeDropdown from '../../common/ScalarTypeDropdown';
import FormSubmitButton from '../../form/FormSubmitButton';
import ClearButton from '../../form/ClearButton';
import CSVFileInput from '../../form/CSVFileInput';
import renderCheckbox from '../../form/renderCheckbox';
import AssetConfigDropdown from '../assetConfig/AssetConfigDropdown';
import ErrorMessage from '../../common/ErrorMessage';
import Typography from '@material-ui/core/Typography';

const csvExample = 'Field Key,Field Name,Field Description,Field Type,Required,\nKey,Name,Field,Number,true,';
const renderFieldArray = ({ fields }) => (
  <div>
    <div>
      <Button
        variant="contained"
        type="button"
        onClick={() => fields.push({})}
      >
        Add Metadata Field
      </Button>
      <br />
      Import Metadata Configuration from CSV
      <br />
      <a href={`data:application/csv;charset=utf-8,${csvExample}`} title="Example metadata configuration csv" download="exampleMetadataConfiguration.csv">
        Download example file
        <i className="material-icons right">cloud_download</i>
      </a>
      <Field
        name="uploadCSV"
        onChange={(data) => {
          let i = 1;
          while (data[i]) {
            const isReq = data[i][4].toLowerCase() === 'true';
            fields.push({
              key: data[i][0],
              name: data[i][1],
              description: data[i][2],
              rule: data[i][3],
              required: isReq,
            });
            i += 1;
          }
        }}
        onError={(err) => {
          console.log(err);
          ErrorMessage({
            msg: err,
            timeOut: 30000,
          });
        }}
        component={CSVFileInput}
      />
    </div>
    {fields.map((assetField, index) => (
      <div key={index}>
        <Button
          variant="contained"
          className="right"
          type="button"
          title="Remove Field"
          onClick={() => fields.remove(index)}
        >
          <i className="material-icons">delete</i>
        </Button>
        <Typography variant="h5" className="ps-form-header">
          Field #
          {index + 1}
        </Typography>
        <Field
          name={`${assetField}.key`}
          type="text"
          component={FormField}
          label="Field Key"
        />
        <Field
          name={`${assetField}.name`}
          type="text"
          component={FormField}
          label="Field Name"
        />
        <Field
          name={`${assetField}.description`}
          type="text"
          component={FormField}
          label="Field Description"
        />
        <ScalarTypeDropdown
          fieldName={`${assetField}.rule`}
          label="Field Type"
        />
        <br />
        <Field
          name={`${assetField}.required`}
          label="Required"
          id={`${assetField}.required`}
          component={renderCheckbox}
        />
      </div>
    ))}
  </div>
);

class AssetTypeForm extends Component {
  render() {
    const {
      handleSubmit, pristine, reset, submitting,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div data-tip="Name of Asset Type">
          <Field
            name="name"
            type="text"
            component={FormField}
            label="Asset Type Name"
          />
        </div>
        <AssetConfigDropdown
          fieldName="assetConfig"
          label="Default Asset Configuration"
        />
        <br />
        <FieldArray name="fields" component={renderFieldArray} />
        <div>
          <FormSubmitButton pristine={pristine} submitting={submitting} />
          <ClearButton
            pristine={pristine}
            submitting={submitting}
            reset={reset}
          />
        </div>
      </form>
    );
  }
}

AssetTypeForm.defaultProps = {
  initialValues: null,
};
const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Cannot be blank';
  }
  return errors;
};

export default reduxForm({ validate, form: 'AssetType' })(
  AssetTypeForm,
);
