import React, { Component } from 'react';
import { reduxForm, change, Field } from 'redux-form';
import { connect } from 'react-redux';
import FormField from '../../form/FormField';
import FormSubmitButton from '../../form/FormSubmitButton';
import DeviceTypeDropdown from '../deviceType/DeviceTypeDropdown';
import DeviceGroupEditDevices from './DeviceGroupEditDevices';

class DeviceGroupForm extends Component {
  constructor(props) {
    super(props);
    let deviceTypeId = null;
    let deviceTypeName = '';
    if (props && props.initialValues && props.initialValues.deviceType) {
      deviceTypeId = props.initialValues.deviceType.id;
      deviceTypeName = props.initialValues.deviceType.name;
    }
    this.state = {
      deviceTypeId,
      deviceTypeName,
      devicesInGroup: [],
      devicesChanged: false,
    };
    this.handleChangeDeviceType = this.handleChangeDeviceType.bind(this);
    this.onDeviceChange = this.onDeviceChange.bind(this);
  }

  handleChangeDeviceType(deviceType) {
    this.setState({ deviceTypeId: deviceType.id, deviceTypeName: deviceType.name });
  }

  onDeviceChange(devices) {
    this.setState({ devicesChanged: true });
    this.props.setDevices(null, devices.map((d) => d.id));
  }

  render() {
    const {
      pristine, submitting, initialValues, handleSubmit,
    } = this.props;

    let initialDevicesInGroup = [];
    if (initialValues) {
      initialDevicesInGroup = initialValues.devices;
    }
    return (
      <div>
        <form onSubmit={handleSubmit}>
          <Field
            name="name"
            type="text"
            component={FormField}
            label="Device Group Name"
          />
          <div data-tip="Device group will include devices of this type">
            <DeviceTypeDropdown
              fieldName="deviceType"
              label="Device Type"
              onDropdownChange={this.handleChangeDeviceType}
            />
          </div>

          <DeviceGroupEditDevices
            deviceTypeId={this.state.deviceTypeId}
            deviceTypeName={this.state.deviceTypeName}
            onChangeDeviceList={this.onDeviceChange}
            initialDevices={initialDevicesInGroup}
          />
          <br />
          <br />
          <div>
            <FormSubmitButton pristine={pristine && !this.state.devicesChanged} submitting={submitting} />
          </div>
          <br />
          <br />
        </form>
      </div>
    );
  }
}

DeviceGroupForm.defaultProps = {
  initialValues: null,
};
const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Cannot be blank';
  }
  if (!values.deviceType) {
    errors.deviceType = 'Cannot be blank';
  }
  return errors;
};

DeviceGroupForm = connect(
  null,
  {
    setDevices: (event, newValue) => change('DeviceGroupForm', 'devices', newValue),
  },
)(DeviceGroupForm);

export default reduxForm({ validate, form: 'DeviceGroupForm' })(
  DeviceGroupForm,
);
