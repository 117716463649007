import { gql } from '@apollo/client';
import { assetConfigOnlyFragment } from '../fragments';

const CreateAssetConfigMutation = gql`
  mutation createAssetConfig($assetConfig: AssetConfigInput) {
    createAssetConfig(assetConfig: $assetConfig) {
      ...assetConfiguration
    }
  }
  ${assetConfigOnlyFragment}
`;

const UpdateAssetConfigMutation = gql`
  mutation updateAssetConfig($assetConfig: AssetConfigInput) {
    updateAssetConfig(assetConfig: $assetConfig) {
      ...assetConfiguration
    }
  }
  ${assetConfigOnlyFragment}
`;

const DeleteAssetConfigMutation = gql`
  mutation deleteAssetConfig($id: ID!) {
    deleteAssetConfig(id: $id) {
      id
      name
    }
  }
`;

export {
  CreateAssetConfigMutation,
  UpdateAssetConfigMutation,
  DeleteAssetConfigMutation,
};
