import update from 'immutability-helper';

export default (props) => {
  // add stuff for indicator widgets
  if (Array.isArray(props.indicatorWidgets)) {
    const newWidgets = [];
    for (let i = 0; i < props.indicatorWidgets.length; i++) {
      let newWidget = props.indicatorWidgets[i];
      if (newWidget.widgetType && newWidget.widgetType.id) {
        newWidget = update(newWidget, {
          widgetType: { $set: newWidget.widgetType.id },
        });
      }
      if (Array.isArray(newWidget.widgetId) && newWidget.widgetId.length > 0) {
        newWidget = update(newWidget, {
          widgetId: { $set: newWidget.widgetId[0] },
        });
      }
      newWidgets.push(newWidget);
    }
    props = update(props, { indicatorWidgets: { $set: newWidgets } });
  }
  return props;
};
