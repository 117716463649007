import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { Field } from 'redux-form';
import renderDropdownList from '../../form/renderDropdownList';
import { AssetTypeDropdownQuery } from '../../../graphql/queries/AssetTypeQueries';

class AssetTypeDropdown extends Component {
  constructor(props) {
    super(props);
    this.handleAssetTypeChange = this.handleAssetTypeChange.bind(this);
  }

  handleAssetTypeChange(e, nv, pv) {
    this.props.onDropdownChange(e, nv, pv);
  }

  render() {
    if (this.props.data) {
      if (this.props.data.loading) {
        return <p>Loading asset types...</p>;
      } if (this.props.data.error) {
        return <p>Error Loading Asset Types!</p>;
      }
      return (
        <div>
          <label>{this.props.label}</label>
          <Field
            name={this.props.fieldName}
            component={renderDropdownList}
            onChange={this.handleAssetTypeChange}
            data={this.props.data.assetTypes}
            valueField="id"
            textField="name"
            canBeNull={this.props.canBeNull}
          />
        </div>
      );
    }
    return (
      <div>
        <label>{this.props.label}</label>
        <Field
          name={this.props.fieldName}
          component={renderDropdownList}
          onChange={this.handleAssetTypeChange}
          data={[]}
          valueField="id"
          textField="name"
          canBeNull={this.props.canBeNull}
        />
      </div>
    );
  }
}
AssetTypeDropdown.defaultProps = {
  label: 'Asset Type',
  canBeNull: false,
  fieldName: 'assetType',
};
export default graphql(AssetTypeDropdownQuery)(AssetTypeDropdown);
