import omitDeep from '../../components/common/omitDeep';

export default ({ variables }) => {
  console.log('variables', variables);
  // remove __typename key, it is added by apollo but not part of Input types
  let vars = omitDeep(variables, '__typename');
  // remove 'fullName' key. Virtual prop for users and assets
  vars = omitDeep(vars, 'fullName');
  console.log('vars', vars);
  const arrKeys = Object.keys(vars);
  arrKeys.forEach((key) => {
    if (typeof vars[key] === 'object' && vars[key] !== null) {
      const nestedArrKeys = Object.keys(vars[key]);
      nestedArrKeys.forEach((nestedKey) => {
        if (
          typeof vars[key][nestedKey] === 'object'
          && vars[key][nestedKey] !== null
          && typeof vars[key][nestedKey].id !== 'undefined'
        ) {
          vars[key][nestedKey] = vars[key][nestedKey].id;
        } else if (
          Array.isArray(vars[key][nestedKey])
          && vars[key][nestedKey].length > 0
        ) {
          vars[key][nestedKey] = vars[key][nestedKey].map((nestedVal) => {
            if (
              typeof nestedVal === 'object'
              && nestedVal !== null
              && typeof nestedVal.id !== 'undefined'
            ) {
              return nestedVal.id;
            }
            return nestedVal;
          });
        } else if (vars[key][nestedKey] === null) {
          console.log('delete null', key, nestedKey);
          delete vars[key][nestedKey];
          // TODO: rewrite as recursive
        } else if (
          typeof vars[key][nestedKey] === 'object'
          && vars[key][nestedKey] !== null
        ) {
          const nkeys2 = Object.keys(vars[key][nestedKey]);
          nkeys2.forEach((nk2) => {
            if (
              typeof vars[key][nestedKey][nk2] === 'object'
              && vars[key][nestedKey][nk2] !== null
              && typeof vars[key][nestedKey][nk2].id !== 'undefined'
            ) {
              vars[key][nestedKey][nk2] = vars[key][nestedKey][nk2].id;
            } else if (
              Array.isArray(vars[key][nestedKey][nk2])
              && vars[key][nestedKey][nk2].length > 0
            ) {
              vars[key][nestedKey][nk2] = vars[key][nestedKey][nk2].map(
                (nestedVal) => {
                  if (
                    typeof nestedVal === 'object'
                    && nestedVal !== null
                    && typeof nestedVal.id !== 'undefined'
                  ) {
                    return nestedVal.id;
                  }
                  return nestedVal;
                },
              );
            }
          });
        }
      });
    } else if (vars[key] === null) {
      console.log('delete null', key);
      delete vars[key];
    }
  });
  console.log('varsAfter', vars);
  return vars;
};
