import { gql } from '@apollo/client';
import { userBasicFragment } from '../fragments';

export const CompanyUserGridQuery = gql`
  query CompanyUserGridQuery {
    companyUsers {
      id
      company {
        id
        name
      }
      user {
        ...userBasic
      }
    }
  }
  ${userBasicFragment}
`;

export const CompanyUserQuery = gql`
  query CompanyUser($id: ID!) {
    companyUser(id: $id) {
      id
      company {
        id
        name
      }
      user {
        ...userBasic
      }
    }
  }
  ${userBasicFragment}
`;

export const companyUsersByUserQuery = gql`
  query companyUsersByUser($userId: ID!) {
    companyUsersByUser(userId: $userId) {
      id
      company {
        id
        name
      }
    }
  }
`;

export const LocationUserGridQuery = gql`
  query LocationUserGridQuery {
    locationUsers {
      id
      location {
        id
        name
        company {
          id
          name
        }
      }
      user {
        ...userBasic
      }
    }
  }
  ${userBasicFragment}
`;

export const LocationUserQuery = gql`
  query LocationUser($id: ID!) {
    locationUser(id: $id) {
      id
      location {
        id
        name
      }
      user {
        ...userBasic
      }
    }
  }
  ${userBasicFragment}
`;

export const locationUsersByUserQuery = gql`
  query locationUsersByUser($userId: ID!) {
    locationUsersByUser(userId: $userId) {
      id
      location {
        id,
        name
      }
      user {
        ...userBasic
      }
    }
  }
  ${userBasicFragment}
`;

export const AssetUserGridQuery = gql`
  query AssetUserGridQuery {
    assetUsers {
      id
      asset {
        id
        name
        location {
          id
          name
          company {
            id
            name
          }
        }
        parent {
          id
          name
          location {
            id
            name
            company {
              id
              name
            }
          }
        }
      }
      user {
        ...userBasic
      }
    }
  }
  ${userBasicFragment}
`;

export const AssetUserQuery = gql`
  query AssetUser($id: ID!) {
    assetUser(id: $id) {
      id
      asset {
        id
        name
      }
      user {
        ...userBasic
      }
    }
  }
  ${userBasicFragment}
`;

export const assetUsersByUserQuery = gql`
  query assetUsersByUser($userId: ID!) {
    assetUsersByUser(userId: $userId) {
      id
      asset {
        id
        name
      }
      user {
        ...userBasic
      }
    }
  }
  ${userBasicFragment}
`;
