import { gql } from '@apollo/client';
import { assetAllFragment } from '../fragments';

const CreateAssetMutation = gql`
  mutation createAsset($asset: AssetInput!) {
    createAsset(asset: $asset) {
      ...assetAll
    }
  }
  ${assetAllFragment}
`;
const UpdateAssetMutation = gql`
  mutation updateAsset($asset: AssetInput!) {
    updateAsset(asset: $asset) {
      ...assetAll
    }
  }
  ${assetAllFragment}
`;

const DeleteAssetMutation = gql`
  mutation deleteAsset($id: ID!) {
    deleteAsset(id: $id) {
      id
      name
    }
  }
`;

export {
  CreateAssetMutation, UpdateAssetMutation, DeleteAssetMutation,
};
