import React, { PureComponent } from 'react';
import { SketchPicker } from 'react-color';

class ColorPicker extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showPicker: false,
      color: props.input && props.input.value ? props.input.value : '#fff',
    };
    this.toggleColor = this.toggleColor.bind(this);
    this.handleChangeComplete = this.handleChangeComplete.bind(this);
  }

  toggleColor(e) {
    e.preventDefault();
    this.setState((prevState) => ({ showPicker: !prevState.showPicker }));
  }

  handleChangeComplete(color) {
    const { onColorChange } = this.props;
    this.setState({ color: color.hex });
    onColorChange(color.hex);
  }

  render() {
    console.log('this.state.color ', this.state.color);
    console.log('this.state ', this.state);
    console.log('this.props ', this.props);
    return (
      <span className="color-picker-container">
        <button type="button" className="color-picker-button" onClick={this.toggleColor}>
          <div className="color-picker-color" style={{ backgroundColor: this.state.color }} />
        </button>
        {this.state.showPicker && (
          <div className="color-picker-picker">
            <SketchPicker
              color={this.state.color}
              onChangeComplete={this.handleChangeComplete}
              disableAlpha
              width={250}
            />
          </div>
        )}
      </span>
    );
  }
}

export default ColorPicker;
