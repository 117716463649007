import { gql } from '@apollo/client';
import { locationAllFragment } from '../fragments';

const CreateLocationMutation = gql`
  mutation createLocation($location: LocationInput!) {
    createLocation(location: $location) {
      ...locationAll
    }
  }
  ${locationAllFragment}
`;

const UpdateLocationMutation = gql`
  mutation updateLocation($location: LocationInput!) {
    updateLocation(location: $location) {
      ...locationAll
    }
  }
  ${locationAllFragment}
`;

const DeleteLocationMutation = gql`
  mutation deleteLocation($id: ID!) {
    deleteLocation(id: $id) {
      name
    }
  }
`;

export {
  CreateLocationMutation,
  UpdateLocationMutation,
  DeleteLocationMutation,
};
