import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import Typography from '@material-ui/core/Typography';
import CSVFileInput from '../form/CSVFileInput';
import FormSubmitButton from '../form/FormSubmitButton';
import ClearButton from '../form/ClearButton';

class UserImportForm extends Component {
  render() {
    console.log(this.props);
    const {
      handleSubmit, pristine, reset, submitting,
    } = this.props;
    return (
      <div>
        <form onSubmit={handleSubmit}>
          <Field
            name="uploadedData"
            onChange={this.props.handleFileLoaded}
            onError={this.props.handleFileError}
            component={CSVFileInput}
          />
          <br />

          <div>
            <FormSubmitButton pristine={pristine} submitting={submitting} />
            <ClearButton
              pristine={pristine}
              submitting={submitting}
              reset={reset}
            />
          </div>
        </form>
        <Typography variant="h5">Instructions</Typography>
        <div>
          Upload a csv file to create new users.
          <br />
          <strong>Required Columns:</strong>
          <ul>
            <li>company_name</li>
            <li>first_name</li>
            <li>last_name</li>
            <li>email</li>
          </ul>
          <br />
          <strong>Optional Columns:</strong>
          <ul>
            <li>send_welcome_email: defaults to false</li>
            <li>role: defaults to User. Options are: SuperAdmin, CompanyAdmin, User</li>
          </ul>
        </div>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};
  return errors;
};

export default reduxForm({
  validate,
  form: 'UserImport',
})(UserImportForm);
