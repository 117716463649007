import axios from 'axios';
import SuccessMessage from '../../SuccessMessage';
import ErrorMessage from '../../ErrorMessage';
import { REACT_APP_BACKEND } from '../../../../config';

export default async (props) => {
  const { ids, onError, onSuccess } = props;
  const url = `${REACT_APP_BACKEND}/batch/batchSendWelcomeEmails`;
  let res;
  try {
    res = await axios.post(url, {
      ids,
    });
  } catch (err) {
    ErrorMessage({
      msg: `Send welcome emails failed due to: ${err}`,
    });
    onError();
    return;
  }
  console.log('res.data', res.data);
  if (res.data.success === false) {
    ErrorMessage({
      msg: `Send welcome emails failed: ${res.data.msg}`,
    });
    onError();
  } else {
    SuccessMessage({
      msg: 'Send welcome emails success!',
    });
    console.log('success', res.data);
    onSuccess();
  }
};
