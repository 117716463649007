import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import update from 'immutability-helper';
import DeviceGroupForm from './DeviceGroupForm';
import FormBase from '../../form/FormBase';
import handleMutation from '../../../graphql/functions/handleMutation';
import {
  DeviceGroupGridQuery,
  DeviceGroupQuery,
} from '../../../graphql/queries/DeviceGroupQueries';
import { UpdateDeviceGroupMutation } from '../../../graphql/mutations/DeviceGroupMutations';

class DeviceGroupUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    // store deviceGroup id
    if (typeof values === 'object') {
      values.id = this.props.deviceGroup.id;
    }
    console.log('this.props deviceGroup update', values, this.props);
    handleMutation({
      mutate: this.props.mutate,
      variables: { deviceGroup: values },
      refetchQueries: [{ query: DeviceGroupGridQuery }],
      onSuccess: this.props.onSuccess,
    });
  }

  render() {
    const {
      DeviceGroupQuery: { loading, error, deviceGroup },
    } = this.props;
    return (
      <FormBase loading={loading} error={error} header="Edit Device Group">
        <DeviceGroupForm
          initialValues={deviceGroup}
          onSubmit={this.handleSubmit}
        />
      </FormBase>
    );
  }
}

export default compose(
  graphql(UpdateDeviceGroupMutation, {
    options: (props) => ({ variables: { id: props.deviceGroup.id } }),
  }),
  graphql(DeviceGroupQuery, {
    name: 'DeviceGroupQuery',
    options: (props) => ({ variables: { id: props.deviceGroup.id }, fetchPolicy: 'network-only' }),
  }),
)(DeviceGroupUpdate);
