import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import update from 'immutability-helper';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import handleDeviceMutation from '../../graphql/functions/handleDeviceMutation';
import handleMutation from '../../graphql/functions/handleMutation';
import { UpdateDeviceMutation } from '../../graphql/mutations/DeviceMutations';
import {
  DeviceQuery,
  DeviceGridQuery,
} from '../../graphql/queries/DeviceQueries';
import PSCard from '../common/PSCard';
import { AssetGridQuery } from '../../graphql/queries/AssetQueries';
import DeviceLocationPage from './DeviceLocationPage';
import DeviceAssetPage from './DeviceAssetPage';
import DeviceCompanyPage from './DeviceCompanyPage';
import DeviceOwnerPage from './DeviceOwnerPage';
import DeviceGroupPage from './DeviceGroupPage';
import DeviceAssignmentSuccess from './DeviceAssignmentSuccess';
import { AddDeviceToGroupsMutation, RemoveDeviceFromGroupsMutation } from '../../graphql/mutations/DeviceGroupMutations';
import { DeviceDeviceGroupsQuery } from '../../graphql/queries/DeviceGroupQueries';

class DeviceAssignmentWizard extends Component {
  constructor(props) {
    super(props);
    this.state = { page: 1, deviceGroupAdd: [], deviceGroupRemove: [] };
    this.onSubmit = this.onSubmit.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
  }

  getDevice(device) {
    let updatedDev = device;
    if (device && device.asset && device.asset.parent) {
      updatedDev = update(updatedDev, {
        childAsset: { $set: updatedDev.asset },
      });
      updatedDev = update(updatedDev, {
        asset: { $set: updatedDev.asset.parent },
      });
    }
    return updatedDev;
  }

  componentWillUnmount() {
    this.props.dispatch(reset('deviceWizard'));
  }

  onSubmit(values) {
    if (typeof values === 'object') {
      values.id = this.props.match.params.id;
      if (values.deviceGroupAdd && values.deviceGroupAdd.length > 0) {
        this.setState({ deviceGroupAdd: values.deviceGroupAdd });
      }
      if (values.deviceGroupRemove && values.deviceGroupRemove.length > 0) {
        this.setState({ deviceGroupRemove: values.deviceGroupRemove });
      }
      handleDeviceMutation({
        values,
        deviceMutation: this.props.UpdateDeviceMutation,
        refetchQueries: [{ query: DeviceGridQuery }, { query: AssetGridQuery }],
        onSuccess: this.onSuccess,
      });
    }
  }

  onSuccess() {
    if (this.state.deviceGroupAdd.length > 0 || this.state.deviceGroupRemove.length > 0) {
      const deviceId = this.props.match.params.id;
      const refetchQueries = [{ query: DeviceDeviceGroupsQuery, variables: { deviceId } }];
      if (this.state.deviceGroupAdd.length > 0) {
        handleMutation({
          mutate: this.props.addDeviceToGroupsMutation,
          variables: {
            deviceGroupDeviceInput: {
              deviceId, deviceGroups: this.state.deviceGroupAdd,
            },
          },
          refetchQueries,
          onSuccess: this.props.onSuccess,
          dontShowSuccess: true,
        });
      }
      if (this.state.deviceGroupRemove.length > 0) {
        handleMutation({
          mutate: this.props.removeDeviceFromGroupsMutation,
          variables: {
            deviceGroupDeviceInput: {
              deviceId, deviceGroups: this.state.deviceGroupRemove,
            },
          },
          refetchQueries,
          onSuccess: this.props.onSuccess,
          dontShowSuccess: true,
        });
      }
    }
    // advance to success page
    this.setState((prevState) => ({ page: prevState.page + 1 }));
  }

  nextPage() {
    this.setState((prevState) => ({ page: prevState.page + 1 }));
  }

  previousPage() {
    this.setState((prevState) => ({ page: prevState.page - 1 }));
  }

  render() {
    const { onSubmit } = this;
    let isSuperAdmin = false;
    // default to submitting from asset page
    let assetPageSubmit = onSubmit;
    if (this.props.auth && this.props.auth.isSuperAdmin) {
      isSuperAdmin = true;
      // if super admin, show the device group page
      assetPageSubmit = this.nextPage;
    }
    const { page } = this.state;
    const {
      DeviceQuery: { loading, error, device },
    } = this.props;

    if (loading) {
      return <p>Loading...</p>;
    } if (error) {
      return <p>Error loading device!</p>;
    } if (device === null || typeof device === 'undefined') {
      return <p>No device found!</p>;
    }
    return (
      <PSCard cardTitle="Assign Device">
        {page === 1 && (
        <Container>
          <div className="row">
            <ul>
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <li>
                    <Button
                      key="replaceDevice"
                      component={Link}
                      to={`/replaceDevice/${device.id}`}
                    >
                      Replace an Existing Device
                    </Button>
                  </li>
                  <li>
                    <Button
                      key="assignToAsset"
                      onClick={this.nextPage}
                    >
                      Assign Device to an Asset
                    </Button>
                  </li>
                </Grid>
              </Grid>
            </ul>
          </div>
        </Container>
        )}
        {page === 2 && (
        <DeviceOwnerPage
          previousPage={this.previousPage}
          onSubmit={this.nextPage}
          initialValues={this.getDevice(device)}
        />
        )}
        {page === 3 && (
        <DeviceCompanyPage
          previousPage={this.previousPage}
          onSubmit={this.nextPage}
          initialValues={this.getDevice(device)}
        />
        )}
        {page === 4 && (
        <DeviceLocationPage
          previousPage={this.previousPage}
          onSubmit={this.nextPage}
          initialValues={this.getDevice(device)}
        />
        )}
        {page === 5 && (
        <DeviceAssetPage
          previousPage={this.previousPage}
          onSubmit={assetPageSubmit}
          nextButtonText={isSuperAdmin ? 'Next' : 'Submit'}
          initialValues={this.getDevice(device)}
        />
        )}
        {page === 6 && isSuperAdmin && (
        <DeviceGroupPage
          previousPage={this.previousPage}
          onSubmit={onSubmit}
          device={this.getDevice(device)}
        />
        )}
        {((page === 6 && !isSuperAdmin)
            || (page === 7 && isSuperAdmin))
            && (
            <DeviceAssignmentSuccess id={device.id} />
            )}

      </PSCard>
    );
  }
}
function mapStateToProps({ auth }) {
  return { auth };
}
DeviceAssignmentWizard.defaultProps = {
  onSuccess: () => {},
};
DeviceAssignmentWizard = connect(
  mapStateToProps,
)(DeviceAssignmentWizard);
DeviceAssignmentWizard = compose(
  graphql(UpdateDeviceMutation, {
    name: 'UpdateDeviceMutation',
    options: (props) => ({ variables: { id: props.match.params.id } }),
  }),
  graphql(DeviceQuery, {
    name: 'DeviceQuery',
    options: (props) => ({ variables: { id: props.match.params.id } }),
  }),
  graphql(RemoveDeviceFromGroupsMutation, { name: 'removeDeviceFromGroupsMutation' }),
  graphql(AddDeviceToGroupsMutation, { name: 'addDeviceToGroupsMutation' }),
)(DeviceAssignmentWizard);
export default connect(null)(DeviceAssignmentWizard);
