import { gql } from '@apollo/client';
import { deviceTypeFragment } from '../fragments';

const DeviceTypeQuery = gql`
  query deviceType($id: ID!) {
    deviceType(id: $id) {
      ...deviceType
    }
  }
  ${deviceTypeFragment}
`;

const DeviceTypeGridQuery = gql`
  query DeviceTypeGridQuery {
    deviceTypes {
      id
      name
      key
      importKeys
    }
  }
`;

const DeviceTypeDropdownQuery = gql`
  query deviceTypes {
    deviceTypes {
      ...deviceType
    }
  }
  ${deviceTypeFragment}
`;

export { DeviceTypeGridQuery, DeviceTypeDropdownQuery, DeviceTypeQuery };
