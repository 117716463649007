import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import moment from 'moment';
import ServicePlanDropdown from './ServicePlanDropdown';
import FormSubmitButton from '../form/FormSubmitButton';
import ClearButton from '../form/ClearButton';
import renderDatePicker from '../form/renderDatePicker';

class ServicePlanForm extends Component {
  render() {
    const {
      handleSubmit,
      pristine,
      reset,
      submitting,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <ServicePlanDropdown fieldName="servicePlan" label="Service Plan" />
        <div>
          <label>Plan Expiration Date</label>
          <Field
            name="serviceExpirationDate"
            component={renderDatePicker}
            label="Expiration Date"
            normalize={(value) => {
              if (value) {
                const retVal = moment(value).unix();
                return retVal;
              }
              return null;
            }}
          />
        </div>
        <div>
          <FormSubmitButton pristine={pristine} submitting={submitting} />
          <ClearButton
            pristine={pristine}
            submitting={submitting}
            reset={reset}
          />
        </div>
      </form>
    );
  }
}

const validate = (values) => {
  console.log('values', values);
  const errors = {};
  if (!values.name) {
    errors.name = 'Cannot be blank';
  }
  if (!values.company) {
    errors.company = 'Cannot be blank';
  }
  console.log('errors', errors);
  return errors;
};

export default reduxForm({
  validate,
  form: 'ServicePlan',
})(ServicePlanForm);
