import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import update from 'immutability-helper';
import DeviceForm from './DeviceForm';
import FormBase from '../form/FormBase';
import handleDeviceMutation from '../../graphql/functions/handleDeviceMutation';
import handleMutation from '../../graphql/functions/handleMutation';
import {
  CreateDeviceMutation,
  UpdateDeviceMutation,
} from '../../graphql/mutations/DeviceMutations';
import {
  DeviceQuery, DeviceGridQuery, Ap2200GridQuery,
} from '../../graphql/queries/DeviceQueries';
import { AddDeviceToGroupsMutation, RemoveDeviceFromGroupsMutation } from '../../graphql/mutations/DeviceGroupMutations';
import { DeviceDeviceGroupsQuery } from '../../graphql/queries/DeviceGroupQueries';

class DeviceUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [], modalIsOpen: false, deviceGroupAdd: [], deviceGroupRemove: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
  }

  handleSubmit(values) {
    if (typeof values === 'object') {
      let mutation = this.props.UpdateDeviceMutation;
      let refetchQueries = [];
      let successFn = this.props.onSuccess;
      if (this.props.duplicate) {
        delete values.id;
        mutation = this.props.CreateDeviceMutation;
        // add all values in currrent list for duplicating device
        if (values.deviceGroupCurrentList && values.deviceGroupCurrentList.length > 0) {
          successFn = this.onSuccess;
          this.setState({ deviceGroupAdd: values.deviceGroupCurrentList });
        } else {
          refetchQueries = [{ query: DeviceGridQuery }, { query: Ap2200GridQuery }];
        }
      } else {
        values.id = this.props.device.id;
        if ((values.deviceGroupAdd && values.deviceGroupAdd.length > 0)
            || (values.deviceGroupRemove && values.deviceGroupRemove.length > 0)) {
          successFn = this.onSuccess;
          if (values.deviceGroupAdd && values.deviceGroupAdd.length > 0) {
            this.setState({ deviceGroupAdd: values.deviceGroupAdd });
          }
          if (values.deviceGroupRemove && values.deviceGroupRemove.length > 0) {
            this.setState({ deviceGroupRemove: values.deviceGroupRemove });
          }
        }
      }

      handleDeviceMutation({
        values,
        deviceMutation: mutation,
        onSuccess: successFn,
        refetchQueries,
      });
    }
  }

  onSuccess(updatedDevice) {
    // if added to groups, send request to add device to groups
    if (this.state.deviceGroupAdd.length > 0 || this.state.deviceGroupRemove.length > 0) {
      let deviceId;
      // if updating device groups, requery for inital device's device groups so they are correct if form is reopened
      let refetchQueries = [{ query: DeviceDeviceGroupsQuery, variables: { deviceId: this.props.device.id } }];
      if (this.props.duplicate) {
        if (updatedDevice.data && updatedDevice.data.createDevice && updatedDevice.data.createDevice.id) {
          deviceId = updatedDevice.data.createDevice.id;
          // this is really a create, update grids, not device groups
          refetchQueries = [{ query: DeviceGridQuery }, { query: Ap2200GridQuery }];
        }
      } else {
        deviceId = this.props.device.id;
      }
      if (deviceId) {
        if (this.state.deviceGroupAdd.length > 0) {
          handleMutation({
            mutate: this.props.addDeviceToGroupsMutation,
            variables: {
              deviceGroupDeviceInput: {
                deviceId, deviceGroups: this.state.deviceGroupAdd,
              },
            },
            refetchQueries,
            onSuccess: this.props.onSuccess,
            dontShowSuccess: true,
          });
        }
        if (this.state.deviceGroupRemove.length > 0) {
          handleMutation({
            mutate: this.props.removeDeviceFromGroupsMutation,
            variables: {
              deviceGroupDeviceInput: {
                deviceId, deviceGroups: this.state.deviceGroupRemove,
              },
            },
            refetchQueries,
            onSuccess: this.props.onSuccess,
            dontShowSuccess: true,
          });
        }
      }
    }
  }

  getDevice(device) {
    let updatedDev = device;
    if (device && device.asset && device.asset.parent) {
      updatedDev = update(updatedDev, {
        childAsset: { $set: updatedDev.asset },
      });
      updatedDev = update(updatedDev, {
        asset: { $set: updatedDev.asset.parent },
      });
    }
    return updatedDev;
  }

  render() {
    // render form with device values pre-loaded
    const {
      DeviceQuery: {
        loading, error, device, submitting,
      },
    } = this.props;
    return (
      <FormBase
        loading={loading}
        submitting={submitting}
        error={error}
        header={this.props.header}
      >
        <DeviceForm
          initialValues={this.getDevice(device)}
          onSubmit={this.handleSubmit}
        />
      </FormBase>
    );
  }
}

export default compose(
  graphql(UpdateDeviceMutation, {
    name: 'UpdateDeviceMutation',
    options: (props) => ({ variables: { id: props.device.id } }),
  }),
  graphql(CreateDeviceMutation, {
    name: 'CreateDeviceMutation',
  }),
  graphql(DeviceQuery, {
    name: 'DeviceQuery',
    options: (props) => ({
      variables: { id: props.device.id },
      fetchPolicy: 'network-only',
    }),
  }),
  graphql(RemoveDeviceFromGroupsMutation, { name: 'removeDeviceFromGroupsMutation' }),
  graphql(AddDeviceToGroupsMutation, { name: 'addDeviceToGroupsMutation' }),
)(DeviceUpdate);
