import React, { Component } from 'react';
import CSVReader from 'react-csv-reader';

class CSVFileInput extends Component {
  render() {
    const {
      className,
      input: { onChange, onError },
      label,
    } = this.props;

    return (
      <div className={`${className}`}>
        <CSVReader
          label={label}
          cssClass="csv-input"
          onFileLoaded={onChange}
          onError={onError}
        />
      </div>
    );
  }
}

CSVFileInput.defaultProps = {
  className: '',
  label: '',
};

export default CSVFileInput;
