import update from 'immutability-helper';

export default (props) => {
  if (Array.isArray(props.columns)) {
    const newColumns = [];
    for (let i = 0; i < props.columns.length; i++) {
      let newCol = props.columns[i];

      if (Array.isArray(newCol.attribute) && newCol.attribute.length > 0) {
        let newAtt;
        if (newCol.attribute[0].id) {
          newAtt = newCol.attribute[0].id;
        } else {
          newAtt = newCol.attribute[0];
        }
        newCol = update(newCol, {
          attribute: { $set: newAtt },
        });
      }
      if (newCol.type && newCol.type.id) {
        newCol = update(newCol, {
          type: { $set: newCol.type.id },
        });
      }
      newColumns.push(newCol);
    }
    props = update(props, { columns: { $set: newColumns } });
  }
  return props;
};
