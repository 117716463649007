import { gql } from '@apollo/client';
import { companyAllFragment } from '../fragments';

const CreateCompanyMutation = gql`
  mutation createCompany($company: CompanyInput!) {
    createCompany(company: $company) {
      ...companyAll
    }
  }
  ${companyAllFragment}
`;

const UpdateCompanyMutation = gql`
  mutation updateCompany($company: CompanyInput!) {
    updateCompany(company: $company) {
      ...companyAll
    }
  }
  ${companyAllFragment}
`;

const DeleteCompanyMutation = gql`
  mutation deleteCompany($id: ID!) {
    deleteCompany(id: $id) {
      name
    }
  }
`;

export { CreateCompanyMutation, UpdateCompanyMutation, DeleteCompanyMutation };
