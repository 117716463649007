import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import ReactJson from 'react-json-view';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import { DeviceLatestDataQuery } from '../../graphql/queries/DeviceQueries';

class DisplayRawData extends Component {
  getDataView(data) {
    let dataView = <div />;
    try {
      let obj;
      if (data !== null && typeof data === 'string') {
        obj = JSON.parse(data);
      } else {
        obj = data;
      }
      if (obj && typeof obj === 'object') {
        dataView = <ReactJson src={obj} />;
      }
    } catch (e) {
      console.error('getDataView err', e);
      dataView = <div>{data}</div>;
    }
    return dataView;
  }

  render() {
    // render raw data
    const {
      data: {
        loading, error, latestDataPacket, latestConnectionPacket, latestAp2200ConnectionPacket,
      },
    } = this.props;
    if (loading) {
      return <p>Loading...</p>;
    } if (error) {
      console.error(error);
      return <p>Error loading data!</p>;
    }
    return (
      <div>
        <Typography variant="h4">{this.props.header}</Typography>
        <Divider />
        <br />
        {latestAp2200ConnectionPacket
            && (
            <div>
              <Typography variant="h6">Most Recent Full AP2200 Packet:</Typography>
              {this.getDataView(latestAp2200ConnectionPacket)}
            </div>
            )}
        <Typography variant="h6">Newest data:</Typography>
        {this.getDataView(latestDataPacket)}
        <br />
        <Typography variant="h6">Data from most recent connection:</Typography>
        {this.getDataView(latestConnectionPacket)}
      </div>
    );
  }
}

export default graphql(DeviceLatestDataQuery, {
  name: 'LatestData',
  options: (props) => ({ variables: { id: props.device.id }, fetchPolicy: 'network-only' }),
  props: ({
    ownProps, LatestData: {
      loading, error, device, refetch,
    },
  }) => {
    let latestDataPacket = '';
    if (device && device.latestDataPacket) {
      latestDataPacket = device.latestDataPacket;
    }
    let latestConnectionPacket = '';
    if (device && device.latestConnectionPacket) {
      latestConnectionPacket = device.latestConnectionPacket;
    }
    let latestAp2200ConnectionPacket = null;
    if (device && device.latestAp2200ConnectionPacket) {
      latestAp2200ConnectionPacket = device.latestAp2200ConnectionPacket;
    }
    return {
      data: {
        latestDataPacket,
        latestConnectionPacket,
        latestAp2200ConnectionPacket,
        loading,
        error,
        refetch,
      },
    };
  },
})(DisplayRawData);
