import { gql } from '@apollo/client';
import { oilSampleTypeFragment } from '../fragments';

const CreateOilSampleTypeMutation = gql`
  mutation createOilSampleType($oilSampleType: OilSampleTypeInput!) {
    createOilSampleType(oilSampleType: $oilSampleType) {
      ...oilSampleType
    }
  }
  ${oilSampleTypeFragment}
`;
const UpdateOilSampleTypeMutation = gql`
  mutation updateOilSampleType($oilSampleType: OilSampleTypeInput!) {
    updateOilSampleType(oilSampleType: $oilSampleType) {
      ...oilSampleType
    }
  }
  ${oilSampleTypeFragment}
`;

const DeleteOilSampleTypeMutation = gql`
  mutation deleteOilSampleType($id: ID!) {
    deleteOilSampleType(id: $id) {
      id
      name
    }
  }
`;
export { CreateOilSampleTypeMutation, UpdateOilSampleTypeMutation, DeleteOilSampleTypeMutation };
