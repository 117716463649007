import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import GridBase from '../../common/GridBase';
import { AttributeGridQuery } from '../../../graphql/queries/AttributeQueries';
import ContextMenuWithTrigger from '../../common/ContextMenuWithTrigger';
import Modal from '../../common/Modal';
import AttributeUpdate from './AttributeUpdate';
import { DeleteAttributeMutation } from '../../../graphql/mutations/AttributeMutations';
import handleContextMenuClick from '../../common/handleContextMenuClick';
import DeleteModel from '../../common/DeleteModel';
import displayBoolean from '../../common/displayBoolean';
import attributeFormFields from './attributeFormFields';

function coloredCell(value) {
  if (value && typeof value === 'string') {
    return <div style={{ background: value }}>{value}</div>;
  }
  return value;
}

class AttributeGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      attribute: null,
      delete: { id: null, name: '' },
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleDeleteClose = this.handleDeleteClose.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  columns = [
    {
      Header: 'ID',
      accessor: 'id',
      maxWidth: 70,
      filterable: true,
    },
    {
      Header: 'Name',
      accessor: 'name',
      filterable: true,
    },
    {
      Header: 'Key',
      accessor: 'key',
      filterable: true,
    },
    {
      Header: 'Data Type',
      id: 'dataType',
      accessor: (d) => (d.dataType ? d.dataType.name : ''),
      filterable: true,
    },
    {
      Header: 'Type',
      id: 'attributeType',
      accessor: (d) => (d.attributeType ? d.attributeType.name : ''),
      filterable: true,
    },
    {
      Header: 'Legacy Id',
      accessor: 'legacy_id',
      filterable: true,
      maxWidth: 50,
    },
    {
      Header: 'Color',
      accessor: 'color',
      filterable: true,
      Cell: ({ value }) => coloredCell(value),
    },
    {
      Header: 'Units',
      accessor: 'units',
      filterable: true,
      maxWidth: 50,
    },
    {
      Header: 'Factor',
      accessor: 'factor',
      filterable: true,
      maxWidth: 50,
    },
    {
      Header: 'Offset',
      accessor: 'offset',
      filterable: true,
      maxWidth: 50,
    },
    {
      Header: 'Round',
      accessor: 'round',
      filterable: true,
      maxWidth: 50,
    },
    {
      Header: 'Rule',
      accessor: 'rule',
      filterable: true,
      maxWidth: 50,
    },
    {
      Header: 'Cumulative',
      id: 'cumulative',
      accessor: (d) => displayBoolean(d, 'cumulative'),
      filterable: true,
      maxWidth: 50,
    },
    {
      Header: 'Browse',
      id: 'browsable',
      accessor: (d) => displayBoolean(d, 'browsable'),
      filterable: true,
      maxWidth: 50,
    },
    // {
    //   Header: 'Export',
    //   id: 'exportable',
    //   accessor: d => {
    //     return displayBoolean(d, 'exportable');
    //   },
    //   filterable: true,
    //   maxWidth: 50,
    // },
    {
      Header: 'Admin Only',
      id: 'adminOnly',
      accessor: (d) => displayBoolean(d, 'adminOnly'),
      filterable: true,
      maxWidth: 50,
    },
    /*     {
      Header: 'Axis',
      accessor: 'axis',
      filterable: true,
      maxWidth: 50,
    }, */
    {
      Header: '',
      maxWidth: 23,
      resizable: false,
      sortable: false,
      Cell: ({ row }) => ContextMenuWithTrigger({
        handleClick: this.handleClick,
        row,
      }),
    },
  ];

  // handle context menu clicks
  handleClick(e, data, target) {
    const modelName = 'attribute';
    const stateUpdate = handleContextMenuClick({
      e, data, target, modelName,
    });
    console.log('stateUpdate', stateUpdate);
    this.setState({ ...stateUpdate });
  }

  // reset delete attribute id and name to null upon deletion
  handleDeleteClose() {
    this.setState({ delete: { id: null, name: '' } });
  }

  render() {
    const {
      data: { loading, error, attributes },
    } = this.props;
    const sorting = [{ id: 'name', asc: true }];
    if (loading) {
      return <p>Loading...</p>;
    } if (error) {
      return <p>Error loading attribtues!</p>;
    }
    const multiSelect = {
      canDelete: false,
      fields: attributeFormFields,
      modelName: 'attribute',
      onSubmit: this.props.data.refetch,
    };
    return (
      <div>
        <GridBase
          dataVar={attributes}
          columns={this.columns}
          sorting={sorting}
          multiSelect={multiSelect}
        />

        <DeleteModel
          deleteMutation={DeleteAttributeMutation}
          refetchQueries={[{ query: AttributeGridQuery }]}
          id={this.state.delete.id}
          name={this.state.delete.name}
          onClose={this.handleDeleteClose}
        />

        <Modal
          onClose={this.closeModal}
          modalIsOpen={this.state.modalIsOpen}
          contentLabel="Edit Attribute"
        >
          <AttributeUpdate attribute={this.state.attribute} />
        </Modal>
      </div>
    );
  }
}

export default graphql(AttributeGridQuery)(AttributeGrid);
