import update from 'immutability-helper';
import handleMutation from './handleMutation';

const handleSubmitInstallerConfigField = (props) => {
  handleMutation({
    mutate: props.mutation,
    variables: { installerConfigField: props.values },
    refetchQueries: props.refetchQueries,
    onSuccess: props.onSuccess,
  });
};

export default (props) => {
  const { values } = props;
  console.log('submit', values);
  if (typeof values === 'object') {
    if (
      typeof values.deviceType === 'object'
      && values.deviceType !== null
      && values.deviceType.id
    ) {
      values.deviceType = values.deviceType.id;
    }
    if (
      typeof values.serialNumberType === 'object'
      && values.serialNumberType !== null
      && values.serialNumberType.id
    ) {
      values.serialNumberType = values.serialNumberType.id;
    }
    if (Array.isArray(values.inputs)) {
      const newInputs = [];
      for (let i = 0; i < values.inputs.length; i++) {
        let newInput = values.inputs[i];
        if (newInput.inputType && newInput.inputType.id) {
          newInput = update(newInput, {
            inputType: { $set: newInput.inputType.id },
          });
        }
        newInputs.push(newInput);
      }
      console.log('newInputs', newInputs);
      props = update(props, {
        values: {
          inputs: { $set: newInputs },
        },
      });
      console.log('props', props);
    }

    handleSubmitInstallerConfigField(props);
  }
};
