import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import LocationForm from './LocationForm';
import FormBase from '../form/FormBase';
import handleMutation from '../../graphql/functions/handleMutation';
import {
  LocationGridQuery,
  LocationDropdownQuery,
  LocationsByCompanyQuery,
} from '../../graphql/queries/LocationQueries';
import { CompanyLocationsQuery } from '../../graphql/queries/CompanyQueries';
import { CreateLocationMutation } from '../../graphql/mutations/LocationMutations';
import { isNonNullObject } from '../common/functions';

class LocationNew extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    if (typeof values.company === 'object' && values.company.id) {
      values.company = values.company.id;
    } else if (this.props.companyId) {
      values.company = this.props.companyId;
    }
    if (typeof values.modelDashboard === 'object' && values.modelDashboard.id) {
      values.modelDashboard = values.modelDashboard.id;
    }
    if (
      isNonNullObject(values.installerConfig)
      && values.installerConfig.id
    ) {
      values.installerConfig = values.installerConfig.id;
    }
    const refetchQueries = [
      { query: LocationGridQuery },
      { query: CompanyLocationsQuery },
      { query: LocationDropdownQuery },
    ];

    if (this.props.companyId) {
      refetchQueries.push({
        query: LocationsByCompanyQuery,
        variables: { companyId: this.props.companyId },
      });
    }

    handleMutation({
      mutate: this.props.mutate,
      variables: { location: values },
      refetchQueries,
      onSuccess: this.props.onSuccess,
    });
  }

  render() {
    return (
      <FormBase header="Create Location">
        <LocationForm
          onSubmit={this.handleSubmit}
        />
      </FormBase>
    );
  }
}

const LocationNewWithMutation = graphql(CreateLocationMutation)(LocationNew);

export default LocationNewWithMutation;
