import React from 'react';
import { Field } from 'redux-form';

const normalizeBoolean = (value) => {
  console.log('normalizeBoolean', value);
  // NOTE: for now, check and uncheck checkboxes used in multiedit form to get them to be set to false
  if (value === 'true') {
    return true;
  }
  if (value === null || typeof value === 'undefined' || value === 'false' || value === '') {
    return false;
  }
  return value;
};
const formatBoolean = (value) => {
  const val = normalizeBoolean(value);
  console.log('formatBoolean', val);
  return val;
};

export default function renderCheckbox(props) {
  return (
    <div className="ps-form-checkbox">
      <label>{props.label}</label>
      <Field
        format={formatBoolean}
        name={props.input.name}
        id={props.id}
        component="input"
        type="checkbox"
        onChange={props.input.onChange ? props.input.onChange : () => {}}
        normalize={normalizeBoolean}
      />
    </div>
  );
}
