import handleMutation from './handleMutation';
import formatAttConfig from './formatAttConfig';

const handleSubmitOilSampleType = (props) => {
  handleMutation({
    mutate: props.mutation,
    variables: { oilSampleType: props.values },
    refetchQueries: props.refetchQueries,
    onSuccess: props.onSuccess,
  });
};

export default (props) => {
  const { values } = props;
  if (typeof values === 'object' && values !== null) {
    const formattedFields = formatAttConfig({ attConfigs: values.fields });
    values.fields = formattedFields.attConfigs;
    const formattedMetadataFields = formatAttConfig({ attConfigs: values.metadataFields });
    values.metadataFields = formattedMetadataFields.attConfigs;
    handleSubmitOilSampleType(props);
  }
};
