import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import Typography from '@material-ui/core/Typography';
import DeviceReplacementForm from './DeviceReplacementForm';
import FormBase from '../../form/FormBase';
import { DeviceGridQuery } from '../../../graphql/queries/DeviceQueries';
import handleMutation from '../../../graphql/functions/handleMutation';
import {
  DeviceReplacementMutation,
} from '../../../graphql/mutations/DeviceReplacementMutations';
import ErrorMessage from '../../common/ErrorMessage';
import ConfirmDialog from '../../common/ConfirmDialog';
import PSLoader from '../../common/PSLoader';

class DeviceReplacement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      incomingDevice:
      props.replacementDevice ? props.replacementDevice : props.originalDevice,
      errors: [],
      selectedDevice: null,
      dialogOpen: false,
      dialogMessage: '',
      replaceDeviceValues: null,
      showLoader: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setSelectedDevice = this.setSelectedDevice.bind(this);
    this.onDialogConfirm = this.onDialogConfirm.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.replaceDevice = this.replaceDevice.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onError = this.onError.bind(this);
  }

  setSelectedDevice(device) {
    this.setState({ selectedDevice: device });
  }

  handleSubmit(values) {
    if (!this.state.selectedDevice) {
      ErrorMessage({
        msg: 'Please select a device from the list',
      });
      return;
    }
    if (!values || typeof values !== 'object') {
      ErrorMessage({
        msg: 'There is an error with your form',
      });
      return;
    }
    let dialogMessage = '';
    if (this.props.replacementDevice) {
      dialogMessage = `The original device (serial: ${this.state.selectedDevice.serial ? this.state.selectedDevice.serial
        : this.state.selectedDevice.legacy_serial}) will be replaced by the replacement device (serial: ${this.props.replacementDevice.serial
        ? this.props.replacementDevice.serial
        : this.props.replacementDevice.legacy_serial}).`;
    } else {
      dialogMessage = `The original device (serial: ${this.props.originalDevice.serial ? this.props.originalDevice.serial
        : this.props.originalDevice.legacy_serial}) will be replaced by the replacement device (serial: ${this.state.selectedDevice.serial
        ? this.state.selectedDevice.serial
        : this.state.selectedDevice.legacy_serial}).`;
    }
    if (values.handleData && values.handleData.id === 'deleteOld') {
      dialogMessage += ' Data will be deleted from the original device prior to the install date of the replacement device';
    } else if (values.handleData && values.handleData.id === 'merge') {
      dialogMessage += ' Data from the original device and the replacement device will be merged post install date.';
    }
    this.setState({
      dialogOpen: true,
      dialogMessage,
      replaceDeviceValues: values,
    });
  }

  replaceDevice() {
    const values = this.state.replaceDeviceValues;
    const deviceReplacement = { installDate: values.installDate, handleData: values.handleData };
    if (this.props.replacementDevice) {
      deviceReplacement.deviceToReplace = this.state.selectedDevice.id;
      deviceReplacement.newDevice = this.props.replacementDevice.id;
    } else {
      deviceReplacement.deviceToReplace = this.props.originalDevice.id;
      deviceReplacement.newDevice = this.state.selectedDevice.id;
    }
    this.setState({ showLoader: true });
    handleMutation({
      mutate: this.props.DeviceReplacementMutation,
      variables: { deviceReplacement },
      refetchQueries: [{ query: DeviceGridQuery }],
      onSuccess: this.onSuccess,
      onError: this.onError,
    });
  }

  onSuccess() {
    this.setState({ showLoader: false });
    this.props.onSuccess();
  }

  onError() {
    this.setState({ showLoader: false });
  }

  onDialogConfirm() {
    this.replaceDevice();
  }

  closeDialog() {
    this.setState({
      dialogOpen: false,
      dialogMessage: '',
      replaceDeviceValues: null,
    });
  }

  render() {
    const sn = this.state.incomingDevice.serial ? this.state.incomingDevice.serial : this.state.incomingDevice.legacy_serial;
    let assetName;
    if (this.state.incomingDevice.assetName) {
      assetName = this.state.incomingDevice.assetName;
    } else {
      assetName = this.state.incomingDevice.asset ? this.state.incomingDevice.asset.fullName
      + (this.state.incomingDevice.installLocation ? ` ${this.state.incomingDevice.installLocation}` : '') : '';
    }
    const headerDeviceDetails = `${assetName} ${this.state.incomingDevice.name} - ${sn}`;
    let subHeader = '';
    if (this.props.replacementDevice) {
      subHeader = `Select old device to replace with ${headerDeviceDetails}`;
    } else {
      subHeader = `Select replacement device to replace ${headerDeviceDetails}`;
    }
    return (
      <FormBase header={this.props.header}>
        <PSLoader showLoader={this.state.showLoader}>
          <Typography variant="h5">{subHeader}</Typography>
          <DeviceReplacementForm
            onSubmit={this.handleSubmit}
            originalDevice={this.props.originalDevice}
            devices={this.props.devices}
            replacementDevice={this.props.replacementDevice}
            setSelectedDevice={this.setSelectedDevice}
          />
          <ConfirmDialog
            title="Replace Device Confirmation"
            open={this.state.dialogOpen}
            message={this.state.dialogMessage}
            onConfirm={this.onDialogConfirm}
            onClose={this.closeDialog}
          />
        </PSLoader>
      </FormBase>
    );
  }
}
DeviceReplacementForm.defaultProps = {
  onSuccess: () => {},
};
export default compose(
  graphql(DeviceReplacementMutation, {
    name: 'DeviceReplacementMutation',
  }),
)(DeviceReplacement);
