import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import GridBase from '../common/GridBase';
import { EventGridQuery } from '../../graphql/queries/EventQueries';
import ContextMenuWithTrigger from '../common/ContextMenuWithTrigger';
import displayBoolean from '../common/displayBoolean';
import displayTimestamp from '../common/displayTimestamp';
import timestampSort from '../common/timestampSort';
import { DeleteEventMutation } from '../../graphql/mutations/EventMutations';
import DeleteModel from '../common/DeleteModel';

class EventGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      event: null,
      delete: { id: null, name: '' },
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleDeleteClose = this.handleDeleteClose.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  columns = [
    {
      Header: 'ID',
      accessor: 'id',
      maxWidth: 90,
      filterable: true,
    },
    {
      Header: 'Event Name',
      accessor: 'name',
      filterable: true,
    },
    {
      Header: 'Date Added',
      id: 'dateAdded',
      accessor: (d) => displayTimestamp(d, 'dateAdded'),
      sortMethod: timestampSort,
      filterable: true,
    },
    {
      Header: 'Timestamp',
      id: 'timestamp',
      accessor: (d) => displayTimestamp(d, 'timestamp'),
      sortMethod: timestampSort,
      filterable: true,
    },
    {
      Header: 'Public',
      id: 'public',
      accessor: (d) => displayBoolean(d, 'public'),
      filterable: true,
    },
    {
      Header: '',
      maxWidth: 23,
      resizable: false,
      sortable: false,
      Cell: ({ row }) => ContextMenuWithTrigger({
        handleClick: this.handleClick,
        row,
        canEdit: false,
      }),
    },
  ];

  // handle context menu clicks
  handleClick(e, data, target) {
    if (data.action === 'Delete') {
      this.setState({
        delete: { id: data.row.id, name: data.row.name },
      });
    }
  }

  // reset delete event id and name to null upon deletion
  handleDeleteClose() {
    this.setState({ delete: { id: null, name: '' } });
  }

  render() {
    const {
      data: { loading, error, events },
    } = this.props;
    const sorting = [{ id: 'timestamp', desc: true }];
    if (loading) {
      return <p>Loading...</p>;
    } if (error) {
      return <p>Error loading events!</p>;
    }
    const multiSelect = {
      fields: [],
      canEdit: false,
      modelName: 'event',
      onSubmit: this.props.data.refetch,
    };
    return (
      <div>
        <GridBase
          dataVar={events}
          columns={this.columns}
          sorting={sorting}
          multiSelect={multiSelect}
        />

        <DeleteModel
          deleteMutation={DeleteEventMutation}
          refetchQueries={[{ query: EventGridQuery }]}
          id={this.state.delete.id}
          name={this.state.delete.name}
          onClose={this.handleDeleteClose}
        />
      </div>
    );
  }
}

export default graphql(EventGridQuery)(EventGrid);
