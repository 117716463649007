import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import AccountManagerForm from './AccountManagerForm';
import FormBase from '../form/FormBase';
import handleMutation from '../../graphql/functions/handleMutation';
import {
  AccountManagerQuery,
} from '../../graphql/queries/AccountManagerQueries';
import {
  CompanyGridQuery,
} from '../../graphql/queries/CompanyQueries';
import {
  UpdateAccountManagerMutation,
} from '../../graphql/mutations/AccountManagerMutations';

class AccountManagerUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    if (typeof values === 'object') {
      const mutation = this.props.UpdateAccountManagerMutation;
      values.id = this.props.accountManager.id;
      values.user = Array.isArray(values.user) ? values.user[0] : values.user;

      handleMutation({
        mutate: mutation,
        variables: { accountManager: values },
        refetchQueries: [{ query: CompanyGridQuery }],
        onSuccess: this.props.onSuccess,
      });
    }
  }

  render() {
    const {
      data: { loading, error, accountManager },
    } = this.props;
    return (
      <FormBase loading={loading} error={error} header={this.props.header}>
        <AccountManagerForm initialValues={accountManager} onSubmit={this.handleSubmit} />
      </FormBase>
    );
  }
}

export default compose(
  graphql(UpdateAccountManagerMutation, {
    name: 'UpdateAccountManagerMutation',
    options: (props) => ({ variables: { id: props.accountManager.id } }),
  }),
  graphql(AccountManagerQuery, {
    options: (props) => ({ variables: { id: props.accountManager.id }, fetchPolicy: 'network-only' }),
  }),
)(AccountManagerUpdate);
