import { gql } from '@apollo/client';
import { deviceAssetLocationCompanyFragment, deviceGroupFragment, deviceBasicsFragment } from '../fragments';

const DeviceGroupQuery = gql`
  query deviceGroup($id: ID!) {
    deviceGroup(id: $id) {
      ...deviceGroup
      devices {
        ...deviceBasics
        ...deviceAssetLocationCompany
      }
    }
  }
  ${deviceAssetLocationCompanyFragment}
  ${deviceGroupFragment}
  ${deviceBasicsFragment}
`;

const DeviceGroupGridQuery = gql`
  query DeviceGroupGridQuery {
    deviceGroups {
      ...deviceGroup
    }
  }
  ${deviceGroupFragment}
`;

const DeviceDeviceGroupsQuery = gql`
  query DeviceDeviceGroupsQuery($deviceId: ID!) {
    deviceDeviceGroups(deviceId: $deviceId) {
      id
      name
    }
  }
`;

const DeviceGroupsByDeviceTypeQuery = gql`
  query DeviceGroupGridQuery($deviceTypeId: ID!) {
    deviceGroups(deviceTypeId: $deviceTypeId) {
      ...deviceGroup
    }
  }
  ${deviceGroupFragment}
`;

export {
  DeviceGroupGridQuery, DeviceGroupQuery, DeviceDeviceGroupsQuery, DeviceGroupsByDeviceTypeQuery,
};
