import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import { Field } from 'redux-form';
import { ChartSelectQuery } from '../../graphql/queries/ChartQueries';
import renderSelectTree from '../form/renderSelectTree';
import { selectMultipleArrayToString } from '../common/functions';

class ChartSelect extends Component {
  constructor(props) {
    super(props);
    this.handleChartChange = this.handleChartChange.bind(this);
    this.normalizeSelectArray = this.normalizeSelectArray.bind(this);
  }

  handleChartChange(e) {
    this.props.onChange(e);
  }

  // Set array to string if only 1 value allowed
  normalizeSelectArray(value) {
    return selectMultipleArrayToString(this.props.multiple, value);
  }

  render() {
    const {
      ChartSelectQuery: { loading, error, defaultCharts },
    } = this.props;
    const charts = defaultCharts;
    if (loading) {
      return <p>Loading...</p>;
    } if (error) {
      return <p>Error Loading Charts!</p>;
    }
    return (
      <div>
        <label>{this.props.label}</label>

        <Field
          onChange={this.handleChartChange}
          name={this.props.fieldName}
          component={renderSelectTree}
          data={charts}
          valueField="id"
          textField="name"
          multiple={this.props.multiple}
          normalize={this.normalizeSelectArray}
        />
      </div>
    );
  }
}

ChartSelect.defaultProps = {
  label: 'Charts',
  fieldName: 'charts',
  multiple: true,
  onChange: () => {},
};
function mapStateToProps({ auth }) {
  return { auth };
}
export default compose(
  connect(mapStateToProps),
  graphql(ChartSelectQuery, {
    name: 'ChartSelectQuery',
  }),
)(ChartSelect);
