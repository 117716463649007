import { gql } from '@apollo/client';
import { locationAllFragment } from '../fragments';

const LocationGridQuery = gql`
  query LocationGridQuery {
    editableLocations {
      ...locationAll
    }
  }
  ${locationAllFragment}
`;

const LocationServicePlanGridQuery = gql`
  query LocationServicePlanGridQuery {
    editableLocations {
      id
      name
      company {
        id
        name
      }
      servicePlan
      serviceExpirationDate
    }
  }
`;

const LocationQuery = gql`
  query location($id: ID!) {
    location(id: $id) {
      ...locationAll
    }
  }
  ${locationAllFragment}
`;

const LocationsByCompanyQuery = gql`
  query visibleLocationsByCompany($companyId: ID!) {
    visibleLocationsByCompany(companyId: $companyId) {
      name
      id
      company {
        id
        name
      }
      latitude
      longitude
    }
  }
`;
const LocationSelectQuery = gql`
  query CompanyLocationQuery {
    visibleCompanies {
      id
      name
      visibleLocations {
        id
        name
      }
    }
  }
`;
const LocationEditableSelectQuery = gql`
  query LocationEditableSelectQuery {
    editableLocations {
      id
      name
      company {
        id
        name
      }
    }
  }
`;
const LocationDropdownQuery = gql`
  query LocationDropdownQuery {
    visibleLocations {
      id
      name
    }
  }
`;

const LocationByPinQuery = gql`
  query locationByPin($pin: String!) {
    locationByPin(pin: $pin) {
      ...locationAll
    }
  }
  ${locationAllFragment}
`;

export {
  LocationDropdownQuery,
  LocationsByCompanyQuery,
  LocationGridQuery,
  LocationQuery,
  LocationSelectQuery,
  LocationByPinQuery,
  LocationServicePlanGridQuery,
  LocationEditableSelectQuery,
};
