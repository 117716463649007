import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import { reduxForm, Field } from 'redux-form';
import CSVFileInput from '../form/CSVFileInput';
import FormSubmitButton from '../form/FormSubmitButton';
import ClearButton from '../form/ClearButton';

class AttributeImportForm extends Component {
  render() {
    console.log(this.props);
    const {
      handleSubmit, pristine, reset, submitting,
    } = this.props;
    return (
      <div>
        <form onSubmit={handleSubmit}>
          <Field
            name="uploadedData"
            onChange={this.props.handleFileLoaded}
            onError={this.props.handleFileError}
            component={CSVFileInput}
          />
          <br />

          <div>
            <FormSubmitButton pristine={pristine} submitting={submitting} />
            <ClearButton
              pristine={pristine}
              submitting={submitting}
              reset={reset}
            />
          </div>
        </form>
        <Typography variant="h5">Instructions</Typography>
        <div>
          Upload a csv file to create new attributes.
          <br />
          <strong>Required Columns:</strong>
          <ul>
            <li>name</li>
            <li>key</li>
            <li>dataType_name: Name of the dataType this attribute belongs to</li>
            <li>rule: Options are: String, Number, Date, Boolean, Mixed, Array</li>
          </ul>
          <br />
          <strong>Optional Columns:</strong>
          <ul>
            <li>units</li>
            <li>round</li>
            <li>color</li>
            <li>factor</li>
            <li>offset</li>
            <li>legacy_id</li>
            <li>modelType: defaults to Device. Options are: Device or Asset</li>
            <li>axis: defaults to 1. Options are: 1 or 2</li>
            <li>cumulative: defaults to false</li>
            <li>adminOnly: defaults to false</li>
          </ul>
        </div>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};
  return errors;
};

export default reduxForm({
  validate,
  form: 'AttributeImport',
})(AttributeImportForm);
