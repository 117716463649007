import { gql } from '@apollo/client';
import { chartFragment } from '../fragments';

const CreateChartMutation = gql`
  mutation createChart($chart: ChartInput!) {
    createDefaultChart(chart: $chart) {
      ...chart
    }
  }
  ${chartFragment}
`;
const UpdateChartMutation = gql`
  mutation updateChart($chart: ChartInput!) {
    updateChart(chart: $chart) {
      ...chart
    }
  }
  ${chartFragment}
`;

const DeleteChartMutation = gql`
  mutation deleteChart($id: ID!) {
    deleteChart(id: $id) {
      id
      name
    }
  }
`;
export { CreateChartMutation, UpdateChartMutation, DeleteChartMutation };
