import React, { Component } from 'react';
import TagsInput from 'react-tagsinput';

export default class renderTagInputField extends Component {
  constructor(props) {
    super(props);
    this.ignoreArrayChanged = this.ignoreArrayChanged.bind(this);
  }

  ignoreArrayChanged(ignoreArray) {
    this.props.input.onChange(ignoreArray);
  }

  render() {
    const { input, label } = this.props;
    return (
      <div>
        <label>{label}</label>
        <div>
          <TagsInput
            value={input.value || []}
            onChange={this.ignoreArrayChanged}
            onlyUnique
          />
        </div>
      </div>
    );
  }
}
