import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { Field } from 'redux-form';
import renderDropdownList from '../../form/renderDropdownList';
import { DeviceConfigDropdownQuery } from '../../../graphql/queries/DeviceConfigQueries';

class DeviceConfigDropdown extends Component {
  constructor(props) {
    super(props);
    this.handleDeviceConfigChange = this.handleDeviceConfigChange.bind(this);
  }

  handleDeviceConfigChange(e, nv, pv) {
    this.props.onDropdownChange(e, nv, pv);
  }

  render() {
    console.log('dropdown', this.props);
    if (this.props.data.loading) {
      return <p>Loading...</p>;
    } if (this.props.data.error) {
      return <p>Error Loading Device Configs!</p>;
    }
    return (
      <div>
        <label>{this.props.label}</label>
        <Field
          name={this.props.fieldName}
          onChange={this.handleDeviceConfigChange}
          component={renderDropdownList}
          data={this.props.data.deviceConfigs}
          valueField="id"
          textField="name"
          canBeNull={this.props.canBeNull}
        />
      </div>
    );
  }
}

DeviceConfigDropdown.defaultProps = {
  onDropdownChange: () => {},
  canBeNull: true,
};

export default graphql(DeviceConfigDropdownQuery)(DeviceConfigDropdown);
