import { gql } from '@apollo/client';

const DeviceReplacementMutation = gql`
  mutation replaceDevice($deviceReplacement: DeviceReplacementInput!) {
    replaceDevice(deviceReplacement: $deviceReplacement) {
      responseValue
    }
  }
`;

export { DeviceReplacementMutation };
