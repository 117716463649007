import React, { Component } from 'react';
import { Field } from 'redux-form';
import renderDropdownList from '../form/renderDropdownList';

const MODELTYPES = [
  { id: 'Device', name: 'Device', type: 'text' },
  { id: 'Asset', name: 'Asset', type: 'text' },
];
class ModelTypeDropdown extends Component {
  constructor(props) {
    super(props);
    this.handleModelTypeChange = this.handleModelTypeChange.bind(this);
  }

  handleModelTypeChange(e) {
    this.props.onDropdownChange(e);
  }

  render() {
    return (
      <div>
        <label>{this.props.label}</label>
        <Field
          name={this.props.fieldName}
          onChange={this.handleModelTypeChange}
          component={renderDropdownList}
          data={MODELTYPES}
          valueField="id"
          textField="name"
          canBeNull={false}
        />
      </div>
    );
  }
}

ModelTypeDropdown.defaultProps = {
  onDropdownChange: () => {},
};

export default ModelTypeDropdown;
