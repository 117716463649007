import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import { Field } from 'redux-form';
import { CompanyEditableSelectQuery } from '../../graphql/queries/CompanyQueries';
import renderSelectTree from '../form/renderSelectTree';

class CompanySelect extends Component {
  constructor(props) {
    super(props);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
  }

  handleCompanyChange(e) {
    this.props.onChange(e);
  }

  render() {
    const { CompanyEditableSelectQuery: { loading, error, editableCompanies } } = this.props;

    if (loading) {
      return <p>Loading companies...</p>;
    } if (error) {
      return <p>Error Loading Companies!</p>;
    }
    return (
      <div>
        <label>{this.props.label}</label>

        <Field
          onChange={this.handleCompanyChange}
          name={this.props.fieldName}
          component={renderSelectTree}
          data={editableCompanies}
          valueField="id"
          textField="name"
        />
      </div>
    );
  }
}

CompanySelect.defaultProps = {
  label: 'Companies',
  fieldName: 'companies',
  onChange: () => {},
};
function mapStateToProps({ auth }) {
  return { auth };
}
export default compose(
  connect(mapStateToProps),
  graphql(CompanyEditableSelectQuery, {
    name: 'CompanyEditableSelectQuery',
  }),
)(CompanySelect);
