import { gql } from '@apollo/client';
import { userBasicFragment } from '../fragments';

export const CreateCompanyUserMutation = gql`
  mutation createCompanyUser($companyUser: CompanyUserInput!) {
    createCompanyUser(companyUser: $companyUser) {
      id
      company {
        id
        name
      }
      user {
        ...userBasic
      }
    }
  }
  ${userBasicFragment}
`;

export const DeleteCompanyUserMutation = gql`
  mutation deleteCompanyUser($id: ID!) {
    deleteCompanyUser(id: $id) {
      id
    }
  }
`;

export const CreateLocationUserMutation = gql`
  mutation createLocationUser($locationUser: LocationUserInput!) {
    createLocationUser(locationUser: $locationUser) {
      id
      location {
        id
        name
      }
      user {
        ...userBasic
      }
    }
  }
  ${userBasicFragment}
`;

export const DeleteLocationUserMutation = gql`
  mutation deleteLocationUser($id: ID!) {
    deleteLocationUser(id: $id) {
      id
    }
  }
`;

export const CreateAssetUserMutation = gql`
  mutation createAssetUser($assetUser: AssetUserInput!) {
    createAssetUser(assetUser: $assetUser) {
      id
      asset {
        id
        name
      }
      user {
        ...userBasic
      }
    }
  }
  ${userBasicFragment}
`;

export const DeleteAssetUserMutation = gql`
  mutation deleteAssetUser($id: ID!) {
    deleteAssetUser(id: $id) {
      id
    }
  }
`;
