import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';

import DeviceConfigForm from './DeviceConfigForm';
import FormBase from '../../form/FormBase';
import { CreateDeviceConfigMutation } from '../../../graphql/mutations/DeviceConfigMutations';
import {
  DeviceConfigGridQuery,
  DeviceConfigDropdownQuery,
} from '../../../graphql/queries/DeviceConfigQueries';
import handleDeviceConfigMutation from '../../../graphql/functions/handleDeviceConfigMutation';

class DeviceConfigNew extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    handleDeviceConfigMutation({
      values,
      mutation: this.props.createDeviceConfigMutation,
      onSuccess: this.props.onSuccess,
      refetchQueries: [
        { query: DeviceConfigGridQuery },
        { query: DeviceConfigDropdownQuery },
      ],
    });
  }

  render() {
    return (
      <FormBase header="Add Device Config">
        <DeviceConfigForm onSubmit={this.handleSubmit} />
      </FormBase>
    );
  }
}

export default compose(
  graphql(CreateDeviceConfigMutation, { name: 'createDeviceConfigMutation' }),
)(DeviceConfigNew);
