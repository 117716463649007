import { combineReducers } from 'redux';
import { reducer as reduxForm } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';
import authReducer from './authReducer';
import mfaReducer from './mfaReducer';
import isFetchingUserReducer from './isFetchingUserReducer';
import isFetchingMfaReducer from './isFetchingMfaReducer';

export default combineReducers({
  auth: authReducer,
  mfaVerified: mfaReducer,
  form: reduxForm,
  toastr: toastrReducer,
  isFetchingUser: isFetchingUserReducer,
  isFetchingMfa: isFetchingMfaReducer,
});
