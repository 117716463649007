import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import ModelDashboardForm from './ModelDashboardForm';
import FormBase from '../form/FormBase';
import handleMutation from '../../graphql/functions/handleMutation';
import {
  ModelDashboardGridQuery,
  ModelDashboardQuery,
} from '../../graphql/queries/ModelDashboardQueries';
import { UpdateModelDashboardMutation } from '../../graphql/mutations/ModelDashboardMutations';

class ModelDashboardUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    // store modelDashboard id
    if (typeof values === 'object') {
      values.id = this.props.modelDashboard.id;
    }
    console.log('this.props modelDashboard update', this.props);
    handleMutation({
      mutate: this.props.mutate,
      variables: { modelDashboard: values },
      refetchQueries: [{ query: ModelDashboardGridQuery }],
      onSuccess: this.props.onSuccess,
    });
  }

  render() {
    console.log('this.props', this.props);
    const {
      ModelDashboardQuery: { loading, error, modelDashboard },
    } = this.props;
    return (
      <FormBase loading={loading} error={error} header="Edit Model Dashboard">
        <ModelDashboardForm
          initialValues={modelDashboard}
          onSubmit={this.handleSubmit}
        />
      </FormBase>
    );
  }
}

export default compose(
  graphql(UpdateModelDashboardMutation, {
    options: (props) => ({ variables: { id: props.modelDashboard.id } }),
  }),
  graphql(ModelDashboardQuery, {
    name: 'ModelDashboardQuery',
    options: (props) => ({ variables: { id: props.modelDashboard.id }, fetchPolicy: 'network-only' }),
  }),
)(ModelDashboardUpdate);
