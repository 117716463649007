import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import GridBase from '../../common/GridBase';
import { DeviceGroupGridQuery } from '../../../graphql/queries/DeviceGroupQueries';
import ContextMenuWithTrigger from '../../common/ContextMenuWithTrigger';
import Modal from '../../common/Modal';
import DeviceGroupUpdate from './DeviceGroupUpdate';
import DeviceGroupDeviceList from './DeviceGroupDeviceList';
import handleContextMenuClick from '../../common/handleContextMenuClick';
import { DeleteDeviceGroupMutation } from '../../../graphql/mutations/DeviceGroupMutations';
import DeleteModel from '../../common/DeleteModel';

class DeviceGroupGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      modalTitle: '',
      modalType: '',
      deviceGroup: null,
      delete: { id: null, name: '' },
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleDeleteClose = this.handleDeleteClose.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  columns = [
    {
      Header: 'ID',
      accessor: 'id',
      maxWidth: 90,
      filterable: true,
    },
    {
      Header: 'Name',
      accessor: 'name',
      filterable: true,
      minWidth: 70,
    },
    {
      Header: 'Device Type',
      id: 'deviceType',
      accessor: (d) => (d.deviceType ? d.deviceType.name : null),
      filterable: true,
    },
    {
      Header: '',
      maxWidth: 23,
      resizable: false,
      sortable: false,
      Cell: ({ row }) => ContextMenuWithTrigger({
        handleClick: this.handleClick,
        row,
        additionalOptions: [
          {
            key: 'listDevices',
            label: 'show all devices',
            action: 'DeviceGroupDevices',
          },
        ],
      }),
    },
  ];

  // handle context menu clicks
  handleClick(e, data, target) {
    const modelName = 'deviceGroup';
    const stateUpdate = handleContextMenuClick({
      e, data, target, modelName,
    });
    this.setState({ ...stateUpdate });
  }

  // reset delete deviceGroup id and name to null upon deletion
  handleDeleteClose() {
    this.setState({ delete: { id: null, name: '' } });
  }

  render() {
    const {
      data: { loading, error, deviceGroups },
    } = this.props;
    const sorting = [{ id: 'name', asc: true }];
    if (loading) {
      return <p>Loading...</p>;
    } if (error) {
      return <p>Error loading device groups!</p>;
    }
    return (
      <div>
        <GridBase
          dataVar={deviceGroups}
          columns={this.columns}
          sorting={sorting}
        />

        <DeleteModel
          deleteMutation={DeleteDeviceGroupMutation}
          refetchQueries={[{ query: DeviceGroupGridQuery }]}
          id={this.state.delete.id}
          name={this.state.delete.name}
          onClose={this.handleDeleteClose}
        />

        <Modal
          onClose={this.closeModal}
          modalIsOpen={this.state.modalIsOpen}
          contentLabel={this.state.modalTitle}
        >
          {this.state.modalType && this.state.modalType === 'DeviceGroupDevices' ? (
            <DeviceGroupDeviceList
              deviceGroup={this.state.deviceGroup}
            />
          ) : (
            <DeviceGroupUpdate deviceGroup={this.state.deviceGroup} />
          )}
        </Modal>
      </div>
    );
  }
}

export default graphql(DeviceGroupGridQuery)(DeviceGroupGrid);
