import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../form/FormField';
import FormSubmitButton from '../../form/FormSubmitButton';
import ClearButton from '../../form/ClearButton';

class DataTypeForm extends Component {
  render() {
    const {
      handleSubmit, pristine, reset, submitting, initialValues,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <Field
          name="name"
          type="text"
          component={FormField}
          label="Data Type Name"
        />
        <div data-tip="Attribute key for database. Once added this cannot be changed.">
          <Field
            props={{ disabled: !!initialValues }}
            name="key"
            type="text"
            component={FormField}
            label="Data Type Key"
          />
        </div>
        <div>
          <FormSubmitButton pristine={pristine} submitting={submitting} />
          <ClearButton
            pristine={pristine}
            submitting={submitting}
            reset={reset}
          />
        </div>
      </form>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Cannot be blank';
  }
  if (!values.key) {
    errors.key = 'Cannot be blank';
  }
  return errors;
};

export default reduxForm({
  validate,
  form: 'DataType',
})(DataTypeForm);
