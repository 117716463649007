import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../form/FormField';
import renderCheckbox from '../../form/renderCheckbox';
import FormSubmitButton from '../../form/FormSubmitButton';
import ClearButton from '../../form/ClearButton';

class Ap2200FirmwareForm extends Component {
  render() {
    const {
      handleSubmit, pristine, reset, submitting,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <Field
          name="majorVersion"
          type="number"
          parse={(value) => (!value ? null : Number(value))}
          component={FormField}
          label="Major Version"
        />
        <Field
          name="version"
          type="text"
          component={FormField}
          label="Version"
        />
        <Field
          name="isDefaultLatest"
          label="Is Default Latest"
          component={renderCheckbox}
        />
        <div>
          <FormSubmitButton pristine={pristine} submitting={submitting} />
          <ClearButton
            pristine={pristine}
            submitting={submitting}
            reset={reset}
          />
        </div>
      </form>
    );
  }
}

Ap2200FirmwareForm.defaultProps = {
  initialValues: null,
};
const validate = (values) => {
  const errors = {};
  if (!values.majorVersion && values.majorVersion !== 0) {
    errors.majorVersion = 'Cannot be blank';
  }
  if (!values.version) {
    errors.version = 'Cannot be blank';
  }
  return errors;
};

Ap2200FirmwareForm = reduxForm({ validate, form: 'Ap2200Firmware' })(
  Ap2200FirmwareForm,
);

export default Ap2200FirmwareForm;
