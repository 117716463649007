import { gql } from '@apollo/client';
import { eventFragment } from '../fragments';

const EventQuery = gql`
  query event($id: ID!) {
    event(id: $id) {
      ...event
    }
  }
  ${eventFragment}
`;

const EventGridQuery = gql`
  query EventGridQuery {
    events {
      ...event
    }
  }
  ${eventFragment}
`;

export { EventGridQuery, EventQuery };
