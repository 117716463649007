import { gql } from '@apollo/client';
import { deviceGroupFragment } from '../fragments';

const CreateDeviceGroupMutation = gql`
  mutation createDeviceGroup($deviceGroup: DeviceGroupInput!) {
    createDeviceGroup(deviceGroup: $deviceGroup) {
      ...deviceGroup
    }
  }
  ${deviceGroupFragment}
`;
const UpdateDeviceGroupMutation = gql`
  mutation updateDeviceGroup($deviceGroup: DeviceGroupInput!) {
    updateDeviceGroup(deviceGroup: $deviceGroup) {
      ...deviceGroup
    }
  }
  ${deviceGroupFragment}
`;

const DeleteDeviceGroupMutation = gql`
  mutation deleteDeviceGroup($id: ID!) {
    deleteDeviceGroup(id: $id) {
      id
      name
    }
  }
`;

const AddDeviceToGroupsMutation = gql`
  mutation addDeviceToGroups($deviceGroupDeviceInput: DeviceGroupDeviceInput!) {
    addDeviceToGroups(deviceGroupDeviceInput: $deviceGroupDeviceInput) {
      responseValue
    }
  }
`;
const RemoveDeviceFromGroupsMutation = gql`
  mutation removeDeviceFromGroups($deviceGroupDeviceInput: DeviceGroupDeviceInput!) {
    removeDeviceFromGroups(deviceGroupDeviceInput: $deviceGroupDeviceInput) {
      responseValue
    }
  }
`;
export {
  CreateDeviceGroupMutation, UpdateDeviceGroupMutation, DeleteDeviceGroupMutation,
  AddDeviceToGroupsMutation, RemoveDeviceFromGroupsMutation,
};
