// redux
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { compose, createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';

// apollo
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client/';
import { BatchHttpLink } from '@apollo/client/link/batch-http';

import App from './components/App';
import reducers from './reducers';
import { REACT_APP_BACKEND } from './config';

// ------------------ react-redux ------------------
function logger({ getState }) {
  return (next) => (action) => {
    // console.log('will dispatch', action);
    // Call the next dispatch method in the middleware chain.
    const returnValue = next(action);
    // console.log('state after dispatch', getState());
    // This will likely be the action itself, unless
    // a middleware further in chain changed it.
    return returnValue;
  };
}
const store = createStore(
  reducers,
  {},
  compose(
    applyMiddleware(reduxThunk, logger),
  ),
);

// ------------------ apollo ------------------
const graphqlUrl = `${REACT_APP_BACKEND}/graphql`;
/* const link = createHttpLink({
  uri: graphqlUrl,
  credentials: 'include',
});
 */
const link = new BatchHttpLink({
  batchMax: 100,
  uri: graphqlUrl,
  credentials: 'include',
});
/* const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
}); */

const client = new ApolloClient({
  // link: ApolloLink.from([errorLink, link]),
  link,
  cache: new InMemoryCache(),
});

// ------------------ render dom -------------------
ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <App />
    </Provider>
  </ApolloProvider>,
  document.querySelector('#root'),
);
