import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import { connect } from 'react-redux';
import CompanyForm from './CompanyForm';
import FormBase from '../form/FormBase';
import handleMutation from '../../graphql/functions/handleMutation';
import {
  CompanyGridQuery,
  CompanyQuery,
} from '../../graphql/queries/CompanyQueries';
import {
  UpdateCompanyMutation,
  CreateCompanyMutation,
} from '../../graphql/mutations/CompanyMutations';

class CompanyUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    if (typeof values === 'object') {
      // remove locations from update model
      if (values.locations) {
        delete values.locations;
      }
      let mutation;
      if (this.props.duplicate) {
        delete values.id;
        mutation = this.props.CreateCompanyMutation;
      } else {
        values.id = this.props.company.id;
        mutation = this.props.UpdateCompanyMutation;
      }
      if (this.props.auth && this.props.auth.obfuscate === true) {
        console.log('this.props.auth', this.props.auth);
        delete values.name;
      }

      handleMutation({
        mutate: mutation,
        variables: { company: values },
        refetchQueries: [{ query: CompanyGridQuery }],
        onSuccess: this.props.onSuccess,
      });
    }
  }

  render() {
    const {
      data: { loading, error, company },
    } = this.props;
    return (
      <FormBase loading={loading} error={error} header={this.props.header}>
        <CompanyForm initialValues={company} onSubmit={this.handleSubmit} />
      </FormBase>
    );
  }
}

CompanyUpdate = compose(
  graphql(UpdateCompanyMutation, {
    name: 'UpdateCompanyMutation',
    options: (props) => ({ variables: { id: props.company.id } }),
  }),
  graphql(CreateCompanyMutation, {
    name: 'CreateCompanyMutation',
  }),
  graphql(CompanyQuery, {
    options: (props) => ({ variables: { id: props.company.id }, fetchPolicy: 'network-only' }),
  }),
)(CompanyUpdate);

function mapStateToProps({ auth }) {
  return { auth };
}
export default connect(mapStateToProps)(CompanyUpdate);
