import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Modal from '../../common/Modal';
import DeviceGridMultiSelect from '../DeviceGridMultiSelect';
import DeviceGroupDeviceSelect from './DeviceGroupDeviceSelect';
import { DevicesByDeviceTypeQuery } from '../../../graphql/queries/DeviceQueries';

class DeviceGroupEditDevices extends Component {
  constructor(props) {
    super(props);
    let initialDevices = [];
    if (props && props.initialDevices) {
      initialDevices = props.initialDevices;
    }
    this.state = {
      modalIsOpen: false, // when this switches it also triggers clearing of selected in grid
      deviceTypeId: null,
      devicesInGroup: initialDevices,
      newDevicesToAdd: [],
      devicesToRemove: [],
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.clearDevices = this.clearDevices.bind(this);
    this.updateAddNewSelectedDevices = this.updateAddNewSelectedDevices.bind(this);
    this.addDevices = this.addDevices.bind(this);
    this.removeDevices = this.removeDevices.bind(this);
    this.onSubmitAddDevices = this.onSubmitAddDevices.bind(this);
    this.updateRemoveSelectedDevices = this.updateRemoveSelectedDevices.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  onSubmitAddDevices() {
    this.setState({ modalIsOpen: false });
    this.addDevices();
  }

  clearDevices() {
    this.setState({ devicesInGroup: [] });
    this.props.onChangeDeviceList([]);
  }

  addDevices() {
    const deviceIds = this.state.newDevicesToAdd;
    if (deviceIds.length > 0) {
      let combinedDevices = [];
      if (this.state.devicesInGroup.length === 0) {
        combinedDevices = deviceIds.map((id) => this.props.DevicesQuery.devicesByDeviceType.find((d) => d.id === id));
      } else {
        combinedDevices = [...this.state.devicesInGroup];
        deviceIds.forEach((deviceId) => {
          if (this.state.devicesInGroup.findIndex((d) => d.id === deviceId) === -1) {
            combinedDevices.push(this.props.DevicesQuery.devicesByDeviceType.find((d) => d.id === deviceId));
          }
        });
      }
      this.setState({ devicesInGroup: combinedDevices });
      this.props.onChangeDeviceList(combinedDevices);
    }
  }

  removeDevices() {
    const deviceIds = this.state.devicesToRemove;
    if (deviceIds.length > 0) {
      this.setState((prevState) => {
        const devicesWithoutRemoved = prevState.devicesInGroup.filter((d) => deviceIds.indexOf(d.id) === -1);
        this.props.onChangeDeviceList(devicesWithoutRemoved);
        return { devicesInGroup: devicesWithoutRemoved };
      });
    }
  }

  updateAddNewSelectedDevices(deviceIds) {
    this.setState({ newDevicesToAdd: deviceIds });
  }

  updateRemoveSelectedDevices(deviceIds) {
    this.setState({ devicesToRemove: deviceIds });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deviceTypeId !== this.props.deviceTypeId) {
      this.clearDevices();
    }
  }

  render() {
    if (this.props.DevicesQuery) {
      const {
        DevicesQuery: { loading, error, devicesByDeviceType },
      } = this.props;
      if (loading) {
        return <p>{`Loading all ${this.props.deviceTypeName} devices...`}</p>;
      } if (error) {
        return <p>Error loading devices!</p>;
      }
      return (
        <div>
          <Typography variant="h5">Devices</Typography>
          <Button onClick={this.openModal}>Add Devices</Button>
          <Button onClick={this.removeDevices}>Remove Selected Devices</Button>
          <Button onClick={this.clearDevices}>Clear All Devices</Button>
          <DeviceGridMultiSelect
            devices={this.state.devicesInGroup}
            updateSelectedDevices={this.updateRemoveSelectedDevices}
            clearSelectedDevices={!this.state.modalIsOpen}
          />
          <Modal
            onClose={this.closeModal}
            showSubmit
            onSubmit={this.onSubmitAddDevices}
            modalIsOpen={this.state.modalIsOpen}
          >
            <DeviceGroupDeviceSelect
              devices={devicesByDeviceType}
              updateSelectedDevices={this.updateAddNewSelectedDevices}
            />
          </Modal>
        </div>
      );
    }
    return (
      <div />
    );
  }
}

DeviceGroupEditDevices.defaultProps = {
};

export default compose(
  graphql(DevicesByDeviceTypeQuery, {
    name: 'DevicesQuery',
    skip: (ownProps) => !ownProps.deviceTypeId,
    options: (props) => ({
      variables: { deviceTypeId: props.deviceTypeId },
      // fetchPolicy: 'network-only',
    }),
  }),
)(DeviceGroupEditDevices);
