import React, { Component } from 'react';
import { Field } from 'redux-form';
import renderDropdownList from '../form/renderDropdownList';

const SCALARS = [
  { id: 'Number', name: 'Number', type: 'text' },
  { id: 'String', name: 'String', type: 'text' },
  { id: 'Boolean', name: 'boolean', type: 'text' },
  { id: 'Date', name: 'Date', type: 'text' },
  { id: 'Array', name: 'Array', type: 'text' },
  { id: 'Mixed', name: 'Mixed', type: 'text' },
  { id: 'ObjectId', name: 'ObjectId', type: 'text' },
];
class ScalarTypeDropdown extends Component {
  constructor(props) {
    super(props);
    this.handleScalarTypeChange = this.handleScalarTypeChange.bind(this);
  }

  handleScalarTypeChange(e) {
    this.props.onDropdownChange(e);
  }

  render() {
    return (
      <div>
        <label>{this.props.label}</label>
        <Field
          name={this.props.fieldName}
          onChange={this.handleScalarTypeChange}
          component={renderDropdownList}
          data={SCALARS}
          valueField="id"
          textField="name"
          canBeNull={false}
        />
      </div>
    );
  }
}

ScalarTypeDropdown.defaultProps = {
  onDropdownChange: () => {},
};

export default ScalarTypeDropdown;
