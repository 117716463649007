import React from 'react';
import { Field } from 'redux-form';
import moment from 'moment';
import renderDatePicker from '../form/renderDatePicker';
import ServicePlanDropdown from './ServicePlanDropdown';

export default [
  {
    id: 'servicePlan',
    name: 'Service Plan',
    field: (
      <ServicePlanDropdown showSuperAdmin />
    ),
  },
  {
    id: 'serviceExperationDate',
    name: 'Expiration Date',
    field: (
      <>
        <label>Plan Expiration Date</label>
        <Field
          name="serviceExpirationDate"
          component={renderDatePicker}
          label="Expiration Date"
          normalize={(value) => {
            console.log('normalize VALUE', value);
            if (value) {
              let retVal = moment(value).unix();
              console.log('normalized 1', retVal);
              retVal *= 1000;
              console.log('normalized 2', retVal);
              return retVal;
            }
            return null;
          }}
        />
      </>
    ),
  },
];
