import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import InstallerConfigFieldForm from './InstallerConfigFieldForm';
import FormBase from '../../../form/FormBase';
import handleInstallerConfigFieldMutation from '../../../../graphql/functions/handleInstallerConfigFieldMutation';
import {
  CreateInstallerConfigFieldMutation,
  UpdateInstallerConfigFieldMutation,
} from '../../../../graphql/mutations/InstallerConfigMutations';
import {
  InstallerConfigFieldQuery,
  InstallerConfigFieldGridQuery,
} from '../../../../graphql/queries/InstallerConfigQueries';

class InstallerConfigFieldUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [], modalIsOpen: false };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    if (typeof values === 'object') {
      let mutation;
      if (this.props.duplicate) {
        delete values.id;
        mutation = this.props.CreateInstallerConfigFieldMutation;
      } else {
        values.id = this.props.installerConfigField.id;
        mutation = this.props.UpdateInstallerConfigFieldMutation;
      }

      handleInstallerConfigFieldMutation({
        values,
        mutation,
        refetchQueries: [{ query: InstallerConfigFieldGridQuery }],
        onSuccess: this.props.onSuccess,
      });
    }
  }

  render() {
    console.log(this.props);
    // render form with installerConfigField values pre-loaded
    const {
      InstallerConfigFieldQuery: {
        loading,
        error,
        installerConfigField,
        submitting,
      },
    } = this.props;

    console.log(this.props);
    return (
      <FormBase
        loading={loading}
        submitting={submitting}
        error={error}
        header="Edit Installer Configuration Field"
      >
        <InstallerConfigFieldForm
          initialValues={installerConfigField}
          onSubmit={this.handleSubmit}
        />
      </FormBase>
    );
  }
}

export default compose(
  graphql(UpdateInstallerConfigFieldMutation, {
    name: 'UpdateInstallerConfigFieldMutation',
    options: (props) => ({ variables: { id: props.installerConfigField.id } }),
  }),
  graphql(CreateInstallerConfigFieldMutation, {
    name: 'CreateInstallerConfigFieldMutation',
  }),
  graphql(InstallerConfigFieldQuery, {
    name: 'InstallerConfigFieldQuery',
    options: (props) => ({ variables: { id: props.installerConfigField.id }, fetchPolicy: 'network-only' }),
  }),
)(InstallerConfigFieldUpdate);
