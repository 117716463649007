import React, { Component } from 'react';
import DataLogGrid from './DataLogGrid';
import PSCard from '../common/PSCard';

class DataLogMain extends Component {
  render() {
    return (
      <div>
        <PSCard cardTitle="Data Logs">
          <DataLogGrid />
        </PSCard>
      </div>
    );
  }
}

export default DataLogMain;
