import React from 'react';
import CompanyDropdown from '../company/CompanyDropdown';
import UserRoleDropdown from '../form/UserRoleDropdown';

export default [
  {
    id: 'userRole',
    name: 'User Role',
    field: (
      <UserRoleDropdown showSuperAdmin />
    ),
  },
  {
    id: 'company',
    name: 'Company',
    field: (
      <CompanyDropdown fieldName="company" label="Company" />
    ),
  },
];
