import React, { Component } from 'react';
import FixedActionButton from '../../common/FixedActionButton';
import AttributeGrid from './AttributeGrid';
import AttributeTypeGrid from './attributeType/AttributeTypeGrid';
import Modal from '../../common/Modal';
import PSCard from '../../common/PSCard';
import AttributeNew from './AttributeNew';
import AttributeTypeNew from './attributeType/AttributeTypeNew';

class AttributeMain extends Component {
  constructor() {
    super();
    this.state = { modalIsOpen: false };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  modalOptions = [
    {
      onClick: () => {
        this.openModal('Attribute Type');
      },
      title: 'Add Attribute Type',
      name: 'attributeType',
    },
    {
      onClick: () => {
        this.openModal('Attribute');
      },
      title: 'Add Attribute',
      name: 'attribute',
    },
  ];

  openModal(type) {
    this.setState({ modalIsOpen: true, modalType: type });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    return (
      <div>
        <PSCard cardTitle="Attributes">
          <AttributeGrid />
        </PSCard>
        <PSCard cardTitle="Attribute Types">
          <AttributeTypeGrid />
        </PSCard>

        <div>
          <FixedActionButton options={this.modalOptions} />
        </div>
        <Modal
          onClose={this.closeModal}
          modalIsOpen={this.state.modalIsOpen}
          contentLabel={`Add ${this.state.modalType}`}
        >
          {(() => {
            switch (this.state.modalType) {
              case 'Attribute Type':
                return <AttributeTypeNew />;
              case 'Attribute':
                return <AttributeNew />;
              default:
                return <AttributeNew />;
            }
          })()}
        </Modal>
      </div>
    );
  }
}

export default AttributeMain;
