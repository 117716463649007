import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import moment from 'moment';
import renderDatePicker from '../../form/renderDatePicker';
import FormSubmitButton from '../../form/FormSubmitButton';
import DeviceGridSelect from '../DeviceGridSelect';
import ClearButton from '../../form/ClearButton';
import renderDropdownList from '../../form/renderDropdownList';
import { CONST } from '../../common/constants';

class DeviceReplacementForm extends Component {
  constructor(props) {
    super(props);
    let incomingDevice;
    if (props.replacementDevice) {
      incomingDevice = props.replacementDevice;
    } else {
      incomingDevice = props.originalDevice;
    }
    let devices;
    const incomingDeviceId = incomingDevice.deviceType && incomingDevice.deviceType.id ? incomingDevice.deviceType.id : incomingDevice.deviceTypeId;
    if (props.devices && props.devices.length) {
      devices = props.devices.filter((d) => d.deviceType && d.deviceType.id === incomingDeviceId
        && d.id !== incomingDevice.id);
    } else {
      devices = props.devices;
    }
    this.state = {
      devices,
      incomingDevice,
      originalDevice:
        props.originalDevice ? props.originalDevice : null,
      replacementDevice:
        props.replacementDevice ? props.replacementDevice : null,
    };
  }

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      reset,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <br />
        {this.props.replacementDevice && (<label>Old Device to Replace</label>)}
        {this.props.originalDevice && (<label>Replacement Device</label>)}
        <DeviceGridSelect
          devices={this.state.devices}
          setSelectedDevice={this.props.setSelectedDevice}
        />
        <div data-tip="Device was installed on this date">
          <label>Install Date</label>
          <Field
            name="installDate"
            component={renderDatePicker}
            label="Install Date"
            normalize={(value) => {
              if (value) {
                const retVal = moment(value).unix();
                return retVal;
              }
              return null;
            }}
          />
        </div>
        <div data-tip="Merge will combine data from the old and new device. <br /> Delete Old will delete data from the old device.">
          <label>How to handle data</label>
          <Field
            name="handleData"
            label="Handle data"
            component={renderDropdownList}
            data={CONST.HANDLE_DATA_OPTIONS}
            valueField="id"
            textField="name"
            canBeNull={false}
          />
        </div>
        <br />
        <div>
          <FormSubmitButton pristine={pristine} submitting={submitting} />
          <ClearButton
            pristine={pristine}
            submitting={submitting}
            reset={reset}
          />
        </div>
      </form>
    );
  }
}

DeviceReplacementForm.defaultProps = {
  initialValues: null,
};
const validate = (values) => {
  const errors = {};
  if (!values.installDate) {
    errors.installDate = 'Cannot be blank';
  }
  if (!values.handleData) {
    errors.handleData = 'Cannot be blank';
  }
  return errors;
};

DeviceReplacementForm = reduxForm({
  validate,
  form: 'DeviceReplacement',
})(DeviceReplacementForm);

export default DeviceReplacementForm;
