import React from 'react';
import ModelDashboardDropdown from '../modelDashboard/ModelDashboardDropdown';

export default [
  {
    id: 'modelDashboard',
    name: 'Model Dashboard',
    field: (
      <ModelDashboardDropdown
        fieldName="modelDashboard"
        label="Model Dashboard"
        onDropdownChange={() => {}}
      />
    ),
  },
];
