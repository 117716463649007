import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

class ConfirmDialog extends Component {
  // close dialog
  handleClose = () => {
    this.props.onClose();
  };

  // trigger confirm function and close dialog
  handleConfirm = () => {
    this.props.onConfirm();
    this.handleClose();
  };

  render() {
    const actions = [
      <Button key="cancel" onClick={this.handleClose}>
        Cancel
      </Button>,
      <Button key="confirm" onClick={this.handleConfirm}>
        Okay
      </Button>,
    ];

    return (
      <Dialog open={this.props.open} onClose={this.handleClose}>
        <DialogTitle>{this.props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{this.props.message}</DialogContentText>
        </DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    );
  }
}

export default ConfirmDialog;
