import React, { Component } from 'react';
import { connect } from 'react-redux';
import FixedActionButton from '../common/FixedActionButton';
import DeviceGrid from './DeviceGrid';
import DeviceGroupGrid from './deviceGroup/DeviceGroupGrid';
import DeviceTypeGrid from './deviceType/DeviceTypeGrid';
import Modal from '../common/Modal';
import PSCard from '../common/PSCard';
import DeviceNew from './DeviceNew';
import DeviceTypeNew from './deviceType/DeviceTypeNew';
import DeviceConfigGrid from './deviceConfig/DeviceConfigGrid';
import DeviceConfigNew from './deviceConfig/DeviceConfigNew';
import DeviceGroupNew from './deviceGroup/DeviceGroupNew';

class DeviceMain extends Component {
  constructor(props) {
    super(props);

    this.state = { modalIsOpen: false };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  getModalOptions() {
    const modalOptions = [
      {
        onClick: () => {
          this.openModal('Device');
        },
        title: 'Add Device',
        color: 'blue',
        icon: 'add',
        name: 'device',
        size: '',
      },
    ];
    if (this.props.auth && this.props.auth.isSuperAdmin) {
      modalOptions.unshift(
        {
          onClick: () => {
            this.openModal('Device Group');
          },
          title: 'Add Device Group',
          name: 'deviceGroup',
        },
        {
          onClick: () => {
            this.openModal('Device Type');
          },
          title: 'Add Device Type',
          name: 'deviceType',
        },
        {
          onClick: () => {
            this.openModal('Device Config');
          },
          title: 'Add Device Config',
          name: 'deviceConfig',
        },
      );
    }
    return modalOptions;
  }

  openModal(type) {
    this.setState({ modalIsOpen: true, modalType: type });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    return (
      <div>
        <PSCard cardTitle="Devices">
          <DeviceGrid />
        </PSCard>

        {this.props.auth && this.props.auth.isSuperAdmin ? (
          <div>
            <PSCard cardTitle="Device Groups">
              <DeviceGroupGrid />
            </PSCard>
            <PSCard cardTitle="Device Types">
              <DeviceTypeGrid />
            </PSCard>
            <PSCard cardTitle="Device Configurations">
              <DeviceConfigGrid />
            </PSCard>
          </div>
        ) : (
          <div />
        )}

        <div>
          <FixedActionButton options={this.getModalOptions()} />
        </div>
        <Modal
          onClose={this.closeModal}
          modalIsOpen={this.state.modalIsOpen}
          contentLabel={`Add ${this.state.modalType}`}
        >
          {(() => {
            switch (this.state.modalType) {
              case 'Device Type':
                return <DeviceTypeNew />;
              case 'Device':
                return <DeviceNew />;
              case 'Device Config':
                return <DeviceConfigNew />;
              case 'Device Group':
                return <DeviceGroupNew />;
              default:
                return <DeviceNew />;
            }
          })()}
        </Modal>
      </div>
    );
  }
}

function mapStateToProps({ auth }) {
  return { auth };
}
export default connect(mapStateToProps)(DeviceMain);
