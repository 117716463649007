import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import FixedActionButton from '../common/FixedActionButton';
import Ap2200Grid from './Ap2200Grid';
import Ap2200FirmwareGrid from './ap2200Firmware/Ap2200FirmwareGrid';
import Modal from '../common/Modal';
import PSCard from '../common/PSCard';
import DeviceNew from '../device/DeviceNew';
import Ap2200FirmwareNew from './ap2200Firmware/Ap2200FirmwareNew';
import Ap2200FirmwareUpload from './ap2200Firmware/Ap2200FirmwareUpload';

const GOOGLE_CLOUD_URL = 'https://console.cloud.google.com/storage/browser/ap2200-firmware;tab=objects?prefix=';

class Ap2200Main extends Component {
  constructor(props) {
    super(props);

    this.state = { modalIsOpen: false };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  getModalOptions() {
    const modalOptions = [
      {
        onClick: () => {
          this.openModal('firmwareFile', 'Upload AP2200 Firmware File');
        },
        title: 'Upload AP2200 Firmware File',
        name: 'ap2200FirmwareFile',
      },
      {
        onClick: () => {
          this.openModal('ap2200Firmware', 'Add AP2200 Firmware');
        },
        title: 'Add AP2200 Firmware',
        name: 'ap2200Firmware',
      },
      {
        onClick: () => {
          this.openModal('ap2200', 'Add AP2200');
        },
        title: 'Add AP2200',
        name: 'ap2200',
      },
    ];
    return modalOptions;
  }

  openModal(type, title) {
    this.setState({ modalIsOpen: true, modalType: type, modalTitle: title });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  goToGoogleCloud() {
    console.log('goToGoogleCloud');
    window.open(GOOGLE_CLOUD_URL, '_blank'); // to open new page
  }

  render() {
    return (
      <div>
        <PSCard cardTitle="AP2200s">
          <Ap2200Grid />
        </PSCard>
        <PSCard cardTitle="AP2200 Firmware">
          <Ap2200FirmwareGrid />
          <p>
            Go to Google Cloud Storage &nbsp;
            <span>
              <Button
                title="Go to Google Cloud Storage"
                onClick={this.goToGoogleCloud}
              >
                <i className="material-icons">open_in_new</i>
              </Button>
            </span>
          </p>
        </PSCard>
        <FixedActionButton options={this.getModalOptions()} />
        <Modal
          onClose={this.closeModal}
          modalIsOpen={this.state.modalIsOpen}
          contentLabel={this.state.modelTitle}
        >
          {(() => {
            switch (this.state.modalType) {
              case 'firmwareFile':
                return <Ap2200FirmwareUpload closeModal={this.closeModal} />;
              case 'ap2200Firmware':
                return <Ap2200FirmwareNew />;
              default:
                return <DeviceNew />;
            }
          })()}
        </Modal>
      </div>
    );
  }
}

function mapStateToProps({ auth }) {
  return { auth };
}
export default connect(mapStateToProps)(Ap2200Main);
