import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';

import GridBase from '../../common/GridBase';
import ContextMenuWithTrigger from '../../common/ContextMenuWithTrigger';
import Modal from '../../common/Modal';
import InstallerConfigUpdate from './InstallerConfigUpdate';
import { InstallerConfigGridQuery } from '../../../graphql/queries/InstallerConfigQueries';
import { DeleteInstallerConfigMutation } from '../../../graphql/mutations/InstallerConfigMutations';
import DeleteModel from '../../common/DeleteModel';

class InstallerConfigGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      installerConfig: null,
      delete: { id: null, name: '' },
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleDeleteClose = this.handleDeleteClose.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  columns = [
    {
      Header: 'ID',
      accessor: 'id',
      maxWidth: 90,
      filterable: true,
    },
    {
      Header: 'Name',
      id: 'name',
      accessor: (d) => d.name,
      minWidth: 100,
      filterable: true,
    },
    {
      Header: 'Default',
      id: 'default',
      accessor: (d) => (d.default === true ? 'Yes' : 'No'),
      maxWidth: 150,
      filterable: true,
    },
    {
      Header: '',
      maxWidth: 23,
      resizable: false,
      sortable: false,
      Cell: ({ row }) => ContextMenuWithTrigger({
        handleClick: this.handleClick,
        row,
      }),
    },
  ];

  handleClick(e, data, target) {
    if (data.action === 'Edit') {
      this.setState({
        installerConfig: data.row,
        modalIsOpen: true,
      });
    } else if (data.action === 'Delete') {
      console.log('delete data', data);
      this.setState({
        delete: {
          id: data.row.id,
          name: data.row.name,
        },
      });
    }
  }

  // reset delete installerConfig id and name to null upon deletion
  handleDeleteClose() {
    this.setState({ delete: { id: null, name: '' } });
  }

  render() {
    const {
      data: { loading, error, installerConfigs },
    } = this.props;
    const sorting = [{ id: 'name', asc: true }];
    if (loading) {
      return <p>Loading...</p>;
    } if (error) {
      return <p>Error loading Installer Configurations!</p>;
    }
    return (
      <div>
        <GridBase
          dataVar={installerConfigs}
          columns={this.columns}
          sorting={sorting}
        />

        <DeleteModel
          deleteMutation={DeleteInstallerConfigMutation}
          refetchQueries={[{ query: InstallerConfigGridQuery }]}
          id={this.state.delete.id}
          name={this.state.delete.name}
          onClose={this.handleDeleteClose}
        />

        <Modal
          onClose={this.closeModal}
          modalIsOpen={this.state.modalIsOpen}
          contentLabel="Edit Installer Configuration"
        >
          <InstallerConfigUpdate
            installerConfig={this.state.installerConfig}
          />
        </Modal>
      </div>
    );
  }
}

export default graphql(InstallerConfigGridQuery)(InstallerConfigGrid);
