import React, { Component } from 'react';
import { Field } from 'redux-form';
import renderDropdownList from '../form/renderDropdownList';

class ServicePlanDropdown extends Component {
  constructor(props) {
    super(props);
    this.handleModelTypeChange = this.handleModelTypeChange.bind(this);
  }

  handleModelTypeChange(e) {
    this.props.onDropdownChange(e);
  }

  render() {
    return (
      <div>
        <label>Service Plan</label>
        <Field
          name="servicePlan"
          onChange={this.handleModelTypeChange}
          component={renderDropdownList}
          valueField="value"
          textField="value"
          canBeNull={false}
          data={
            [
              'No Plan',
              'Archive',
              'Not Installed',
              'Distributor',
              'Trial',
              'Platinum',
              'Gold',
              'Silver',
            ]
          }
        />
      </div>
    );
  }
}

ServicePlanDropdown.defaultProps = {
  onDropdownChange: () => {},
};

export default ServicePlanDropdown;
