import React, { Component } from 'react';
import ErrorMessage from '../common/ErrorMessage';
import PSLoader from '../common/PSLoader';
import processData from '../form/uploadData';
import UserImportForm from './UserImportForm';
import FormBase from '../form/FormBase';
import { REACT_APP_BACKEND } from '../../config';

class UserImport extends Component {
  constructor(props) {
    super(props);
    this.handleFileError = this.handleFileError.bind(this);
    this.handleFileLoaded = this.handleFileLoaded.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onError = this.onError.bind(this);
    this.onFinally = this.onFinally.bind(this);
    this.state = { fileLoaded: false, error: false, showLoader: false };
  }

  onSuccess(values) {
    console.log('UserImport Values', values);
    this.onFinally();
  }

  onError(error) {
    console.log('onError', error);
    this.onFinally();
  }

  onFinally() {
    console.log('onFinally');
    this.setState({ showLoader: false });
  }

  handleFileLoaded(data) {
    console.log(data);
    this.setState({ fileLoaded: true });
  }

  handleFileError(err) {
    console.log(err);
    this.setState({ error: true });
    ErrorMessage({
      msg: err,
      timeOut: 30000,
    });
  }

  handleSubmit(values) {
    console.log('values', values);
    if (!values.uploadedData) {
      this.setState({ error: true });
      ErrorMessage({
        msg: 'Please submit a file',
      });
    }
    this.setState({ showLoader: true });
    processData({
      path: `${REACT_APP_BACKEND}/csv/userImport`,
      values: values.uploadedData,
      onSuccess: this.onSuccess,
      onError: this.onError,
    });
  }

  render() {
    return (
      <FormBase header="Import Users from CSV">
        <PSLoader showLoader={this.state.showLoader}>
          <UserImportForm
            onSubmit={this.handleSubmit}
            handleFileError={this.handleFileError}
            handleFileLoaded={this.handleFileLoaded}
          />
        </PSLoader>
      </FormBase>
    );
  }
}

export default UserImport;
