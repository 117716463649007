import React, { Component } from 'react';
import { reduxForm, change } from 'redux-form';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import WizardBase from '../form/WizardBase';
import DeviceGroupSelection from '../device/DeviceGroupSelection';

class DeviceGroupPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceTypeId:
        props.device && props.device.deviceType
          ? props.device.deviceType.id
            ? props.device.deviceType.id
            : props.device.deviceType
          : null,
      deviceId:
        props.device && props.device.id
          ? props.device.id
          : null,
    };
    this.onChangeDeviceGroups = this.onChangeDeviceGroups.bind(this);
  }

  onChangeDeviceGroups(newValue) {
    this.props.setDeviceGroupAdd(newValue.idsToAdd);
    this.props.setDeviceGroupRemove(newValue.idsToRemove);
  }

  render() {
    const { handleSubmit, previousPage } = this.props;
    return (
      <WizardBase
        header="What groups is this device in?"
      >
        <form onSubmit={handleSubmit}>
          <DeviceGroupSelection
            deviceId={this.state.deviceId}
            deviceTypeId={this.state.deviceTypeId}
            onChange={this.onChangeDeviceGroups}
          />

          <br />
          <div>
            <Button className="previous" onClick={previousPage}>
              Previous
            </Button>
            <Button type="submit" className="next">
              Submit
            </Button>
          </div>
        </form>
      </WizardBase>
    );
  }
}

DeviceGroupPage = reduxForm({
  form: 'deviceWizard', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(DeviceGroupPage);
export default connect(
  null,
  {
    setDeviceGroupAdd: (newValue) => change('deviceWizard', 'deviceGroupAdd', newValue),
    setDeviceGroupRemove: (newValue) => change('deviceWizard', 'deviceGroupRemove', newValue),
  },
)(DeviceGroupPage);
