import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

class HomepageCard extends Component {
  render() {
    return (
      <Grid item xs={12} sm={6}>
        <Card>
          <CardContent className="ps-text-black">
            <div className="record-counter">
              <Typography
                variant="h4"
                className="inline"
              >
                {this.props.number}
              </Typography>
              <span>
                Records
                <span style={{ float: 'right' }} className="material-icons ps-secondary-text large-icon">
                  {this.props.icon}
                </span>
              </span>
            </div>
          </CardContent>
          <CardActions className="ps-background">
            <Box mx="auto">
              <Typography variant="h5">
                <Link to={this.props.link}>
                  <span className="ps-homepage-card-title ps-text-white">
                    {this.props.title}
                  </span>
                </Link>
              </Typography>
            </Box>
          </CardActions>
        </Card>
      </Grid>
    );
  }
}

export default HomepageCard;
