import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { withApollo } from '@apollo/client/react/hoc';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import ConfirmDialog from '../../ConfirmDialog';
import SelectFields from './SelectFields';
import EditFields from './EditFields';
import handleMultiSelectMutation from './handleMultiSelectMutation';
import handleConvertLegacySerial from './handleConvertLegacySerial';
import PSLoader from '../../PSLoader';

class MultiEdit extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onError = this.onError.bind(this);
    this.onFinally = this.onFinally.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.clickConvertLegacySerial = this.clickConvertLegacySerial.bind(this);
    this.submitConvertLegacySerial = this.submitConvertLegacySerial.bind(this);
    this.handleSelectFields = this.handleSelectFields.bind(this);
    this.state = {
      page: 1,
      fields: [],
      showLoader: false,
      showConfirmDialog: false,
    };
  }

  handleSelectFields(values) {
    let newFields = [];
    if (
      values.multiSelectFields
      && typeof values.multiSelectFields !== 'undefined'
      && values.multiSelectFields.length > 0
    ) {
      const fieldIds = Object.values(values.multiSelectFields);
      // eslint-disable-next-line
      newFields = this.props.fields.filter(field => {
        if (fieldIds.indexOf(field.id) >= 0) {
          return true;
        }
      });
    }
    this.setState({ fields: newFields });
    this.nextPage();
  }

  componentWillUnmount() {
    this.props.dispatch(reset('multiEditWizard'));
  }

  clickConvertLegacySerial() {
    this.setState({
      showConfirmDialog: true,
    });
  }

  submitConvertLegacySerial() {
    console.log('submitConvertLegacySerial');
    this.closeDialog();
    this.setState({ showLoader: true });
    handleConvertLegacySerial({
      ids: this.props.ids,
      onSuccess: this.onSuccess,
      onError: this.onError,
    });
  }

  onSubmit(values) {
    console.log('multiedit values', JSON.stringify(values));
    this.setState({ showLoader: true });
    handleMultiSelectMutation({
      modelName: this.props.modelName,
      ids: this.props.ids,
      values,
      onSuccess: this.onSuccess,
      onError: this.onError,
    });
  }

  onSuccess(_values) {
    this.onFinally();
  }

  onError(_error) {
    this.onFinally();
  }

  onFinally() {
    this.setState({ showLoader: false });
    this.props.onSubmit();
  }

  nextPage() {
    this.setState((prevState) => ({ page: prevState.page + 1 }));
  }

  previousPage() {
    this.setState((prevState) => ({ page: prevState.page - 1 }));
  }

  closeDialog() {
    this.setState({
      showConfirmDialog: false,
    });
  }

  render() {
    const { onSubmit } = this;
    const { page } = this.state;
    return (
      <div>
        <PSLoader showLoader={this.state.showLoader}>
          <div>
            <div>
              {page === 1 && (
                <SelectFields
                  fields={this.props.fields}
                  onSubmit={this.handleSelectFields}
                />
              )}
              {page === 1 && this.props.modelName === 'device' && this.props.showConvertLegacySerialForDevices && (
                <div>
                  <Typography variant="h6">OR</Typography>
                  <Divider />
                  <Card style={{ overflow: 'visible' }}>
                    <CardHeader title="Convert Legacy Serial Numbers to Serial Numbers" />
                    <CardContent>
                      <Button onClick={this.clickConvertLegacySerial} className="next">
                        Submit
                      </Button>

                      <ConfirmDialog
                        title="Confirm Serial Change"
                        open={this.state.showConfirmDialog}
                        message={`Convert Serials for ${this.props.ids.length} devices?`}
                        onConfirm={this.submitConvertLegacySerial}
                        onClose={this.closeDialog}
                      />
                    </CardContent>
                  </Card>
                </div>
              )}
              {page === 2 && (
                <EditFields
                  previousPage={this.previousPage}
                  onSubmit={onSubmit}
                  fields={this.state.fields}
                />
              )}
            </div>
          </div>
        </PSLoader>
      </div>
    );
  }
}

MultiEdit.defaultProps = {
  modelName: '',
  ids: [],
};

export default withApollo(connect(null)(MultiEdit));
