import { gql } from '@apollo/client';
import { deviceConfigOnlyFragment } from '../fragments';

const CreateDeviceConfigMutation = gql`
  mutation createDeviceConfig($deviceConfig: DeviceConfigInput) {
    createDeviceConfig(deviceConfig: $deviceConfig) {
      ...deviceConfiguration
    }
  }
  ${deviceConfigOnlyFragment}
`;

const UpdateDeviceConfigMutation = gql`
  mutation updateDeviceConfig($deviceConfig: DeviceConfigInput) {
    updateDeviceConfig(deviceConfig: $deviceConfig) {
      ...deviceConfiguration
    }
  }
  ${deviceConfigOnlyFragment}
`;

const DeleteDeviceConfigMutation = gql`
  mutation deleteDeviceConfig($id: ID!) {
    deleteDeviceConfig(id: $id) {
      id
      name
    }
  }
`;

export {
  CreateDeviceConfigMutation,
  UpdateDeviceConfigMutation,
  DeleteDeviceConfigMutation,
};
