import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import InstallerLogGrid from './InstallerLogGrid';
import PSCard from '../common/PSCard';
import PSLoader from '../common/PSLoader';
import ErrorMessage from '../common/ErrorMessage';
import getInstallerLogInfo from './getInstallerLogInfo';

class InstallerLogMain extends Component {
  constructor() {
    super();
    this.state = {
      showLoader: false,
      locationName: '',
      installerLogs: [],
      formValue: '',
    };

    this.onSuccess = this.onSuccess.bind(this);
    this.onError = this.onError.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ formValue: event.target.value });
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({
      showLoader: true,
    });
    try {
      await getInstallerLogInfo({
        locationPin: this.state.formValue,
        onSuccess: this.onSuccess,
        onError: this.onError,
      });
    } catch (err) {
      console.log('handleSubmitError', err);
    }
    this.setState({
      showLoader: false,
    });
  }

  onSuccess(values) {
    this.setState({
      locationName: values.locationName,
      installerLogs: values.installerLogs,
    });
  }

  onError(error) {
    this.setState({
      locationName: '',
      installerLogs: [],
    });
    ErrorMessage({
      msg: error,
    });
  }

  render() {
    return (
      <div>
        <PSLoader showLoader={this.state.showLoader}>
          <Typography variant="h3">Installer Logs</Typography>
          <form onSubmit={this.handleSubmit}>
            <label>
              Location Pin:
              <input
                type="text"
                value={this.state.formValue}
                onChange={this.handleChange}
              />
            </label>
            <Button variant="contained" type="submit">
              Submit
              {' '}
              <i className="material-icons right">send</i>
            </Button>
          </form>

          {this.state.locationName
            && this.state.locationName.length > 0
            && this.state.installerLogs && (
              <PSCard>
                <InstallerLogGrid
                  locationName={this.state.locationName}
                  installerLogs={this.state.installerLogs}
                />
              </PSCard>
          )}
        </PSLoader>
      </div>
    );
  }
}

export default InstallerLogMain;
