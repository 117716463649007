import moment from 'moment';

const displayTimestamp = (d, key) => {
  if (typeof d[key] === 'undefined' || !d[key]) {
    return null;
  }
  let dateNum = d[key];
  if (dateNum.toString().length === 13) {
    dateNum /= 1000;
  }
  return moment.unix(dateNum).format('MM/DD/YYYY h:mm A');
};

export default displayTimestamp;
