import React, { Component } from 'react';
import { Field } from 'redux-form';
import renderDropdownList from '../../form/renderDropdownList';
import { CONST } from '../../common/constants';
import ChartSelect from '../../chart/ChartSelect';
import TableSelect from '../../table/TableSelect';

class IndicatorWidgetSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      widgetType:
        this.props.indicatorWidget && this.props.indicatorWidget.widgetType
          ? this.props.indicatorWidget.widgetType
          : null,
    };
    this.handleWidgetTypeChange = this.handleWidgetTypeChange.bind(this);
  }

  handleWidgetTypeChange(e) {
    console.log('change type ', e);
    this.setState({ widgetType: e.id });
  }

  render() {
    console.log('indicator widget p', this.props);
    console.log('indicator widget s', this.state);
    if (
      this.state.widgetType === null
      && this.props.indicatorWidget
      && this.props.indicatorWidget.widgetType
    ) {
      this.setState({ widgetType: this.props.indicatorWidget.widgetType });
    }
    return (
      <div>
        <div data-tip="Widget Type">
          <Field
            onChange={this.handleWidgetTypeChange}
            name={`${this.props.fieldName}.widgetType`}
            component={renderDropdownList}
            data={CONST.WIDGET_TYPES}
            valueField="id"
            textField="name"
            label="Widget Type"
            canBeNull={false}
          />
        </div>
        <div data-tip="Widget Type">
          {this.state.widgetType === 'chart' && (
            <ChartSelect
              label="Charts"
              fieldName={`${this.props.fieldName}.widgetId`}
              multiple={false}
            />
          )}
          {this.state.widgetType === 'table' && (
            <TableSelect
              label="Table"
              fieldName={`${this.props.fieldName}.widgetId`}
              multiple={false}
            />
          )}
        </div>
      </div>
    );
  }
}
export default IndicatorWidgetSelect;
