import React, { Component } from 'react';
import FixedActionButton from '../common/FixedActionButton';
import Modal from '../common/Modal';
import PSCard from '../common/PSCard';
import {
  CompanyUserGridQuery,
  LocationUserGridQuery,
  AssetUserGridQuery,
} from '../../graphql/queries/AccessControlQueries';
import AccessControlGridBase from './AccessControlGridBase';
import AccessControlNew from './AccessControlNew';
import CompanySelect from '../company/CompanySelect';
import LocationSelect from '../location/LocationSelect';
import AssetSelect from '../asset/AssetSelect';

class AccessControlMain extends Component {
  constructor() {
    super();
    this.state = { modalIsOpen: false };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  modalOptions = [
    {
      onClick: () => {
        this.openModal('company');
      },
      title: 'Grant Company Access',
      color: 'green',
      icon: 'business',
      name: 'company',
      size: '',
    },
    {
      onClick: () => {
        this.openModal('location');
      },
      title: 'Grant Location Access',
      color: 'blue',
      icon: 'add_location',
      name: 'location',
      size: '',
    },
    {
      onClick: () => {
        this.openModal('asset');
      },
      title: 'Grant Asset Access',
      color: 'orange',
      icon: 'devices_other',
      name: 'asset',
      size: '',
    },
  ];

  openModal(type) {
    this.setState({ modalIsOpen: true, modalType: type });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    let refetchQueries;
    return (
      <div>
        <PSCard cardTitle="Company Permissions">
          <AccessControlGridBase model="company" />
        </PSCard>
        <PSCard cardTitle="Location Permissions">
          <AccessControlGridBase model="location" />
        </PSCard>
        <PSCard cardTitle="Asset Permissions">
          <AccessControlGridBase model="asset" />
        </PSCard>
        <div>
          <FixedActionButton options={this.modalOptions} />
        </div>
        <Modal
          onClose={this.closeModal}
          modalIsOpen={this.state.modalIsOpen}
          contentLabel={`Add ${this.state.modalType}`}
        >
          {(() => {
            switch (this.state.modalType) {
              case 'asset':
                refetchQueries = [{ query: AssetUserGridQuery }];
                return (
                  <AccessControlNew
                    header="Grant Asset Permissions"
                    model="asset"
                    refetchQueries={refetchQueries}
                    modelSelect={
                      <AssetSelect parentsOnly fieldName="asset" />
                    }
                  />
                );
              case 'location':
                refetchQueries = [{ query: LocationUserGridQuery }];
                return (
                  <AccessControlNew
                    header="Grant Location Permissions"
                    model="location"
                    refetchQueries={refetchQueries}
                    modelSelect={<LocationSelect fieldName="location" />}
                  />
                );
              default:
                refetchQueries = [{ query: CompanyUserGridQuery }];
                return (
                  <AccessControlNew
                    header="Grant Company Permissions"
                    model="company"
                    refetchQueries={refetchQueries}
                    modelSelect={<CompanySelect fieldName="company" />}
                  />
                );
            }
          })()}
        </Modal>
      </div>
    );
  }
}
export default AccessControlMain;
