import React, { Component } from 'react';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import { connect } from 'react-redux';

import AssetForm from './AssetForm';
import FormBase from '../form/FormBase';
import handleAssetMutation from '../../graphql/functions/handleAssetMutation';
import {
  AssetQuery,
  ChildAssetsQuery,
} from '../../graphql/queries/AssetQueries';
import { UpdateAssetMutation } from '../../graphql/mutations/AssetMutations';
import { CreateAssetTypeMutation } from '../../graphql/mutations/AssetTypeMutations';
import {
  LocationsByCompanyQuery,
} from '../../graphql/queries/LocationQueries';

class AssetUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    if (typeof values === 'object') {
      // store asset id
      values.id = this.props.asset.id;

      const refetchQueries = [
        // { query: AssetGridQuery }, // dont need, the grid updates on its own
        // { query: AssetDropdownQuery },
      ];

      if (this.props.asset.parentId) {
        values.parent = this.props.asset.parentId;
        refetchQueries.push({
          query: ChildAssetsQuery,
          variables: { parentId: this.props.asset.parentId },
        });
      }
      delete values.company;
      delete values.components;
      delete values.childAssets;
      delete values.uploadCSV;

      if (this.props.auth && this.props.auth.obfuscate === true) {
        delete values.name;
      }

      handleAssetMutation({
        values,
        assetTypeMutation: this.props.createAssetTypeMutation,
        assetMutation: this.props.updateAssetMutation,
        onSuccess: this.props.onSuccess,
        refetchQueries,
      });
    }
  }

  render() {
    const {
      LocationsByCompanyQuery: { visibleLocationsByCompany },
    } = this.props;
    const {
      AssetQuery: { loading, error, asset },
    } = this.props;
    const isSubAsset = !!(asset && asset.parentId);
    // organize the initial assetTypeConfig to match the order of the assetType fields
    let formattedAsset = asset;
    if (asset && asset.assetType && asset.assetType.fields) {
      const organizedConfig = asset.assetType.fields.map((f) => {
        let configVal;
        const foundValue = asset.assetTypeConfig.find((c) => c !== null && typeof c !== 'undefined' && c.k === f.key);
        if (foundValue) {
          configVal = foundValue;
        } else if (f.rule === 'Boolean') {
          // if this boolean has not been set, automatically set it to false
          configVal = { k: f.key, v: false };
        } else {
          configVal = { k: f.key, v: null };
        }
        return configVal;
      });
      formattedAsset = { ...asset, assetTypeConfig: organizedConfig };
    }
    return (
      <FormBase loading={loading} error={error} header={isSubAsset ? 'Edit Asset Component' : 'Edit Asset'}>
        <AssetForm
          locations={visibleLocationsByCompany}
          initialValues={formattedAsset}
          subAsset={isSubAsset}
          onSubmit={this.handleSubmit}
        />
      </FormBase>
    );
  }
}

AssetUpdate = compose(
  graphql(UpdateAssetMutation, {
    name: 'updateAssetMutation',
    options: (props) => ({ variables: { id: props.asset.id } }),
  }),
  graphql(CreateAssetTypeMutation, { name: 'createAssetTypeMutation' }),
  graphql(AssetQuery, {
    name: 'AssetQuery',
    options: (props) => ({ variables: { id: props.asset.id }, fetchPolicy: 'network-only' }),
  }),
  graphql(LocationsByCompanyQuery, {
    name: 'LocationsByCompanyQuery',
    options: (props) => ({
      variables: { companyId: props.asset.companyId },
      fetchPolicy: 'network-only',
    }),
  }),
)(AssetUpdate);

function mapStateToProps({ auth }) {
  return { auth };
}
export default withApollo(connect(mapStateToProps)(AssetUpdate));
