import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import _ from 'lodash';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import HomepageCard from './HomepageCard';

class Homepage extends Component {
  getNum(model) {
    switch (model) {
      case 'company':
        return this.props.data.editableCompanies
          ? this.props.data.editableCompanies.length
          : null;
      case 'location':
        return this.props.data.editableLocations
          ? this.props.data.editableLocations.length
          : null;
      case 'asset':
        return this.props.data.editableParentAssets ? this.props.data.editableParentAssets.length : null;
      case 'device':
        return this.props.data.editableDevices ? this.props.data.editableDevices.length : null;
      default:
        return 0;
    }
  }

  getCards() {
    const cards = [
      {
        title: 'Locations',
        model: 'location',
        icon: 'add_location',
        link: '/location',
        color: '',
      },
      {
        title: 'Assets',
        model: 'asset',
        icon: 'devices_other',
        link: '/asset',
        color: '',
      },
      {
        title: 'Devices',
        model: 'device',
        icon: 'router',
        link: '/device',
        color: '',
      }];

    if (this.props.auth.isSuperAdmin) {
      cards.unshift({
        title: 'Companies',
        model: 'company',
        icon: 'business_center',
        link: '/company',
        color: '',
      });
    }
    return cards;
  }

  renderCards() {
    const cards = this.getCards();
    return _.map(cards, ({
      title, model, icon, link, color,
    }) => (
      <HomepageCard
        key={model}
        title={title}
        model={model}
        icon={icon}
        link={link}
        color={color}
        number={this.getNum(model)}
      />
    ));
  }

  render() {
    return (
      <div>
        {this.props.auth && this.props.auth.isAdmin ? (
          <Container>
            <div>
              <List>
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={6}>
                    <ListItem>
                      <Button
                        key="newDevice"
                        component={Link}
                        to="/deviceWizard"
                      >
                        Configure New Device
                      </Button>
                    </ListItem>
                    <ListItem>
                      <Button
                        key="userImport"
                        component={Link}
                        to="/userImport"
                      >
                        Bulk User Import
                      </Button>
                    </ListItem>
                  </Grid>
                  {this.props.auth && this.props.auth.isSuperAdmin ? (
                    <Grid item xs={12} sm={6}>
                      <ListItem>
                        <Button
                          key="attributeImport"
                          component={Link}
                          to="/attributeImport"
                        >
                          Bulk Attribute Import
                        </Button>
                      </ListItem>
                      <ListItem>
                        <Button
                          key="simpleImport"
                          component={Link}
                          to="/simpleImport"
                        >
                          Bulk Asset/Device Import
                        </Button>
                      </ListItem>
                      <ListItem>
                        <Button
                          key="csvImport"
                          component={Link}
                          to="/wizardCsvImport"
                        >
                          Bulk Asset/Device Import Wizard
                        </Button>
                      </ListItem>
                    </Grid>
                  ) : <div />}
                </Grid>
              </List>
            </div>
            <div>
              <Grid container spacing={2}>
                {this.renderCards()}
              </Grid>
            </div>
          </Container>
        ) : this.props.auth ? (
          <Container>
            <Typography variant="h3">Restricted</Typography>
            <Typography variant="h5">Please contact site administrator for access</Typography>
          </Container>
        ) : (
          <div>Loading...</div>
        )}
      </div>
    );
  }
}

const HomepageQuery = gql`
  query HomepageQuery {
    editableCompanies {
      id
    }
    editableLocations {
      id
    }
    editableParentAssets {
      id
    }
    editableDevices {
      id
    }
  }
`;
function mapStateToProps({ auth }) {
  return { auth };
}
Homepage = connect(mapStateToProps)(Homepage);
export default graphql(HomepageQuery)(Homepage);
