import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {
  ArtTrack,
  Business,
  Home,
  ChevronLeft,
  Dashboard,
  LocalShipping,
  Router,
  LocationOn,
  People,
  SignalCellular4Bar,
  InsertChart,
  DateRange,
  Assignment,
  AssignmentLate,
  LocalDrink,
  Storage,
  Edit,
  Lock,
  GridOn,
  Pages,
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import * as actions from '../../actions';
import Config from './Config';

const styles = (theme) => ({
  drawerPaper: {
    position: 'relative',
    height: '100%',
    width: Config.drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
});

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.getProtectedItems = this.getProtectedItems.bind(this);
  }

  componentDidMount() {
    this.props.fetchUser();
  }

  handleDrawerClose = () => {
    this.props.onDrawerClose();
  };

  getProtectedItems() {
    const companyAdminLinks = this.props.auth && this.props.auth.isAdmin ? (
      <div>
        <ListItem
          component={Link}
          to="/homepage"
          onClick={this.handleDrawerClose}
          button
        >
          <ListItemIcon>
            <Home />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem
          component={Link}
          to="/device"
          onClick={this.handleDrawerClose}
          button
        >
          <ListItemIcon>
            <Router />
          </ListItemIcon>
          <ListItemText primary="Devices" />
        </ListItem>
        <ListItem
          component={Link}
          to="/asset"
          onClick={this.handleDrawerClose}
          button
        >
          <ListItemIcon>
            <LocalShipping />
          </ListItemIcon>
          <ListItemText primary="Assets" />
        </ListItem>
        <ListItem
          component={Link}
          to="/metadata"
          onClick={this.handleDrawerClose}
          button
        >
          <ListItemIcon>
            <ArtTrack />
          </ListItemIcon>
          <ListItemText primary="Asset Metadata" />
        </ListItem>
        <ListItem
          component={Link}
          to="/location"
          onClick={this.handleDrawerClose}
          button
        >
          <ListItemIcon>
            <LocationOn />
          </ListItemIcon>
          <ListItemText primary="Locations" />
        </ListItem>
      </div>
    ) : (
      <div />
    );
    const superAdminLinks = this.props.auth && this.props.auth.isSuperAdmin ? (
      <div>
        <ListItem
          component={Link}
          to="/company"
          onClick={this.handleDrawerClose}
          button
        >
          <ListItemIcon>
            <Business />
          </ListItemIcon>
          <ListItemText primary="Companies" />
        </ListItem>
        <ListItem
          component={Link}
          to="/data"
          onClick={this.handleDrawerClose}
          button
        >
          <ListItemIcon>
            <Storage />
          </ListItemIcon>
          <ListItemText primary="Data" />
        </ListItem>
        <ListItem
          component={Link}
          to="/attribute"
          onClick={this.handleDrawerClose}
          button
        >
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <ListItemText primary="Attributes" />
        </ListItem>

        <ListItem
          component={Link}
          to="/chart"
          onClick={this.handleDrawerClose}
          button
        >
          <ListItemIcon>
            <InsertChart />
          </ListItemIcon>
          <ListItemText primary="Charts" />
        </ListItem>

        <ListItem
          component={Link}
          to="/table"
          onClick={this.handleDrawerClose}
          button
        >
          <ListItemIcon>
            <GridOn />
          </ListItemIcon>
          <ListItemText primary="Tables" />
        </ListItem>

        <ListItem
          component={Link}
          to="/modelDashboard"
          onClick={this.handleDrawerClose}
          button
        >
          <ListItemIcon>
            <Dashboard />
          </ListItemIcon>
          <ListItemText primary="Model Dashboards" />
        </ListItem>
        <ListItem
          component={Link}
          to="/event"
          onClick={this.handleDrawerClose}
          button
        >
          <ListItemIcon>
            <Assignment />
          </ListItemIcon>
          <ListItemText primary="Events" />
        </ListItem>
        <ListItem
          component={Link}
          to="/oilSampleType"
          onClick={this.handleDrawerClose}
          button
        >
          <ListItemIcon>
            <LocalDrink />
          </ListItemIcon>
          <ListItemText primary="Oil Sample Types" />
        </ListItem>
        <ListItem
          component={Link}
          to="/ap2200s"
          onClick={this.handleDrawerClose}
          button
        >
          <ListItemIcon>
            <SignalCellular4Bar />
          </ListItemIcon>
          <ListItemText primary="AP2200s" />
        </ListItem>
        <ListItem
          component={Link}
          to="/dataLog"
          onClick={this.handleDrawerClose}
          button
        >
          <ListItemIcon>
            <AssignmentLate />
          </ListItemIcon>
          <ListItemText primary="Data Logs" />
        </ListItem>
        <ListItem
          component={Link}
          to="/servicePlan"
          onClick={this.handleDrawerClose}
          button
        >
          <ListItemIcon>
            <DateRange />
          </ListItemIcon>
          <ListItemText primary="Service Plan" />
        </ListItem>
      </div>
    ) : (
      <div />
    );

    const installerLink = (
      <div>
        <ListItem
          component={Link}
          to="/installerLog"
          onClick={this.handleDrawerClose}
          button
        >
          <ListItemIcon>
            <Pages />
          </ListItemIcon>
          <ListItemText primary="Installer Logs" />
        </ListItem>
      </div>
    );
    return (
      <div>
        {companyAdminLinks}
        {superAdminLinks}
        {this.props.auth && this.props.auth.isAdmin && (
        <div>
          <ListItem
            component={Link}
            to="/user"
            onClick={this.handleDrawerClose}
            button
          >
            <ListItemIcon>
              <People />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
          <ListItem
            component={Link}
            to="/accessControl"
            onClick={this.handleDrawerClose}
            button
          >
            <ListItemIcon>
              <Lock />
            </ListItemIcon>
            <ListItemText primary="User Permissions" />
          </ListItem>
        </div>
        )}
        {installerLink}
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Drawer
        variant="persistent"
        anchor="left"
        open={this.props.open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div>
          <div className={classes.drawerHeader}>
            <IconButton onClick={this.handleDrawerClose}>
              <ChevronLeft />
            </IconButton>
          </div>
          <Divider />

          <List className={classes.list}>{this.getProtectedItems()}</List>
        </div>
      </Drawer>
    );
  }
}

function mapStateToProps({ auth }) {
  return { auth };
}
export default connect(
  mapStateToProps,
  actions,
)(withStyles(styles, { withTheme: true })(Sidebar));
