import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';

import {
  AttributeTypeGridQuery,
  AttributeTypeQuery,
} from '../../../../graphql/queries/AttributeTypeQueries';
import AttributeTypeForm from './AttributeTypeForm';
import FormBase from '../../../form/FormBase';
import handleMutation from '../../../../graphql/functions/handleMutation';
import { UpdateAttributeTypeMutation } from '../../../../graphql/mutations/AttributeTypeMutations';

class AttributeTypeUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    if (typeof values === 'object') {
      // store attributeType id
      values.id = this.props.attributeType.id;

      if (values.fields) {
        for (let i = 0; i < values.fields.length; i++) {
          if (values.fields[i].rule && values.fields[i].rule.id) {
            values.fields[i].rule = values.fields[i].rule.id;
          }
        }
      }
      console.log('values', values);
      handleMutation({
        mutate: this.props.updateAttributeTypeMutation,
        variables: { attributeType: values },
        refetchQueries: [{ query: AttributeTypeGridQuery }],
        onSuccess: this.props.onSuccess,
      });
    }
  }

  render() {
    // render form with attributeType values pre-loaded
    const {
      data: { loading, error, attributeType },
    } = this.props;
    return (
      <FormBase loading={loading} error={error} header="Edit Attribute Type">
        <AttributeTypeForm
          initialValues={attributeType}
          onSubmit={this.handleSubmit}
        />
      </FormBase>
    );
  }
}

export default compose(
  graphql(UpdateAttributeTypeMutation, {
    name: 'updateAttributeTypeMutation',
    options: (props) => ({ variables: { id: props.attributeType.id } }),
  }),
  graphql(AttributeTypeQuery, {
    options: (props) => ({ variables: { id: props.attributeType.id }, fetchPolicy: 'network-only' }),
  }),
)(AttributeTypeUpdate);
