import handleMutation from './handleMutation';

const handleSubmitDeviceType = (props) => {
  handleMutation({
    mutate: props.mutation,
    variables: { deviceType: props.values },
    refetchQueries: props.refetchQueries,
    onSuccess: props.onSuccess,
  });
};

export default (props) => {
  const { values } = props;
  console.log('submit', values);
  if (typeof values === 'object') {
    if (
      typeof values.deviceConfig === 'object'
      && values.deviceConfig !== null
      && values.deviceConfig.id
    ) {
      values.deviceConfig = values.deviceConfig.id;
    }
    handleSubmitDeviceType(props);
  }
};
