import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { Field, FieldArray, change } from 'redux-form';
import _ from 'lodash';
import FormField from '../../form/FormField';
import { AttributeTypeQuery } from '../../../graphql/queries/AttributeTypeQueries';
import renderCheckbox from '../../form/renderCheckbox';

class AttributeConfig extends Component {
  constructor(props) {
    super(props);
    this.renderConfigFields = this.renderConfigFields.bind(this);
    this.setConfigKey = this.setConfigKey.bind(this);
  }

  setConfigKey(key, e) {
    this.props.setConfigKeyVal(e, key, null);
  }

  renderConfigFields({ attributeType }) {
    const allFields = _.map(attributeType.fields, (obj, fieldIndex) => {
      switch (obj.rule) {
        case 'String':
          return (
            <div key={`${fieldIndex}.tooltip`} data-tip={obj.description}>
              <Field
                key={`config[${fieldIndex}].v`}
                name={`config[${fieldIndex}].v`}
                type="text"
                component={FormField}
                label={obj.name}
                onChange={(e, n, p) => this.setConfigKey(obj.key, e, n, p)}
              />
            </div>
          );
        case 'Number':
          return (
            <div key={`${fieldIndex}.tooltip`} data-tip={obj.description}>
              <Field
                key={`config[${fieldIndex}].v`}
                name={`config[${fieldIndex}].v`}
                type="text"
                component={FormField}
                label={obj.name}
                onChange={(e, n, p) => this.setConfigKey(obj.key, e, n, p)}
              />
            </div>
          );
        case 'Boolean':
          return (
            <div key={`${fieldIndex}.tooltip`} data-tip={obj.description}>
              <Field
                key={`config[${fieldIndex}].v`}
                name={`config[${fieldIndex}].v`}
                label={obj.name}
                id={`config[${fieldIndex}].v`}
                component={renderCheckbox}
                onChange={(e, n, p) => this.setConfigKey(obj.key, e, n, p)}
              />
            </div>
          );
        default:
          return <div />;
      }
    });
    return allFields;
  }

  render() {
    if (!this.props.data) {
      return <div />;
    }
    const { data: { loading, error, attributeType } } = this.props;

    if (loading) {
      return <p>Loading...</p>;
    } if (error) {
      return <p>Error Loading Attribute Configuration!</p>;
    }
    return (
      <div>
        <FieldArray
          name="config"
          component={this.renderConfigFields}
          props={{ attributeType }}
        />
        <br />
      </div>
    );
  }
}
AttributeConfig = connect(null, {
  setConfigKeyVal: (event, newValue) => {
    const name = `${event.currentTarget.name.slice(0, -1)}k`;
    return change('Attribute', name, newValue);
  },
})(AttributeConfig);

export default graphql(AttributeTypeQuery, {
  skip: (ownProps) => !ownProps.attributeTypeId,
  options: (ownProps) => ({
    variables: { id: ownProps.attributeTypeId },
  }),
})(AttributeConfig);
