import React, { Component } from 'react';
import {
  reduxForm,
  Field,
  FieldArray,
} from 'redux-form';
import Button from '@material-ui/core/Button';
import renderCheckbox from '../../form/renderCheckbox';
import FormSubmitButton from '../../form/FormSubmitButton';
import FormField from '../../form/FormField';
import ClearButton from '../../form/ClearButton';
import InstallerConfigFieldSelect from './installerConfigField/InstallerConfigFieldSelect';
import ExpansionPanelBase from '../../common/ExpansionPanelBase';

class InstallerConfigForm extends Component {
  getFieldPanels(fields) {
    return fields.map((installerField, index) => {
      const attNum = index + 1;
      return {
        key: `panel${index}`,
        header: `Field #${attNum}`,
        data: (
          <div className="expandable-form-div">
            <Button
              variant="contained"
              className="right"
              type="button"
              title="Remove Field"
              onClick={() => fields.remove(index)}
            >
              <i className="material-icons">delete</i>
            </Button>

            <InstallerConfigFieldSelect
              multiple={false}
              fieldName={`${installerField}.installerConfigField`}
              label="Select Field"
            />
            <br />
            <Field
              name={`${installerField}.default`}
              label="Default"
              component={renderCheckbox}
            />
            <br />
          </div>
        ),
      };
    });
  }

  renderFieldArrray = ({ fields, meta: { error, submitFailed } }) => (
    <div style={{ marginTop: '5px' }}>
      <div>
        <Button
          variant="contained"
          style={{ marginTop: '5px' }}
          type="button"
          onClick={() => fields.push({})}
        >
          Add Field
        </Button>
        {submitFailed && error && <span>{error}</span>}
      </div>
      <div>
        <ExpansionPanelBase panelData={this.getFieldPanels(fields)} />
      </div>
    </div>
  );

  render() {
    const {
      handleSubmit,
      pristine,
      reset,
      submitting,
    } = this.props;
    return (
    /* Name */

    /* Is default? */

    /* Select Form Fields */

      <form onSubmit={handleSubmit}>
        <Field name="name" type="text" component={FormField} label="Name" />
        <Field name="default" label="Default" component={renderCheckbox} />
        <label>Fields</label>
        <FieldArray name="formFields" component={this.renderFieldArrray} />
        <div>
          <FormSubmitButton pristine={pristine} submitting={submitting} />
          <ClearButton
            pristine={pristine}
            submitting={submitting}
            reset={reset}
          />
        </div>
      </form>
    );
  }
}

InstallerConfigForm.defaultProps = {
  initialValues: null,
};
const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Cannot be blank';
  }
  return errors;
};

InstallerConfigForm = reduxForm({
  validate,
  form: 'InstallerConfig',
})(InstallerConfigForm);

export default InstallerConfigForm;
