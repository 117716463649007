import React, { Component } from 'react';
import { connect } from 'react-redux';
import FixedActionButton from '../common/FixedActionButton';
import AssetGrid from './AssetGrid';
import AssetTypeGrid from './assetType/AssetTypeGrid';
import AssetTypeNew from './assetType/AssetTypeNew';
import AssetConfigGrid from './assetConfig/AssetConfigGrid';
import AssetConfigNew from './assetConfig/AssetConfigNew';
import Modal from '../common/Modal';
import PSCard from '../common/PSCard';
import AssetNew from './AssetNew';

class AssetMain extends Component {
  constructor() {
    super();
    this.state = { modalIsOpen: false };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  getModalOptions() {
    const modalOptions = [{
      onClick: () => {
        this.openModal('Asset');
      },
      title: 'Add Asset',
      color: 'blue',
      icon: 'add',
      name: 'asset',
      size: '',
    }];
    if (this.props.auth && this.props.auth.isSuperAdmin) {
      modalOptions.unshift(
        {
          onClick: () => {
            this.openModal('Asset Config');
          },
          title: 'Add Asset Config',
          name: 'assetConfig',
        },
        {
          onClick: () => {
            this.openModal('Asset Type');
          },
          title: 'Add Asset Type',
          name: 'assetType',
        },
      );
    }
    return modalOptions;
  }

  openModal(type) {
    this.setState({ modalIsOpen: true, modalType: type });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    return (
      <div>
        <PSCard cardTitle="Assets">
          <AssetGrid />
        </PSCard>
        {this.props.auth && this.props.auth.isSuperAdmin ? (
          <div>
            <PSCard cardTitle="Asset Types">
              <AssetTypeGrid />
            </PSCard>
            <PSCard cardTitle="Asset Data Configurations">
              <AssetConfigGrid />
            </PSCard>
          </div>
        ) : (
          <div />
        )}

        <div>
          <FixedActionButton options={this.getModalOptions()} />
        </div>
        <Modal
          onClose={this.closeModal}
          modalIsOpen={this.state.modalIsOpen}
          contentLabel={`Add ${this.state.modalType}`}
        >
          {(() => {
            switch (this.state.modalType) {
              case 'Asset Config':
                return <AssetConfigNew />;
              case 'Asset Type':
                return <AssetTypeNew />;
              case 'Asset':
                return <AssetNew />;
              default:
                return <AssetNew />;
            }
          })()}
        </Modal>
      </div>
    );
  }
}

function mapStateToProps({ auth }) {
  return { auth };
}
export default connect(mapStateToProps)(AssetMain);
