import React, { Component } from 'react';

class HomepageLayout extends Component {
  render() {
    return (
      <div className="side-nav-and-content-wrapper ">
        <div className="main-content ps-background-lightgray">
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default HomepageLayout;
