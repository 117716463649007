// react router stuff
import React, { Component } from 'react';
import {
  BrowserRouter, Route, Redirect, Switch,
} from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';
import { connect } from 'react-redux';

import * as actions from '../actions';
import BaseLayout from './layout/BaseLayout';
import HomepageLayout from './layout/HomepageLayout';
import Header from './layout/Header';
import Footer from './layout/Footer';
import Landing from './Landing'; // landing page
import Login from './login/Login'; // login
import Mfa from './mfa/Mfa'; // redirect after login
import Homepage from './homepage/Homepage'; // redirect after mfa
import UserNew from './user/UserNew';
import CompanyMain from './company/CompanyMain';
import ChartMain from './chart/ChartMain';
import EventMain from './event/EventMain';
import DataLogMain from './dataLog/DataLogMain';
import TableMain from './table/TableMain';
import LocationMain from './location/LocationMain';
import AssetMain from './asset/AssetMain';
import DeviceMain from './device/DeviceMain';
import UserMain from './user/UserMain';
import ServicePlanMain from './servicePlan/ServicePlanMain';
import ModelDashboardMain from './modelDashboard/ModelDashboardMain';
import DataTypeMain from './data/dataType/DataTypeMain';
import AttributeMain from './data/attribute/AttributeMain';
import DataMain from './data/DataMain';
import Ap2200Main from './ap2200/Ap2200Main';
import OilSampleTypeMain from './oilSampleType/OilSampleTypeMain';
import AccessControlMain from './accessControl/AccessControlMain';
import MetadataMain from './metadata/MetadataMain';
import DeviceWizard from './deviceCreationWizard/DeviceWizard';
import DeviceAssignmentWizard from './deviceAssignmentWizard/DeviceAssignmentWizard';
import DeviceReplacementPage from './device/deviceReplacement/DeviceReplacementPage';
import InstallerLogMain from './installerLog/InstallerLogMain';
import SimpleImport from './simpleImport/SimpleImport';
import UserImport from './userImport/UserImport';
import AttributeImport from './attributeImport/AttributeImport';
import CSVImport from './csvImport/CSVImport';
import Restricted from './restricted/Restricted';
import AdminFileUpload from './adminFileUpload/AdminFileUpload';

import 'react-table/react-table.css';
import 'react-widgets/styles.css';
import '../App.css';

import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import 'typeface-roboto';

import Sidebar from './layout/Sidebar';
import MainContent from './layout/MainContent';
import { PRIMARY_COLOR, SECONDARY_COLOR, APP_BAR_COLOR } from '../config';
import { CONST } from './common/constants';

const theme = {
  // typography v2
  typography: {
    useNextVariants: true,
  },
  palette: {
    type: 'light',
    primary: {
      main: PRIMARY_COLOR,
      contrastText: '#fff',
    },
    secondary: {
      main: SECONDARY_COLOR,
      contrastText: '#000',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        margin: '5px',
      },
    },
    MuiFab: {
      root: {
        bottom: '23px',
        marginBottom: 0,
        position: 'fixed',
        right: '23px',
        zIndex: '997',
        backgroundColor: '#E53935',
        '&:hover': {
          backgroundColor: '#F44336',
        },
        '&:focus': {
          backgroundColor: '#F44336',
        },
      },
    },
    MuiCard: {
      root: {
        marginBottom: '16px',
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: '#fff',
      },
    },
    MuiCollapse: {
      root: {
        '.overflowVisible > &': {
          overflowY: 'visible',
        },
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: APP_BAR_COLOR,
      },
    },
  },
};

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      theme: 'light',
    };
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleToggleTheme = () => {
    if (this.state.theme === 'dark') {
      this.setState({ theme: 'light' });
      localStorage.setItem('theme', 'light');
      theme.palette.type = 'light';
      theme.overrides.MuiPaper.root.backgroundColor = '#fff';
      theme.overrides.MuiAppBar.colorPrimary.backgroundColor = APP_BAR_COLOR;
      document.body.classList.remove('dark');
    } else {
      this.setState({ theme: 'dark' });
      localStorage.setItem('theme', 'dark');
      theme.palette.type = 'dark';
      theme.overrides.MuiPaper.root.backgroundColor = '#121212';
      theme.overrides.MuiAppBar.colorPrimary.backgroundColor = '#121212';
      document.body.classList.add('dark');
    }
  };

  getToggleThemeName() {
    if (this.state.theme === 'dark') {
      return 'Light';
    }
    return 'Dark';
  }

  componentDidMount() {
    if (localStorage.getItem('theme') === 'dark') {
      this.handleToggleTheme();
    }
    this.props.fetchUser();
    this.props.fetchMfa();
  }

  render() {
    return (
      <MuiThemeProvider theme={createTheme(theme)}>
        <CssBaseline />
        <div>
          <BrowserRouter>
            <div style={{ width: '100%' }}>
              <div
                className="route-wrapper ps-background-lightgray"
                style={{
                  position: 'relative',
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                }}
              >
                <Header
                  open={this.state.open}
                  onDrawerOpen={this.handleDrawerOpen}
                  themeToggle={this.handleToggleTheme}
                  toggleThemeName={this.getToggleThemeName()}
                />
                <Sidebar
                  open={this.state.open}
                  onDrawerClose={this.handleDrawerClose}
                />
                <MainContent open={this.state.open}>
                  <Switch>
                    <BaseRoute exact path="/" component={Landing} />
                    <BaseRoute exact path="/login" component={Login} />
                    <BaseRoute exact path="/restricted" component={Restricted} />
                    <BaseRoute
                      exact
                      path="/installerLog"
                      component={InstallerLogMain}
                    />

                    <MFARoute
                      exact
                      path="/mfa"
                      component={Mfa}
                    />
                    <AuthenticatedRoute
                      exact
                      path="/homepage"
                      component={Homepage}
                    />
                    <AuthenticatedRoute
                      exact
                      path="/user/new"
                      component={UserNew}
                    />
                    <AuthenticatedRoute
                      exact
                      path="/location"
                      component={LocationMain}
                    />
                    <AuthenticatedRoute
                      exact
                      path="/asset"
                      component={AssetMain}
                    />
                    <AuthenticatedRoute
                      exact
                      path="/metadata"
                      component={MetadataMain}
                    />
                    <AuthenticatedRoute
                      exact
                      path="/device"
                      component={DeviceMain}
                    />
                    <AuthenticatedRoute
                      exact
                      path="/user"
                      component={UserMain}
                    />
                    <AuthenticatedRoute
                      exact
                      path="/accessControl"
                      component={AccessControlMain}
                    />
                    <AuthenticatedRoute
                      exact
                      path="/deviceWizard"
                      component={DeviceWizard}
                    />
                    <AuthenticatedRoute
                      exact
                      path="/assignDevice/:id"
                      component={DeviceAssignmentWizard}
                    />
                    <AuthenticatedRoute
                      exact
                      path="/replaceDevice/:id"
                      component={DeviceReplacementPage}
                    />
                    <AuthenticatedRoute
                      exact
                      path="/userImport"
                      component={UserImport}
                    />

                    <SuperAdminRoute
                      exact
                      path="/attributeImport"
                      component={AttributeImport}
                    />
                    <SuperAdminRoute
                      exact
                      path="/servicePlan"
                      component={ServicePlanMain}
                    />
                    <SuperAdminRoute
                      exact
                      path="/oilSampleType"
                      component={OilSampleTypeMain}
                    />
                    <SuperAdminRoute
                      exact
                      path="/company"
                      component={CompanyMain}
                    />
                    <SuperAdminRoute
                      exact
                      path="/modelDashboard"
                      component={ModelDashboardMain}
                    />
                    <SuperAdminRoute
                      exact
                      path="/dataType"
                      component={DataTypeMain}
                    />
                    <SuperAdminRoute
                      exact
                      path="/attribute"
                      component={AttributeMain}
                    />
                    <SuperAdminRoute
                      exact
                      path="/ap2200s"
                      component={Ap2200Main}
                    />
                    <SuperAdminRoute
                      exact
                      path="/data"
                      component={DataMain}
                    />
                    <SuperAdminRoute
                      exact
                      path="/chart"
                      component={ChartMain}
                    />
                    <SuperAdminRoute
                      exact
                      path="/event"
                      component={EventMain}
                    />
                    <SuperAdminRoute
                      exact
                      path="/dataLog"
                      component={DataLogMain}
                    />
                    <SuperAdminRoute
                      exact
                      path="/table"
                      component={TableMain}
                    />
                    <SuperAdminRoute
                      exact
                      path="/wizardCsvImport"
                      component={CSVImport}
                    />
                    <SuperAdminRoute
                      exact
                      path="/simpleImport"
                      component={SimpleImport}
                    />
                    <SuperAdminRoute
                      exact
                      path="/uploadAdminFile"
                      component={AdminFileUpload}
                    />
                  </Switch>
                  <Footer />
                </MainContent>
              </div>
            </div>
          </BrowserRouter>
          <ReduxToastr />
        </div>
      </MuiThemeProvider>
    );
  }
}
const getAuth = (auth) => {
  let role = 0;
  try {
    if (auth && auth.role) {
      role = auth.role;
    }
  } catch (e) {
    console.log(e);
  }
  return role;
};
function mapStateToProps({
  auth, isFetchingUser, isFetchingMfa, mfaVerified,
}) {
  return {
    auth, isFetchingUser, isFetchingMfa, mfaVerified,
  };
}
// eslint-disable-next-line
function SuperAdminRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(matchProps) => {
        const {
          auth, isFetchingUser, isFetchingMfa, mfaVerified,
        } = rest;
        if (((!auth && isFetchingUser === true) || isFetchingUser === null)
         || ((!mfaVerified && isFetchingMfa === true) || isFetchingMfa === null)) {
          return <p>Loading...</p>;
        }
        // first check if we are logged in, then check if we are a Super Admin
        const authVal = getAuth(auth);
        const isLoggedIn = authVal > 0;
        const isSuperAdmin = authVal === CONST.SUPER_ADMIN_ROLE;
        const isMfaVerified = mfaVerified;
        return (
          isLoggedIn ? (
            isSuperAdmin ? (
              isMfaVerified === true ? (
                <HomepageLayout>
                  <Component {...matchProps} />
                </HomepageLayout>
              ) : (
                <Redirect
                  to={{
                    pathname: '/mfa',
                    state: { from: matchProps.location },
                  }}
                />
              )
            ) : (
              <Redirect
                to={{
                  pathname: '/restricted',
                  state: { from: matchProps.location },
                }}

              />
            )
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: matchProps.location },
              }}
            />
          )
        );
      }}
    />
  );
}
SuperAdminRoute = connect(mapStateToProps)(SuperAdminRoute);  // eslint-disable-line
// eslint-disable-next-line
function AuthenticatedRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(matchProps) => {
        const {
          auth, isFetchingUser, isFetchingMfa, mfaVerified,
        } = rest;
        if (((!auth && isFetchingUser === true) || isFetchingUser === null)
         || ((!mfaVerified && isFetchingMfa === true) || isFetchingMfa === null)) {
          return <p>Loading...</p>;
        }
        // first check if we are logged in, then check if we are an Admin
        const authVal = getAuth(auth);
        const isLoggedIn = authVal > 0;
        const isAdmin = authVal >= CONST.COMPANY_ADMIN_ROLE;
        const isMfaVerified = mfaVerified;
        return (
          isLoggedIn ? (
            isAdmin ? (
              isMfaVerified === true ? (
                <HomepageLayout>
                  <Component {...matchProps} />
                </HomepageLayout>
              ) : (
                <Redirect
                  to={{
                    pathname: '/mfa',
                    state: { from: matchProps.location },
                  }}
                />
              )
            ) : (
              <Redirect
                to={{
                  pathname: '/restricted',
                  state: { from: matchProps.location },
                }}

              />
            )
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: matchProps.location },
              }}
            />
          )
        );
      }}
    />
  );
}
AuthenticatedRoute = connect(mapStateToProps)(AuthenticatedRoute);  // eslint-disable-line
// eslint-disable-next-line
function MFARoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(matchProps) => {
        // first check if we are logged in, then check if we are an Admin
        const authVal = getAuth(rest.auth);
        const isLoggedIn = authVal > 0;
        const isAdmin = authVal >= CONST.COMPANY_ADMIN_ROLE;
        const isMfaVerified = rest.mfaVerified;

        let pathname = '/homepage';
        if (matchProps && matchProps.location && matchProps.location.state
          && matchProps.location.state.from && matchProps.location.state.from.pathname) {
          pathname = matchProps.location.state.from.pathname;
        }
        return (
          isLoggedIn ? (
            isAdmin ? (
              isMfaVerified !== true ? (
                <BaseLayout>
                  <Component {...matchProps} />
                </BaseLayout>
              ) : (
                <Redirect
                  to={{
                    pathname,
                    state: { from: matchProps.location },
                  }}

                />
              )
            ) : (
              <Redirect
                to={{
                  pathname: '/restricted',
                  state: { from: matchProps.location },
                }}

              />
            )
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: matchProps.location },
              }}
            />
          )
        );
      }}
    />
  );
}
// eslint-disable-next-line
MFARoute = connect(mapStateToProps)(MFARoute);
// eslint-disable-next-line
function BaseRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <BaseLayout>
          <Component {...matchProps} />
        </BaseLayout>
      )}
    />
  );
}

export default connect(
  mapStateToProps,
  actions,
)(App);
