import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';

import handleMutation from '../../graphql/functions/handleMutation';
import FormBase from '../form/FormBase';
import { CompanyGridQuery } from '../../graphql/queries/CompanyQueries';
import { CreateAccountManagerMutation } from '../../graphql/mutations/AccountManagerMutations';
import AccountManagerForm from './AccountManagerForm';

class AccountManagerNew extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // submit mutation and refetch accountManager grid query
  handleSubmit(values) {
    values.company = this.props.companyId;
    values.user = Array.isArray(values.user) ? values.user[0] : values.user;
    handleMutation({
      mutate: this.props.mutate,
      variables: { accountManager: values },
      refetchQueries: [
        { query: CompanyGridQuery },
      ],
      onSuccess: this.props.onSuccess,
    });
  }

  render() {
    return (
      <FormBase header="Create AccountManager">
        <AccountManagerForm onSubmit={this.handleSubmit} />
      </FormBase>
    );
  }
}

const AccountManagerNewWithMutation = graphql(CreateAccountManagerMutation)(AccountManagerNew);

export default AccountManagerNewWithMutation;
