import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import ReactTable from 'react-table';
import Checkbox from '@material-ui/core/Checkbox';
import displayTimestamp from '../common/displayTimestamp';
import timestampSort from '../common/timestampSort';
import containsFilter from '../common/containsFilter';

const checkboxStyle = { height: '20px' };

function HeaderCheckbox(selectAll, toggleSelectAll) {
  return (
    <Checkbox
      style={checkboxStyle}
      checked={selectAll === 1}
      onClick={() => toggleSelectAll()}
      indeterminate={selectAll === 2}
      color="primary"
      disableRipple
    />
  );
}

function CellCheckbox(original, selectAll, toggleRow, isSelected) {
  return (
    <Checkbox
      style={checkboxStyle}
      onClick={() => toggleRow(original.id)}
      checked={selectAll === 1 || isSelected(original.id) === true}
      color="primary"
      disableRipple
    />
  );
}

class DeviceGridMultiSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectAll: 0,
      selected: [],
    };
    this.toggleRow = this.toggleRow.bind(this);
    this.toggleSelectAll = this.toggleSelectAll.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.clearAllSelected = this.clearAllSelected.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
  }

  columns = [
    {
      id: 'checkbox',
      accessor: '',
      Header: (x) => HeaderCheckbox(this.state.selectAll, this.toggleSelectAll),
      Cell: ({ original }) => CellCheckbox(original, this.state.selectAll, this.toggleRow, this.isSelected),
      sortable: false,
      width: 45,
      resizable: false,
    },
    {
      Header: 'ID',
      accessor: 'id',
      maxWidth: 50,
      filterable: true,
      show: true,
    },
    {
      Header: 'Company',
      id: 'companyName',
      accessor: (d) => (d.asset && d.asset.location && d.asset.location.company
        ? d.asset.location.company.name
        : null),
      maxWidth: 120,
      filterable: true,
    },
    {
      Header: 'Location',
      id: 'locationName',
      accessor: (d) => (d.asset && d.asset.location ? d.asset.location.name : null),
      maxWidth: 120,
      filterable: true,
    },
    {
      Header: 'Asset',
      id: 'assetName',
      accessor: (d) => (d.asset ? d.asset.fullName + (d.installLocation ? ` ${d.installLocation}` : '') : null),
      minWidth: 110,
      filterable: true,
    },
    {
      Header: 'Name',
      accessor: 'name',
      minWidth: 100,
      filterable: true,
    },
    // {
    //   Header: 'Config',
    //   id: 'deviceConfig',
    //   accessor: d => (d.deviceConfig ? d.deviceConfig.name : null),
    //   maxWidth: 80,
    //   filterable: true,
    // },
    {
      Header: 'Serial',
      accessor: 'serial',
      maxWidth: 50,
      filterable: true,
    },
    {
      Header: 'Legacy serial',
      accessor: 'legacy_serial',
      maxWidth: 50,
      filterable: true,
    },
    {
      Header: 'Owner',
      id: 'owner',
      accessor: (d) => (d.owner ? d.owner.name : null),
      maxWidth: 70,
      filterable: true,
    },
    {
      Header: 'Latest Connection',
      id: 'latestConnection',
      accessor: (d) => displayTimestamp(d, 'latestConnection'),
      sortMethod: timestampSort,
      maxWidth: 50,
      filterable: true,
    },
  ];

  toggleRow = (rowId) => {
    // start off with the existing state
    this.setState((prevState) => {
      let selected = [...prevState.selected];
      const rowIdIndex = selected.indexOf(rowId);
      // check to see if the rowId exists
      if (rowIdIndex >= 0) {
      // it does exist so we will remove it
        selected = [
          ...selected.slice(0, rowIdIndex),
          ...selected.slice(rowIdIndex + 1),
        ];
      } else {
      // it does not exist so add it
        selected.push(rowId);
      }
      // update the state
      return { selected };
    });
  };

  toggleSelectAll = () => {
    this.setState((prevState) => {
      const selectAll = prevState.selectAll === 1 ? 0 : 1;
      let selected = [];
      if (selectAll) {
        selected = this.getSelectAllRecords();
      }
      return { selectAll, selected };
    });
  };

  isSelected = (rowId) => {
    const rowIdIndex = this.state.selected.indexOf(rowId);
    // check to see if the rowId exists
    if (rowIdIndex >= 0) {
      return true;
    }
    return false;
  };

  // handle selection when filter is changed
  onFilterChange = () => {
    let newSelection = [];
    // If "Select All" is checked, set selected to all filtered rows
    if (this.state.selectAll === 1) {
      newSelection = this.getSelectAllRecords();
    } else {
      // only include current selection ids that in our filtered list
      // get all filteredIds
      const filteredIds = this.table.getResolvedState().sortedData.map((item) => item._original.id);
      // filter selected list to only those in filteredIds
      newSelection = this.state.selected.filter((selectedId) => filteredIds.indexOf(selectedId) > -1);
    }
    this.setState({ selected: newSelection });
  };

  getSelectAllRecords = () => {
    const selected = [];
    // we need to get at the internals of ReactTable
    // the 'sortedData' property contains the currently accessible records based on the filter and sort
    const currentRecords = this.table.getResolvedState().sortedData;
    // we just push all the IDs onto the selected array
    currentRecords.forEach((item) => {
      selected.push(item._original.id);
    });
    return selected;
  };

  clearAllSelected() {
    this.setState({ selectAll: 0, selected: [] });
  }

  componentDidUpdate(prevProps, prevState) {
    // update selected for upstream component
    if (prevState.selected !== this.state.selected) {
      this.props.updateSelectedDevices(this.state.selected);
    }
    if (prevProps.clearSelectedDevices !== this.props.clearSelectedDevices && this.props.clearSelectedDevices === true) {
      this.clearAllSelected();
    }
  }

  render() {
    const sorting = [
      { id: 'companyName', asc: true },
      { id: 'locationName', asc: true },
      { id: 'assetName', asc: true },
      { id: 'name', asc: true },
    ];
    return (
      <div>
        <ReactTable
          ref={(r) => (this.table = r)}
          data={this.props.devices}
          columns={this.columns}
          minRows={1}
          defaultPageSize={5}
          className="-striped -highlight"
          defaultSorted={sorting}
          defaultFilterMethod={this.props.filterMethod}
          onFilteredChange={this.onFilterChange}
        />
      </div>
    );
  }
}
DeviceGridMultiSelect.defaultProps = {
  clearSelectedDevices: [],
  filterMethod: (filter, row) => containsFilter(filter, row),
};
export default DeviceGridMultiSelect;
