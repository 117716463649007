import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { connect } from 'react-redux';
import GridBase from '../common/GridBase';
import { formatRole } from '../common/functions';
import ContextMenuWithTrigger from '../common/ContextMenuWithTrigger';
import Modal from '../common/Modal';
import UserUpdate from './UserUpdate';
import ResetPassword from './ResetPassword';
import { UserGridQuery } from '../../graphql/queries/UserQueries';
import { DeleteUserMutation } from '../../graphql/mutations/UserMutations';
import DeleteModel from '../common/DeleteModel';
import SendPasswordEmail from './SendPasswordEmail';
import SendWelcomeEmail from './SendWelcomeEmail';
import userFormFields from './userFormFields';
import displayTimestamp from '../common/displayTimestamp';
import timestampSort from '../common/timestampSort';

class UserGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      user: null,
      delete: { id: null, name: '' },
      passwordReset: { id: null, name: '' },
      sendWelcome: { id: null, name: '' },
      edit: '',
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleDeleteClose = this.handleDeleteClose.bind(this);
    this.handlePasswordResetClose = this.handlePasswordResetClose.bind(this);
    this.handleSendWelcomeClose = this.handleSendWelcomeClose.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  columns = [
    {
      Header: 'ID',
      accessor: 'id',
      maxWidth: 90,
      filterable: true,
    },
    {
      Header: 'Company',
      id: 'companyName',
      accessor: (d) => d.company.name,
      maxWidth: 200,
      filterable: true,
    },
    {
      Header: 'Company Id',
      id: 'companyId',
      accessor: (d) => d.company.id,
      show: false,
    },
    {
      Header: 'First Name',
      accessor: 'firstName',
      maxWidth: 200,
      filterable: true,
    },
    {
      Header: 'Last Name',
      accessor: 'lastName',
      maxWidth: 200,
      filterable: true,
    },
    {
      Header: 'Email',
      accessor: 'email',
      minWidth: 200,
      filterable: true,
    },
    {
      Header: 'Role',
      id: 'role',
      accessor: (d) => {
        if (d.role != null) {
          return formatRole(d.role);
        }
        return '';
      },
      filterable: true,
    },
    {
      Header: 'Latest Visit',
      id: 'latestVisit',
      accessor: (d) => displayTimestamp(d, 'latestVisit'),
      sortMethod: timestampSort,
      minWidth: 100,
      filterable: true,
    },
    {
      Header: 'Email Notifications',
      id: 'receiveEmail',
      accessor: (d) => (d.receiveEmail === true ? 'Yes' : 'No'),
      maxWidth: 100,
      filterable: true,
    },
    {
      Header: 'Text Notifications',
      id: 'receiveSMS',
      accessor: (d) => (d.receiveSMS === true ? 'Yes' : 'No'),
      maxWidth: 100,
      filterable: true,
    },
    {
      Header: '',
      maxWidth: 23,
      resizable: false,
      sortable: false,
      Cell: ({ row }) => {
        const optionsMenu = [
          { key: 'edit', label: 'edit', action: 'Edit' },
          { key: 'sendPasswordEmail', label: 'password reset', action: 'PasswordReset' },
          { key: 'sendWelcomeEmail', label: 'welcome email', action: 'SendWelcome' },
          { key: 'delete', label: 'delete', action: 'Delete' },
        ];
        if (this.props && this.props.auth && this.props.auth.isSuperAdmin) {
          optionsMenu.push({ key: 'password', label: 'change password', action: 'Password' });
        }
        return ContextMenuWithTrigger({
          handleClick: this.handleClick,
          row,
          options: optionsMenu,
        });
      },
    },
  ];

  handleClick(e, data, target) {
    if (data.action === 'Edit') {
      this.setState({ user: data.row, modalIsOpen: true, edit: 'user' });
    } else if (data.action === 'Delete') {
      this.setState({ delete: { id: data.row.id, name: data.row.email } });
    } else if (data.action === 'Password') {
      this.setState({ user: data.row, modalIsOpen: true, edit: 'password' });
    } else if (data.action === 'PasswordReset') {
      this.setState({ passwordReset: { id: data.row.id, name: data.row.email } });
    } else if (data.action === 'SendWelcome') {
      this.setState({ sendWelcome: { id: data.row.id, name: data.row.email } });
    }
  }

  handleDeleteClose() {
    this.setState({ delete: { id: null, name: '' } });
  }

  handlePasswordResetClose() {
    this.setState({ passwordReset: { id: null, name: '' } });
  }

  handleSendWelcomeClose() {
    this.setState({ sendWelcome: { id: null, name: '' } });
  }

  render() {
    const { data: { loading, error, users } } = this.props;
    const sorting = [
      { id: 'companyName', asc: true },
      { id: 'email', asc: true },
    ];
    const multiSelect = {
      canSend: true,
      fields: userFormFields,
      modelName: 'user',
      onSubmit: this.props.data.refetch,
    };
    if (loading) {
      return <p>Loading...</p>;
    } if (error) {
      return <p>Error loading users!</p>;
    }
    return (
      <div>
        <GridBase
          dataVar={users}
          columns={this.columns}
          sorting={sorting}
          multiSelect={multiSelect}
        />

        <DeleteModel
          deleteMutation={DeleteUserMutation}
          refetchQueries={[{ query: UserGridQuery }]}
          id={this.state.delete.id}
          name={this.state.delete.name}
          onClose={this.handleDeleteClose}
        />

        <SendPasswordEmail
          id={this.state.passwordReset.id}
          name={this.state.passwordReset.name}
          onClose={this.handlePasswordResetClose}
        />

        <SendWelcomeEmail
          id={this.state.sendWelcome.id}
          name={this.state.sendWelcome.name}
          onClose={this.handleSendWelcomeClose}
        />

        <Modal
          onClose={this.closeModal}
          modalIsOpen={this.state.modalIsOpen}
          contentLabel="Edit User"
        >
          {(() => {
            switch (this.state.edit) {
              case 'user':
                return <UserUpdate user={this.state.user} />;
              case 'password':
                return <ResetPassword user={this.state.user} />;
              default:
                return <div />;
            }
          })()}
        </Modal>
      </div>
    );
  }
}

function mapStateToProps({ auth }) {
  return { auth };
}
UserGrid = connect(mapStateToProps)(UserGrid);
export default graphql(UserGridQuery)(UserGrid);
