import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import GridBase from '../common/GridBase';
import { TableGridQuery } from '../../graphql/queries/TableQueries';
import ContextMenuWithTrigger from '../common/ContextMenuWithTrigger';
import Modal from '../common/Modal';
import TableUpdate from './TableUpdate';
import { DeleteTableMutation } from '../../graphql/mutations/TableMutations';
import DeleteModel from '../common/DeleteModel';

class TableGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      table: null,
      delete: { id: null, name: '' },
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleDeleteClose = this.handleDeleteClose.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  columns = [
    {
      Header: 'ID',
      accessor: 'id',
      maxWidth: 90,
      filterable: true,
    },
    {
      Header: 'Table Name',
      accessor: 'name',
      filterable: true,
    },
    {
      Header: 'Table Title',
      accessor: 'title',
      filterable: true,
    },
    {
      Header: 'Raw Data',
      id: 'raw',
      accessor: (d) => (d.raw === true ? 'Yes' : 'No'),
      filterable: true,
      maxWidth: 150,
    },
    {
      Header: 'Most Recent Data',
      id: 'mostRecent',
      accessor: (d) => (d.mostRecent === true ? 'Yes' : 'No'),
      filterable: true,
      maxWidth: 150,
    },
    {
      Header: 'Show Date Column',
      id: 'dateColumn',
      accessor: (d) => (d.dateColumn === true ? 'Yes' : 'No'),
      filterable: true,
      maxWidth: 150,
    },
    {
      Header: '',
      maxWidth: 23,
      resizable: false,
      sortable: false,
      Cell: ({ row }) => ContextMenuWithTrigger({
        handleClick: this.handleClick,
        row,
      }),
    },
  ];

  // handle context menu clicks
  handleClick(e, data, target) {
    if (data.action === 'Edit') {
      this.setState({
        table: data.row,
        modalIsOpen: true,
      });
    } else if (data.action === 'Delete') {
      this.setState({
        delete: { id: data.row.id, name: data.row.name },
      });
    }
  }

  // reset delete table id and name to null upon deletion
  handleDeleteClose() {
    this.setState({ delete: { id: null, name: '' } });
  }

  render() {
    const {
      data: { loading, error, defaultTables },
    } = this.props;
    const sorting = [{ id: 'name', asc: true }];
    const tables = defaultTables;
    if (loading) {
      return <p>Loading...</p>;
    } if (error) {
      return <p>Error loading tables!</p>;
    }
    return (
      <div>
        <GridBase dataVar={tables} columns={this.columns} sorting={sorting} />

        <DeleteModel
          deleteMutation={DeleteTableMutation}
          refetchQueries={[{ query: TableGridQuery }]}
          id={this.state.delete.id}
          name={this.state.delete.name}
          onClose={this.handleDeleteClose}
        />

        <Modal
          onClose={this.closeModal}
          modalIsOpen={this.state.modalIsOpen}
          contentLabel="Edit Table"
        >
          <TableUpdate table={this.state.table} />
        </Modal>
      </div>
    );
  }
}

export default graphql(TableGridQuery)(TableGrid);
