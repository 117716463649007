import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import Typography from '@material-ui/core/Typography';
import FieldFileUpload from '../form/FieldFileUpload';
import FormSubmitButton from '../form/FormSubmitButton';
import ClearButton from '../form/ClearButton';

class AdminFileUploadForm extends Component {
  render() {
    const {
      handleSubmit, pristine, reset, submitting,
    } = this.props;
    return (
      <div>
        <form onSubmit={handleSubmit}>
          <Field
            name="file"
            accept=".csv"
            onError={this.handleFileError}
            component={FieldFileUpload}
          />
          <br />

          <div>
            <FormSubmitButton pristine={pristine} submitting={submitting} />
            <ClearButton
              pristine={pristine}
              submitting={submitting}
              reset={reset}
            />
          </div>
        </form>
        <Typography variant="h5">Instructions</Typography>
        <p>
          Upload Admin File
        </p>
      </div>
    );
  }
}

export default reduxForm({
  form: 'AdminFileUpload',
})(AdminFileUploadForm);
