import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';

import DeviceTypeForm from './DeviceTypeForm';
import FormBase from '../../form/FormBase';
import { CreateDeviceTypeMutation } from '../../../graphql/mutations/DeviceTypeMutations';
import { DeviceTypeGridQuery } from '../../../graphql/queries/DeviceTypeQueries';
import handleDeviceTypeMutation from '../../../graphql/functions/handleDeviceTypeMutation';

class DeviceTypeNew extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    if (typeof values === 'object' && values.fields) {
      for (let i = 0; i < values.fields.length; i++) {
        if (values.fields[i].rule && values.fields[i].rule.id) {
          values.fields[i].rule = values.fields[i].rule.id;
        }
      }
    }
    handleDeviceTypeMutation({
      values,
      mutation: this.props.createDeviceTypeMutation,
      onSuccess: this.props.onSuccess,
      refetchQueries: [{ query: DeviceTypeGridQuery }],
    });
  }

  render() {
    return (
      <FormBase header="Add Device Type">
        <DeviceTypeForm onSubmit={this.handleSubmit} />
      </FormBase>
    );
  }
}

export default compose(
  graphql(CreateDeviceTypeMutation, { name: 'createDeviceTypeMutation' }),
)(DeviceTypeNew);
