import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import CircularProgressbar from 'react-circular-progressbar';
import LoadingOverlay from 'react-loading-overlay';

LoadingOverlay.propTypes = undefined;
class PSLoader extends Component {
  render() {
    const { showLoader, percentage, children } = this.props;
    let message;
    if (percentage !== null && typeof percentage !== 'undefined') {
      message = (
        <div>
          <Typography variant="h6">
            <i>Loading...</i>
          </Typography>
          <div style={{ margin: 'auto', width: '100px' }}>
            <CircularProgressbar percentage={percentage} />
          </div>
        </div>
      );
    } else {
      message = (
        <div>
          <Typography variant="h6">
            <i>Loading...</i>
          </Typography>
        </div>
      );
    }
    return (
      <div>
        <LoadingOverlay
          active={showLoader}
          styles={{
            overlay: (base) => ({
              ...base,
              background: 'rgba(17, 51, 84, 0.5)',
            }),
          }}
          text={message}
        >
          {children}
        </LoadingOverlay>
      </div>
    );
  }
}

export default PSLoader;
