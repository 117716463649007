import { gql } from '@apollo/client';
import { ap2200FirmwareFragment } from '../fragments';

const Ap2200FirmwareQuery = gql`
  query ap2200Firmware($id: ID!) {
    ap2200Firmware(id: $id) {
      ...ap2200Firmware
    }
  }
  ${ap2200FirmwareFragment}
`;

const Ap2200FirmwareGridQuery = gql`
  query Ap2200FirmwareGridQuery {
    ap2200Firmwares {
      ...ap2200Firmware
    }
  }
  ${ap2200FirmwareFragment}
`;

const Ap2200FirmwareDropdownQuery = gql`
  query ap2200Firmwares {
    ap2200Firmwares {
      ...ap2200Firmware
    }
  }
  ${ap2200FirmwareFragment}
`;

export { Ap2200FirmwareGridQuery, Ap2200FirmwareDropdownQuery, Ap2200FirmwareQuery };
