import React, { Component } from 'react';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import _ from 'lodash';

class ContextMenuWithTriggerComponent extends Component {
  constructor(props) {
    super(props);
    this.renderOptions = this.renderOptions.bind(this);
  }

  renderOptions() {
    let options = [];
    if (!this.props.options) {
      if (this.props.canEdit === true) {
        if (this.props.duplicateOption === true) {
          options = [
            { key: 'edit', label: 'edit', action: 'Edit' },
            { key: 'duplicate', label: 'duplicate', action: 'Duplicate' },
          ];
        } else {
          options = [
            { key: 'edit', label: 'edit', action: 'Edit' },
          ];
        }
      }
      if (this.props.canDelete === true) {
        options.push({ key: 'delete', label: 'delete', action: 'Delete' });
      }
      if (
        this.props.additionalOptions
        && Array.isArray(this.props.additionalOptions)
        && this.props.additionalOptions.length > 0
      ) {
        options = options.concat(this.props.additionalOptions);
      }
    } else {
      options = this.props.options;
    }
    return _.map(options, ({ key, label, action }) => (
      <MenuItem
        key={`${key}_${this.props.row.id}`}
        onClick={this.props.handleClick}
        data={{
          action,
          row: this.props.row,
        }}
      >
        {label}
      </MenuItem>
    ));
  }

  render() {
    const { row, disable } = this.props;

    return (
      <div>
        <ContextMenuTrigger
          name={row.id}
          id={`actions_${row.id}`}
          holdToDisplay={0}
          disable={disable}
        >
          <div key={`menu_${row.id}`}>
            <div className="ps-cursor-pointer">
              <span className="left">
                <i className="material-icons tiny">more_vert</i>
              </span>
            </div>
          </div>
        </ContextMenuTrigger>
        <ContextMenu style={{ height: 'auto' }} id={`actions_${row.id}`}>
          {this.renderOptions()}
        </ContextMenu>
      </div>
    );
  }
}
ContextMenuWithTriggerComponent.defaultProps = {
  disable: false,
  duplicateOption: false,
  canDelete: true,
  canEdit: true,
  additionalOptions: [],
};
export default function ContextMenuWithTrigger({
  handleClick, row, additionalOptions, duplicateOption, canDelete, canEdit, disable, options,
}) {
  return (
    <ContextMenuWithTriggerComponent
      options={options}
      additionalOptions={additionalOptions}
      duplicateOption={duplicateOption}
      row={row}
      handleClick={handleClick}
      disable={disable}
      canDelete={canDelete}
      canEdit={canEdit}
    />
  );
}
