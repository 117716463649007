import React from 'react';

export default function FormField(props) {
  const {
    input,
    label,
    type,
    placeholder,
    meta: { asyncValidating, touched, error },
  } = props;
  const disabled = props.disabled === undefined ? false : props.disabled;
  return (
    <div>
      <label>{label}</label>
      <div className={asyncValidating ? 'async-validating' : ''}>
        <input
          {...input}
          placeholder={placeholder}
          type={type}
          disabled={disabled}
        />
        <div className="ps-text-red" style={{ marginBottom: '20px' }}>
          {touched && error}
        </div>
      </div>
    </div>
  );
}
