import React, { Component } from 'react';
import { connect } from 'react-redux';
import FixedActionButton from '../common/FixedActionButton';
import LocationGrid from './LocationGrid';
import Modal from '../common/Modal';
import PSCard from '../common/PSCard';
import LocationNew from './LocationNew';
import InstallerConfigGrid from './installerConfig/InstallerConfigGrid';
import InstallerConfigNew from './installerConfig/InstallerConfigNew';
import InstallerConfigFieldGrid from './installerConfig/installerConfigField/InstallerConfigFieldGrid';
import InstallerConfigFieldNew from './installerConfig/installerConfigField/InstallerConfigFieldNew';

class LocationMain extends Component {
  constructor(props) {
    super(props);
    this.state = { modalIsOpen: false, showInstallerConfig: false };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  getModalOptions() {
    const modalOptions = [
      {
        onClick: () => {
          this.openModal('Location');
        },
        title: 'Add Location',
        name: 'location',
      },
    ];
    if (this.props.auth && this.props.auth.isSuperAdmin && this.state.showInstallerConfig) {
      modalOptions.unshift(
        {
          onClick: () => {
            this.openModal('Installer Configuration');
          },
          title: 'Add Installer Configuration',
          name: 'installerConfiguration',
        },
        {
          onClick: () => {
            this.openModal('Installer Configuration Field');
          },
          title: 'Add Installer Configuration Field',
          name: 'installerConfigurationField',
        },
      );
    }
    return modalOptions;
  }

  openModal(type) {
    this.setState({ modalIsOpen: true, modalType: type });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    return (
      <div>
        <PSCard cardTitle="Locations">
          <LocationGrid />
        </PSCard>

        {this.props.auth && this.props.auth.isSuperAdmin && this.state.showInstallerConfig ? (
          <div>
            <PSCard cardTitle="Installer Configurations">
              <InstallerConfigGrid />
            </PSCard>
            <PSCard cardTitle="Installer Configuration Fields">
              <InstallerConfigFieldGrid />
            </PSCard>
          </div>
        ) : (
          <div />
        )}
        <div>
          <FixedActionButton options={this.getModalOptions()} />
        </div>
        <Modal
          onClose={this.closeModal}
          modalIsOpen={this.state.modalIsOpen}
          contentLabel={`Add ${this.state.modalType}`}
        >
          {(() => {
            switch (this.state.modalType) {
              case 'Installer Configuration Field':
                return <InstallerConfigFieldNew />;
              case 'Installer Configuration':
                return <InstallerConfigNew />;
              case 'Location':
                return <LocationNew />;
              default:
                return <LocationNew />;
            }
          })()}
        </Modal>
      </div>
    );
  }
}
function mapStateToProps({ auth }) {
  return { auth };
}
export default connect(mapStateToProps)(LocationMain);
