import handleMutation from './handleMutation';
import formatTableColumns from './formatTableColumns';

const handleSubmitTable = (props) => {
  handleMutation({
    mutate: props.mutation,
    variables: { table: props.values },
    refetchQueries: props.refetchQueries,
    onSuccess: props.onSuccess,
  });
};

export default (props) => {
  const { values } = props;
  console.log('submit', values);
  if (typeof values === 'object' && values !== null) {
    // reformat columns
    const formattedTable = formatTableColumns(values);
    console.log('formattedTable', formattedTable);
    values.columns = formattedTable.columns;
    handleSubmitTable(props);
  }
};
