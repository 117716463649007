import { gql } from '@apollo/client';

const DataLogGridQuery = gql`
  query DataLogGridQuery(
    $searchTerm: String,
    $t1: Int,
    $t2: Int
  ) {
    dataLogs(
      searchTerm: $searchTerm,
      t1: $t1,
      t2: $t2
    ) {
      createdAt
      dataJson
      ipAddress
    }
  }
`;

export {
  DataLogGridQuery,
};
