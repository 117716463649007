import { gql } from '@apollo/client';
import { attributeAllFragment } from '../fragments';

const AttributeGridQuery = gql`
  query AttributeGridQuery {
    attributes {
      id
      name
      key
      browsable
      units
      color
      factor
      offset
      round
      rule
      legacy_id
      modelType
      cumulative
      adminOnly
      dataType {
        id
        name
      }
      attributeType {
        id
        name
      }
    }
  }
`;

const AttributeDropdownQuery = gql`
  query AttributeDropdownQuery {
    attributes {
      id
      name
    }
  }
`;

const AttributeSelectQuery = gql`
  query DataTypeAttributeQuery {
    dataTypes {
      id
      name
      attributes {
        id
        name
      }
    }
  }
`;

const AttributeQuery = gql`
  query attribute($id: ID!) {
    attribute(id: $id) {
      ...attributeAll
    }
  }
  ${attributeAllFragment}
`;

export {
  AttributeDropdownQuery,
  AttributeSelectQuery,
  AttributeQuery,
  AttributeGridQuery,
};
