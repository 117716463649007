import axios from 'axios';
import SuccessMessage from '../../SuccessMessage';
import ErrorMessage from '../../ErrorMessage';
import formatMutationValues from '../../../../graphql/functions/formatMutationValues';
import { REACT_APP_BACKEND } from '../../../../config';

export default async (props) => {
  let {
    ids, values, modelName, onError, onSuccess,
  } = props;

  console.log('handleMultiSelectMutation', JSON.stringify(values));
  delete values.multiSelectFields;
  const vals = {};
  vals[modelName] = values;
  values = formatMutationValues({ variables: vals });
  console.log('handleMultiSelectMutation formatMutationValues', JSON.stringify(values));
  const url = `${REACT_APP_BACKEND}/batch/batchMutation`;
  let res;
  try {
    res = await axios.post(url, {
      ids,
      modelName,
      values: values[modelName],
    });
  } catch (err) {
    ErrorMessage({
      msg: `Update failed due to: ${err}`,
    });
    console.log('error', err);
    onError();
    return;
  }
  console.log('res.data', res.data);
  if (res.data.success === false) {
    ErrorMessage({
      msg: `Update failed: ${res.data.msg}`,
    });
    console.log('error', res.data);
    onError();
  } else {
    SuccessMessage({
      msg: 'Update successful!',
    });
    console.log('success', res.data);
    onSuccess();
  }
};
