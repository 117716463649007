import React, { Component } from 'react';
import Tree, { TreeNode } from 'rc-tree';

class PSTree extends Component {
  constructor(props) {
    super(props);
    let initialCheckedKeys = null;
    if (this.props.value !== null && typeof this.props.value !== 'undefined'
      && Array.isArray(this.props.value) && this.props.value.length > 0) {
      initialCheckedKeys = this.props.value.map((val) => {
        if (val.id) {
          return val.id;
        } if (val && typeof val === 'string') {
          return val;
        }
        return null;
      });
    } else if (
      typeof this.props.value === 'object'
      && this.props.value !== null
    ) {
      if (this.props.value.id) {
        initialCheckedKeys = [this.props.value.id];
      }
    } else if (this.props.value && typeof this.props.value === 'string') {
      initialCheckedKeys = [this.props.value];
    }
    this.state = {
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: initialCheckedKeys,
    };

    this.loop = this.loop.bind(this);
  }

  onExpand = (expandedKeys) => {
    // if not set autoExpandParent to false, if children expanded, parent cannot collapse.
    // or, you can remove all expanded chilren keys.
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });
  };

  onCheck = (allCheckedKeys, e) => {
    let checkedKeys = [];
    // Filter out keys to only child nodes
    // eslint-disable-next-line
    checkedKeys = e.checkedNodes.filter(node => {
      if (node && node.props && !node.props.children) {
        return true;
      }
    }).map((node) => node.key);

    // // Remove expanded keys from checked keys
    // const expanded = this.state.expandedKeys;
    // if (expanded && Array.isArray(expanded) && expanded.length > 0) {
    //   expanded.forEach(expId => {
    //     const index = checkedKeys.indexOf(expId);
    //     if (index > -1) {
    //       checkedKeys.splice(index, 1);
    //     }
    //   });
    // }
    // Not able to select multiple-> remove last checked
    const currentKeys = this.state.checkedKeys;
    if (this.props.multiple === false) {
      // if more than one checked key
      if (checkedKeys.length > 1) {
        const currId = currentKeys[0];
        // remove the original value
        const index = checkedKeys.indexOf(currId);
        if (index > -1) {
          checkedKeys.splice(index, 1);
        }
      }
    }

    this.setState({
      checkedKeys,
    });
    this.props.onChange(checkedKeys);
  };

  loop = (data) => data.map((item) => {
    const _this = this;

    const objKeys = Object.keys(item);
    for (let i = 0; i < objKeys.length; i++) {
      if (
        item[objKeys[i]]
          && item[objKeys[i]] !== 'undefined'
          && Array.isArray(item[objKeys[i]])
      ) {
        let disableCheckbox = false;
        if (this.props.multiple === false) {
          disableCheckbox = true;
        }
        return (
          <TreeNode
            disableCheckbox={disableCheckbox}
            key={item[_this.props.valueField]}
            title={item[_this.props.textField]}
          >
            {_this.loop(item[objKeys[i]])}
          </TreeNode>
        );
      }
    }
    return (
      <TreeNode
        key={item[this.props.valueField]}
        title={item[this.props.textField]}
      />
    );
  });

  render() {
    return (
      <div style={{ padding: '0 20px' }}>
        <Tree
          showIcon={false}
          checkable={this.props.checkable}
          selectable={false}
          onExpand={this.onExpand}
          expandedKeys={this.state.expandedKeys}
          autoExpandParent={this.state.autoExpandParent}
          onCheck={this.onCheck}
          checkedKeys={this.state.checkedKeys}
        >
          {this.props.data && this.loop(this.props.data)}
        </Tree>
      </div>
    );
  }
}
PSTree.defaultProps = { multiple: true };
export default PSTree;
