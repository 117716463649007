import React, { Component } from 'react';
import axios from 'axios';
import { graphql } from '@apollo/client/react/hoc';
import ConfirmDialog from '../../common/ConfirmDialog';
import SuccessMessage from '../../common/SuccessMessage';
import ErrorMessage from '../../common/ErrorMessage';
import PSLoader from '../../common/PSLoader';
import FormBase from '../../form/FormBase';
import { REACT_APP_BACKEND } from '../../../config';
import { Ap2200FirmwareGridQuery } from '../../../graphql/queries/Ap2200FirmwareQueries';
import Ap2200FirmwareUploadForm from './Ap2200FirmwareUploadForm';

class Ap2200FirmwareUpload extends Component {
  constructor(props) {
    super(props);
    this.handleFileError = this.handleFileError.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onDialogConfirm = this.onDialogConfirm.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.uploadFirmwareFile = this.uploadFirmwareFile.bind(this);
    this.state = {
      showLoader: false,
      dialogOpen: false,
      firmwareFileName: '',
      fileValues: null,
    };
  }

  handleFileError(err) {
    console.log(err);
    ErrorMessage({
      msg: err,
      timeOut: 30000,
    });
  }

  async handleSubmit(values) {
    if (!values.fileData) {
      ErrorMessage({
        msg: 'Please submit a file',
      });
      return;
    }
    const url = `${REACT_APP_BACKEND}/ap2200/listFirmwareFiles`;
    let fileNames = '';
    try {
      const res = await axios.get(url);
      fileNames = res.data;
    } catch (err) {
      ErrorMessage({
        msg: 'Unable to load firmware file names',
      });
      return;
    }
    if (fileNames.includes(values.fileData.name)) {
      this.setState({
        dialogOpen: true,
        firmwareFileName: values.fileData.name,
        fileValues: values,
      });
    } else {
      this.setState({ fileValues: values });
      this.uploadFirmwareFile();
    }
  }

  async uploadFirmwareFile() {
    this.setState({ showLoader: true });
    const formData = new FormData();
    formData.append('fileData', this.state.fileValues.fileData);
    // eslint-disable-next-line no-unused-vars
    let res;
    try {
      res = await axios.post(`${REACT_APP_BACKEND}/ap2200/uploadFirmware`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      this.setState({ showLoader: false, fileValues: null });
      SuccessMessage({
        msg: 'Upload successful!',
        timeOut: 60000,
      });
      const {
        data: { refetch },
      } = this.props;
      // refetch to upload if "fileExists"
      refetch();
      this.props.closeModal();
    } catch (err) {
      let msg = 'Upload failed';
      if (err && err.response && err.response.data) {
        msg += `: ${err.response.data}`;
      }
      this.setState({ showLoader: false, fileValues: null });
      ErrorMessage({
        msg,
        timeOut: 60000,
      });
    }
  }

  onDialogConfirm() {
    // upload file
    this.uploadFirmwareFile();
  }

  closeDialog() {
    this.setState({
      dialogOpen: false,
      firmwareFileName: '',
      fileValues: null,
    });
  }

  render() {
    return (
      <div>
        <FormBase header="Upload AP2200 Firmware File">
          <PSLoader showLoader={this.state.showLoader}>
            <Ap2200FirmwareUploadForm
              onSubmit={this.handleSubmit}
              handleFileError={this.handleFileError}
            />
          </PSLoader>
        </FormBase>
        <ConfirmDialog
          title="File Exists!"
          open={this.state.dialogOpen}
          message={`Overwrite firmware file ${this.state.firmwareFileName}?`}
          onConfirm={this.onDialogConfirm}
          onClose={this.closeDialog}
        />
      </div>
    );
  }
}

export default graphql(Ap2200FirmwareGridQuery)(Ap2200FirmwareUpload);
