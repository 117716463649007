import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import GridBase from '../common/GridBase';
import {
  CompanyUserGridQuery,
  LocationUserGridQuery,
  AssetUserGridQuery,
} from '../../graphql/queries/AccessControlQueries';
import ContextMenuWithTrigger from '../common/ContextMenuWithTrigger';
import {
  DeleteCompanyUserMutation,
  DeleteAssetUserMutation,
  DeleteLocationUserMutation,
} from '../../graphql/mutations/AccessControlMutations';
import DeleteModel from '../common/DeleteModel';

class AccessControlGridBase extends Component {
  constructor(props) {
    super(props);
    let deleteMutation;
    switch (props.model) {
      case 'company':
        deleteMutation = DeleteCompanyUserMutation;
        break;
      case 'location':
        deleteMutation = DeleteLocationUserMutation;
        break;
      case 'asset':
        deleteMutation = DeleteAssetUserMutation;
        break;

      default:
        break;
    }
    this.state = {
      model: props.model,
      companyUser: { id: null },
      locationUser: { id: null },
      assetUser: { id: null },
      delete: { id: null, name: '' },
      deleteMutation,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleDeleteClose = this.handleDeleteClose.bind(this);
    this.checkUser = this.checkUser.bind(this);

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  getColumns() {
    let columns = [
      {
        Header: 'ID ',
        accessor: 'id',
        show: false,
      },
    ];
    switch (this.props.model) {
      case 'company':
        columns.push({
          Header: 'Company',
          id: 'company',
          accessor: (d) => (d.company ? d.company.name : ''),
          minWidth: 70,
          filterable: true,
        });
        break;
      case 'location':
        columns.push({
          Header: 'Company',
          id: 'company',
          accessor: (d) => (d.location && d.location.company ? d.location.company.name : ''),
          minWidth: 70,
          filterable: true,
        });
        columns.push({
          Header: 'Location',
          id: 'location',
          accessor: (d) => (d.location ? d.location.name : ''),
          minWidth: 70,
          filterable: true,
        });
        break;
      case 'asset':
        columns.push({
          Header: 'Company',
          id: 'company',
          accessor: (d) => (d.asset && d.asset.location && d.asset.location.company ? d.asset.location.company.name : ''),
          minWidth: 70,
          filterable: true,
        });
        columns.push({
          Header: 'Location',
          id: 'location',
          accessor: (d) => (d.asset && d.asset.location ? d.asset.location.name : ''),
          minWidth: 70,
          filterable: true,
        });
        columns.push({
          Header: 'Asset',
          id: 'asset',
          accessor: (d) => (d.asset ? d.asset.name : ''),
          minWidth: 70,
          filterable: true,
        });
        break;
      default:
        columns.push({
          Header: 'Model',
          id: this.props.model,
          accessor: (d) => (d[`${this.props.model}`] ? d[`${this.props.model}`].name : ''),
          minWidth: 70,
          filterable: true,
        });
    }
    columns = columns.concat([
      {
        Header: 'User',
        id: 'user',
        accessor: (d) => (d.user ? d.user.fullName : ''),
        minWidth: 70,
        filterable: true,
      },
      {
        Header: 'User Id',
        id: 'userId',
        accessor: (d) => (d.user ? d.user.id : ''),
        minWidth: 70,
        show: false,
      },
      {
        Header: '',
        maxWidth: 23,
        resizable: false,
        sortable: false,
        Cell: ({ row }) => ContextMenuWithTrigger({
          options: [
            { key: 'delete', label: 'Revoke Access', action: 'Delete' },
          ],
          disable: this.checkUser(row),
          handleClick: this.handleClick,
          row,
        }),
      },
    ]);
    return columns;
  }

  // handle context menu clicks
  handleClick(e, data, target) {
    if (data.action === 'Edit') {
      this.setState({
        [`${this.props.model}User`]: data.row,
        modalIsOpen: true,
      });
    } else if (data.action === 'Delete') {
      this.setState({
        delete: {
          id: data.row.id,
          name: `${data.row[this.props.model]} ${data.row.user}`,
        },
      });
    }
  }

  // reset delete modelUser id and name to null upon deletion
  handleDeleteClose() {
    this.setState({ delete: { id: null, name: '' } });
  }

  checkUser(row) {
    if (this.props.auth.id === row.userId) {
      return true;
    }
    return false;
  }

  render() {
    const {
      ModelUserQuery: { loading, error, modelUsers },
    } = this.props;
    let sorting;
    switch (this.props.model) {
      case 'company':
        sorting = [{ id: 'company', asc: true }];
        break;
      case 'location':
        sorting = [{ id: 'company', asc: true }, { id: 'location', asc: true }];
        break;
      case 'asset':
        sorting = [{ id: 'company', asc: true }, { id: 'location', asc: true }, { id: 'asset', asc: true }];
        break;
      default:
        sorting = [{ id: 'name', asc: true }];
    }
    sorting.push({ id: 'user', asc: true });
    if (loading || !this.props.auth || !this.props.auth.id) {
      return <p>Loading...</p>;
    } if (error) {
      return <p>Error loading data!</p>;
    }
    return (
      <div>
        <GridBase
          dataVar={modelUsers}
          columns={this.getColumns()}
          sorting={sorting}
        />

        <DeleteModel
          deleteMutation={this.state.deleteMutation}
          refetchQueries={[
            { query: CompanyUserGridQuery },
            { query: LocationUserGridQuery },
            { query: AssetUserGridQuery },
          ]}
          id={this.state.delete.id}
          name={this.state.delete.name}
          onClose={this.handleDeleteClose}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => ({ auth });

export default compose(
  connect(mapStateToProps),

  graphql(CompanyUserGridQuery, {
    name: 'ModelUserQuery',
    skip: (ownProps) => ownProps.model !== 'company',
    props: ({ ownProps, ModelUserQuery: { loading, error, companyUsers } }) => ({
      ModelUserQuery: {
        modelUsers: companyUsers,
        loading,
        error,
      },
    }),
  }),
  graphql(LocationUserGridQuery, {
    name: 'ModelUserQuery',
    skip: (ownProps) => ownProps.model !== 'location',
    props: ({
      ownProps,
      ModelUserQuery: { loading, error, locationUsers },
    }) => ({
      ModelUserQuery: {
        modelUsers: locationUsers,
        loading,
        error,
      },
    }),
  }),
  graphql(AssetUserGridQuery, {
    name: 'ModelUserQuery',
    skip: (ownProps) => ownProps.model !== 'asset',
    props: ({ ownProps, ModelUserQuery: { loading, error, assetUsers } }) => ({
      ModelUserQuery: {
        modelUsers: assetUsers,
        loading,
        error,
      },
    }),
  }),
)(AccessControlGridBase);
