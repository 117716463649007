import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';

import handleMutation from '../../graphql/functions/handleMutation';
import ModelDashboardForm from './ModelDashboardForm';
import FormBase from '../form/FormBase';
import { ModelDashboardGridQuery } from '../../graphql/queries/ModelDashboardQueries';
import { CreateModelDashboardMutation } from '../../graphql/mutations/ModelDashboardMutations';

class ModelDashboardNew extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // submit mutation and refetch modelDashboard grid query
  handleSubmit(values) {
    handleMutation({
      mutate: this.props.mutate,
      variables: { modelDashboard: values },
      refetchQueries: [{ query: ModelDashboardGridQuery }],
      onSuccess: this.props.onSuccess,
    });
  }

  render() {
    return (
      <FormBase header="Create Model Dashboard">
        <ModelDashboardForm onSubmit={this.handleSubmit} />
      </FormBase>
    );
  }
}

const ModelDashboardNewWithMutation = graphql(CreateModelDashboardMutation)(
  ModelDashboardNew,
);

export default ModelDashboardNewWithMutation;
