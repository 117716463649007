import handleMutation from './handleMutation';

const handleSubmitDevice = (props) => {
  console.log('handleSubmitDevice');
  console.log(props);
  handleMutation({
    mutate: props.deviceMutation,
    variables: { device: props.values },
    refetchQueries: props.refetchQueries,
    onSuccess: props.onSuccess,
    update: props.update,
  });
};

export default (props) => {
  const { values } = props;
  console.log(values);

  if (typeof values === 'object' && values !== null) {
    if (
      typeof values.childAsset === 'object'
      && values.childAsset !== null
      && values.childAsset.id
    ) {
      values.asset = values.childAsset.id;
    } else if (
      values.childAsset !== null
      && typeof values.childAsset === 'string'
    ) {
      values.asset = values.childAsset;
    } else if (
      typeof values.asset === 'object'
      && values.asset !== null
      && values.asset.id
    ) {
      values.asset = values.asset.id;
    }

    if (
      typeof values.owner === 'object'
      && values.owner !== null
      && values.owner.id
    ) {
      values.owner = values.owner.id;
    }
    if (
      typeof values.deviceType === 'object'
      && values.deviceType !== null
      && values.deviceType.id
    ) {
      values.deviceType = values.deviceType.id;
    }
    if (
      typeof values.deviceConfig === 'object'
      && values.deviceConfig !== null
      && values.deviceConfig.id
    ) {
      values.deviceConfig = values.deviceConfig.id;
    }
    if (values.installDate && values.installDate > 0) {
      values.installDate *= 1000;
    }
    if (Object.prototype.hasOwnProperty.call(values, 'location')) {
      delete values.location;
    }
    if (Object.prototype.hasOwnProperty.call(values, 'latestIp')) {
      delete values.latestIp;
    }
    if (Object.prototype.hasOwnProperty.call(values, 'company')) {
      delete values.company;
    }
    if (Object.prototype.hasOwnProperty.call(values, 'defaultConfig')) {
      delete values.defaultConfig;
    }
    if (Object.prototype.hasOwnProperty.call(values, 'childAsset')) {
      delete values.childAsset;
    }
    if (Object.prototype.hasOwnProperty.call(values, 'device')) {
      delete values.device;
    }
    if (Object.prototype.hasOwnProperty.call(values, 'deviceGroupAdd')) {
      delete values.deviceGroupAdd;
    }
    if (Object.prototype.hasOwnProperty.call(values, 'deviceGroupRemove')) {
      delete values.deviceGroupRemove;
    }
    if (Object.prototype.hasOwnProperty.call(values, 'deviceGroupCurrentList')) {
      delete values.deviceGroupCurrentList;
    }
    handleSubmitDevice(props);
  }
};
