import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';

import OilSampleTypeForm from './OilSampleTypeForm';
import FormBase from '../form/FormBase';
import { CreateOilSampleTypeMutation } from '../../graphql/mutations/OilSampleTypeMutations';
import {
  OilSampleTypeGridQuery,
} from '../../graphql/queries/OilSampleTypeQueries';
import handleOilSampleTypeMutation from '../../graphql/functions/handleOilSampleTypeMutation';

class OilSampleTypeNew extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    handleOilSampleTypeMutation({
      values,
      mutation: this.props.createOilSampleTypeMutation,
      onSuccess: this.props.onSuccess,
      refetchQueries: [
        { query: OilSampleTypeGridQuery },
      ],
    });
  }

  render() {
    return (
      <FormBase header="Add Oil Sample Type">
        <OilSampleTypeForm onSubmit={this.handleSubmit} />
      </FormBase>
    );
  }
}

export default compose(
  graphql(CreateOilSampleTypeMutation, { name: 'createOilSampleTypeMutation' }),
)(OilSampleTypeNew);
