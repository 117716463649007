import React, { Component } from 'react';
import Edit from '@material-ui/icons/Edit';
import Send from '@material-ui/icons/Send';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import MultiEdit from './multiEdit/MultiEdit';
import Modal from '../Modal';
import MultiDelete from './multiDelete/MultiDelete';
import MultiSend from './multiSend/MultiSend';

class MultiTools extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      modalTitle: '',
      method: '',
    };
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.onSendClick = this.onSendClick.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  onDeleteClick() {
    this.setState({ method: 'delete' });
    this.openModal();
  }

  onEditClick() {
    this.setState({ method: 'edit' });
    this.openModal();
  }

  onSendClick() {
    this.setState({ method: 'send' });
    this.openModal();
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({
      modalIsOpen: false,
    });
    this.props.handleClose();
  }

  render() {
    return (
      <div>
        {this.props.canEdit && (
          <IconButton
            color="primary"
            onClick={this.onEditClick}
            disabled={this.props.disabled}
          >
            <Edit />
          </IconButton>
        )}
        {this.props.canSend && (
          <IconButton
            color="primary"
            onClick={this.onSendClick}
            disabled={this.props.disabled}
          >
            <Send />
          </IconButton>
        )}
        {this.props.canDelete && (
          <IconButton
            color="primary"
            onClick={this.onDeleteClick}
            disabled={this.props.disabled}
          >
            <Delete />
          </IconButton>
        )}
        <Modal onClose={this.closeModal} modalIsOpen={this.state.modalIsOpen}>
          {this.state.method === 'edit' ? (
            <MultiEdit
              fields={this.props.fields}
              showConvertLegacySerialForDevices={this.props.showConvertLegacySerialForDevices}
              modelName={this.props.modelName}
              ids={this.props.ids}
              onSubmit={this.closeModal}
            />
          ) : this.state.method === 'delete' ? (
            <MultiDelete
              modelName={this.props.modelName}
              ids={this.props.ids}
              onSubmit={this.closeModal}
            />
          ) : this.state.method === 'send' ? (
            <MultiSend
              modelName={this.props.modelName}
              ids={this.props.ids}
              onSubmit={this.closeModal}
            />
          ) : (
            <div />
          )}
        </Modal>
      </div>
    );
  }
}

MultiTools.defaultProps = {
  handleClose: () => {},
  canDelete: true,
  canEdit: true,
  canSend: false,
};

export default MultiTools;
