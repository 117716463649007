import React, { Component } from 'react';
import FixedActionButton from '../common/FixedActionButton';
import CompanyGrid from './CompanyGrid';
import Modal from '../common/Modal';
import PSCard from '../common/PSCard';
import CompanyNew from './CompanyNew';

class CompanyMain extends Component {
  constructor() {
    super();
    this.state = { modalIsOpen: false };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    return (
      <div>
        <PSCard cardTitle="Companies">
          <CompanyGrid />
        </PSCard>

        <div>
          <FixedActionButton onClick={this.openModal} />
        </div>
        <Modal
          onClose={this.closeModal}
          modalIsOpen={this.state.modalIsOpen}
          contentLabel="Add Company"
        >
          <CompanyNew />
        </Modal>
      </div>
    );
  }
}

export default CompanyMain;
