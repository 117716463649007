import { toastr } from 'react-redux-toastr';

export default (props) => (
  // show error toast
  toastr.light('Error', props.msg, {
    icon: 'error',
    status: 'error',
    timeOut: props.timeOut || 10000,
  })
);
