import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import Typography from '@material-ui/core/Typography';
import * as compose from 'lodash.flowright';
import { DeviceQuery } from '../../graphql/queries/DeviceQueries';

class DeviceAssignmentSuccess extends Component {
  getDevice(device) {
    console.log('getDevice');
    const updatedDev = {
      name: device.name,
      ownerName: device.owner.name,
      componentName: '',
      assetName: '',
      locationName: '',
      companyName: '',
    };
    if (device && device.asset && device.asset.parent) {
      updatedDev.componentName = device.asset.name;
      updatedDev.assetName = device.asset.parent.name;
      updatedDev.companyName = device.asset.parent.location.company.name;
      updatedDev.locationName = device.asset.parent.location.name;
    } else if (device && device.asset) {
      updatedDev.assetName = device.asset.name;
      updatedDev.companyName = device.asset.location.company.name;
      updatedDev.locationName = device.asset.location.name;
    }
    console.log('device', updatedDev);
    return updatedDev;
  }

  render() {
    console.log(this.props);
    // render form with device values pre-loaded
    const {
      DeviceQuery: { loading, error, device },
    } = this.props;
    if (loading) {
      return <p>Loading...</p>;
    } if (error) {
      return <p>Error loading device!</p>;
    } if (device === null || typeof device === 'undefined') {
      return <p>No device found!</p>;
    }
    const updatedDev = this.getDevice(device);
    return (
      <div>
        <Typography variant="h5">Success!</Typography>
        <ul>
          <li>
            <strong>Name:</strong>
            {updatedDev.name}
          </li>
          <li>
            <strong>Owner:</strong>
            {updatedDev.ownerName}
          </li>
          <li>
            <strong>Company:</strong>
            {updatedDev.companyName}
          </li>
          <li>
            <strong>Location:</strong>
            {updatedDev.locationName}
          </li>
          <li>
            <strong>Asset:</strong>
            {updatedDev.assetName}
          </li>
          {updatedDev.componentName && (
          <li>
            <strong>Component:</strong>
            {updatedDev.componentName}
          </li>
          )}
        </ul>
      </div>
    );
  }
}
export default compose(
  graphql(DeviceQuery, {
    name: 'DeviceQuery',
    options: (props) => ({ variables: { id: props.id } }),
  }),
)(DeviceAssignmentSuccess);
