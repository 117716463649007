import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import handleMutation from '../../../graphql/functions/handleMutation';
import DeviceGroupForm from './DeviceGroupForm';
import FormBase from '../../form/FormBase';
import { DeviceGroupGridQuery } from '../../../graphql/queries/DeviceGroupQueries';
import { CreateDeviceGroupMutation } from '../../../graphql/mutations/DeviceGroupMutations';

class DeviceGroupNew extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // submit mutation and refetch deviceGroup grid query
  handleSubmit(values) {
    handleMutation({
      mutate: this.props.mutate,
      variables: { deviceGroup: values },
      refetchQueries: [{ query: DeviceGroupGridQuery }],
      onSuccess: this.props.onSuccess,
    });
  }

  render() {
    return (
      <FormBase header="Create Device Group">
        <DeviceGroupForm onSubmit={this.handleSubmit} />
      </FormBase>
    );
  }
}

const DeviceGroupNewWithMutation = graphql(CreateDeviceGroupMutation)(
  DeviceGroupNew,
);

export default DeviceGroupNewWithMutation;
