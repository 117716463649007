import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import DataTypeForm from './DataTypeForm';
import FormBase from '../../form/FormBase';
import handleMutation from '../../../graphql/functions/handleMutation';
import {
  DataTypeGridQuery,
  DataTypeQuery,
} from '../../../graphql/queries/DataTypeQueries';
import { UpdateDataTypeMutation } from '../../../graphql/mutations/DataTypeMutations';

class DataTypeUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    // store dataType id
    if (typeof values === 'object') {
      values.id = this.props.dataType.id;

      if (values.attributes) {
        delete values.attributes;
      }
    }
    handleMutation({
      mutate: this.props.mutate,
      variables: { dataType: values },
      refetchQueries: [{ query: DataTypeGridQuery }],
      onSuccess: this.props.onSuccess,
    });
  }

  render() {
    const { data: { loading, error, dataType } } = this.props;
    return (
      <FormBase loading={loading} error={error} header="Edit Data Type">
        <DataTypeForm initialValues={dataType} onSubmit={this.handleSubmit} />
      </FormBase>
    );
  }
}

export default compose(
  graphql(UpdateDataTypeMutation, {
    options: (props) => ({ variables: { id: props.dataType.id } }),
  }),
  graphql(DataTypeQuery, {
    options: (props) => ({ variables: { id: props.dataType.id }, fetchPolicy: 'network-only' }),
  }),
)(DataTypeUpdate);
