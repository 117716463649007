import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import GridBase from '../common/GridBase';
import { CompanyGridQuery } from '../../graphql/queries/CompanyQueries';
import ContextMenuWithTrigger from '../common/ContextMenuWithTrigger';
import Modal from '../common/Modal';
import CompanyUpdate from './CompanyUpdate';
import CompanyAccountManagerGrid from './CompanyAccountManagerGrid';
import companyFormFields from './companyFormFields';
import { DeleteCompanyMutation } from '../../graphql/mutations/CompanyMutations';
import DeleteModel from '../common/DeleteModel';
import displayBoolean from '../common/displayBoolean';
import handleContextMenuClick from '../common/handleContextMenuClick';

class CompanyGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      modalTitle: '',
      modalType: '',
      company: null,
      delete: {
        id: null,
        name: '',
      },
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleDeleteClose = this.handleDeleteClose.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  columns = [
    {
      Header: 'ID',
      accessor: 'id',
      maxWidth: 90,
      filterable: true,
    }, {
      Header: 'Company Name',
      accessor: 'name',
      minWidth: 70,
      filterable: true,
    }, {
      Header: 'Model Dashboard',
      id: 'modelDashboard',
      accessor: (d) => (
        d.modelDashboard
          ? d.modelDashboard.name
          : ''),
      minWidth: 70,
      filterable: true,
    }, {
      Header: 'Monitoring Company',
      id: 'isMonitoringCompany',
      accessor: (d) => displayBoolean(d, 'isMonitoringCompany'),
      filterable: true,
    }, {
      Header: 'Primary Responsible Users',
      id: 'primaryResponsibleUsers',
      accessor: (d) => (
        d.accountManagers
          ? d.accountManagers.filter((aU) => aU.rank === 1).map((aU) => aU.user.fullName).join(', ')
          : ''),
      minWidth: 70,
      filterable: true,
    }, {
      Header: 'Secondary Responsible Users',
      id: 'secondaryResponsibleUsers',
      accessor: (d) => (
        d.accountManagers
          ? d.accountManagers.filter((aU) => aU.rank === 2).map((aU) => aU.user.fullName).join(', ')
          : ''),
      minWidth: 70,
      filterable: true,
    }, {
      Header: '',
      maxWidth: 23,
      resizable: false,
      sortable: false,
      Cell: ({ row }) => ContextMenuWithTrigger({
        handleClick: this.handleClick,
        row,
        additionalOptions: [{
          key: 'accountManagers',
          label: 'account managers',
          action: 'AccountManagers',
        }],
        duplicateOption: true,
      }),
    },
  ];

  // handle context menu clicks
  handleClick(e, data, target) {
    const modelName = 'company';
    const stateUpdate = handleContextMenuClick({
      e, data, target, modelName,
    });
    console.log('stateUpdate', stateUpdate);
    this.setState({
      ...stateUpdate,
    });
  }

  // reset delete company id and name to null upon deletion
  handleDeleteClose() {
    this.setState({
      delete: {
        id: null,
        name: '',
      },
    });
  }

  render() {
    console.log(this.state);
    const {
      data: {
        loading,
        error,
        editableCompanies,
      },
    } = this.props;
    const sorting = [
      {
        id: 'name',
        asc: true,
      },
    ];
    if (loading) {
      return <p>Loading...</p>;
    } if (error) {
      return <p>Error loading companies!</p>;
    }
    const multiSelect = {
      fields: companyFormFields,
      modelName: 'company',
      onSubmit: this.props.data.refetch,
    };
    return (
      <div>
        <GridBase dataVar={editableCompanies} columns={this.columns} sorting={sorting} multiSelect={multiSelect} />

        <DeleteModel
          deleteMutation={DeleteCompanyMutation}
          refetchQueries={[{
            query: CompanyGridQuery,
          },
          ]}
          id={this.state.delete.id}
          name={this.state.delete.name}
          onClose={this.handleDeleteClose}
        />

        <Modal onClose={this.closeModal} modalIsOpen={this.state.modalIsOpen} contentLabel={this.state.modalTitle}>
          {
            this.state.modalType && this.state.modalType === 'AccountManagers'
              ? (<CompanyAccountManagerGrid header={this.state.modalTitle} company={this.state.company} />)
              : (
                <CompanyUpdate
                  company={this.state.company}
                  header={this.state.modalTitle}
                  duplicate={!!(this.state.modalType && this.state.modalType === 'Duplicate')}
                />
              )
          }
        </Modal>
      </div>
    );
  }
}

export default graphql(CompanyGridQuery)(CompanyGrid);
