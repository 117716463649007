import React, { Component } from 'react';
import _ from 'lodash';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class ExpansionPanelBase extends Component {
  constructor(props) {
    super(props);
    this.state = { expanded: props.initExpanded ? props.initExpanded : null };
  }

  handleChange = (panel) => (event, expanded) => {
    this.setState({
      expanded: expanded === false ? null : panel,
    });
  };

  getPanels = (panelData) => _.map(panelData, ({ header, data, key }) => (
    <Accordion
      key={key}
      expanded={this.state.expanded === key}
      onChange={this.handleChange(key)}
      classes={this.props.showOverflow ? { expanded: 'overflowVisible' } : {}}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <label>{header}</label>
      </AccordionSummary>
      <AccordionDetails>{data}</AccordionDetails>
    </Accordion>
  ));

  render() {
    const { panelData } = this.props;
    return <div>{this.getPanels(panelData)}</div>;
  }
}
ExpansionPanelBase.defaultProps = {
  showOverflow: false,
};
export default ExpansionPanelBase;
