import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import TableForm from './TableForm';
import FormBase from '../form/FormBase';
import {
  TableGridQuery,
  TableQuery,
  TableSelectQuery,
} from '../../graphql/queries/TableQueries';
import { UpdateTableMutation } from '../../graphql/mutations/TableMutations';
import handleTableMutation from '../../graphql/functions/handleTableMutation';

class TableUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    if (typeof values === 'object') {
      values.id = this.props.table.id;
      if (values.columns && values.columns.length > 0) {
        values.columns = values.columns.map((column) => {
          const a = {};
          if (typeof column.attribute === 'object' && column.attribute.id) {
            a.attribute = column.attribute.id;
          } else if (Array.isArray(column.attribute)) {
            a.attribute = column.attribute[0];
          } else {
            a.attribute = column.attribute;
          }
          if (
            typeof column.type === 'object'
            && column.type !== null
            && column.type.id
          ) {
            a.type = column.type.id;
          } else {
            a.type = column.type;
          }
          a.name = column.name;
          a.addPercent = column.addPercent;
          return a;
        });
      }
    }
    handleTableMutation({
      values,
      mutation: this.props.mutate,
      onSuccess: this.props.onSuccess,
      refetchQueries: [{ query: TableGridQuery }, { query: TableSelectQuery }],
    });
  }

  render() {
    console.log('this.props', this.props);
    const {
      data: { loading, error, table },
    } = this.props;
    console.log('table', table);
    return (
      <FormBase loading={loading} error={error} header="Edit Table">
        <TableForm initialValues={table} onSubmit={this.handleSubmit} />
      </FormBase>
    );
  }
}

export default compose(
  graphql(UpdateTableMutation, {
    options: (props) => ({ variables: { id: props.table.id } }),
  }),
  graphql(TableQuery, {
    name: 'TableQuery',
    options: (props) => ({ variables: { id: props.table.id }, fetchPolicy: 'network-only' }),
    props: ({ ownProps, TableQuery: { loading, error, table } }) => ({
      data: {
        table,
        loading,
        error,
      },
    }),
  }),
)(TableUpdate);
