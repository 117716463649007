import { gql } from '@apollo/client';
import { dataTypeAllFragment } from '../fragments';

const CreateDataTypeMutation = gql`
  mutation createDataType($dataType: DataTypeInput!) {
    createDataType(dataType: $dataType) {
      ...dataTypeAll
    }
  }
  ${dataTypeAllFragment}
`;

const UpdateDataTypeMutation = gql`
  mutation updateDataType($dataType: DataTypeInput!) {
    updateDataType(dataType: $dataType) {
      ...dataTypeAll
    }
  }
  ${dataTypeAllFragment}
`;

const DeleteDataTypeMutation = gql`
  mutation deleteDataType($id: ID!) {
    deleteDataType(id: $id) {
      name
    }
  }
`;

export {
  DeleteDataTypeMutation,
  UpdateDataTypeMutation,
  CreateDataTypeMutation,
};
