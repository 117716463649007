import { gql } from '@apollo/client';
import { tableFragment } from '../fragments';

const CreateTableMutation = gql`
  mutation createTable($table: TableInput!) {
    createDefaultTable(table: $table) {
      ...table
    }
  }
  ${tableFragment}
`;
const UpdateTableMutation = gql`
  mutation updateTable($table: TableInput!) {
    updateTable(table: $table) {
      ...table
    }
  }
  ${tableFragment}
`;

const DeleteTableMutation = gql`
  mutation deleteTable($id: ID!) {
    deleteTable(id: $id) {
      id
      name
    }
  }
`;
export { CreateTableMutation, UpdateTableMutation, DeleteTableMutation };
