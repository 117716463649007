import { gql } from '@apollo/client';
import {
  installerConfigFragment,
  installerConfigFieldFragment,
} from '../fragments';

const CreateInstallerConfigMutation = gql`
  mutation createInstallerConfig($installerConfig: InstallerConfigInput!) {
    createInstallerConfig(installerConfig: $installerConfig) {
      ...installerConfig
    }
  }
  ${installerConfigFragment}
`;

const UpdateInstallerConfigMutation = gql`
  mutation updateInstallerConfig($installerConfig: InstallerConfigInput!) {
    updateInstallerConfig(installerConfig: $installerConfig) {
      ...installerConfig
    }
  }
  ${installerConfigFragment}
`;

const DeleteInstallerConfigMutation = gql`
  mutation deleteInstallerConfig($id: ID!) {
    deleteInstallerConfig(id: $id) {
      id
    }
  }
`;

const CreateInstallerConfigFieldMutation = gql`
  mutation createInstallerConfigField(
    $installerConfigField: InstallerConfigFieldInput!
  ) {
    createInstallerConfigField(installerConfigField: $installerConfigField) {
      ...installerConfigField
    }
  }
  ${installerConfigFieldFragment}
`;

const UpdateInstallerConfigFieldMutation = gql`
  mutation updateInstallerConfigField(
    $installerConfigField: InstallerConfigFieldInput!
  ) {
    updateInstallerConfigField(installerConfigField: $installerConfigField) {
      ...installerConfigField
    }
  }
  ${installerConfigFieldFragment}
`;

const DeleteInstallerConfigFieldMutation = gql`
  mutation deleteInstallerConfigField($id: ID!) {
    deleteInstallerConfigField(id: $id) {
      name
    }
  }
`;

export {
  CreateInstallerConfigMutation,
  UpdateInstallerConfigMutation,
  DeleteInstallerConfigMutation,
  CreateInstallerConfigFieldMutation,
  UpdateInstallerConfigFieldMutation,
  DeleteInstallerConfigFieldMutation,
};
