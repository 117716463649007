import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import { Field } from 'redux-form';
import update from 'immutability-helper';

import { CONST } from '../common/constants';
import { UserSelectQuery } from '../../graphql/queries/UserQueries';
import renderSelectTree from '../form/renderSelectTree';
import { selectMultipleArrayToString } from '../common/functions';

class UserSelect extends Component {
  constructor(props) {
    super(props);
    this.handleUserChange = this.handleUserChange.bind(this);
    this.normalizeSelectArray = this.normalizeSelectArray.bind(this);
  }

  handleUserChange(e) {
    this.props.onChange(e);
  }

  // Set array to string if only 1 value allowed
  normalizeSelectArray(value) {
    return selectMultipleArrayToString(this.props.multiple, value);
  }

  render() {
    const { UserSelectQuery: { loading, error, companies } } = this.props;

    if (loading) {
      return <p>Loading...</p>;
    } if (error) {
      return <p>Error Loading Users!</p>;
    }
    return (
      <div>
        <label>{this.props.label}</label>

        <Field
          onChange={this.handleUserChange}
          name={this.props.fieldName}
          component={renderSelectTree}
          data={companies}
          multiple={this.props.multiple}
          normalize={this.normalizeSelectArray}
          valueField="id"
          textField="name"
        />
      </div>
    );
  }
}

UserSelect.defaultProps = {
  label: 'Users',
  fieldName: 'users',
  onChange: () => {},
  multiple: true,
  includeSelf: false,
  superAdminOnly: false,
};
function mapStateToProps({ auth }) {
  return { auth };
}
export default compose(
  connect(mapStateToProps),
  graphql(UserSelectQuery, {
    name: 'UserSelectQuery',
    props: ({ ownProps, UserSelectQuery: { loading, error, companies } }) => {
      console.log(ownProps);
      if (companies && companies.length > 0) {
        companies = companies.map((company) => {
          if (company.users && company.users.length > 0) {
            let companyUsers = company.users;
            if (!ownProps.includeSelf) {
              companyUsers = companyUsers
                .filter((user) => {
                  if (user.id === ownProps.auth.id) {
                    return false;
                  }
                  return true;
                });
            }
            if (ownProps.superAdminOnly) {
              console.log('companyUsers', companyUsers);
              companyUsers = companyUsers
                .filter((user) => {
                  if (user.role === CONST.SUPER_ADMIN_ROLE) {
                    return true;
                  }
                  return false;
                });
              console.log('only super admins', companyUsers);
            }

            companyUsers = companyUsers.map((user) => ({ id: user.id, name: user.fullName }));

            company = update(company, {
              users: {
                $set: companyUsers,
              },
            });
          }
          return company;
        });
        // remove companies without users
        companies = companies.filter((company) => company.users && company.users.length > 0);
      }
      return {
        UserSelectQuery: {
          companies,
          loading,
          error,
        },
      };
    },
  }),
)(UserSelect);
