import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import {
  Button, List, ListItem, ListItemText,
}
  from '@material-ui/core';
import renderDropdownList from '../form/renderDropdownList';
import renderCheckbox from '../form/renderCheckbox';
import renderSelectTree from '../form/renderSelectTree';
import ExpansionPanelBase from '../common/ExpansionPanelBase';
import WizardBase from '../form/WizardBase';
import AssetTypeDropdown from '../asset/assetType/AssetTypeDropdown';

// #region definitions
// #region Dropdown options
const modelTypes = [
  { id: 'device', name: 'Devices' },
  { id: 'asset', name: 'Assets' },
];
const newOptions = [
  { id: 'create', name: 'Create New' },
  { id: 'update', name: 'Update Existing' },
];
const identifyOptions = [
  { id: 'name', name: 'Name' },
  { id: 'serial', name: 'Serial Number' },
  { id: 'legacySerial', name: 'Legacy Serial Number' },
];
// #endregion
// #region Company Location Fields
const companyAndLocationFields = [
  { id: 'company_name', name: 'Company Name' },
  { id: 'location_name', name: 'Location Name' },
];
// #endregion

// #region Asset Fields
// #region New Company Location Fields
const newCompanyAndLocationFields = [
  {
    id: 'newLocationCompany_name',
    name: "New Location's Company (Required if changing location)",
  },
  { id: 'newLocation_name', name: 'New Location' },
];
// #endregion
// #region New Company Location Asset Fields
const newCompanyLocationAndAssetFields = [
  {
    id: 'newLocationCompany_name',
    name: "New Asset's Location's Company (Required if changing asset)",
  },
  {
    id: 'newLocation_name',
    name: "New Asset's Location (Required if changing asset)",
  },
  { id: 'newAsset_name', name: 'New Asset' },
  { id: 'newAssetComponent_name', name: 'New Asset Component' },
];
// #endregion
const assetNameField = [
  {
    id: 'asset_name',
    name: 'Asset Name',
  },
];
const updatedAssetNameField = [
  {
    id: 'updatedAssetName',
    name: 'Change Asset Name',
  },
];
const updatedAssetComponentNameField = [
  {
    id: 'updatedAssetComponentName',
    name: 'Change Asset Component Name',
  },
];
const childAssetNameField = [
  {
    id: 'assetComponent_name',
    name: 'Asset Component Name',
  },
];
const deviceUpdateChildAssetNameField = [
  {
    id: 'assetComponent_name',
    name:
      'Asset Component Name (Required if updating device installed on an asset component)',
  },
];
const assetFields = [
  {
    id: 'asset_assetType',
    name: 'Asset Type Name',
    create: 'required',
  },
  { id: 'asset_assetConfig', name: 'Asset Configuration Name' },
  { id: 'asset_latitude', name: 'Asset Latitude' },
  { id: 'asset_longitude', name: 'Asset Longitude' },
];
const childAssetFields = [
  {
    id: 'assetComponent_assetType',
    name: 'Asset Component Type Name',
    create: 'required',
  },
  {
    id: 'assetComponent_assetConfig',
    name: 'Asset Component Configuration Name',
  },
  { id: 'assetComponent_latitude', name: 'Asset Component Latitude' },
  { id: 'assetComponent_longitude', name: 'Asset Component Longitude' },
];
// #endregion
// #region Device Fields
const deviceNameField = [{ id: 'device_name', name: 'Device Name' }];
const deviceSerialField = [{ id: 'device_serial', name: 'Device Serial' }];
const deviceTypeField = [{ id: 'device_deviceType', name: 'Device Type' }];
const deviceLegacySerialFields = [
  { id: 'device_legacy_serial', name: 'Device Legacy Serial' },
  { id: 'device_legacy_config', name: 'Device Legacy Config' },
];
const deviceFields = [
  { id: 'device_owner', name: 'Device Owner Name' },
  { id: 'device_deviceConfig', name: 'Device Configuration Name' },
  { id: 'device_importId', name: 'Device Import Id' },
  { id: 'device_additionalOptions', name: 'Device Additional Options JSON' },
  { id: 'device_active', name: 'Device Active (defaults to true)' },
  { id: 'device_deviceGroupNames', name: "Device Group Names (separated by ';')" },
  { id: 'device_legacy_id', name: 'Device Legacy Id' },
];
// #endregion
// #endregion
class CSVConfigFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modelType: null,
      createOrUpdate: null,
      identifyBy: null,
      subAssets: null,
      requiredFields: [],
      optionalFields: [],
      assetMetadataCsvHeaders: [],
      componentMetadataCsvHeaders: [],
    };
    this.handleModelTypeChange = this.handleModelTypeChange.bind(this);
    this.handleCreateOrUpdateChange = this.handleCreateOrUpdateChange.bind(
      this,
    );
    this.handleIdentifyByChange = this.handleIdentifyByChange.bind(this);
    this.handleSubAssetsChange = this.handleSubAssetsChange.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleAssetTypeChange = this.handleAssetTypeChange.bind(this);
    this.handleComponentTypeChange = this.handleComponentTypeChange.bind(this);
  }

  // #region handle form changes
  handleModelTypeChange(modelType) {
    this.setState({ modelType: modelType.id });

    const fieldsOptions = {
      modelType: modelType.id,
      createOrUpdate: this.state.createOrUpdate,
      identifyBy: this.state.identifyBy,
      subAssets: this.state.subAssets,
    };
    this.setFields(fieldsOptions);
  }

  handleCreateOrUpdateChange(createOrUpdate) {
    this.setState({ createOrUpdate: createOrUpdate.id });
    const fieldsOptions = {
      modelType: this.state.modelType,
      createOrUpdate: createOrUpdate.id,
      identifyBy: this.state.identifyBy,
      subAssets: this.state.subAssets,
    };
    this.setFields(fieldsOptions);
  }

  handleIdentifyByChange(identifyBy) {
    this.setState({ identifyBy: identifyBy.id });
    const fieldsOptions = {
      modelType: this.state.modelType,
      createOrUpdate: this.state.createOrUpdate,
      identifyBy: identifyBy.id,
      subAssets: this.state.subAssets,
    };
    this.setFields(fieldsOptions);
  }

  handleSubAssetsChange(subAssets) {
    const show = subAssets.target.checked;
    this.setState({ subAssets: show });
    const fieldsOptions = {
      modelType: this.state.modelType,
      createOrUpdate: this.state.createOrUpdate,
      identifyBy: this.state.identifyBy,
      subAssets: show,
    };
    this.setFields(fieldsOptions);
  }

  // #endregion
  // #region setFields
  setFields({
    modelType, createOrUpdate, identifyBy, subAssets,
  }) {
    let optionalFields = [];
    let requiredFields = [];
    // create asset
    if (modelType === 'asset') {
      if (createOrUpdate === 'create') {
        // location and company are required
        requiredFields = requiredFields.concat(companyAndLocationFields);
        // asset name is required
        requiredFields = requiredFields.concat(assetNameField);
        if (subAssets === true) {
          requiredFields = requiredFields.concat(childAssetNameField);
        }
        // get all additional required fields on asset create
        requiredFields = requiredFields.concat(
          assetFields.filter((assetField) => assetField.create === 'required'),
        );
        // get all optional fields on asset create
        optionalFields = optionalFields.concat(
          assetFields.filter((assetField) => !assetField.create),
        );
        if (subAssets === true) {
          // get all additional required fields on child asset create
          requiredFields = requiredFields.concat(
            childAssetFields.filter(
              (assetField) => assetField.create === 'required',
            ),
          );
          // get all optional fields on child asset create
          optionalFields = optionalFields.concat(
            childAssetFields.filter((assetField) => !assetField.create),
          );
        }
      } else if (createOrUpdate === 'update') { // update asset
        // location and company are required
        requiredFields = requiredFields.concat(companyAndLocationFields);
        // asset name is required
        requiredFields = requiredFields.concat(assetNameField);
        if (subAssets === true) {
          requiredFields = requiredFields.concat(childAssetNameField);
        }
        // location and company are optional
        optionalFields = optionalFields.concat(newCompanyAndLocationFields);
        // updated asset name (to change asset name) is optional
        optionalFields = optionalFields.concat(updatedAssetNameField);
        // get all optional fields on asset update
        optionalFields = optionalFields.concat(
          assetFields.filter((assetField) => !assetField.update),
        );
        if (subAssets === true) {
          // updated asset component name (to change asset name) is optional
          optionalFields = optionalFields.concat(updatedAssetComponentNameField);
          // get all optional fields on child  asset update
          optionalFields = optionalFields.concat(
            childAssetFields.filter((assetField) => !assetField.update),
          );
        }
      }
    } else if (modelType === 'device') {
      if (createOrUpdate === 'create') {
        requiredFields = requiredFields.concat(deviceNameField);
        // device type is required on create
        requiredFields = requiredFields.concat(deviceTypeField);
        // company location names
        optionalFields = optionalFields.concat(companyAndLocationFields);
        // asset names
        optionalFields = optionalFields.concat(assetNameField);
        optionalFields = optionalFields.concat(childAssetNameField);

        // serial fields are optional
        optionalFields = optionalFields.concat(deviceSerialField);
        // legacy serial fields are optional
        optionalFields = optionalFields.concat(deviceLegacySerialFields);
        // get all additional optional device fields
        optionalFields = optionalFields.concat(deviceFields);
      } else if (createOrUpdate === 'update') {
        if (identifyBy === 'name') {
          // company location names
          requiredFields = requiredFields.concat(companyAndLocationFields);
          // asset name
          requiredFields = requiredFields.concat(assetNameField);
          // device name
          requiredFields = requiredFields.concat(deviceNameField);
          // optional asset component name
          optionalFields = optionalFields.concat(
            deviceUpdateChildAssetNameField,
          );
          // serial fields are optional
          optionalFields = optionalFields.concat(deviceSerialField);
          // device type is optional on update
          optionalFields = optionalFields.concat(deviceTypeField);
          // legacy serial fields are optional
          optionalFields = optionalFields.concat(deviceLegacySerialFields);
        } else if (identifyBy === 'serial') { // update device identify by serial
          // device name is optional
          optionalFields = optionalFields.concat(deviceNameField);
          // serial fields are required
          requiredFields = requiredFields.concat(deviceSerialField);
          // device type is required
          requiredFields = requiredFields.concat(deviceTypeField);
        } else if (identifyBy === 'legacySerial') { // update device identify by legacy serial
          // device name is optional
          optionalFields = optionalFields.concat(deviceNameField);
          // device type is optional
          optionalFields = optionalFields.concat(deviceTypeField);
          // legacy serial fields are required
          requiredFields = requiredFields.concat(deviceLegacySerialFields);
        }
        // get all additional optional device fields
        optionalFields = optionalFields.concat(
          newCompanyLocationAndAssetFields,
        );
        optionalFields = optionalFields.concat(deviceFields);
      }
    }
    this.setState({ optionalFields, requiredFields });
  }

  // #endregion
  getRequiredFieldsList() {
    const rfs = this.state.requiredFields;
    return rfs.map((rf) => (
      <ListItem dense key={rf.id}>
        <ListItemText primary={rf.name} />
      </ListItem>
    ));
  }

  getFieldsPanel(csvHeaders) {
    return [
      {
        key: 'csv-fields-panel',
        header: 'Example CSV Format',
        data: (
          <div>
            <label>Required Fields:</label>
            <List>{this.getRequiredFieldsList()}</List>
            <label>Optional Fields to Edit:</label>
            <Field
              name="selectFields"
              component={renderSelectTree}
              data={this.state.optionalFields}
              valueField="id"
              textField="name"
            />
            {this.state.modelType === 'asset' && (
              <div>
                <AssetTypeDropdown
                  onDropdownChange={(e) => this.handleAssetTypeChange(e)}
                  label="Asset Type"
                  fieldName="assetType"
                  canBeNull
                />
                <br />
                {this.state.subAssets && (
                  <AssetTypeDropdown
                    onDropdownChange={(e) => this.handleComponentTypeChange(e)}
                    label="Component Type"
                    fieldName="componentType"
                    canBeNull
                  />
                )}
              </div>
            )}
            <br />
            {csvHeaders && (
              <CSVLink
                filename="importData.csv"
                data={csvHeaders}
                target="_blank"
              >
                Download Example File
                <i className="material-icons right">cloud_download</i>
              </CSVLink>
            )}
          </div>
        ),
      },
    ];
  }

  handleAssetTypeChange(assetType) {
    const assetMetadataCsvHeaders = [];
    if (assetType !== null && typeof assetType !== 'undefined' && assetType.id !== null) {
      assetType.fields.forEach((field) => {
        assetMetadataCsvHeaders.push(`asset_metadata_${field.key}`);
      });
    }
    this.setState({ assetMetadataCsvHeaders });
  }

  handleComponentTypeChange(assetType) {
    const componentMetadataCsvHeaders = [];
    if (assetType !== null && typeof assetType !== 'undefined' && assetType.id !== null) {
      assetType.fields.forEach((field) => {
        componentMetadataCsvHeaders.push(`assetComponent_metadata_${field.key}`);
      });
    }
    this.setState({ componentMetadataCsvHeaders });
  }

  handleNext(event) {
    event.preventDefault();
    this.props.change('requiredFields', this.state.requiredFields);
    this.props.onSubmit(this.state);
  }

  getCSVHeaders() {
    // create csv with all required and selected field ids
    let csvHeaders = null;
    let headers = [];
    if (this.state.requiredFields.length > 0) {
      headers = headers.concat(this.state.requiredFields.map((rf) => rf.id));
    }
    if (
      this.props.formState.CSVImport
      && this.props.formState.CSVImport.values
      && this.props.formState.CSVImport.values.selectFields
      && this.props.formState.CSVImport.values.selectFields.length > 0
    ) {
      headers = headers.concat(
        this.props.formState.CSVImport.values.selectFields,
      );
    }
    if (this.state.assetMetadataCsvHeaders) {
      headers = headers.concat(this.state.assetMetadataCsvHeaders);
    }
    if (this.state.componentMetadataCsvHeaders) {
      headers = headers.concat(this.state.componentMetadataCsvHeaders);
    }
    if (headers.length > 0) {
      csvHeaders = [headers];
    }
    return csvHeaders;
  }

  render() {
    const { pristine, submitting } = this.props;
    const csvHeaders = this.getCSVHeaders();
    return (
      <WizardBase header="Configure Upload">
        <form onSubmit={this.handleNext}>
          <label>Import Type</label>
          <Field
            onChange={this.handleModelTypeChange}
            name="model"
            component={renderDropdownList}
            data={modelTypes}
            valueField="id"
            textField="name"
            canBeNull={false}
          />
          <label>Create or Update</label>
          <Field
            onChange={this.handleCreateOrUpdateChange}
            name="createOrUpdate"
            component={renderDropdownList}
            canBeNull={false}
            data={newOptions}
            valueField="id"
            textField="name"
          />
          <div>
            {this.state.createOrUpdate === 'update'
              && this.state.modelType === 'device' && (
                <div>
                  <label>Identify Device By:</label>
                  <Field
                    onChange={this.handleIdentifyByChange}
                    name="identifyBy"
                    component={renderDropdownList}
                    canBeNull={false}
                    data={identifyOptions}
                    valueField="id"
                    textField="name"
                  />
                </div>
            )}
          </div>
          <div>
            {this.state.modelType === 'asset' && (
              <div>
                <label>Include Sub-Assets Components:</label>
                <Field
                  onChange={this.handleSubAssetsChange}
                  name="subAssets"
                  component={renderCheckbox}
                />
              </div>
            )}
          </div>
          <br />
          <ExpansionPanelBase showOverflow panelData={this.getFieldsPanel(csvHeaders)} />

          <br />
          <br />
          <div>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={pristine || submitting}
            >
              Next
              <i className="material-icons right">send</i>
            </Button>
          </div>
        </form>
      </WizardBase>
    );
  }
}

CSVConfigFormComponent.defaultProps = {
  initialValues: null,
};

const validate = (values) => {
  const errors = {};
  if (!values.model) {
    errors.model = 'Type cannot be blank';
  }
  if (!values.createOrUpdate) {
    errors.createOrUpdate = 'Create or Update cannot be blank';
  }
  return errors;
};
const mapStateToProps = (state) => ({
  formState: state.form,
});
const CSVConfigForm = reduxForm({
  validate,
  form: 'CSVImport',
  destroyOnUnmount: false, // <------ preserve form data
})(CSVConfigFormComponent);

export default connect(mapStateToProps)(CSVConfigForm);
