import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import InstallerConfigForm from './InstallerConfigForm';
import FormBase from '../../form/FormBase';
import handleInstallerConfigMutation from '../../../graphql/functions/handleInstallerConfigMutation';
import {
  CreateInstallerConfigMutation,
  UpdateInstallerConfigMutation,
} from '../../../graphql/mutations/InstallerConfigMutations';
import {
  InstallerConfigQuery,
  InstallerConfigGridQuery,
} from '../../../graphql/queries/InstallerConfigQueries';

class InstallerConfigUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [], modalIsOpen: false };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    if (typeof values === 'object') {
      let mutation;
      if (this.props.duplicate) {
        delete values.id;
        mutation = this.props.CreateInstallerConfigMutation;
      } else {
        values.id = this.props.installerConfig.id;
        mutation = this.props.UpdateInstallerConfigMutation;
      }

      handleInstallerConfigMutation({
        values,
        mutation,
        refetchQueries: [{ query: InstallerConfigGridQuery }],
        onSuccess: this.props.onSuccess,
      });
    }
  }

  render() {
    console.log(this.props);
    // render form with installerConfig values pre-loaded
    const {
      InstallerConfigQuery: {
        loading, error, installerConfig, submitting,
      },
    } = this.props;

    console.log(this.props);
    return (
      <FormBase
        loading={loading}
        submitting={submitting}
        error={error}
        header="Edit Installer Configuration"
      >
        <InstallerConfigForm
          initialValues={installerConfig}
          onSubmit={this.handleSubmit}
        />
      </FormBase>
    );
  }
}

export default compose(
  graphql(UpdateInstallerConfigMutation, {
    name: 'UpdateInstallerConfigMutation',
    options: (props) => ({ variables: { id: props.installerConfig.id } }),
  }),
  graphql(CreateInstallerConfigMutation, {
    name: 'CreateInstallerConfigMutation',
  }),
  graphql(InstallerConfigQuery, {
    name: 'InstallerConfigQuery',
    options: (props) => ({ variables: { id: props.installerConfig.id }, fetchPolicy: 'network-only' }),
  }),
)(InstallerConfigUpdate);
