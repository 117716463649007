import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { Field } from 'redux-form';
import renderDropdownList from '../../form/renderDropdownList';
import { Ap2200FirmwareDropdownQuery } from '../../../graphql/queries/Ap2200FirmwareQueries';

class Ap2200FirmwareDropdown extends Component {
  constructor(props) {
    super(props);
    this.handleAp2200FirmwareChange = this.handleAp2200FirmwareChange.bind(this);
  }

  handleAp2200FirmwareChange(e, nv, pv) {
    this.props.onDropdownChange(e, nv, pv);
  }

  render() {
    if (this.props.data.loading) {
      return <p>Loading...</p>;
    } if (this.props.data.error) {
      return <p>Error Loading AP2200 Firmware Versions!</p>;
    }
    return (
      <div>
        <label>{this.props.label}</label>
        <Field
          name={this.props.fieldName}
          onChange={this.handleAp2200FirmwareChange}
          component={renderDropdownList}
          canBeNull={this.props.canBeNull}
          data={this.props.data.ap2200Firmwares}
          valueField="id"
          textField="version"
        />
      </div>
    );
  }
}

Ap2200FirmwareDropdown.defaultProps = {
  onDropdownChange: () => {},
  canBeNull: true,
};

export default graphql(Ap2200FirmwareDropdownQuery)(Ap2200FirmwareDropdown);
