import { gql } from '@apollo/client';

const CreateAttributeTypeMutation = gql`
  mutation($attributeType: AttributeTypeInput!) {
    createAttributeType(attributeType: $attributeType) {
      id
      name
      fields {
        key
        description
        name
        required
        rule
      }
    }
  }
`;

const UpdateAttributeTypeMutation = gql`
  mutation($attributeType: AttributeTypeInput!) {
    updateAttributeType(attributeType: $attributeType) {
      id
      name
      fields {
        key
        description
        name
        required
        rule
      }
    }
  }
`;

const DeleteAttributeTypeMutation = gql`
  mutation deleteAttributeType($id: ID!) {
    deleteAttributeType(id: $id) {
      name
    }
  }
`;
export {
  DeleteAttributeTypeMutation,
  UpdateAttributeTypeMutation,
  CreateAttributeTypeMutation,
};
