import { gql } from '@apollo/client';
import { chartFragment } from '../fragments';

const ChartQuery = gql`
  query chart($id: ID!) {
    chart(id: $id) {
      ...chart
    }
  }
  ${chartFragment}
`;

const ChartGridQuery = gql`
  query ChartGridQuery {
    defaultCharts {
      ...chart
    }
  }
  ${chartFragment}
`;

const ChartSelectQuery = gql`
  query charts {
    defaultCharts {
      id
      name
    }
  }
`;

export { ChartGridQuery, ChartSelectQuery, ChartQuery };
