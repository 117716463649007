import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import { Field } from 'redux-form';
import renderDropdownList from '../form/renderDropdownList';
import {
  LocationsByCompanyQuery,
  LocationDropdownQuery,
} from '../../graphql/queries/LocationQueries';

class LocationDropdown extends Component {
  constructor(props) {
    super(props);
    this.handleLocationChange = this.handleLocationChange.bind(this);
  }

  handleLocationChange(e) {
    this.props.onDropdownChange(e);
  }

  render() {
    if (this.props.LocationQuery) {
      const {
        LocationQuery: { loading, error, locations },
      } = this.props;

      if (loading) {
        return <p>Loading...</p>;
      } if (error) {
        return <p>Error Loading Locations!</p>;
      }
      return (
        <div>
          <label>Location</label>
          <Field
            onChange={this.handleLocationChange}
            name={this.props.fieldName}
            component={renderDropdownList}
            data={locations}
            valueField="id"
            textField="name"
            canBeNull={this.props.canBeNull}
          />
        </div>
      );
    }
    // not running query yet, render empty input
    return (
      <div>
        <label>Location</label>
        <Field
          name={this.props.fieldName}
          component={renderDropdownList}
          data={[]}
          valueField="id"
          textField="name"
          canBeNull={this.props.canBeNull}
        />
      </div>
    );
  }
}

LocationDropdown.defaultProps = {
  waitForParent: 'false',
  onDropdownChange: () => {},
  canBeNull: false,
};

export default compose(
  graphql(LocationDropdownQuery, {
    name: 'LocationQuery',
    skip: (ownProps) => ownProps.companyId || ownProps.waitForParent,
    props: ({
      ownProps,
      LocationQuery: { loading, error, visibleLocations },
    }) => ({
      LocationQuery: {
        locations: visibleLocations,
        loading,
        error,
      },
    }),
  }),
  graphql(LocationsByCompanyQuery, {
    name: 'LocationQuery',
    skip: (ownProps) => !ownProps.companyId,
    props: ({
      ownProps,
      LocationQuery: { loading, error, visibleLocationsByCompany },
    }) => ({
      LocationQuery: {
        locations: visibleLocationsByCompany,
        loading,
        error,
      },
    }),
    options: (ownProps) => ({
      variables: { companyId: ownProps.companyId },
    }),
  }),
)(LocationDropdown);
