import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';

import InstallerConfigForm from './InstallerConfigForm';
import FormBase from '../../form/FormBase';
import { CreateInstallerConfigMutation } from '../../../graphql/mutations/InstallerConfigMutations';
import { InstallerConfigGridQuery } from '../../../graphql/queries/InstallerConfigQueries';
import handleInstallerConfigMutation from '../../../graphql/functions/handleInstallerConfigMutation';

class InstallerConfigNew extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    handleInstallerConfigMutation({
      values,
      mutation: this.props.createInstallerConfigMutation,
      refetchQueries: [{ query: InstallerConfigGridQuery }],
      onSuccess: this.props.onSuccess,
    });
  }

  render() {
    return (
      <FormBase header="Add Installer Configuration">
        <InstallerConfigForm onSubmit={this.handleSubmit} />
      </FormBase>
    );
  }
}

const InstallerConfigNewWithMutation = compose(
  graphql(CreateInstallerConfigMutation, {
    name: 'createInstallerConfigMutation',
  }),
)(InstallerConfigNew);

export default InstallerConfigNewWithMutation;
