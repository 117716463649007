import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import { Field } from 'redux-form';
import renderDropdownList from '../form/renderDropdownList';
import {
  DevicesByAssetQuery,
  DeviceDropdownQuery,
} from '../../graphql/queries/DeviceQueries';

class DeviceDropdown extends Component {
  constructor(props) {
    super(props);
    this.handleDeviceChange = this.handleDeviceChange.bind(this);
  }

  handleDeviceChange(e) {
    this.props.onDropdownChange(e);
  }

  render() {
    if (this.props.DeviceQuery) {
      let {
        DeviceQuery: { loading, error, devices },
      } = this.props;
      console.log('this.props DeviceDropdown', this.props);
      if (loading) {
        return <p>Loading...</p>;
      } if (error) {
        return <p>Error Loading Devices!</p>;
      }
      let deviceNamesWithIds = [];
      if (devices && devices.length > 0 && this.props.deviceTypeFilter) {
        devices = devices.filter((d) => this.props.deviceTypeFilter(d));
        deviceNamesWithIds = devices.map((d) => {
          let nameString = '';
          if (d.installLocation) {
            nameString += `${d.installLocation} - `;
          }
          nameString += d.name;
          if (d.serial) {
            nameString += ` - ${d.serial}`;
          }
          if (d.legacy_serial) {
            nameString += ` - ${d.legacy_serial} `;
          }
          return { name: nameString, id: d.id };
        });
      } else {
        deviceNamesWithIds = devices;
      }
      return (
        <div>
          {this.props.label ? (
            <label>{this.props.label}</label>
          ) : (
            <label>Device</label>
          )}
          <Field
            onChange={this.handleDeviceChange}
            name={this.props.fieldName}
            component={renderDropdownList}
            data={deviceNamesWithIds}
            valueField="id"
            textField="name"
            canBeNull={this.props.canBeNull}
          />
        </div>
      );
    }
    return (
      <div>
        {this.props.label ? (
          <label>{this.props.label}</label>
        ) : (
          <label>Device</label>
        )}
        <Field
          name={this.props.fieldName}
          component={renderDropdownList}
          canBeNull={this.props.canBeNull}
          data={[]}
          valueField="id"
          textField="name"
        />
      </div>
    );
  }
}

DeviceDropdown.defaultProps = {
  fieldName: 'device',
  waitForParent: 'false',
  canBeNull: false,
};
// if we have a asset id, filter by that,
// otherwise fetch all devices
export default compose(
  graphql(DeviceDropdownQuery, {
    name: 'DeviceQuery',
    skip: (ownProps) => ownProps.assetId || ownProps.waitForParent,
  }),
  graphql(DevicesByAssetQuery, {
    name: 'DeviceQuery',
    skip: (ownProps) => !ownProps.assetId,
    props: ({ ownProps, DeviceQuery: { loading, error, devicesByAsset } }) => ({
      DeviceQuery: {
        devices: devicesByAsset,
        loading,
        error,
      },
    }),
    options: (ownProps) => ({
      variables: { assetId: ownProps.assetId },
    }),
  }),
)(DeviceDropdown);
