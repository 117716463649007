import React, { Component } from 'react';
import ReactJson from 'react-json-view';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

class ViewJson extends Component {
  getDataView(data) {
    console.log('data', data, typeof data);
    let dataView = <div />;
    if (data !== null && typeof data === 'string') {
      let obj;
      try {
        obj = JSON.parse(data);
        dataView = <ReactJson src={obj} />;
      } catch (e) {
        console.log('data is not a valid json string');
        dataView = <div>{data}</div>;
      }
    }
    return dataView;
  }

  render() {
    return (
      <div>
        <Typography variant="h4">{this.props.header}</Typography>
        <Divider />
        <br />
        {this.props.data
            && (
            <div>
              {this.getDataView(this.props.data)}
            </div>
            )}
      </div>
    );
  }
}

export default ViewJson;
