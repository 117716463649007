import axios from 'axios';
import { REACT_APP_BACKEND } from '../../config';

export default async (props) => {
  const { locationPin, onError, onSuccess } = props;
  if (locationPin) {
    let url = `${REACT_APP_BACKEND}/api/getLocationFromPin`;
    let locationName = '';
    try {
      const res = await axios.get(url, {
        params: { locationPin },
      });
      locationName = res.data.location.name;
    } catch (err) {
      onError('Unable to find location');
      return;
    }

    url = `${REACT_APP_BACKEND}/api/getInstallerLogsFromPin`;
    let installerLogs = [];
    try {
      const res = await axios.get(url, {
        params: { locationPin },
      });
      installerLogs = res.data.installerLogs;
    } catch (err) {
      onError(err);
      return;
    }
    onSuccess({ locationName, installerLogs });
  }
};
