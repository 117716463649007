import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import { Field } from 'redux-form';
import { TableSelectQuery } from '../../graphql/queries/TableQueries';
import renderSelectTree from '../form/renderSelectTree';
import { selectMultipleArrayToString } from '../common/functions';

class TableSelect extends Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.normalizeSelectArray = this.normalizeSelectArray.bind(this);
  }

  handleTableChange(e) {
    this.props.onChange(e);
  }

  // Set array to string if only 1 value allowed
  normalizeSelectArray(value) {
    return selectMultipleArrayToString(this.props.multiple, value);
  }

  render() {
    const {
      TableSelectQuery: { loading, error, defaultTables },
    } = this.props;

    if (loading) {
      return <p>Loading...</p>;
    } if (error) {
      return <p>Error Loading Tables!</p>;
    }
    return (
      <div>
        <label>{this.props.label}</label>

        <Field
          onChange={this.handleTableChange}
          name={this.props.fieldName}
          component={renderSelectTree}
          data={defaultTables}
          valueField="id"
          textField="name"
          multiple={this.props.multiple}
          normalize={this.normalizeSelectArray}
        />
      </div>
    );
  }
}

TableSelect.defaultProps = {
  label: 'Tables',
  fieldName: 'tables',
  multiple: true,
  onChange: () => {},
};
function mapStateToProps({ auth }) {
  return { auth };
}
export default compose(
  connect(mapStateToProps),
  graphql(TableSelectQuery, {
    name: 'TableSelectQuery',
  }),
)(TableSelect);
