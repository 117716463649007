import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import update from 'immutability-helper';
import { connect } from 'react-redux';
import GridBase from '../common/GridBase';
import ContextMenuWithTrigger from '../common/ContextMenuWithTrigger';
import Modal from '../common/Modal';
import DeviceUpdate from './DeviceUpdate';
import { DeviceGridQuery } from '../../graphql/queries/DeviceQueries';
import { DeleteDeviceMutation } from '../../graphql/mutations/DeviceMutations';
import DeleteModel from '../common/DeleteModel';
import handleContextMenuClick from '../common/handleContextMenuClick';
import deviceFormFields from './deviceFormFields';
import DisplayRawData from '../common/DisplayRawData';
import displayTimestamp from '../common/displayTimestamp';
import timestampSort from '../common/timestampSort';
import DeviceReplacement from './deviceReplacement/DeviceReplacement';
import cacheDeleteFromArray from '../../graphql/functions/cacheDeleteFromArray';

class DeviceGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      modalTitle: '',
      modalType: '',
      device: {
        id: null, locationId: null, companyId: null, name: '',
      },
      delete: { id: null, name: '' },
      updateFn: null,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleDeleteClose = this.handleDeleteClose.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  columns = [
    {
      Header: 'ID',
      accessor: 'id',
      maxWidth: 50,
      filterable: true,
      show: true,
    },
    {
      Header: 'Company',
      id: 'companyName',
      accessor: (d) => (d.asset && d.asset.location && d.asset.location.company
        ? d.asset.location.company.name
        : null),
      maxWidth: 120,

      filterable: true,
    },
    {
      Header: 'Company Id',
      id: 'companyId',
      accessor: (d) => (d.asset && d.asset.location && d.asset.location.company
        ? d.asset.location.company.id
        : null),
      show: false,
    },
    {
      Header: 'Location',
      id: 'locationName',
      accessor: (d) => (d.asset && d.asset.location ? d.asset.location.name : null),
      maxWidth: 120,
      filterable: true,
    },
    {
      Header: 'Location Id',
      id: 'locationId',
      accessor: (d) => (d.asset && d.asset.location ? d.asset.location.id : null),
      show: false,
    },
    {
      Header: 'Asset',
      id: 'assetName',
      accessor: (d) => (d.asset ? d.asset.fullName + (d.installLocation ? ` ${d.installLocation}` : '') : null),
      minWidth: 110,
      filterable: true,
    },
    {
      Header: 'Parent Id',
      id: 'parentId',
      accessor: (d) => (d.asset && d.asset.parent ? d.asset.parent.id : null),
      show: false,
    },
    {
      Header: 'Parent Name',
      id: 'parentName',
      accessor: (d) => (d.asset && d.asset.parent ? d.asset.parent.name : null),
      show: false,
    },
    {
      Header: 'Name',
      accessor: 'name',
      minWidth: 100,
      filterable: true,
    },
    {
      Header: 'Type',
      id: 'deviceType',
      accessor: (d) => (d.deviceType ? d.deviceType.name : null),
      maxWidth: 80,
      filterable: true,
    },
    {
      Header: 'Device Type Id',
      id: 'deviceTypeId',
      accessor: (d) => (d.deviceType ? d.deviceType.id : null),
      show: false,
    },
    {
      Header: 'Config',
      id: 'deviceConfig',
      accessor: (d) => (d.deviceConfig ? d.deviceConfig.name : null),
      maxWidth: 80,
      filterable: true,
    },
    {
      Header: 'Serial',
      accessor: 'serial',
      maxWidth: 50,
      filterable: true,
    },
    {
      Header: 'Import ID',
      accessor: 'importId',
      maxWidth: 50,
      filterable: true,
      show: false,
    },
    {
      Header: 'Legacy config',
      accessor: 'legacy_config',
      maxWidth: 50,
      filterable: true,
    },
    {
      Header: 'Legacy serial',
      accessor: 'legacy_serial',
      maxWidth: 50,
      filterable: true,
    },
    {
      Header: 'Owner',
      id: 'owner',
      accessor: (d) => (d.owner ? d.owner.name : null),
      maxWidth: 70,
      filterable: true,
    },
    {
      Header: 'Firmware',
      accessor: 'firmware',
      maxWidth: 50,
      filterable: true,
    },
    {
      Header: 'Latest Connection',
      id: 'latestConnection',
      accessor: (d) => displayTimestamp(d, 'latestConnection'),
      sortMethod: timestampSort,
      maxWidth: 50,
      filterable: true,
    },
    {
      Header: 'Install Date',
      id: 'installDate',
      accessor: (d) => displayTimestamp(d, 'installDate'),
      sortMethod: timestampSort,
      maxWidth: 50,
      filterable: true,
    },
    {
      Header: 'Active',
      id: 'active',
      accessor: (d) => (d.active === false ? 'No' : 'Yes'),
      maxWidth: 50,
      filterable: true,
    },
    // {
    //   Header: 'Display',
    //   id: 'display',
    //   accessor: d => (d.display === false ? 'No' : 'Yes'),
    //   maxWidth: 50,
    //   filterable: true,
    // },
    {
      Header: 'Backfilling',
      id: 'isBackfilling',
      accessor: (d) => (d.isBackfilling === false ? 'No' : 'Yes'),
      maxWidth: 50,
      filterable: true,
    },
    // {
    //   Header: 'Continuous Pts',
    //   accessor: 'numContinuousPts',
    //   maxWidth: 50,
    //   filterable: true,
    // },
    {
      Header: 'Legacy Id',
      accessor: 'legacy_id',
      maxWidth: 50,
      filterable: true,
    },
    {
      Header: 'Time Between Points (s)',
      accessor: 'secondsBetweenPts',
      maxWidth: 50,
      filterable: true,
    },
  ];

  superAdminCols = [
    {
      Header: 'Forwarding Url',
      accessor: 'dataForwardUrl',
      maxWidth: 50,
      filterable: true,
    },
    {
      Header: 'Forward Only',
      id: 'dataForwardOnly',
      accessor: (d) => (d.dataForwardOnly === true ? 'Yes' : 'No'),
      maxWidth: 50,
      filterable: true,
    },
  ];

  finalCol = {
    Header: '',
    maxWidth: 23,
    resizable: false,
    sortable: false,
    Cell: ({ row }) => ContextMenuWithTrigger({
      handleClick: this.handleClick,
      row,
      additionalOptions: [
        {
          key: 'replaceDevice',
          label: 'replace device',
          action: 'ReplaceDevice',
        },
        {
          key: 'latestData',
          label: 'view latest data',
          action: 'LatestData',
        },
      ],
      duplicateOption: true,
    }),
  };

  // handle context menu clicks
  handleClick(e, data, target) {
    const modelName = 'device';
    const stateUpdate = handleContextMenuClick({
      e, data, target, modelName,
    });
    let updateFn;
    if (stateUpdate.delete && stateUpdate.delete.id) {
      updateFn = (cache) => {
        cacheDeleteFromArray({
          cache,
          deletedId: stateUpdate.delete.id,
          cacheOptions:
        [{ cachedListName: 'editableDevices' }, { cachedListName: 'editableAp2200s' }],
        });
      };
    }
    this.setState({ ...stateUpdate, updateFn });
  }

  handleDeleteClose() {
    this.setState({ delete: { id: null, name: '' } });
  }

  render() {
    const {
      data: { loading, error, devices },
    } = this.props;
    const sorting = [
      { id: 'companyName', asc: true },
      { id: 'locationName', asc: true },
      { id: 'assetName', asc: true },
      { id: 'name', asc: true },
    ];
    if (loading) {
      return <p>Loading...</p>;
    } if (error) {
      console.log(error);
      return <p>Error loading devices!</p>;
    }
    const multiSelect = {
      fields: deviceFormFields,
      modelName: 'device',
      onSubmit: this.props.data.refetch,
    };
    let displayCol = this.columns;
    if (this.props.auth && this.props.auth.isSuperAdmin) {
      // add data forward cols
      displayCol = displayCol.concat(this.superAdminCols);
    }
    displayCol.push(this.finalCol);
    return (
      <div>
        <GridBase
          dataVar={devices}
          columns={displayCol}
          sorting={sorting}
          multiSelect={multiSelect}
        />

        <DeleteModel
          deleteMutation={DeleteDeviceMutation}
          update={this.state.updateFn}
          id={this.state.delete.id}
          name={this.state.delete.name}
          onClose={this.handleDeleteClose}
        />

        <Modal
          onClose={this.closeModal}
          modalIsOpen={this.state.modalIsOpen}
          contentLabel={this.state.modalTitle}
        >
          {this.state.modalType && this.state.modalType === 'LatestData' ? (
            <DisplayRawData
              header={this.state.modalTitle}
              device={this.state.device}
            />
          ) : this.state.modalType
              && this.state.modalType === 'ReplaceDevice' ? (
                <DeviceReplacement
                  devices={devices}
                  originalDevice={this.state.device}
                  header={this.state.modalTitle}
                />
            ) : (
              <DeviceUpdate
                device={this.state.device}
                header={this.state.modalTitle}
                duplicate={
                  !!(this.state.modalType && this.state.modalType === 'Duplicate')
                }
              />
            )}
        </Modal>
      </div>
    );
  }
}
function mapStateToProps({ auth }) {
  return { auth };
}
DeviceGrid = connect(mapStateToProps)(DeviceGrid);
export default graphql(DeviceGridQuery, {
  name: 'DeviceGridQuery',
  props: ({
    ownProps,
    DeviceGridQuery: {
      loading, error, editableDevices, refetch,
    },
  }) => {
    if (editableDevices) {
      editableDevices = editableDevices.map((device) => {
        if (device.asset && device.asset.parent) {
          device = update(device, {
            asset: { location: { $set: device.asset.parent.location } },
          });
        }
        return device;
      });
    }
    return {
      data: {
        devices: editableDevices,
        loading,
        error,
        refetch,
      },
    };
  },
})(DeviceGrid);
