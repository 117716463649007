import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { reduxForm, Field, FieldArray } from 'redux-form';
import renderDropdownList from '../../../form/renderDropdownList';
import FormField from '../../../form/FormField';
import FormSubmitButton from '../../../form/FormSubmitButton';
import ClearButton from '../../../form/ClearButton';
import ExpansionPanelBase from '../../../common/ExpansionPanelBase';
import DeviceTypeDropdown from '../../../device/deviceType/DeviceTypeDropdown';

const SERIAL_NUMBER_TYPES = [
  { name: 'String', id: 'String' },
  { name: 'Number', id: 'Number' },
];
const INPUT_TYPES = [
  { name: 'String', id: 'String' },
  { name: 'Number', id: 'Number' },
  { name: 'Dropdown', id: 'Dropdown' },
];

class InstallerConfigFieldForm extends Component {
  constructor(props) {
    super(props);
    this.getDropdownOptions = this.getDropdownOptions.bind(this);
  }

  getDropdownOptions(input, index) {
    console.log('getDropdownOptions', input, index);
    console.log('formState', this.props.formState);
    const { formState } = this.props;
    if (
      formState
      && formState.InstallerConfigField
      && formState.InstallerConfigField.values
      && formState.InstallerConfigField.values.inputs
      && formState.InstallerConfigField.values.inputs.length > 0
      && formState.InstallerConfigField.values.inputs[index]
      && formState.InstallerConfigField.values.inputs[index].inputType
      && ((typeof formState.InstallerConfigField.values.inputs[index].inputType
        === 'object'
        && formState.InstallerConfigField.values.inputs[index].inputType.id
          === 'Dropdown')
        || (typeof formState.InstallerConfigField.values.inputs[index]
          .inputType === 'string'
          && formState.InstallerConfigField.values.inputs[index].inputType
            === 'Dropdown'))
    ) {
      return (
        <div>
          <label>Dropdown Options</label>
          <FieldArray
            name={`${input}.options`}
            component={this.renderOptionsArray}
          />
          <br />
        </div>
      );
    }
    return <div />;
  }

  getInputPanels(inputs) {
    return inputs.map((input, index) => {
      const attNum = index + 1;
      return {
        key: `panel${index}`,
        header: `Input #${attNum}`,
        data: (
          <div className="expandable-form-div">
            <Button
              variant="contained"
              className="right"
              type="button"
              title="Remove Input"
              onClick={() => inputs.remove(index)}
            >
              <i className="material-icons">delete</i>
            </Button>

            <Field
              name={`${input}.label`}
              type="text"
              component={FormField}
              label="Label"
            />
            <Field
              name={`${input}.fieldKey`}
              type="text"
              component={FormField}
              label="Attribute Key"
            />
            <Field
              name={`${input}.inputType`}
              component={renderDropdownList}
              data={INPUT_TYPES}
              valueField="id"
              textField="name"
              label="Input Type"
              canBeNull={false}
            />
            {this.getDropdownOptions(input, index)}

            <Field
              name={`${input}.informationalImageUrl`}
              type="text"
              component={FormField}
              label="Image URL"
            />
            <br />
          </div>
        ),
      };
    });
  }

  getOptionsPanels(options) {
    return options.map((option, index) => {
      const attNum = index + 1;
      return {
        key: `panel${index}`,
        header: `Option #${attNum}`,
        data: (
          <div className="expandable-form-div">
            <Button
              variant="contained"
              className="right"
              type="button"
              title="Remove Option"
              onClick={() => options.remove(index)}
            >
              <i className="material-icons">delete</i>
            </Button>

            <Field
              name={`${option}.name`}
              type="text"
              component={FormField}
              label="Name"
            />
            <Field
              name={`${option}.val`}
              type="text"
              component={FormField}
              label="Value"
            />
            <br />
          </div>
        ),
      };
    });
  }

  renderOptionsArray = ({ fields, meta: { error, submitFailed } }) => (
    <div style={{ marginTop: '5px' }}>
      <div>
        <Button
          variant="contained"
          style={{ marginTop: '5px' }}
          type="button"
          onClick={() => fields.push({})}
        >
          Add Option
        </Button>
        {submitFailed && error && <span>{error}</span>}
      </div>
      <div>
        <ExpansionPanelBase panelData={this.getOptionsPanels(fields)} />
      </div>
    </div>
  );

  renderInputArray = ({ fields, meta: { error, submitFailed } }) => (
    <div style={{ marginTop: '5px' }}>
      <div>
        <Button
          variant="contained"
          style={{ marginTop: '5px' }}
          type="button"
          onClick={() => fields.push({})}
        >
          Add Input
        </Button>
        {submitFailed && error && <span>{error}</span>}
      </div>
      <div>
        <ExpansionPanelBase panelData={this.getInputPanels(fields)} />
      </div>
    </div>
  );

  render() {
    const {
      handleSubmit, pristine, reset, submitting,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <Field name="name" type="text" component={FormField} label="Name" />
        <DeviceTypeDropdown label="Device Type" fieldName="deviceType" />

        <label>Serial Number Type</label>
        <Field
          name="serialNumberType"
          component={renderDropdownList}
          data={SERIAL_NUMBER_TYPES}
          valueField="id"
          textField="name"
          label="Serial Number Type"
          canBeNull={false}
        />
        <Field
          name="serialNumberPrefix"
          type="number"
          parse={(value) => (!value ? null : Number(value))}
          component={FormField}
          label="Serial Number Prefix"
        />
        <label>Additional Inputs</label>
        <FieldArray name="inputs" component={this.renderInputArray} />
        <div>
          <FormSubmitButton pristine={pristine} submitting={submitting} />
          <ClearButton
            pristine={pristine}
            submitting={submitting}
            reset={reset}
          />
        </div>
      </form>
    );
  }
}

InstallerConfigFieldForm.defaultProps = {
  initialValues: null,
};
const validate = (values) => {
  const errors = {};
  if (!values.deviceType) {
    errors.deviceType = 'Cannot be blank';
  }
  if (!values.name) {
    errors.name = 'Cannot be blank';
  }
  return errors;
};
const mapStateToProps = (state) => ({
  formState: state.form,
});
InstallerConfigFieldForm = reduxForm({
  validate,
  form: 'InstallerConfigField',
})(InstallerConfigFieldForm);

export default connect(mapStateToProps)(InstallerConfigFieldForm);
