import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import Button from '@material-ui/core/Button';
import WizardBase from '../../../form/WizardBase';
import renderSelectTree from '../../../form/renderSelectTree';

class SelectFields extends Component {
  render() {
    const { handleSubmit } = this.props;
    return (
      <WizardBase header="Select Fields to Edit">
        <form onSubmit={handleSubmit}>
          <Field
            name="multiSelectFields"
            component={renderSelectTree}
            data={this.props.fields}
            valueField="id"
            textField="name"
          />
          <br />
          <div>
            <Button type="submit" className="next">
              Next
            </Button>
          </div>
        </form>
      </WizardBase>
    );
  }
}

export default (SelectFields = reduxForm({
  form: 'multiEditWizard', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(SelectFields));
