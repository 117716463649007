import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import { connect } from 'react-redux';
import ServicePlanForm from './ServicePlanForm';
import FormBase from '../form/FormBase';
import handleMutation from '../../graphql/functions/handleMutation';
import {
  LocationQuery,
  LocationServicePlanGridQuery,
} from '../../graphql/queries/LocationQueries';
import { UpdateLocationMutation } from '../../graphql/mutations/LocationMutations';

class ServicePlanUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
  }

  handleSuccess(res) {
    this.props.onSuccess(res);
  }

  handleSubmit(values) {
    if (typeof values === 'object') {
      const updateVals = {};
      // store location id
      updateVals.id = this.props.location.id;
      // we only want to send the service plan attributes of the location
      if (values.serviceExpirationDate) {
        updateVals.serviceExpirationDate = values.serviceExpirationDate * 1000;
      }
      if (values.servicePlan) {
        updateVals.servicePlan = values.servicePlan;
      }
      // submit mutation and refetch location plan grid
      handleMutation({
        mutate: this.props.mutate,
        variables: { location: updateVals },
        refetchQueries: [
          { query: LocationServicePlanGridQuery },
        ],
        onSuccess: this.handleSuccess,
      });
    }
  }

  render() {
    const {
      data: { loading, error, location },
    } = this.props;
    return (
      <FormBase loading={loading} error={error} header="Edit Location">
        <ServicePlanForm initialValues={location} onSubmit={this.handleSubmit} />
      </FormBase>
    );
  }
}

ServicePlanUpdate = compose(
  graphql(UpdateLocationMutation, {
    options: (props) => ({ variables: { id: props.location.id } }),
  }),
  graphql(LocationQuery, {
    options: (props) => ({ variables: { id: props.location.id }, fetchPolicy: 'network-only' }),
  }),
)(ServicePlanUpdate);

function mapStateToProps({ auth }) {
  return { auth };
}
export default connect(mapStateToProps)(ServicePlanUpdate);
