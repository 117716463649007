import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';

import handleMutation from '../../../graphql/functions/handleMutation';
import AttributeForm from './AttributeForm';
import FormBase from '../../form/FormBase';
import { AttributeGridQuery, AttributeSelectQuery } from '../../../graphql/queries/AttributeQueries';
import { CreateAttributeMutation } from '../../../graphql/mutations/AttributeMutations';

class AttributeNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      configVals: {},
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setConfigVals = this.setConfigVals.bind(this);
  }

  setConfigVals(vals) {
    this.setState({ configVals: vals });
  }

  // submit mutation and refetch attribute grid query
  handleSubmit(values) {
    const configs = this.state.configVals;
    for (let i = 0; i < configs.length; i++) {
      if (configs[i].rule === 'Boolean') {
        // if this boolean has not been set, automatically set it to false
        if (!values.config[i]) {
          values.config[i] = { k: configs[i].key, v: false };
        }
      }
    }
    if (typeof values === 'object') {
      /*       if (
        typeof values.axis === 'object' &&
        values.axis !== null &&
        values.axis.id
      ) {
        values.axis = values.axis.id;
      } */
      if (
        typeof values.attributeType === 'object'
        && values.attributeType !== null
        && values.attributeType.id
      ) {
        values.attributeType = values.attributeType.id;
      }
      if (
        typeof values.dataType === 'object'
        && values.dataType !== null
        && values.dataType.id
      ) {
        values.dataType = values.dataType.id;
      }
      if (
        typeof values.rule === 'object'
        && values.rule !== null
        && values.rule.id
      ) {
        values.rule = values.rule.id;
      }
      if (
        typeof values.modelType === 'object'
        && values.modelType !== null
        && values.modelType.id
      ) {
        values.modelType = values.modelType.id;
      }
    }
    handleMutation({
      mutate: this.props.mutate,
      variables: { attribute: values },
      refetchQueries: [{ query: AttributeGridQuery }, { query: AttributeSelectQuery }],
      onSuccess: this.props.onSuccess,
    });
  }

  render() {
    return (
      <FormBase header="Create Attribute">
        <AttributeForm
          setConfigVals={this.setConfigVals}
          onSubmit={this.handleSubmit}
        />
      </FormBase>
    );
  }
}

const AttributeNewWithMutation = graphql(CreateAttributeMutation)(AttributeNew);

export default AttributeNewWithMutation;
