import axios from 'axios';
import SuccessMessage from '../common/SuccessMessage';
import ErrorMessage from '../common/ErrorMessage';

export default async (props) => {
  const { onError, onSuccess, path } = props;
  const url = path;
  console.log('url', url);
  console.log('props,', props);
  let res;
  let resStr;
  try {
    res = await axios.post(url, props);
    if (res.data && res.data.length > 0) {
      resStr = res.data.join(' ');
    }
    SuccessMessage({
      msg: `Import successful! Details: ${resStr}`,
      timeOut: 60000,
    });
    console.log('success', res);
    onSuccess(res);
  } catch (err) {
    let msg = 'Import failed';
    if (err && err.response && err.response.data) {
      msg += `: ${err.response.data}`;
    }
    ErrorMessage({
      msg,
      timeOut: 60000,
    });
    onError(err);
  }
};
