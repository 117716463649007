import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import { Field } from 'redux-form';
import update from 'immutability-helper';
import { AssetSelectQuery } from '../../graphql/queries/AssetQueries';
import renderSelectTree from '../form/renderSelectTree';

class AssetSelect extends Component {
  constructor(props) {
    super(props);
    this.handleAssetChange = this.handleAssetChange.bind(this);
  }

  handleAssetChange(e) {
    this.props.onChange(e);
  }

  render() {
    console.log('this props', this.props);
    const {
      AssetSelectQuery: { loading, error, companies },
    } = this.props;

    if (loading) {
      return <p>Loading...</p>;
    } if (error) {
      return <p>Error Loading Assets!</p>;
    }
    return (
      <div>
        <label>{this.props.label}</label>

        <Field
          onChange={this.handleAssetChange}
          name={this.props.fieldName}
          component={renderSelectTree}
          data={companies}
          valueField="id"
          textField="name"
        />
      </div>
    );
  }
}
AssetSelect.defaultProps = {
  label: 'Assets',
  fieldName: 'assets',
  parentsOnly: false,
  onChange: () => {},
};
function mapStateToProps({ auth }) {
  return { auth };
}
// TODO: add query for ALL assets (including children)
export default compose(
  connect(mapStateToProps),
  graphql(AssetSelectQuery, {
    name: 'AssetSelectQuery',
    props: ({ ownProps, AssetSelectQuery: { loading, error, companies } }) => {
      if (companies && companies.length > 0) {
        companies = companies.map((company) => {
          if (company.locations && company.locations.length > 0) {
            const companyLocations = company.locations.map((location) => {
              if (location.assets && location.assets.length > 0) {
                const locationAssets = location.assets.map((asset) => ({ id: asset.id, name: asset.name }));
                location = update(location, {
                  assets: {
                    $set: locationAssets,
                  },
                });
              }
              return location;
            });

            company = update(company, {
              locations: {
                $set: companyLocations,
              },
            });
          }
          return company;
        });
      }
      return {
        AssetSelectQuery: {
          companies,
          loading,
          error,
        },
      };
    },
  }),
)(AssetSelect);
