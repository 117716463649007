import React, { Component } from 'react';
import { reduxForm, reset } from 'redux-form';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import ErrorMessage from '../common/ErrorMessage';
import PSLoader from '../common/PSLoader';
import CSVConfigForm from './CSVConfigForm';
import CSVUpload from './CSVUpload';
import ReviewUpload from './ReviewUpload';
import processData from '../form/uploadData';
import { REACT_APP_BACKEND } from '../../config';

class CSVImport extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onError = this.onError.bind(this);
    this.onFinally = this.onFinally.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.submitUpload = this.submitUpload.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.state = {
      page: 1,
      showLoader: false,
    };
  }

  componentWillUnmount(dispatch) {
    console.log('unmount CSVImport');
    this.props.dispatch(reset('CSVImport'));
  }

  onSuccess(values) {
    console.log('CSVImport Values', values);
    this.onFinally();
    // this.props.history.push('/homepage');
  }

  onSubmit() {
    const columns = this.props.uploadedData[0];
    const data = this.props.uploadedData.slice(1);
    // check for required fields
    const missingRq = [];
    const rowMissingRq = [];
    this.props.requiredFields.forEach((rq) => {
      // search for rq.id in columns
      const rqCol = columns.findIndex((header) => header === rq.id);
      if (typeof rqCol === 'undefined') {
        missingRq.push(`Missing column ${rq.id}. `);
      } else {
        // we have this column, check for it in each row
        data.forEach((dR, ind) => {
          if (dR.length === 1 && dR[0] === '') {
            // empty row
          } else if (!dR[rqCol] || dR[rqCol] === '') {
            rowMissingRq.push(`Missing ${rq.id} from row # ${ind + 1}. `);
          }
        });
      }
    });
    if (missingRq.length > 0 || rowMissingRq.length > 0) {
      // show error don't submit
      let err = missingRq.join(' ');
      err += rowMissingRq.join(' ');
      ErrorMessage({
        msg: err,
        timeOut: 60000,
      });
    } else {
      this.setState({ showLoader: true });
      processData({
        path: `${REACT_APP_BACKEND}/csv/importFromCSV`,
        modelName: this.props.model,
        identifyBy: this.props.identifyBy,
        createOrUpdate: this.props.createOrUpdate,
        values: this.props.uploadedData,
        onSuccess: this.onSuccess,
        onError: this.onError,
      });
    }
  }

  onError(error) {
    console.log('onError');

    this.onFinally();
  }

  onFinally() {
    console.log('onFinally');
    this.setState({ showLoader: false });
    // run queries again? this.props.onSubmit();
  }

  submitUpload() {
    const data = this.props.uploadedData;
    const fixedData = [];
    const keys = Object.keys(data);
    keys.forEach((key) => {
      if (
        key !== 'preventDefault'
        && (data[key].length > 1 || data[key][0] !== '')
      ) {
        fixedData.push(data[key]);
      }
    });
    this.props.change('uploadedData', fixedData);
    this.nextPage();
  }

  nextPage() {
    this.setState((prevState) => ({ page: prevState.page + 1 }));
  }

  previousPage() {
    this.setState((prevState) => ({ page: prevState.page - 1 }));
  }

  render() {
    const { onSubmit } = this;
    const { page } = this.state;
    return (
      <div>
        <PSLoader showLoader={this.state.showLoader}>
          <Typography variant="h4">Import Assets and Devices from CSV Wizard</Typography>
          {page === 1 && <CSVConfigForm onSubmit={this.nextPage} />}
          {page === 2 && (
            <CSVUpload
              previousPage={this.previousPage}
              onSubmit={this.submitUpload}
            />
          )}
          {page === 3 && (
            <ReviewUpload
              previousPage={this.previousPage}
              onSubmit={onSubmit}
            />
          )}
        </PSLoader>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  uploadedData:
    state.form
    && state.form.CSVImport
    && state.form.CSVImport.values
    && state.form.CSVImport.values.uploadedData
    && state.form.CSVImport.values.uploadedData.length > 0
      ? state.form.CSVImport.values.uploadedData
      : null,
  requiredFields:
    state.form
    && state.form.CSVImport
    && state.form.CSVImport.values
    && state.form.CSVImport.values.requiredFields
    && state.form.CSVImport.values.requiredFields.length > 0
      ? state.form.CSVImport.values.requiredFields
      : null,
  selectFields:
    state.form
    && state.form.CSVImport
    && state.form.CSVImport.values
    && state.form.CSVImport.values.selectFields
    && state.form.CSVImport.values.selectFields.length > 0
      ? state.form.CSVImport.values.selectFields
      : null,
  model:
    state.form
    && state.form.CSVImport
    && state.form.CSVImport.values
    && state.form.CSVImport.values.model
    && state.form.CSVImport.values.model.id
      ? state.form.CSVImport.values.model.id
      : null,
  createOrUpdate:
    state.form
    && state.form.CSVImport
    && state.form.CSVImport.values
    && state.form.CSVImport.values.createOrUpdate
    && state.form.CSVImport.values.createOrUpdate.id
      ? state.form.CSVImport.values.createOrUpdate.id
      : null,
  identifyBy:
    state.form
    && state.form.CSVImport
    && state.form.CSVImport.values
    && state.form.CSVImport.values.identifyBy
    && state.form.CSVImport.values.identifyBy.id
      ? state.form.CSVImport.values.identifyBy.id
      : null,
  subAssets:
    !!(state.form
    && state.form.CSVImport
    && state.form.CSVImport.values
    && state.form.CSVImport.values.subAssets),
});

CSVImport = reduxForm({
  form: 'CSVImport',
  destroyOnUnmount: false, // <------ preserve form data
})(CSVImport);
export default connect(mapStateToProps)(CSVImport);
