import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

class FormBase extends Component {
  render() {
    const { loading, error, header } = this.props;
    if (loading) {
      return <p>Loading...</p>;
    } if (error) {
      console.log(error);
      return <p>Error loading form!</p>;
    }
    return (
      <div>
        <Container>
          <ReactTooltip multiline />
          <Typography variant="h4" className="ps-form-header">{header}</Typography>
          {this.props.children}
        </Container>
      </div>
    );
  }
}
FormBase.defaultProps = {
  loading: false,
  error: false,
  header: '',
};
export default FormBase;
