import React, { Component } from 'react';
import { reduxForm, Field, change } from 'redux-form';
import { connect } from 'react-redux';
import moment from 'moment';
import FormField from '../form/FormField';
import renderCheckbox from '../form/renderCheckbox';
import FormSubmitButton from '../form/FormSubmitButton';
import CompanyLocationDropdown from '../company/CompanyLocationDropdown';
import ClearButton from '../form/ClearButton';
import CompanyDropdown from '../company/CompanyDropdown';
import DeviceConfigDropdown from './deviceConfig/DeviceConfigDropdown';
import BasicDeviceForm from './BasicDeviceForm';
import renderDatePicker from '../form/renderDatePicker';
import renderDropdownList from '../form/renderDropdownList';
import { DATA_FORWARDING_URLS } from '../../config';

class DeviceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parentId:
        props.initialValues && props.initialValues.parent
          ? props.initialValues.parent.id
          : null,
      dataForwardUrl:
      props.initialValues && props.initialValues.dataForwardUrl
        ? props.initialValues.dataForwardUrl
        : null,
    };
    this.onDataForwardUrlChange = this.onDataForwardUrlChange.bind(this);
  }

  onDataForwardUrlChange(event, newValue, previousValue) {
    this.setState({ dataForwardUrl: newValue.id });
    if (newValue.id === null) {
      this.props.setDataForwardOnly();
    }
  }

  render() {
    const {
      handleSubmit,
      pristine,
      reset,
      submitting,
      initialValues,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <BasicDeviceForm
          setDeviceName={this.props.setDeviceName}
          setDeviceConfig={this.props.setDeviceConfig}
          selectDeviceType={this.props.selectDeviceType}
          setDeviceGroupAdd={this.props.setDeviceGroupAdd}
          setDeviceGroupRemove={this.props.setDeviceGroupRemove}
          setDeviceGroupCurrentList={this.props.setDeviceGroupCurrentList}
          initialValues={initialValues}
        />
        <DeviceConfigDropdown
          fieldName="deviceConfig"
          label="Device Configuration"
        />
        <br />
        <CompanyDropdown fieldName="owner" label="Device Owner" />
        <br />
        <label>Asset the Device is Installed On:</label>
        <CompanyLocationDropdown
          companyFieldName="asset.location.company"
          locationFieldName="asset.location"
          companyId={
            initialValues
            && initialValues.asset
            && initialValues.asset.location
            && initialValues.asset.location.company
              ? initialValues.asset.location.company.id
              : null
          }
          locationId={
            initialValues && initialValues.asset && initialValues.asset.location
              ? initialValues.asset.location.id
              : null
          }
          parentId={
            initialValues
            && initialValues.childAsset
            && initialValues.childAsset.parent
              ? initialValues.childAsset.parent.id
              : null
          }
          selectCompany={this.props.selectCompany}
          selectLocation={this.props.selectLocation}
          selectAsset={this.props.selectAsset}
          selectParent={this.props.selectParent}
          assetInput
        />
        <div data-tip="Specific location on the asset">
          <Field
            name="installLocation"
            type="text"
            component={FormField}
            label="Location on the Asset"
          />
        </div>
        {this.props.initialValues
          && (
          <div data-tip="Active devices are monitored for missed data uploads">
            <Field
              name="active"
              label="Active"
              component={renderCheckbox}
            />
          </div>
          )}
        <br />
        <div>
          <label>Install Date</label>
          <Field
            name="installDate"
            component={renderDatePicker}
            label="Install Date"
            normalize={(value) => {
              if (value) {
                const retVal = moment(value).unix();
                return retVal;
              }
              return null;
            }}
          />
        </div>
        <br />
        <label>Additional Options JSON:</label>
        <Field
          name="additionalOptions"
          component="textarea"
          label="Additional Options JSON"
          placeholder='{"targetTemp":100}'
        />
        {this.props.auth && this.props.auth.isSuperAdmin
          && (
            <div>
              <br />
              <br />
              <div>
                <label>Data Forwarding Site</label>
                <Field
                  name="dataForwardUrl"
                  component={renderDropdownList}
                  data={DATA_FORWARDING_URLS}
                  valueField="id"
                  textField="name"
                  label="Forward Data to this Site"
                  canBeNull
                  onChange={this.onDataForwardUrlChange}
                />
              </div>
              {this.state.dataForwardUrl && (
                <div data-tip="Only forward data, do not save to original destination database">
                  <Field
                    name="dataForwardOnly"
                    label="Forward Data Only"
                    component={renderCheckbox}
                  />
                </div>
              )}
            </div>
          )}
        <br />
        <div>
          <FormSubmitButton pristine={pristine} submitting={submitting} />
          <ClearButton
            pristine={pristine}
            submitting={submitting}
            reset={reset}
          />
        </div>
      </form>
    );
  }
}

DeviceForm.defaultProps = {
  initialValues: null,
};
const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Cannot be blank';
  }
  if (
    !values.importId
    && !values.serial
    && !values.legacy_serial
    && !values.legacy_config
  ) {
    errors.importId = 'Must provide a unique identifier if serial number or legacy serial number is not provided';
  }
  if (
    !values.importId
    && !values.serial
    && (!values.legacy_serial || !values.legacy_config)
  ) {
    errors.legacy_config = 'Must provide both legacy serial number and legacy config for device identification';
  }
  return errors;
};
DeviceForm = reduxForm({
  enableReinitialize: true,
  validate,
  form: 'Device',
})(DeviceForm);
function mapStateToProps({ auth }) {
  return { auth };
}
DeviceForm = connect(
  mapStateToProps,
  {
    selectCompany: () => change('Device', 'asset.location', {
      id: '',
      name: '',
    }),
    selectLocation: () => change('Device', 'asset', {
      id: '',
      name: '',
      fullName: '',
    }),
    selectAsset: () => change('Device', 'device', {
      id: '',
      name: '',
      fullName: '',
    }),
    selectParent: () => change('Device', 'childAsset', {
      id: '',
      name: '',
      fullName: '',
    }),
    setDataForwardOnly: (event, newValue, previousValue) => change('Device', 'dataForwardOnly', false),
    selectDeviceType: () => change('Device', 'deviceTypeConfig', []),
    setDeviceConfig: (event, newValue, previousValue) => change('Device', 'deviceConfig', event.deviceConfig),
    setDeviceName: (event, newValue, previousValue) => change('Device', 'name', event.name),
    setDeviceGroupAdd: (newValue) => change('Device', 'deviceGroupAdd', newValue),
    setDeviceGroupRemove: (newValue) => change('Device', 'deviceGroupRemove', newValue),
    setDeviceGroupCurrentList: (newValue) => change('Device', 'deviceGroupCurrentList', newValue),
  },
)(DeviceForm);

export default DeviceForm;
