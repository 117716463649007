import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import GridBase from '../../common/GridBase';
import ContextMenuWithTrigger from '../../common/ContextMenuWithTrigger';
import Modal from '../../common/Modal';
import DeleteModel from '../../common/DeleteModel';
import AssetTypeUpdate from './AssetTypeUpdate';
import { AssetTypeGridQuery } from '../../../graphql/queries/AssetTypeQueries';
import { DeleteAssetTypeMutation } from '../../../graphql/mutations/AssetTypeMutations';

class AssetTypeGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      assetType: null,
      delete: { id: null, name: '' },
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleDeleteClose = this.handleDeleteClose.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  columns = [
    {
      Header: 'ID',
      accessor: 'id',
      maxWidth: 90,
      filterable: true,
    },
    {
      Header: 'Asset Type',
      accessor: 'name',
      minWidth: 100,
      filterable: true,
    },
    {
      Header: '',
      maxWidth: 23,
      resizable: false,
      sortable: false,
      Cell: ({ row }) => ContextMenuWithTrigger({
        handleClick: this.handleClick,
        row,
      }),
    },
  ];

  handleClick(e, data, target) {
    if (data.action === 'Edit') {
      this.setState({
        assetType: data.row,
        modalIsOpen: true,
      });
    } else if (data.action === 'Delete') {
      this.setState({ delete: { id: data.row.id, name: data.row.name } });
    }
  }

  handleDeleteClose() {
    this.setState({ delete: { id: null, name: '' } });
  }

  render() {
    const {
      data: { loading, error, assetTypes },
    } = this.props;
    const sorting = [{ id: 'name', asc: true }];
    if (loading) {
      return <p>Loading...</p>;
    } if (error) {
      return <p>Error loading asset types!</p>;
    }
    if (Array.isArray(assetTypes)) {
      return (
        <div>
          <GridBase
            dataVar={assetTypes}
            columns={this.columns}
            sorting={sorting}
          />

          <DeleteModel
            deleteMutation={DeleteAssetTypeMutation}
            refetchQueries={[{ query: AssetTypeGridQuery }]}
            id={this.state.delete.id}
            name={this.state.delete.name}
            onClose={this.handleDeleteClose}
          />

          <Modal
            onClose={this.closeModal}
            modalIsOpen={this.state.modalIsOpen}
            contentLabel="Edit Asset Type"
          >
            <AssetTypeUpdate assetType={this.state.assetType} />
          </Modal>
        </div>
      );
    }
    console.log('no asset type array', assetTypes);
    return null;
  }
}

export default graphql(AssetTypeGridQuery)(AssetTypeGrid);
