import React from 'react';
import { Field } from 'redux-form';
import FormField from '../form/FormField';
import AssetTypeDropdownWithConfig from './assetType/AssetTypeDropdownWithConfig';
import AssetConfigDropdown from './assetConfig/AssetConfigDropdown';
import CompanyDropdown from '../company/CompanyDropdown';
import renderCheckbox from '../form/renderCheckbox';

export default [
  {
    id: 'name',
    name: 'Name',
    field: (
      <Field name="name" type="text" component={FormField} label="Asset Name" />
    ),
  },
  {
    id: 'type',
    name: 'Asset Type',
    field: <AssetTypeDropdownWithConfig formName="multiEditWizard" />,
  },
  {
    id: 'config',
    name: 'Asset Configuration',
    field: (
      <AssetConfigDropdown
        fieldName="assetConfig"
        label="Asset Configuration"
        onDropdownChange={() => {}}
      />
    ),
  },
  {
    id: 'monitoringCompany',
    name: 'Monitoring Company',
    field: <CompanyDropdown fieldName="monitoringCompany" label="Monitoring Company" />,
  },
  {
    id: 'parentCompanyCanView',
    name: 'Owner Company Can View',
    field: (
      <Field
        name="parentCompanyCanView"
        label="Owner Company Can View"
        component={renderCheckbox}
      />
    ),
  },
];
