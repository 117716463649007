import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { connect } from 'react-redux';
import GridBase from '../common/GridBase';
import ContextMenuWithTrigger from '../common/ContextMenuWithTrigger';
import Modal from '../common/Modal';
import servicePlanFormFields from './servicePlanFormFields';
import ServicePlanUpdate from './ServicePlanUpdate';
import { LocationServicePlanGridQuery } from '../../graphql/queries/LocationQueries';
import displayTimestamp from '../common/displayTimestamp';
import timestampSort from '../common/timestampSort';

class ServicePlanGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      location: null,
      edit: '',
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  columns = [
    {
      Header: 'ID',
      accessor: 'id',
      maxWidth: 90,
      filterable: true,
      sortable: true,
      show: false,
    },
    {
      Header: 'Company',
      id: 'companyName',
      accessor: (d) => d.company.name,
      filterable: true,
    },
    {
      Header: 'Location',
      id: 'locationName',
      accessor: (d) => d.name,
      filterable: true,
    },
    {
      Header: 'Location Id',
      id: 'locationId',
      accessor: (d) => d.id,
      show: false,
    },
    {
      Header: 'Service Plan',
      id: 'servicePlan',
      accessor: (d) => (d.servicePlan ? d.servicePlan : 'No Plan'),
      filterable: true,
    },
    {
      Header: 'Expiration',
      id: 'serviceExpirationDate',
      accessor: (d) => displayTimestamp(d, 'serviceExpirationDate'),
      sortMethod: timestampSort,
      filterable: true,
    },
    {
      Header: '',
      maxWidth: 23,
      resizable: false,
      sortable: false,
      Cell: ({ row }) => ContextMenuWithTrigger({
        handleClick: this.handleClick,
        row,
        options: [
          { key: 'edit', label: 'edit', action: 'Edit' },
        ],
      }),
    },
  ];

  handleClick(e, data, target) {
    if (data.action === 'Edit') {
      this.setState({ location: data.row, modalIsOpen: true });
    }
  }

  render() {
    const { data: { loading, error, editableLocations } } = this.props;
    const sorting = [
      { id: 'companyName', asc: true },
    ];
    const multiSelect = {
      canSend: false,
      canDelete: false,
      fields: servicePlanFormFields,
      modelName: 'location',
      onSubmit: this.props.data.refetch,
    };
    if (loading) {
      return <p>Loading...</p>;
    } if (error) {
      return <p>Error loading service plans!</p>;
    }
    return (
      <div>
        <GridBase
          dataVar={editableLocations}
          columns={this.columns}
          sorting={sorting}
          multiSelect={multiSelect}
        />

        <Modal
          onClose={this.closeModal}
          modalIsOpen={this.state.modalIsOpen}
          contentLabel="Edit Service Plan"
        >
          <ServicePlanUpdate location={this.state.location} />
        </Modal>
      </div>
    );
  }
}

function mapStateToProps({ auth }) {
  return { auth };
}
ServicePlanGrid = connect(mapStateToProps)(ServicePlanGrid);
export default graphql(LocationServicePlanGridQuery)(ServicePlanGrid);
