import handleMutation from './handleMutation';
import handleAssetTypeMutation from './handleAssetTypeMutation';

const handleSubmitAsset = (props) => {
  handleMutation({
    mutate: props.assetMutation,
    variables: { asset: props.values },
    refetchQueries: props.refetchQueries,
    onSuccess: props.onSuccess,
    update: props.update,
  });
};

export default (props) => {
  const { values, assetTypeMutation } = props;
  if (typeof values === 'object') {
    delete values.fullName;
    if (
      typeof values.location === 'object'
      && values.location !== null
      && values.location.id
    ) {
      values.location = values.location.id;
    }
    if (
      typeof values.parent === 'object'
      && values.parent !== null
      && values.parent.id
    ) {
      values.parent = values.parent.id;
    }
    if (
      typeof values.monitoringCompany === 'object'
      && values.monitoringCompany !== null
      && values.monitoringCompany.id
    ) {
      values.monitoringCompany = values.monitoringCompany.id;
    }
    if (typeof values.assetType && typeof values.assetType === 'object') {
      // if new AssetType, save this AssetType
      if (values.assetType.assetTypeName) {
        handleAssetTypeMutation({
          mutation: assetTypeMutation,
          values,
          handleSubmitAsset: () => {
            handleSubmitAsset(props);
          },
        });
      } else if (values.assetType.id) {
        // store AssetType Id as assetType
        values.assetType = values.assetType.id;
        handleSubmitAsset(props);
      }
    } else {
      handleSubmitAsset(props);
    }
  }
};
