import React from 'react';
import { Field } from 'redux-form';
import FormField from '../../form/FormField';
import ScalarTypeDropdown from '../../common/ScalarTypeDropdown';
import ModelTypeDropdown from '../../common/ModelTypeDropdown';
import DataTypeDropdown from '../dataType/DataTypeDropdown';

export default [
  {
    id: 'dataType',
    name: 'Data Type',
    field: (
      <DataTypeDropdown label="Data Type" fieldName="dataType" />
    ),
  },
  {
    id: 'rule',
    name: 'Rule',
    field: (
      <ScalarTypeDropdown fieldName="rule" label="Attribute Rule" />
    ),
  },
  {
    id: 'modelType',
    name: 'Model Type',
    field: (
      <ModelTypeDropdown label="Model Type" fieldName="modelType" />
    ),
  },
  {
    id: 'units',
    name: 'Units',
    field: (
      <Field name="units" type="text" component={FormField} label="Units" />
    ),
  },
  {
    id: 'round',
    name: 'Round',
    field: (
      <Field name="round" type="text" component={FormField} label="Round" />
    ),
  },
];
