import React, { Component } from 'react';
import { reduxForm, Field, change } from 'redux-form';
import { connect } from 'react-redux';
import FormField from '../../form/FormField';
import FormSubmitButton from '../../form/FormSubmitButton';
import ClearButton from '../../form/ClearButton';
import DeviceConfigFormSection from './DeviceConfigFormSection';

class DeviceConfigForm extends Component {
  render() {
    const {
      handleSubmit, pristine, reset, submitting,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div data-tip="Name of Device Configuration">
          <Field
            name="name"
            type="text"
            component={FormField}
            label="Device Config Name"
          />
        </div>
        <DeviceConfigFormSection
          setConfigAtts={this.props.setConfigAtts}
        />
        <div>
          <FormSubmitButton pristine={pristine} submitting={submitting} />
          <ClearButton
            pristine={pristine}
            submitting={submitting}
            reset={reset}
          />
        </div>
      </form>
    );
  }
}

DeviceConfigForm.defaultProps = {
  initialValues: null,
};
const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Cannot be blank';
  }
  return errors;
};

DeviceConfigForm = reduxForm({ validate, form: 'DeviceConfig' })(
  DeviceConfigForm,
);

DeviceConfigForm = connect(
  null,
  {
    setConfigAtts: (event, newValue, previousValue) => change('DeviceConfig', 'attConfigs', newValue),
  },
)(DeviceConfigForm);

export default DeviceConfigForm;
