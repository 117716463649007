import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

class PSCard extends Component {
  render() {
    return (
      <Container className="ps-container">
        <Card className="ps-card">
          <CardContent>
            <span className="ps-card-title">
              <Typography variant="h4">{this.props.cardTitle}</Typography>
            </span>
            {this.props.children}
          </CardContent>
        </Card>
      </Container>
    );
  }
}
export default PSCard;
