import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import FormBase from '../form/FormBase';
import handleUserMutation from '../../graphql/functions/handleUserMutation';
import { UpdateUserMutation } from '../../graphql/mutations/UserMutations';
import { UserQuery, UserGridQuery } from '../../graphql/queries/UserQueries';
import ResetPasswordForm from './ResetPasswordForm';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    if (typeof values === 'object') {
      const vals = { id: this.props.user.id, password: values.password, existingPassword: values.existingPassword };
      // console.log(vals);
      handleUserMutation({
        values: vals,
        mutation: this.props.UpdateUserMutation,
        refetchQueries: [{ query: UserGridQuery }],
        onSuccess: this.props.onSuccess,
      });
    }
  }

  render() {
    const { UserQuery: { loading, error, user } } = this.props;
    return (
      <FormBase loading={loading} error={error} header="Reset Password">
        <ResetPasswordForm initialValues={user} onSubmit={this.handleSubmit} />
      </FormBase>
    );
  }
}

export default compose(
  graphql(UpdateUserMutation, {
    name: 'UpdateUserMutation',
    options: (props) => ({ variables: { id: props.user.id } }),
  }),
  graphql(UserQuery, {
    name: 'UserQuery',
    options: (props) => ({ variables: { id: props.user.id } }),
  }),
)(ResetPassword);
