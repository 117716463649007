import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';

import UserForm from './UserForm';
import FormBase from '../form/FormBase';
import handleUserMutation from '../../graphql/functions/handleUserMutation';
import { UpdateUserMutation } from '../../graphql/mutations/UserMutations';
import {
  UserQuery,
  UserGridQuery,
  UserSelectQuery,
} from '../../graphql/queries/UserQueries';
import UserAccessGrid from './UserAccessGrid';

class UserUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    if (typeof values === 'object') {
      values.id = this.props.user.id;
      if (values.role) {
        values.role = values.role.id;
      }
    }
    handleUserMutation({
      values,
      mutation: this.props.UpdateUserMutation,
      refetchQueries: [{ query: UserGridQuery }, { query: UserSelectQuery }],
      onSuccess: this.props.onSuccess,
    });
  }

  render() {
    const {
      UserQuery: { loading, error, user },
    } = this.props;
    return (
      <div>
        <FormBase loading={loading} error={error} header="Edit User">
          <UserForm
            isCreate={false}
            initialValues={user}
            onSubmit={this.handleSubmit}
          />
        </FormBase>
        <br />
        <br />
        <UserAccessGrid userId={this.props.user.id} />
      </div>

    );
  }
}

export default compose(
  graphql(UpdateUserMutation, {
    name: 'UpdateUserMutation',
    options: (props) => ({ variables: { id: props.user.id } }),
  }),
  graphql(UserQuery, {
    name: 'UserQuery',
    options: (props) => ({ variables: { id: props.user.id }, fetchPolicy: 'network-only' }),
  }),
)(UserUpdate);
