import React from 'react';
import Button from '@material-ui/core/Button';

export default function ClearButton({ pristine, submitting, reset }) {
  return (
    <Button
      variant="contained"
      type="button"
      disabled={pristine || submitting}
      onClick={reset}
    >
      Clear Values
    </Button>
  );
}
