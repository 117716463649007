import React, { Component } from 'react';
import { reduxForm, Field, FieldArray } from 'redux-form';
import Button from '@material-ui/core/Button';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import { connect } from 'react-redux';
import { CONST } from '../common/constants';
import renderDropdownList from '../form/renderDropdownList';
import { required } from '../common/functions';
import renderCheckbox from '../form/renderCheckbox';
import FormField from '../form/FormField';
import FormSubmitButton from '../form/FormSubmitButton';
import ClearButton from '../form/ClearButton';
import AttributeSelect from '../data/attribute/AttributeSelect';
import ExpansionPanelBase from '../common/ExpansionPanelBase';
import { AttributeGridQuery } from '../../graphql/queries/AttributeQueries';

class TableForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attributes: null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.AttributeGridQuery
      && this.props.AttributeGridQuery.attributes
      && this.props.AttributeGridQuery.attributes.length
      && prevState.attributes === null) {
      this.setState({ attributes: this.props.AttributeGridQuery.attributes });
    }
  }

  getColumnPanels(fields) {
    return fields.map((tableColumn, index) => {
      console.log('tableColumn', tableColumn);
      const attNum = index + 1;
      let header = `Column #${attNum}`;
      if (this.state.attributes && this.props.formState
        && this.props.formState.TableForm
        && this.props.formState.TableForm.values
        && this.props.formState.TableForm.values.columns
        && this.props.formState.TableForm.values.columns[index]
      ) {
        let columnAtt = this.props.formState.TableForm.values.columns[index];
        console.log('columnAtt', columnAtt);
        if (columnAtt.attribute) {
          columnAtt = columnAtt.attribute;
        }
        // get the name
        let attId;
        if (typeof columnAtt === 'object' && columnAtt.id) {
          attId = columnAtt.id;
        } else if (Array.isArray(columnAtt)) {
          attId = columnAtt[0];
        } else {
          attId = columnAtt;
        }
        const foundAtt = this.state.attributes.find((att) => att.id === attId);
        if (foundAtt && foundAtt.name) {
          header += ` - ${foundAtt.name}`;
        }
      }

      return {
        key: `panel${index}`,
        header,
        data: (
          <div className="expandable-form-div">
            <Button
              variant="contained"
              className="right"
              type="button"
              title="Remove Column"
              onClick={() => fields.remove(index)}
            >
              <i className="material-icons">delete</i>
            </Button>

            <Field
              name={`${tableColumn}.name`}
              type="text"
              component={FormField}
              label="Column Name"
              validate={[required]}
            />

            <div data-tip="Attribute for column">
              <AttributeSelect
                multiple={false}
                fieldName={`${tableColumn}.attribute`}
                label="Select Attribute"
              />
            </div>
            <div data-tip="Type of data to display">
              <Field
                name={`${tableColumn}.type`}
                label="Column Type"
                validate={[required]}
                component={renderDropdownList}
                data={CONST.TABLE_COLUMN_TYPES}
                valueField="id"
                textField="name"
                canBeNull={false}
              />
            </div>
            <br />
            <br />
            <div data-tip="Add a percent of alert column">
              <Field
                name={`${tableColumn}.addPercent`}
                label="Percent"
                component={renderCheckbox}
              />
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        ),
      };
    });
  }

  renderColumnArrray = ({ fields, meta: { error, submitFailed } }) => (
    <div style={{ marginTop: '5px' }}>
      <div>
        <Button
          variant="contained"
          style={{ marginTop: '5px' }}
          type="button"
          onClick={() => fields.push({})}
        >
          Add Column
        </Button>
        {submitFailed && error && <span>{error}</span>}
      </div>
      <div>
        <ExpansionPanelBase panelData={this.getColumnPanels(fields)} />
      </div>
    </div>
  );

  render() {
    const {
      handleSubmit, pristine, reset, submitting,
    } = this.props;
    if (this.state.attributes === null) {
      return <p>Loading...</p>;
    }
    return (
      <form onSubmit={handleSubmit}>
        <Field
          name="name"
          type="text"
          component={FormField}
          label="Table Name"
        />
        <Field
          name="title"
          type="text"
          component={FormField}
          label="Display Title"
        />
        <Field
          name="raw"
          label="Raw Data"
          component={renderCheckbox}
        />
        <Field
          name="mostRecent"
          label="Fetch Most Recent Data"
          component={renderCheckbox}
        />
        <Field
          name="dateColumn"
          label="Show Date Column"
          component={renderCheckbox}
        />
        <br />
        <label>Columns</label>
        <FieldArray name="columns" component={this.renderColumnArrray} />

        <br />
        <br />
        <div>
          <FormSubmitButton pristine={pristine} submitting={submitting} />
          <ClearButton
            pristine={pristine}
            submitting={submitting}
            reset={reset}
          />
        </div>
      </form>
    );
  }
}

TableForm.defaultProps = {
  initialValues: null,
};
const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Cannot be blank';
  }
  if (!values.columns) {
    errors.columns = 'Cannot be empty';
  }
  if (!values.title) {
    errors.title = 'Cannot be empty';
  }
  return errors;
};
const mapStateToProps = (state) => ({
  formState: state.form,
});

TableForm = compose(
  graphql(AttributeGridQuery, {
    name: 'AttributeGridQuery',
    options: () => ({
      fetchPolicy: 'network-only',
    }),
  }),
)(TableForm);
TableForm = reduxForm({ validate, form: 'TableForm' })(TableForm);
export default connect(mapStateToProps)(TableForm);
