import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import AttributeForm from './AttributeForm';
import FormBase from '../../form/FormBase';
import handleMutation from '../../../graphql/functions/handleMutation';
import {
  AttributeGridQuery,
  AttributeSelectQuery,
  AttributeQuery,
} from '../../../graphql/queries/AttributeQueries';
import { UpdateAttributeMutation } from '../../../graphql/mutations/AttributeMutations';

class AttributeUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    console.log('values', values);
    // store attribute id
    if (typeof values === 'object') {
      values.id = this.props.attribute.id;
      if (
        typeof values.attributeType === 'object'
        && values.attributeType !== null
        && values.attributeType.id
      ) {
        values.attributeType = values.attributeType.id;
      }
      /*       if (
        typeof values.axis === 'object' &&
        values.axis !== null &&
        values.axis.id
      ) {
        values.axis = values.axis.id;
      } */
      if (
        typeof values.dataType === 'object'
        && values.dataType !== null
        && values.dataType.id
      ) {
        values.dataType = values.dataType.id;
      }
      if (
        typeof values.rule === 'object'
        && values.rule !== null
        && values.rule.id
      ) {
        values.rule = values.rule.id;
      }
      if (
        typeof values.modelType === 'object'
        && values.modelType !== null
        && values.modelType.id
      ) {
        values.modelType = values.modelType.id;
      }
      if (typeof values.legacy_id === 'string' && values.legacy_id.length === 0) {
        values.legacy_id = null;
      }
    }
    handleMutation({
      mutate: this.props.mutate,
      variables: { attribute: values },
      refetchQueries: [{ query: AttributeGridQuery }, { query: AttributeSelectQuery }],
      onSuccess: this.props.onSuccess,
    });
  }

  render() {
    const {
      data: { loading, error, attribute },
    } = this.props;
    return (
      <FormBase loading={loading} error={error} header="Edit Attribute">
        <AttributeForm initialValues={attribute} onSubmit={this.handleSubmit} />
      </FormBase>
    );
  }
}

export default compose(
  graphql(UpdateAttributeMutation, {
    options: (props) => ({ variables: { id: props.attribute.id } }),
  }),
  graphql(AttributeQuery, {
    options: (props) => ({ variables: { id: props.attribute.id }, fetchPolicy: 'network-only' }),
  }),
)(AttributeUpdate);
