import handleMutation from './handleMutation';

const handleSubmitUser = (props) => {
  handleMutation({
    mutate: props.mutation,
    variables: { user: props.values },
    refetchQueries: props.refetchQueries,
    onSuccess: props.onSuccess,
  });
};

export default (props) => {
  const { values } = props;
  if (typeof values === 'object') {
    if (typeof values.company === 'object' && values.company.id) {
      values.company = values.company.id;
    }
    // dont send latest visit
    delete values.latestVisit;

    handleSubmitUser(props);
  }
};
