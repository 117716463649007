import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';

import Ap2200FirmwareForm from './Ap2200FirmwareForm';
import FormBase from '../../form/FormBase';
import { CreateAp2200FirmwareMutation } from '../../../graphql/mutations/Ap2200FirmwareMutations';
import {
  Ap2200FirmwareGridQuery,
  Ap2200FirmwareDropdownQuery,
} from '../../../graphql/queries/Ap2200FirmwareQueries';
import handleMutation from '../../../graphql/functions/handleMutation';

class Ap2200FirmwareNew extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    handleMutation({
      mutate: this.props.CreateAp2200FirmwareMutation,
      variables: { ap2200Firmware: values },
      refetchQueries: [
        { query: Ap2200FirmwareGridQuery }, { query: Ap2200FirmwareDropdownQuery },
      ],
      onSuccess: this.props.onSuccess,
    });
  }

  render() {
    return (
      <FormBase header="Add AP2200 Firmware">
        <Ap2200FirmwareForm onSubmit={this.handleSubmit} />
      </FormBase>
    );
  }
}

export default compose(
  graphql(CreateAp2200FirmwareMutation, { name: 'CreateAp2200FirmwareMutation' }),
)(Ap2200FirmwareNew);
