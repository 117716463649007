import React, { Component } from 'react';
import { reduxForm } from 'redux-form';

import FormSubmitButton from '../form/FormSubmitButton';
import ClearButton from '../form/ClearButton';
import UserSelect from '../user/UserSelect';

class AccessControlForm extends Component {
  render() {
    console.log(this.props);
    const {
      handleSubmit, pristine, reset, submitting,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        {this.props.modelSelect}
        <UserSelect />
        <div>
          <FormSubmitButton pristine={pristine} submitting={submitting} />
          <ClearButton
            pristine={pristine}
            submitting={submitting}
            reset={reset}
          />
        </div>
      </form>
    );
  }
}

const validate = (values) => {
  const errors = {};
  return errors;
};

export default reduxForm({
  validate,
  form: 'AccessControl',
})(AccessControlForm);
