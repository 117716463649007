import React, { Component } from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import { toastr } from 'react-redux-toastr';
import ConfirmDialog from './ConfirmDialog';
import handleMutation from '../../graphql/functions/handleMutation';

// Deletes a model using incoming id and mutation
// prompts user with a confirm dialog box before deleting
class DeleteModel extends Component {
  constructor(props) {
    super(props);
    this.timeout = null;
    this.state = {
      id: null,
      name: '',
      dialogMessage: '',
    };
    this.closeDialog = this.closeDialog.bind(this);
    this.onDeleteSuccess = this.onDeleteSuccess.bind(this);
    this.onDialogConfirm = this.onDialogConfirm.bind(this);
  }

  // when props change
  componentDidUpdate(prevProps, prevState) {
    if (this.props.id !== prevProps.id || this.props.name !== prevProps.name) {
      this.setState({ id: this.props.id, name: this.props.name });
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  // close dialog, triggered after cancel or confirm
  closeDialog() {
    this.props.onClose();
    this.setState({
      id: null,
      dialogMessage: '',
      onDialogConfirm: () => {},
    });
  }

  // show success toast
  onDeleteSuccess() {
    toastr.light('Success', 'Deletion successful', {
      icon: 'success',
      status: 'success',
      timeOut: 10000,
    });
    // run refetches after
    this.timeout = setTimeout(() => {
      this.props.client.refetchQueries({ include: this.props.refetchQueries });
    });
  }

  // trigger mutation
  onDialogConfirm() {
    handleMutation({
      mutate: this.props.client.mutate,
      mutation: this.props.deleteMutation,
      variables: { id: this.state.id },
      // refetchQueries: this.props.refetchQueries, // run these manually after delete success to avoid lag
      update: this.props.update,
      onSuccess: this.onDeleteSuccess,
      dontShowSuccess: true,
    });
  }

  render() {
    // when id is set, show confirmation dialog
    let dialogOpen = false;
    if (this.state.id !== null) {
      dialogOpen = true;
    }
    return (
      <ConfirmDialog
        title="Confirm Deletion"
        open={dialogOpen}
        message={`Delete ${this.state.name}?`}
        onConfirm={this.onDialogConfirm}
        onClose={this.closeDialog}
      />
    );
  }
}

export default withApollo(DeleteModel);
