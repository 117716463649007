import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';

import UserForm from './UserForm';
import FormBase from '../form/FormBase';
import { CreateUserMutation } from '../../graphql/mutations/UserMutations';
import {
  UserGridQuery,
  UserSelectQuery,
} from '../../graphql/queries/UserQueries';
import handleUserMutation from '../../graphql/functions/handleUserMutation';

class UserNew extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    if (typeof values === 'object' && values.role) {
      values.role = values.role.id;
    }
    handleUserMutation({
      values,
      mutation: this.props.createUserMutation,
      refetchQueries: [{ query: UserGridQuery }, { query: UserSelectQuery }],
      onSuccess: this.props.onSuccess,
    });
  }

  render() {
    return (
      <FormBase header="Add user">
        <UserForm
          isCreate
          onSubmit={this.handleSubmit}
        />
      </FormBase>
    );
  }
}

export default graphql(CreateUserMutation, { name: 'createUserMutation' })(
  UserNew,
);
