import { CONST } from './constants';

export const validateNumber = (value) => (value && Number.isNaN(Number(value)) ? 'Must be a number' : undefined);
export const required = (value) => (value ? undefined : 'Required');
export const exists = (variable) => {
  // not undefined and not === to null
  if (typeof variable !== 'undefined' && variable !== null) {
    return true;
  }
  return false;
};
export const isNonNullObject = (variable) => {
  // is object and is not null
  if (typeof variable === 'object' && variable !== null) {
    return true;
  }
  return false;
};
export const formatRole = (roleInput) => {
  // console.log('formatRole', roleInput);
  if (roleInput == null) {
    return '';
  }
  let roleNum;
  // console.log('formatRole', roleInput);
  if (typeof roleInput !== 'number') {
    if (typeof roleInput === 'string') {
      roleNum = parseInt(roleInput, 10);
    } else if (typeof roleInput === 'object') {
      if (roleInput.name) {
        return roleInput.name;
      }
    }
  } else {
    roleNum = roleInput;
  }
  // console.log('formatRole', roleNum);
  const roleVal = CONST.ROLES.filter((role) => {
    if (parseInt(role.id, 10) === roleNum) {
      return true;
    }
    return false;
  });
  // console.log('formatRole', roleVal);
  if (roleVal && roleVal.length > 0) {
    return roleVal[0].name;
  }
  return '';
};
export const selectMultipleArrayToString = (multiple, value) => {
  if (multiple !== true && value !== null && typeof value !== 'undefined'
     && typeof Array.isArray(value) && value.length === 1) {
    value = value[0];
  }
  return value;
};
