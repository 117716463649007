import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { Field } from 'redux-form';
import renderDropdownList from '../form/renderDropdownList';
import { ModelDashboardDropdownQuery } from '../../graphql/queries/ModelDashboardQueries';

class ModelDashboardDropdown extends Component {
  constructor(props) {
    super(props);
    this.handleModelDashboardChange = this.handleModelDashboardChange.bind(
      this,
    );
  }

  handleModelDashboardChange(e) {
    this.props.onDropdownChange(e);
  }

  render() {
    console.log(this.props);
    if (this.props.data.loading) {
      return <p>Loading...</p>;
    } if (this.props.data.error) {
      return <p>Error Loading ModelDashboards!</p>;
    }
    return (
      <div>
        <label>{this.props.label}</label>
        <Field
          name={this.props.fieldName}
          onChange={this.handleModelDashboardChange}
          component={renderDropdownList}
          data={this.props.data.modelDashboards}
          valueField="id"
          textField="name"
          canBeNull={this.props.canBeNull}
        />
      </div>
    );
  }
}

ModelDashboardDropdown.defaultProps = {
  onDropdownChange: () => {},
  fieldName: 'modelDashboard',
  label: 'ModelDashboard',
  canBeNull: true,
};

export default graphql(ModelDashboardDropdownQuery)(ModelDashboardDropdown);
