import update from 'immutability-helper';

export default (props) => {
  if (Array.isArray(props.attConfigs)) {
    const newAtts = [];
    for (let i = 0; i < props.attConfigs.length; i++) {
      let newAtt = props.attConfigs[i];
      if (newAtt.attribute && newAtt.attribute.id) {
        newAtt = update(newAtt, {
          attribute: { $set: newAtt.attribute.id },
        });
      }
      if (newAtt.queryFunction && typeof newAtt.queryFunction.id !== 'undefined') {
        newAtt = update(newAtt, {
          queryFunction: { $set: newAtt.queryFunction.id },
        });
      }
      // set empty string to null
      if (typeof newAtt.queryParams === 'string' && /^\s*$/.test(newAtt.queryParams)) {
        newAtt = update(newAtt, {
          queryParams: { $set: null },
        });
      }
      newAtts.push(newAtt);
    }
    props = update(props, { attConfigs: { $set: newAtts } });
  }
  return props;
};
