import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { Field } from 'redux-form';
import renderDropdownList from '../../form/renderDropdownList';
import { DataTypeDropdownQuery } from '../../../graphql/queries/DataTypeQueries';

class DataTypeDropdown extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.props.onDropdownChange(e);
  }

  render() {
    if (this.props.data.loading) {
      return <p>Loading...</p>;
    } if (this.props.data.error) {
      return <p>Error Loading DataTypes!</p>;
    }
    return (
      <div>
        <label>{this.props.label}</label>
        <Field
          name={this.props.fieldName}
          onChange={this.handleChange}
          component={renderDropdownList}
          data={this.props.data.dataTypes}
          valueField="id"
          textField="name"
          canBeNull={this.props.canBeNull}
          disabled={this.props.disabled}
        />
      </div>
    );
  }
}

DataTypeDropdown.defaultProps = {
  onDropdownChange: () => {},
  canBeNull: false,
  disabled: false,
};

export default graphql(DataTypeDropdownQuery)(DataTypeDropdown);
