import React, { Component } from 'react';
import { reduxForm } from 'redux-form';

import RankDropdown from './RankDropdown';
import FormSubmitButton from '../form/FormSubmitButton';
import ClearButton from '../form/ClearButton';
import UserSelect from '../user/UserSelect';

class AccountManagerForm extends Component {
  render() {
    console.log('initialValues', this.props.initialValues);
    const {
      handleSubmit,
      pristine,
      reset,
      submitting,
      initialValues,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <RankDropdown
          label="Rank"
          fieldName="rank"
        />
        {!initialValues
          && (
          <UserSelect
            label="User"
            fieldName="user"
            multiple={false}
            includeSelf
            superAdminOnly
          />
          )}

        <br />
        <div>
          <FormSubmitButton pristine={pristine} submitting={submitting} />
          <ClearButton
            pristine={pristine}
            submitting={submitting}
            reset={reset}
          />
        </div>
      </form>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.rank) {
    errors.rank = 'Cannot be blank';
  }
  if (!values.user) {
    errors.user = 'Cannot be blank';
  }
  return errors;
};

export default reduxForm({
  validate,
  form: 'AccountManager',
})(AccountManagerForm);
