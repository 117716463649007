import React, { Component } from 'react';

class FieldFileUpload extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const { input: { onChange } } = this.props;
    onChange(e.target.files[0]);
  }

  render() {
    const { label } = this.props; // whatever props you send to the component from redux-form Field
    return (
      <div>
        <label>{label}</label>
        <div>
          <input
            type="file"
            accept={this.props.accept}
            onChange={this.onChange}
          />
        </div>
      </div>
    );
  }
}
FieldFileUpload.defaultProps = {
  accept: '.csv, .xlsx',
};
export default FieldFileUpload;
