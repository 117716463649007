import { gql } from '@apollo/client';
import {
  installerConfigFragment,
  installerConfigFieldFragment,
} from '../fragments';

const InstallerConfigQuery = gql`
  query installerConfig($id: ID!) {
    installerConfig(id: $id) {
      ...installerConfig
    }
  }
  ${installerConfigFragment}
`;

const InstallerConfigGridQuery = gql`
  query InstallerConfigGridQuery {
    installerConfigs {
      ...installerConfig
    }
  }
  ${installerConfigFragment}
`;

const InstallerConfigDropdownQuery = gql`
  query InstallerConfigDropdownQuery {
    installerConfigs {
      id
      name
    }
  }
`;

const InstallerConfigFieldQuery = gql`
  query installerConfigField($id: ID!) {
    installerConfigField(id: $id) {
      ...installerConfigField
    }
  }
  ${installerConfigFieldFragment}
`;

const InstallerConfigFieldGridQuery = gql`
  query InstallerConfigFieldGridQuery {
    installerConfigFields {
      ...installerConfigField
    }
  }
  ${installerConfigFieldFragment}
`;

const InstallerConfigFieldSelectQuery = gql`
  query InstallerConfigFieldSelectQuery {
    installerConfigFields {
      id
      name
    }
  }
`;

export {
  InstallerConfigDropdownQuery,
  InstallerConfigGridQuery,
  InstallerConfigQuery,
  InstallerConfigFieldQuery,
  InstallerConfigFieldSelectQuery,
  InstallerConfigFieldGridQuery,
};
