import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Config from './Config';

const styles = (theme) => ({
  content: {
    width: '100%',
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height: 'calc(100% - 56px)',
    marginTop: 56,
    [theme.breakpoints.up('sm')]: {
      content: {
        height: 'calc(100% - 64px)',
        marginTop: 64,
      },
    },
    marginLeft: -Config.drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
});

class MainContent extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <main
        className={classNames(classes.content, {
          [classes.contentShift]: this.props.open,
        }, 'ps-background-lightgray')}
      >
        {this.props.children}
      </main>
    );
  }
}

export default withStyles(styles, { withTheme: true })(MainContent);
