import React, { Component } from 'react';
import DeviceGridMultiSelect from '../DeviceGridMultiSelect';

class DeviceGroupDeviceSelect extends Component {
  render() {
    return (
      <div>
        <br />
        <DeviceGridMultiSelect
          devices={this.props.devices}
          updateSelectedDevices={this.props.updateSelectedDevices}
        />
      </div>
    );
  }
}

export default DeviceGroupDeviceSelect;
