import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import { toastr } from 'react-redux-toastr';
import ConfirmDialog from '../common/ConfirmDialog';
import handleMutation from '../../graphql/functions/handleMutation';
import { SendWelcomeEmailToUser } from '../../graphql/mutations/UserMutations';

class SendWelcomeEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      name: '',
      dialogMessage: '',
    };
    this.closeDialog = this.closeDialog.bind(this);
    this.onSendSuccess = this.onSendSuccess.bind(this);
    this.onDialogConfirm = this.onDialogConfirm.bind(this);
  }

  // when props change
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.id !== this.props.id || prevProps.name !== this.props.name) {
      this.setState({ id: this.props.id, name: this.props.name });
    }
  }

  // close dialog, triggered after cancel or confirm
  closeDialog() {
    this.props.onClose();
    this.setState({
      id: null,
      dialogMessage: '',
      onDialogConfirm: () => {},
    });
  }

  // show success toast
  onSendSuccess() {
    toastr.light('Success', 'Message sent', {
      icon: 'success',
      status: 'success',
      timeOut: 10000,
    });
  }

  // trigger mutation
  onDialogConfirm() {
    handleMutation({
      mutate: this.props.mutate,
      variables: { id: this.state.id },
      refetchQueries: [],
      onSuccess: this.onSendSuccess,
      dontShowSuccess: true,
    });
  }

  render() {
    // when id is set, show confirmation dialog
    let dialogOpen = false;
    if (this.state.id !== null) {
      dialogOpen = true;
    }
    return (
      <ConfirmDialog
        title="Send Welcome Email"
        open={dialogOpen}
        message={`Send welcome email to ${this.state.name}?`}
        onConfirm={this.onDialogConfirm}
        onClose={this.closeDialog}
      />
    );
  }
}

export default compose(
  graphql(SendWelcomeEmailToUser),
)(SendWelcomeEmail);
