import { gql } from '@apollo/client';
import {
  companyAllFragment,
  companyLocationFragment,
  companyLocationAssetFragment,
  companyAllWithResponsibleUsersFragment,
} from '../fragments';

const CompanyGridQuery = gql`
  query CompanyGridQuery {
    editableCompanies {
      ...companyAllWithResponsibleUsers
    }
  }
  ${companyAllWithResponsibleUsersFragment}
`;

const CompanyLocationsAssetsQuery = gql`
  query CompanyLocationsAssetsQuery {
    companies {
      ...companyLocationAsset
    }
  }
  ${companyLocationAssetFragment}
`;

const CompanyLocationsQuery = gql`
  query CompanyLocationsQuery {
    companies {
      ...companyLocation
    }
  }
  ${companyLocationFragment}
`;

const CompanyEditableSelectQuery = gql`
  query CompanyEditableSelectQuery {
    editableCompanies {
      id
      name
    }
  }
`;

const CompanySelectQuery = gql`
  query CompanySelectQuery {
    visibleCompanies {
      id
      name
    }
  }
`;

const CompanyAccountManagerQuery = gql`
  query company($id: ID!) {
    company(id: $id) {
      ...companyAllWithResponsibleUsers
    }
  }
  ${companyAllWithResponsibleUsersFragment}
`;

const CompanyQuery = gql`
  query company($id: ID!) {
    company(id: $id) {
      ...companyAll
    }
  }
  ${companyAllFragment}
`;

const CompanyDropdownQuery = gql`
  query CompanyDropdownQuery {
    visibleCompanies {
      name
      id
    }
  }
`;

export {
  CompanyDropdownQuery,
  CompanyQuery,
  CompanyLocationsQuery,
  CompanyGridQuery,
  CompanySelectQuery,
  CompanyLocationsAssetsQuery,
  CompanyAccountManagerQuery,
  CompanyEditableSelectQuery,
};
