import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';

import AssetConfigForm from './AssetConfigForm';
import FormBase from '../../form/FormBase';
import { CreateAssetConfigMutation } from '../../../graphql/mutations/AssetConfigMutations';
import { AssetConfigGridQuery, AssetConfigDropdownQuery } from '../../../graphql/queries/AssetConfigQueries';
import handleAssetConfigMutation from '../../../graphql/functions/handleAssetConfigMutation';

class AssetConfigNew extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    handleAssetConfigMutation({
      values,
      mutation: this.props.createAssetConfigMutation,
      onSuccess: this.props.onSuccess,
      refetchQueries: [
        { query: AssetConfigGridQuery },
        { query: AssetConfigDropdownQuery },
      ],
    });
  }

  render() {
    return (
      <FormBase header="Add Asset Configuration">
        <AssetConfigForm onSubmit={this.handleSubmit} />
      </FormBase>
    );
  }
}

export default compose(
  graphql(CreateAssetConfigMutation, {
    name: 'createAssetConfigMutation',
  }),
)(AssetConfigNew);
