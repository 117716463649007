import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import ReactTooltip from 'react-tooltip';
import Modal from '../common/Modal';

function WizardBase(props) {
  return (
    <div>
      <ReactTooltip multiline />
      <div>
        <Card style={{ overflow: 'visible' }}>
          <CardHeader title={props.header} />
          <CardContent>{props.children}</CardContent>
        </Card>
      </div>
      {props.modalContent && (
        <Modal
          onClose={props.closeModal}
          modalIsOpen={props.modalIsOpen}
          contentLabel={`Add ${props.modalType}`}
        >
          {props.modalContent}
        </Modal>
      )}
    </div>
  );
}
export default WizardBase;
