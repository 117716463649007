import { gql } from '@apollo/client';
import { modelDashboardFragment } from '../fragments';

const CreateModelDashboardMutation = gql`
  mutation createModelDashboard($modelDashboard: ModelDashboardInput!) {
    createModelDashboard(modelDashboard: $modelDashboard) {
      ...modelDashboardAll
    }
  }
  ${modelDashboardFragment}
`;
const UpdateModelDashboardMutation = gql`
  mutation updateModelDashboard($modelDashboard: ModelDashboardInput!) {
    updateModelDashboard(modelDashboard: $modelDashboard) {
      ...modelDashboardAll
    }
  }
  ${modelDashboardFragment}
`;

const DeleteModelDashboardMutation = gql`
  mutation deleteModelDashboard($id: ID!) {
    deleteModelDashboard(id: $id) {
      id
      name
    }
  }
`;
export {
  CreateModelDashboardMutation,
  UpdateModelDashboardMutation,
  DeleteModelDashboardMutation,
};
