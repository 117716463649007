import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import GridBase from '../common/GridBase';
import ContextMenuWithTrigger from '../common/ContextMenuWithTrigger';
import Modal from '../common/Modal';
import AssetUpdate from '../asset/AssetUpdate';
import { AssetAndChildAssetGridQuery } from '../../graphql/queries/AssetQueries';
import { DeviceGridQuery } from '../../graphql/queries/DeviceQueries';
import { CompanyLocationsAssetsQuery } from '../../graphql/queries/CompanyQueries';
import { DeleteAssetMutation } from '../../graphql/mutations/AssetMutations';
import DeleteModel from '../common/DeleteModel';
import assetFormFields from '../asset/assetFormFields';
import AssetTypeDropdown from '../asset/assetType/AssetTypeDropdown';

class MetadataGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      modalLabel: 'Edit Asset',
      modalType: 'update',
      asset: null,
      parent: null,
      canDelete: false,
      delete: { id: null, name: '' },
      selectedAssetType: null,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleDeleteClose = this.handleDeleteClose.bind(this);
    this.handleMulti = this.handleMulti.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  columns = [
    {
      Header: 'ID',
      accessor: 'id',
      maxWidth: 90,
      filterable: true,
      sortable: true,
    },
    {
      Header: 'Company',
      id: 'companyName',
      accessor: (d) => (d.location && d.location.company ? d.location.company.name : ''),
      minWidth: 70,
      filterable: true,
    },
    {
      Header: 'Company Id',
      id: 'companyId',
      accessor: (d) => (d.location && d.location.company ? d.location.company.id : ''),
      show: false,
    },
    {
      Header: 'Location',
      id: 'locationName',
      accessor: (d) => (d.location ? d.location.name : ''),
      minWidth: 70,
      filterable: true,
    },
    {
      Header: 'Asset',
      id: 'name',
      accessor: (d) => (d.parent ? d.parent.name : d.name),
      minWidth: 70,
      filterable: true,
    },
    {
      Header: 'Asset Component',
      id: 'componentName',
      // show this if there is a parent asset
      accessor: (d) => (d.parent ? d.name : ''),
      minWidth: 70,
      filterable: true,
    },
    {
      Header: 'Type',
      id: 'assetType',
      accessor: (d) => (d.assetType ? d.assetType.name : ''),
      minWidth: 70,
      filterable: true,
    },
    {
      Header: 'Latitude',
      id: 'latitude',
      accessor: (d) => (d.latitude ? d.latitude : ''),
      show: false,
    },
    {
      Header: 'Longitude ',
      id: 'longitude',
      accessor: (d) => (d.longitude ? d.longitude : ''),
      show: false,
    },
    {
      Header: '',
      maxWidth: 23,
      resizable: false,
      sortable: false,
      Cell: ({ row }) => ContextMenuWithTrigger({
        handleClick: this.handleClick,
        row,
        canDelete: this.state.canDelete,
      }),
    },
  ];

  handleClick(e, data, target) {
    if (data.action === 'Edit') {
      this.setState({
        asset: data.row,
        modalIsOpen: true,
        modalLabel: 'Edit Asset',
        modalType: 'update',
      });
    } else if (data.action === 'Delete') {
      let refetchQueries;
      if (data.row && data.row.parentId && typeof data.row.parentId === 'string') {
        refetchQueries = [
          { query: AssetAndChildAssetGridQuery },
          { query: CompanyLocationsAssetsQuery },
          { query: DeviceGridQuery },
        ];
      } else {
        refetchQueries = [
          { query: AssetAndChildAssetGridQuery },
          { query: CompanyLocationsAssetsQuery },
          { query: DeviceGridQuery },
        ];
      }
      this.setState({ refetchQueries, delete: { id: data.row.id, name: data.row.name } });
    }
  }

  handleDeleteClose() {
    this.setState({ delete: { id: null, name: '' } });
  }

  handleMulti() {
    this.props.data.refetch();
  }

  handleAssetTypeChange(assetType) {
    this.columns = this.columns.filter((c) => {
      if (c.id) {
        return !c.id.startsWith('meta');
      }
      return true;
    });
    this.setState({ selectedAssetType: assetType });
    if (assetType !== null && typeof assetType !== 'undefined' && assetType.id !== null) {
      assetType.fields.forEach((field) => {
        this.columns.splice(this.columns.length - 1, 0, {
          Header: field.name,
          id: `meta${field.key}`,
          accessor: (d) => {
            let val = '';
            const fieldInd = d.assetTypeConfig.findIndex((f) => {
              if (typeof f !== 'undefined' && f !== null && f.k === field.key) {
                return true;
              }
              return false;
            });
            if (fieldInd > -1) {
              val = d.assetTypeConfig[fieldInd].v;
            }
            return val;
          },
          filterable: true,
        });
      });
    }
    // this.setState({t: 't'});
  }

  getAssets() {
    if (this.state.selectedAssetType !== null && typeof this.state.selectedAssetType !== 'undefined' && this.state.selectedAssetType.id !== null) {
      return this.props.data.assets.filter((a) => a && a.assetType && a.assetType.id && this.state.selectedAssetType.id === a.assetType.id);
    }
    return this.props.data.assets;
  }

  componentDidUpdate(prevProps) {
    if (this.props && this.props.auth && this.props.auth.isSuperAdmin) {
      if (this.state.canDelete === false) {
        this.setState({ canDelete: true });
      }
    }
  }

  render() {
    const {
      data: { loading, error },
    } = this.props;
    const sorting = [
      { id: 'companyName', asc: true },
      { id: 'locationName', asc: true },
      { id: 'name', asc: true },
    ];
    if (loading) {
      return <p>Loading...</p>;
    } if (error) {
      return <p>Error loading assets!</p>;
    }
    const columns = this.columns.slice(0);
    const multiSelect = {
      fields: assetFormFields,
      modelName: 'asset',
      onSubmit: this.handleMulti,
      canDelete: this.state.canDelete,
    };
    return (
      <div>
        <form>
          <AssetTypeDropdown
            onDropdownChange={(e) => this.handleAssetTypeChange(e)}
            label="Asset Type"
            fieldName="assetType"
            canBeNull
          />
        </form>
        <GridBase
          dataVar={this.getAssets()}
          columns={columns}
          sorting={sorting}
          multiSelect={multiSelect}
        />

        <DeleteModel
          deleteMutation={DeleteAssetMutation}
          refetchQueries={this.state.refetchQueries}
          id={this.state.delete.id}
          name={this.state.delete.name}
          onClose={this.handleDeleteClose}
        />

        <Modal
          onClose={this.closeModal}
          modalIsOpen={this.state.modalIsOpen}
          contentLabel={this.state.modalLabel}
        >
          <AssetUpdate asset={this.state.asset} />
        </Modal>
      </div>
    );
  }
}

function mapStateToProps({ auth }) {
  return { auth };
}
MetadataGrid = reduxForm({ form: 'Metadata' })(MetadataGrid);
MetadataGrid = connect(mapStateToProps)(MetadataGrid);
export default graphql(AssetAndChildAssetGridQuery, {
  name: 'AssetAndChildAssetGridQuery',
  props: ({
    ownProps,
    AssetAndChildAssetGridQuery: {
      loading, error, editableParentAndChildAssets, refetch,
    },
  }) => ({
    data: {
      assets: editableParentAndChildAssets,
      loading,
      error,
      refetch,
    },
  }),
})(MetadataGrid);
