import update from 'immutability-helper';
import handleMutation from './handleMutation';

const handleSubmitInstallerConfig = (props) => {
  handleMutation({
    mutate: props.mutation,
    variables: { installerConfig: props.values },
    refetchQueries: props.refetchQueries,
    onSuccess: props.onSuccess,
  });
};

export default (props) => {
  const { values } = props;
  console.log('submit', values);
  if (typeof values === 'object') {
    if (Array.isArray(values.formFields)) {
      const newFields = [];
      for (let i = 0; i < values.formFields.length; i++) {
        let newField = values.formFields[i];
        if (newField.installerConfigField && newField.installerConfigField.id) {
          newField = update(newField, {
            installerConfigField: { $set: newField.installerConfigField.id },
          });
        }
        newFields.push(newField);
      }
      props = update(props, {
        values: {
          formFields: { $set: newFields },
        },
      });
    }

    handleSubmitInstallerConfig(props);
  }
};
