import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { withApollo } from '@apollo/client/react/hoc';
import { connect } from 'react-redux';
import Send from '@material-ui/icons/Send';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import PSLoader from '../../PSLoader';
import handleMultiSendWelcomeEmails from './handleMultiSendWelcomeEmails';
import ConfirmDialog from '../../ConfirmDialog';

class MultiSend extends Component {
  constructor(props) {
    super(props);
    this.onSuccess = this.onSuccess.bind(this);
    this.onError = this.onError.bind(this);
    this.onFinally = this.onFinally.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.clickSendWelcomeEmail = this.clickSendWelcomeEmail.bind(this);
    this.submitSendWelcomeEmails = this.submitSendWelcomeEmails.bind(this);
    this.state = {
      showLoader: false,
      showConfirmDialog: false,
    };
  }

  clickSendWelcomeEmail() {
    this.setState({
      showConfirmDialog: true,
    });
  }

  submitSendWelcomeEmails() {
    console.log('submitSendWelcomeEmails');
    this.setState({ showLoader: true });
    handleMultiSendWelcomeEmails({
      ids: this.props.ids,
      onSuccess: this.onSuccess,
      onError: this.onError,
    });
  }

  onSuccess() {
    this.onFinally();
  }

  onError(error) {
    console.error(error);
    this.onFinally();
  }

  onFinally() {
    this.setState({ showLoader: false });
    this.props.onSubmit();
  }

  closeDialog() {
    this.setState({
      showConfirmDialog: false,
    });
  }

  render() {
    return (
      <div>
        <PSLoader showLoader={this.state.showLoader}>
          <div>
            {this.props.modelName === 'user' && (
            <div>
              <Card style={{ overflow: 'visible' }}>
                <CardHeader title="Send Welcome Emails to Users" />
                <CardContent>
                  <Button onClick={this.clickSendWelcomeEmail} className="next">
                    <Send />
                  </Button>

                  <ConfirmDialog
                    title="Send Emails"
                    open={this.state.showConfirmDialog}
                    message={`Send welcome emails to ${this.props.ids.length} users?`}
                    onConfirm={this.submitSendWelcomeEmails}
                    onClose={this.closeDialog}
                  />
                </CardContent>
              </Card>
            </div>
            )}
          </div>
        </PSLoader>
      </div>
    );
  }
}

MultiSend.defaultProps = {
  modelName: '',
  ids: [],
};

export default withApollo(connect(null)(MultiSend));
