import { gql } from '@apollo/client';
import { attributeAllFragment } from '../fragments';

const CreateAttributeMutation = gql`
  mutation createAttribute($attribute: AttributeInput!) {
    createAttribute(attribute: $attribute) {
      ...attributeAll
    }
  }
  ${attributeAllFragment}
`;

const UpdateAttributeMutation = gql`
  mutation updateAttribute($attribute: AttributeInput!) {
    updateAttribute(attribute: $attribute) {
      ...attributeAll
    }
  }
  ${attributeAllFragment}
`;

const DeleteAttributeMutation = gql`
  mutation deleteAttribute($id: ID!) {
    deleteAttribute(id: $id) {
      name
    }
  }
`;

export {
  DeleteAttributeMutation,
  UpdateAttributeMutation,
  CreateAttributeMutation,
};
