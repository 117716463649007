export default ({ cache, deletedId, cacheOptions }) => {
  cacheOptions.forEach((element) => {
    cache.modify({
      fields: {
        [element.cachedListName](existingArr, { readField }) {
          return existingArr.filter(
            (objRef) => deletedId !== readField('id', objRef),
          );
        },
      },
    });
  });
};
