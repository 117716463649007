import handleMutation from './handleMutation';

export default ({ mutation, values, handleSubmitAsset }) => {
  handleMutation({
    mutate: mutation,
    variables: { assetType: { name: values.assetType.assetTypeName } },
    refetchQueries: [],
    onSuccess: (response) => {
      values.assetType = response.data.createAssetType.id;
      console.log('handleAssetTypeSubmitSuccess', values);
      handleSubmitAsset();
    },
  });
};
