import React, { Component } from 'react';
import FixedActionButton from '../common/FixedActionButton';
import DataTypeGrid from './dataType/DataTypeGrid';
import Modal from '../common/Modal';
import PSCard from '../common/PSCard';
import DataTypeNew from './dataType/DataTypeNew';

class DataMain extends Component {
  constructor() {
    super();
    this.state = { modalIsOpen: false };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  modalOptions = [
    {
      onClick: () => {
        this.openModal('DataType');
      },
      title: 'Add DataType',
      color: 'blue',
      icon: 'add',
      name: 'dataType',
      size: '',
    },
  ];

  openModal(type) {
    this.setState({ modalIsOpen: true, modalType: type });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    return (
      <div>
        <PSCard cardTitle="Data Types">
          <DataTypeGrid />
        </PSCard>
        <div>
          <FixedActionButton options={this.modalOptions} />
        </div>
        <Modal
          onClose={this.closeModal}
          modalIsOpen={this.state.modalIsOpen}
          contentLabel={`Add ${this.state.modalType}`}
        >
          {(() => {
            switch (this.state.modalType) {
              case 'DataType':
              default:
                return <DataTypeNew />;
            }
          })()}
        </Modal>
      </div>
    );
  }
}

export default DataMain;
