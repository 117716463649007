import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import zxcvbn from 'zxcvbn';
import FormField from '../form/FormField';
import FormSubmitButton from '../form/FormSubmitButton';
import ClearButton from '../form/ClearButton';

const calculateByteLength = (str) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(str);
  return data.length;
};

class ResetPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordStrength: null, passwordText: null,
    };
  }

  calculatePasswordStrength = (password) => {
    const result = zxcvbn(password);
    let strengthText = '';
    if (result.score === 0) {
      strengthText = 'Very Weak';
    } else if (result.score === 1) {
      strengthText = 'Weak';
    } else if (result.score === 2) {
      strengthText = 'Fair';
    } else if (result.score === 3) {
      strengthText = 'Good';
    } else if (result.score === 4) {
      strengthText = 'Strong';
    }
    this.setState({ passwordStrength: result.score, passwordText: strengthText });
  };

  getStrengthMeterStyle = () => {
    const { passwordStrength } = this.state;

    const strengthStyles = [
      { width: '20%', backgroundColor: '#f53d3d' },
      { width: '40%', backgroundColor: '#ffb914' },
      { width: '60%', backgroundColor: '#ffd700' },
      { width: '80%', backgroundColor: '#2196f3' },
      { width: '100%', backgroundColor: '#69bb7b' },
    ];
    return strengthStyles[passwordStrength];
  };

  render() {
    const {
      handleSubmit, pristine, reset, submitting,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div key="password">
          <Field
            component={FormField}
            type="password"
            label="Password"
            name="password"
            onChange={(e) => this.calculatePasswordStrength(e.target.value)}
          />
          <div className="password-strength-meter" style={this.getStrengthMeterStyle()}>&nbsp;</div>
          <div className="password-strength-text" style={{ fontWeight: 'bold' }}>
            {' '}
            Strength:
            {this.state.passwordText}
          </div>
        </div>

        <Field
          key="confirmPassword"
          component={FormField}
          type="password"
          label="Confirm Password"
          name="confirmPassword"
        />
        <br />
        <div>
          <FormSubmitButton pristine={pristine} submitting={submitting} />
          <ClearButton
            pristine={pristine}
            submitting={submitting}
            reset={reset}
          />
        </div>
      </form>
    );
  }
}

ResetPasswordForm.defaultProps = {
  initialValues: null,
};

const validate = (values) => {
  const errors = {};
  if (!values.password) {
    errors.password = 'Cannot be blank';
  } else if (values.password.trim().length < 12) {
    errors.password = 'Password must be at least 12 characters';
  } else if (values.password.trim().length > 72 || calculateByteLength(values.password.trim()) > 72) {
    errors.password = 'Password must be less than 72 characters';
  }

  if (!values.confirmPassword) {
    errors.confirmPassword = 'Cannot be blank';
  } else if (values.confirmPassword !== values.password) {
    errors.confirmPassword = 'Passwords do not match';
  }
  return errors;
};

ResetPasswordForm = reduxForm({ validate, form: 'ResetPassword' })(
  ResetPasswordForm,
);

export default ResetPasswordForm;
