import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { yellow } from '@material-ui/core/colors';

import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import shellLogo from '../../assets/icon/shell-icon-512.png';
import poseidonLogo from '../../assets/icon/poseidon-icon-512.png';
import Config from './Config';
import MoreMenu from './MoreMenu';
import {
  APP_NAME, HEADER_CONTRAST, HEADER_TITLE, WHITELABEL,
} from '../../config';

const styles = (theme) => ({
  appBar: {
    position: 'absolute',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${Config.drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: Config.drawerWidth,
  },
  menuButton: {
    marginLeft: 0,
    marginRight: 10,
  },
  hide: {
    display: 'none',
  },
  root: {
    color: HEADER_CONTRAST,
  },
});

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(yellow[500]),
    backgroundColor: yellow[500],
    '&:hover': {
      backgroundColor: yellow[700],
    },
  },
}))(Button);

class Header extends Component {
  handleDrawerOpen = () => {
    this.props.onDrawerOpen();
  };

  render() {
    let headerTypography;
    let logoFile;
    switch (WHITELABEL) {
      case 'shell':
        logoFile = shellLogo;
        headerTypography = 'textPrimary';
        break;
      default:
        logoFile = poseidonLogo;
        headerTypography = 'inherit';
        break;
    }
    const {
      auth, mfaVerified, open, classes, themeToggle, toggleThemeName,
    } = this.props;
    const fullyAuth = auth && mfaVerified;
    let buttons = [];
    if (fullyAuth) {
      buttons = [<MoreMenu key="name" name={auth.fullName} themeToggle={themeToggle} toggleThemeName={toggleThemeName} />];
    } else {
      buttons = [
        <ColorButton
          key="installerLog"
          component={Link}
          to="/installerLog"
          color="secondary"
        >
          Installer Logs
        </ColorButton>,
      ];
      if (!auth) {
        buttons.push(
          <Button key="login" component={Link} to="/login" color="secondary">
            Login
          </Button>,
        );
      }
    }
    return (
      <AppBar
        className={classNames(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        position="absolute"
      >
        <Toolbar>
          <IconButton
            color="default"
            aria-label="Menu"
            onClick={this.handleDrawerOpen}
            className={classNames(
              classes.menuButton,
              open && classes.hide,
              !fullyAuth && classes.hide,
            )}
            classes={{ root: this.props.classes.root }}
          >
            <MenuIcon />
          </IconButton>
          <IconButton component={Link} to="/homepage">
            <img className="navbar-logo" src={logoFile} alt={APP_NAME} />
          </IconButton>
          <Typography
            component={Link}
            to="/homepage"
            type="h6"
            variant="h5"
            color={headerTypography}
            style={{ flex: 1 }}
          >
            { HEADER_TITLE }
          </Typography>
          {buttons}
        </Toolbar>
      </AppBar>
    );
  }
}

function mapStateToProps({ auth, mfaVerified }) {
  return { auth, mfaVerified };
}

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(Header),
);
