import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';

import AttributeTypeForm from './AttributeTypeForm';
import FormBase from '../../../form/FormBase';
import { CreateAttributeTypeMutation } from '../../../../graphql/mutations/AttributeTypeMutations';
import { AttributeTypeGridQuery } from '../../../../graphql/queries/AttributeTypeQueries';
import handleMutation from '../../../../graphql/functions/handleMutation';

class AttributeTypeNew extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    if (typeof values === 'object' && values.fields) {
      for (let i = 0; i < values.fields.length; i++) {
        if (values.fields[i].rule && values.fields[i].rule.id) {
          values.fields[i].rule = values.fields[i].rule.id;
        }
      }
    }
    handleMutation({
      mutate: this.props.createAttributeTypeMutation,
      variables: { attributeType: values },
      refetchQueries: [{ query: AttributeTypeGridQuery }],
      onSuccess: this.props.onSuccess,
    });
  }

  render() {
    return (
      <FormBase header="Add Attribute Type">
        <AttributeTypeForm onSubmit={this.handleSubmit} />
      </FormBase>
    );
  }
}

export default compose(
  graphql(CreateAttributeTypeMutation, { name: 'createAttributeTypeMutation' }),
)(AttributeTypeNew);
