import React, { Component } from 'react';
import PSCard from '../common/PSCard';
import ServicePlanGrid from './ServicePlanGrid';

class ServicePlanMain extends Component {
  constructor() {
    super();
    this.state = { modalIsOpen: false };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    return (
      <div>
        <PSCard cardTitle="Service Plans">
          <ServicePlanGrid />
        </PSCard>
      </div>
    );
  }
}

export default ServicePlanMain;
