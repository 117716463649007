import React, { Component } from 'react';
import ReactTable from 'react-table';
import update from 'immutability-helper';
import { connect } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CloudDownload from '@material-ui/icons/CloudDownload';
import { CSVLink } from 'react-csv';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import isTimestamp from './isTimestamp';
import MultiTools from './multi/MultiTools';
import containsFilter from './containsFilter';

const checkboxStyle = { height: '20px' };

function HeaderComponent(grid) {
  return (
    <Checkbox
      style={checkboxStyle}
      checked={grid.state.selectAll === 1}
      onClick={() => grid.toggleSelectAll()}
      indeterminate={grid.state.selectAll === 2}
      color="primary"
      disableRipple
    />
  );
}

function CellComponent(grid, original) {
  return (
    <Checkbox
      style={checkboxStyle}
      onClick={() => grid.toggleRow(original.id)}
      checked={grid.state.selectAll === 1 || grid.isSelected(original.id) === true}
      color="primary"
      disableRipple
    />
  );
}

class GridBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectAll: 0,
      selected: [],
      dataToDownload: [],
    };
    this.toggleRow = this.toggleRow.bind(this);
    this.toggleSelectAll = this.toggleSelectAll.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.handleCloseMulti = this.handleCloseMulti.bind(this);
    this.resetSelected = this.resetSelected.bind(this);
    this.download = this.download.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
  }

  handleCloseMulti() {
    this.props.multiSelect.onSubmit();
    this.resetSelected();
  }

  resetSelected() {
    this.setState({
      selectAll: 0,
      selected: [],
    });
  }

  toggleRow = (rowId) => {
    // start off with the existing state
    this.setState((prevState) => {
      let { selectAll } = this.state;
      let selected = [...prevState.selected];
      const rowIdIndex = selected.indexOf(rowId);
      // check to see if the rowId exists
      if (rowIdIndex >= 0) {
        // it does exist so we will remove it
        selected = [
          ...selected.slice(0, rowIdIndex),
          ...selected.slice(rowIdIndex + 1),
        ];
        if (prevState.selectAll === 1) {
          selectAll = 2; // if we are select all, but we uncheck row, set state to indeterminate
        }
      } else {
        // it does not exist so add it
        selected.push(rowId);
      }
      // update the state
      return { selectAll, selected };
    });
  };

  toggleSelectAll = () => {
    this.setState((prevState) => {
      const selectAll = prevState.selectAll === 1 ? 0 : 1;
      let selected = [];
      if (selectAll) {
        selected = this.getSelectAllRecords();
      }
      return { selectAll, selected };
    });
  };

  isSelected = (rowId) => {
    const rowIdIndex = this.state.selected.indexOf(rowId);
    // check to see if the rowId exists
    if (rowIdIndex >= 0) {
      return true;
    }
    return false;
  };

  // handle selection when filter is changed
  onFilterChange = () => {
    if (this.showMultiSelect()) {
      let newSelection = [];
      // If "Select All" is checked, set selected to all filtered rows
      if (this.state.selectAll === 1) {
        newSelection = this.getSelectAllRecords();
      } else {
        // only include current selection ids that in our filtered list
        // get all filteredIds
        const filteredIds = this.table.getResolvedState().sortedData.map((item) => item._original.id);
        // filter selected list to only those in filteredIds
        newSelection = this.state.selected.filter((selectedId) => filteredIds.indexOf(selectedId) > -1);
      }
      this.setState({ selected: newSelection });
    }
  };

  getSelectAllRecords = () => {
    const selected = [];
    // we need to get at the internals of ReactTable
    // the 'sortedData' property contains the currently accessible records based on the filter and sort
    const currentRecords = this.table.getResolvedState().sortedData;
    // we just push all the IDs onto the selected array
    currentRecords.forEach((item) => {
      selected.push(item._original.id);
    });
    return selected;
  };

  checkBoxColumn = {
    id: 'checkbox',
    accessor: '',
    Header: (x) => HeaderComponent(this),
    Cell: ({ original }) => CellComponent(this, original),
    sortable: false,
    width: 45,
    resizable: false,
  };

  download(event) {
    const currentRecords = this.table.getResolvedState().sortedData;
    const dataToDownload = [];
    const { columns } = this.props;
    for (let index = 0; index < currentRecords.length; index++) {
      const recordToDownload = {};
      for (let colIndex = 0; colIndex < columns.length; colIndex++) {
        let val;
        if (typeof columns[colIndex].accessor !== 'string') {
          val = currentRecords[index][columns[colIndex].id];
        } else {
          val = currentRecords[index][columns[colIndex].accessor];
        }
        // check for timestamps
        if (isTimestamp(val)) {
          // convert to 24 hr utc
          val = new Date(val);
          val = moment(val).utc().format('MM/DD/YYYY HH:mm');
        }
        recordToDownload[columns[colIndex].Header] = val;
      }
      dataToDownload.push(recordToDownload);
    }
    this.setState({ dataToDownload }, () => {
      // click the CSVLink component to trigger the CSV download
      this.csvLink.link.click();
    });
  }

  showMultiSelect = () => this.props.auth && this.props.auth.isSuperAdmin && this.props.multiSelect !== null;

  render() {
    const {
      dataVar, columns, sorting, subComponent,
    } = this.props;
    let cols;
    if (this.showMultiSelect()) {
      // prepend checkbox column to columns
      cols = update(columns, { $unshift: [this.checkBoxColumn] });
    } else {
      cols = columns;
    }
    let table = null;
    if (subComponent) {
      table = (
        <ReactTable
          ref={(r) => (this.table = r)}
          data={dataVar}
          columns={cols}
          minRows={1}
          defaultPageSize={this.props.defaultPageSize}
          className="-striped -highlight"
          defaultSorted={sorting}
          SubComponent={subComponent}
          defaultFilterMethod={this.props.filterMethod}
          onFilteredChange={this.onFilterChange}
        />
      );
    } else {
      table = (
        <ReactTable
          ref={(r) => (this.table = r)}
          data={dataVar}
          columns={cols}
          minRows={1}
          defaultPageSize={this.props.defaultPageSize}
          className="-striped -highlight"
          defaultSorted={sorting}
          defaultFilterMethod={this.props.filterMethod}
          onFilteredChange={this.onFilterChange}
        />
      );
    }
    return (
      <div className="row">
        <Grid justifyContent="space-between" container spacing={10}>
          <Grid item>
            {this.showMultiSelect() && (
              <MultiTools
                fields={this.props.multiSelect.fields}
                showConvertLegacySerialForDevices={this.props.multiSelect.showConvertLegacySerialForDevices}
                modelName={this.props.multiSelect.modelName}
                ids={this.state.selected}
                handleClose={this.handleCloseMulti}
                disabled={this.state.selected.length === 0}
                canDelete={this.props.multiSelect.canDelete}
                canEdit={this.props.multiSelect.canEdit}
                canSend={this.props.multiSelect.canSend}
              />
            )}
          </Grid>
          <Grid item>
            <IconButton color="primary" onClick={this.download}>
              <CloudDownload />
            </IconButton>
          </Grid>
        </Grid>

        <div>
          <CSVLink
            data={this.state.dataToDownload}
            filename="psladmindata.csv"
            className="hidden"
            ref={(r) => (this.csvLink = r)}
            target="_blank"
          />
        </div>
        <div className="col s12">{table}</div>
      </div>
    );
  }
}

GridBase.defaultProps = {
  dataVar: [],
  columns: [],
  sorting: [],
  defaultPageSize: 10,
  multiSelect: null,
  filterMethod: (filter, row) => containsFilter(filter, row),
};

function mapStateToProps({ auth }) {
  return { auth };
}
export default connect(mapStateToProps)(GridBase);
