import { gql } from '@apollo/client';

const CreateAp2200FirmwareMutation = gql`
  mutation createAp2200Firmware($ap2200Firmware: Ap2200FirmwareInput!) {
    createAp2200Firmware(ap2200Firmware: $ap2200Firmware) {
      version
      id
    }
  }
`;

const UpdateAp2200FirmwareMutation = gql`
  mutation updateAp2200Firmware($ap2200Firmware: Ap2200FirmwareInput!) {
    updateAp2200Firmware(ap2200Firmware: $ap2200Firmware) {
      version
      id
    }
  }
`;

const DeleteAp2200FirmwareMutation = gql`
  mutation deleteAp2200Firmware($id: ID!) {
    deleteAp2200Firmware(id: $id) {
      version
    }
  }
`;

export {
  CreateAp2200FirmwareMutation,
  UpdateAp2200FirmwareMutation,
  DeleteAp2200FirmwareMutation,
};
