import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import ReactTable from 'react-table';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import ContextMenuWithTrigger from '../common/ContextMenuWithTrigger';
import { ChildAssetsQuery } from '../../graphql/queries/AssetQueries';

class ChildAssetGrid extends Component {
  childColumns = [
    {
      Header: 'Asset Component',
      accessor: 'name',
      minWidth: 100,
      filterable: false,
    },
    {
      Header: 'Company Id',
      id: 'companyId',
      accessor: (d) => (d.parent && d.parent.location && d.parent.location.company
        ? d.parent.location.company.id
        : null),
      show: false,
    },
    {
      Header: 'ID',
      accessor: 'id',
      maxWidth: 90,
      show: false,
    },
    {
      Header: 'Parent Id',
      id: 'parentId',
      accessor: (d) => d.parent.id,
      show: false,
    },
    {
      Header: 'Type',
      id: 'assetType',
      accessor: (d) => (d.assetType ? d.assetType.name : ''),
      minWidth: 100,
      filterable: false,
    },
    {
      Header: '',
      maxWidth: 23,
      resizable: false,
      sortable: false,
      Cell: ({ row }) => ContextMenuWithTrigger({
        handleClick: this.props.handleClick,
        row,
      }),
    },
  ];

  addSubAssetButton() {
    return (
      <Button
        className="right"
        onClick={() => {
          this.props.handleAddSubAssetClick(this.props.parent.original);
        }}
      >
        Add Component
        {' '}
        <Add />
      </Button>
    );
  }

  render() {
    const {
      ChildAssetsQuery: { loading, error, childAssets },
    } = this.props;
    if (loading) {
      return <p>Loading...</p>;
    } if (error) {
      return <p>Error loading asset components!</p>;
    }
    if (!childAssets) {
      return (
        <div style={{ padding: '20px' }}>
          <em>Asset Components: 0</em>
          {this.addSubAssetButton()}
          <br />
          <br />
        </div>
      );
    }
    console.log(childAssets);
    return (
      <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
        <ReactTable
          showPagination={false}
          showPageSizeOptions={false}
          minRows={1}
          data={childAssets}
          columns={this.childColumns}
          className="-striped -highlight"
          defaultSorted={[{ id: 'name', asc: true }]}
        />
        {this.addSubAssetButton()}
      </div>
    );
  }
}
export default graphql(ChildAssetsQuery, {
  name: 'ChildAssetsQuery',
  options: (props) => ({ variables: { parentId: props.parent.original.id } }),
})(ChildAssetGrid);
