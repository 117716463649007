import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import Select from 'react-select';
import { DeviceGroupsByDeviceTypeQuery, DeviceDeviceGroupsQuery } from '../../graphql/queries/DeviceGroupQueries';

class DeviceGroupSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialSelected: null,
      defaultSelected: [],
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deviceTypeId !== this.props.deviceTypeId) {
      if (this.state.defaultSelected.length > 0) {
        // had initial selected, but deviceType has changed, clear defaultSelected
        this.setState({ defaultSelected: [] });
        this.props.onChange({ idsToAdd: [], idsToRemove: [], currentIdList: [] });
      }
    } else if (this.props.DeviceDeviceGroups
      && this.props.DeviceDeviceGroups.deviceDeviceGroups
      && this.props.DeviceDeviceGroups.deviceDeviceGroups.length > 0
      && this.state.initialSelected === null) {
      const defaultSelected = this.props.DeviceDeviceGroups.deviceDeviceGroups
        .map((d) => ({ value: d.id, label: d.name }));
      const initialSelected = this.props.DeviceDeviceGroups.deviceDeviceGroups.map((d) => d.id);
      this.setState({ defaultSelected, initialSelected });
      // add the initial selected to the currentIdList in the form if this is a duplication
      this.props.onChange({ idsToAdd: [], idsToRemove: [], currentIdList: initialSelected });
    }
  }

  handleChange(currentList) {
    const idsToAdd = [];
    const idsToRemove = [];
    let currentIds = [];
    if (currentList && currentList.length > 0) {
      currentIds = currentList.map((c) => c.value);
      currentIds.forEach((id) => {
        // check for ids to add
        if (this.state.initialSelected && this.state.initialSelected.length > 0) {
          // we have initialSelected but this id was not in that list
          // if id is not in the list, add it
          if (this.state.initialSelected.indexOf(id) === -1) {
            idsToAdd.push(id);
          }
        } else {
          // no initialSelected was provided
          idsToAdd.push(id);
        }
      });
    }
    // check for any ids to remove
    if (this.state.initialSelected && this.state.initialSelected.length > 0) {
      this.state.initialSelected.forEach((id) => {
        // if id is not in currentIds, remove it
        if (currentIds.indexOf(id) === -1) {
          idsToRemove.push(id);
        }
      });
    }
    this.props.onChange({ idsToAdd, idsToRemove, currentIdList: currentIds });
  }

  render() {
    let loading;
    let error;
    if (this.props.DeviceDeviceGroups) {
      loading = this.props.DeviceDeviceGroups.loading;
      error = this.props.DeviceDeviceGroups.error;
    }
    let loadingAll;
    let errorAll;
    let allDeviceGroups = [];
    if (this.props.AllDeviceGroups) {
      loadingAll = this.props.AllDeviceGroups.loading;
      errorAll = this.props.AllDeviceGroups.error;
      allDeviceGroups = this.props.AllDeviceGroups.deviceGroups;
    }

    if (loading || loadingAll) {
      return <p>Loading device groups...</p>;
    } if (error) {
      return <p>Error loading device groups!</p>;
    } if (errorAll) {
      return <p>Error loading all device groups!</p>;
    }
    const options = allDeviceGroups.map((a) => ({ value: a.id, label: a.name }));
    return (
      <div>
        <label>Device Groups:</label>
        <Select
          options={options}
          isMulti
          defaultValue={this.state.defaultSelected}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

export default compose(
  graphql(DeviceGroupsByDeviceTypeQuery, {
    name: 'AllDeviceGroups',
    skip: (ownProps) => !ownProps.deviceTypeId,
    options: (props) => ({
      variables: { deviceTypeId: props.deviceTypeId },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    }),
  }),
  graphql(DeviceDeviceGroupsQuery, {
    name: 'DeviceDeviceGroups',
    skip: (ownProps) => !ownProps.deviceId,
    options: (props) => ({
      variables: { deviceId: props.deviceId },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    }),
  }),
)(DeviceGroupSelection);
